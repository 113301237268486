import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Autocomplete,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  FormGroup,
} from "@mui/material";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { Country, State, City } from "country-state-city";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../Views/AxiosInstance";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import InputText from "../InputFields/InputText";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "./style.css";
import { Team } from "./Index";

const Visit = ({ open, setOpen, data, ContractId, contractData ,CompanyId }) => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const CustomerId = data?.CustomerId;

  var companyId = localStorage.getItem("CompanyId");
  console.log(CompanyId,"CompanyId")
  const formik = useFormik({
    initialValues: {
      CompanyId: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      try {
        values["CompanyId"] = CompanyId;
        values["ContractId"] = ContractId;
        const response = await AxiosInstance.post(`${baseUrl}/visits`, values);
        if (response.data.statusCode === 200) {
          toast.success(response.data.message);
          setOpen(false);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while submitting the form.");
      }
    },
  });

  useEffect(() => {
    if (open?.propertyData) {
      formik.setValues(open?.propertyData);
    } else {
      formik.resetForm();
    }
  }, [open?.propertyData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "Country") {
      formik.setFieldValue(name, value.name);
    } else {
      formik.setFieldValue(name, type === "checkbox" ? checked : value);
    }
  };

  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [checkedState, setCheckedState] = useState({});
  // const [selectedTeams, setSelectedTeams] = useState([]);
  // const [ids, setIds] = useState([]);
  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };

  // const handleOutsideClick = () => {
  //   toggleDropdown();
  // };
  // const [formData, setFormData] = useState({
  //   FullName: "",
  //   Email: "",
  //   MobileNumber: "",
  // });

  // const handleUSerChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // const toggleModal = () => {
  //   setIsModalOpen(!isModalOpen);
  // };

  // const handleSubmit = async () => {
  //   try {
  //     const companyId = localStorage.getItem("CompanyId");

  //     if (!companyId) {
  //       console.error("CompanyId is not found in localStorage");
  //       return;
  //     }

  //     const response = await AxiosInstance.post(`/contract/assign_team`, {
  //       AssignPersonId: formData.AssignPersonId,
  //       FullName: formData.FullName,
  //       Email: formData.Email,
  //       PhoneNumber: formData.MobileNumber,
  //       CompanyId: companyId,
  //     });
  //     if (response.data.statusCode === 201) {
  //       toggleModal();
  //       fetchData();
  //     }
  //   } catch (error) {
  //     console.error("Error posting data:", error);
  //   }
  // };

  // const [visitData, setVisitData] = useState([]);
  // const [loading, setLoading] = useState(true);

  // const fetchData = async () => {
  //   try {
  //     const response = await AxiosInstance.get(
  //       `/visits/${localStorage.getItem("CompanyId")}`
  //     );
  //     if (response.status === 200) {
  //       setVisitData(response.data.data);
  //     }
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // if (loading) {
  //   return <p>Loading...</p>;
  // }

  // const handleRemoveTeam = (teamName) => {
  //   setSelectedTeams(selectedTeams.filter((name) => name !== teamName));
  //   setCheckedState((prevState) => ({
  //     ...prevState,
  //     [teamName]: false,
  //   }));
  // };

  // const handleTeamSelect = (event, team) => {
  //   if (event.target.checked) {
  //     setSelectedTeams([...selectedTeams, team?.FullName]);
  //     setCheckedState((prevState) => ({
  //       ...prevState,
  //       [team?.FullName]: true,
  //     }));
  //     setIds(() => [...ids, team.AssignPersonId]);
  //   } else {
  //     setSelectedTeams(selectedTeams.filter((name) => name !== team?.FullName));
  //     setCheckedState((prevState) => ({
  //       ...prevState,
  //       [team?.FullName]: false,
  //     }));
  //     setIds(() => ids.filter((name) => name !== team?.AssignPersonId));
  //   }
  // };
  const [isCalendarVisible, setIsCalendarVisible] = React.useState(true);

  const [isAnyTime, setIsAnyTime] = useState(false);

  return (
    <Dialog
      fullWidth
      open={open?.isOpen}
      onClose={() => setOpen({ isOpen: false, propertyData: null })}
      className="client"
    >
      <DialogTitle>
        <div className=" d-flex justify-content-start align-items-center">
          <h4
            className="text-blue-color text-property"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "42px",
              margin: "0 10px",
            }}
          >
            Scheduled a Visit
          </h4>
        </div>
      </DialogTitle>
      <Divider
        style={{ height: "1px", backgroundColor: "rgba(42, 79, 97, 0.8)" }}
      />
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-2 d-flex">
            <div className="col-6" style={{ marginRight: "5px" }}>
              <InputText
                value={formik.values?.ItemName}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.ItemName && Boolean(formik.errors.ItemName)
                }
                helperText={formik.touched.ItemName && formik.errors.ItemName}
                name="ItemName"
                placeholder="Enter Item Name here..."
                label="ItemName"
                type="text"
                className="text-blue-color w-100 mb-2 mx-0"
                fieldHeight="56px"
              />
              <TextField
                className="Note-details text-blue-color my-2"
                id="Note"
                name="Note"
                label="Note details"
                value={formik.values?.Note}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                // value={Notes}
                // onChange={handleNoteChange}
                defaultValue=""
                placeholder="Enter Notes here..."
                type="text"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#063164",
                    },
                    "&:hover fieldset": {
                      borderColor: "#063164",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#063164",
                    },
                    "& input": {
                      height: "70px",
                      alignItems: "start",
                    },
                  },
                }}
                InputProps={{
                  readOnly: false,
                }}
                style={{ width: "100%", paddingTop: "0px" }}
                InputLabelProps={{
                  style: { fontSize: "15px" },
                }}
              />
            </div>
            <div className="col-6">
              <p
                className="mb-0 Sub-title text-blue-color"
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Contract details
              </p>
              <hr />
              <Table borderless>
                <tbody className="Contract-table-detail">
                  <tr>
                    <td>Contract#</td>
                    <td>{contractData?.ContractNumber}</td>
                  </tr>
                  <tr>
                    <td>Customer</td>
                    <td>
                      {contractData?.customer?.FirstName || ""}{" "}
                      {contractData?.customer?.LastName || ""}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{contractData?.customer?.PhoneNumber}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>
                      {contractData?.location?.Address || ""}{" "}
                      {contractData?.location?.City || ""}{" "}
                      {contractData?.location?.State || ""}{" "}
                      {contractData?.location?.Country || ""}{" "}
                      {contractData?.location?.Zip || ""}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <hr />
          <div className="d-flex">
            <div>
              {/* Conditionally hide Date and Time pickers but keep their width */}
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Start Date"
                      value={formik.values?.StartDate}
                      onChange={(value) =>
                        formik.setFieldValue("StartDate", value ? value : null)
                      }
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            formik.touched.StartDate &&
                            Boolean(formik.errors.StartDate)
                          }
                          helperText={
                            formik.touched.StartDate && formik.errors.StartDate
                          }
                        />
                      )}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: "8px",
                        },
                        "& .MuiInputBase-input": {
                          color: "#063164",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#063164",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#063164",
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="End Date"
                      value={formik.values?.EndDate}
                      onChange={(value) =>
                        formik.setFieldValue("EndDate", value ? value : null)
                      }
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            formik.touched.EndDate &&
                            Boolean(formik.errors.EndDate)
                          }
                          helperText={
                            formik.touched.EndDate && formik.errors.EndDate
                          }
                        />
                      )}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: "8px",
                        },
                        "& .MuiInputBase-input": {
                          color: "#063164",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#063164",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#063164",
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                <div style={{ visibility: isAnyTime ? "hidden" : "visible" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        label="Start Time"
                        value={formik.values?.StartTime}
                        onChange={(value) =>
                          formik.setFieldValue(
                            "StartTime",
                            value ? value : null
                          )
                        }
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              formik.touched.StartTime &&
                              Boolean(formik.errors.StartTime)
                            }
                            helperText={
                              formik.touched.StartTime &&
                              formik.errors.StartTime
                            }
                          />
                        )}
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "8px",
                          },
                          "& .MuiInputBase-input": {
                            color: "#063164",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#063164",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#063164",
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        label="End Time"
                        {...formik.getFieldProps("EndTime")}
                        value={
                          formik.values.EndTime &&
                          dayjs(formik.values.EndTime).isValid()
                            ? dayjs(formik.values.EndTime)
                            : null
                        }
                        onChange={(value) =>
                          formik.setFieldValue(
                            "EndTime",
                            value && dayjs(value).isValid()
                              ? value.toISOString()
                              : null
                          )
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.EndTime &&
                          Boolean(formik.errors.EndTime)
                        }
                        helperText={
                          formik.touched.EndTime && formik.errors.EndTime
                        }
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "8px",
                          },
                          "& .MuiInputBase-input": {
                            color: "#063164",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#063164",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#063164",
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="checkbox-group my-2">
                <input
                  type="checkbox"
                  id="any-time"
                  name="any-time"
                  checked={isAnyTime}
                  onChange={(e) => setIsAnyTime(e.target.checked)}
                />
                <label htmlFor="any-time">Any Time</label>
              </div>
            </div>

            <div className="user-assignment mx-5">
              <h2>Visit Schedule</h2>
              <p>No users are currently assigned</p>
              <div className="col-12 ">
                <Team isAddTeam={false} />
              </div>
              {/* <div className="checkbox-group my-5">
                <Input type="checkbox" id="any-time" name="any-time" />
                <label for="any-time">Any Time</label>
              </div> */}
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          className="cancelButton"
          style={{
            backgroundColor: "#fff",
            border: "1px solid rgba(6, 49, 100, 0.8)",
            color: "rgba(6, 49, 100, 1)",
          }}
          onClick={() => setOpen({ isOpen: false, propertyData: null })}
        >
          Cancel
        </Button>
        <Button
          className="bg-button-blue-color createButton"
          style={{ color: "#fff" }}
          onClick={formik.handleSubmit}
        >
          Save Time Entry
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Visit;
