import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Auth from "./components/Layouts/Auth";
import SuperAdmin from "./components/Layouts/SuperAdmin";
import Admin from "./components/Layouts/Admin";
import Client from "./components/Layouts/Client";
import StaffMember from "./components/Layouts/StaffMember";
import Plan from "./Views/Admin/Plan/Index";
import PlanPurchase from "./Views/Admin/Plan/PlanPurchase";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/auth/*" element={<Auth />} />
      <Route path="/superadmin/*" element={<SuperAdmin />} />
      <Route path="/customers/*" element={<Client />} />
      <Route path="/staff-member/*" element={<StaffMember />} />
      <Route path="/:companyName/*" element={<Admin />} />
      <Route path="/plan" element={<Plan />} />
      <Route path="/plan-purchase" element={<PlanPurchase />} />
      <Route path="/" element={<Navigate to="/auth/login" replace />} />
    </Routes>
  </BrowserRouter>
);

console.error = () => {};
console.warn = () => {};

