import React from "react";
import ContractMail from "../ContractMail";
import CustomerModal from "../../Quotes/CustomerModal";
import { FormGroup } from "@mui/material";
import {
  Button,
  Card,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
import { ColorRing } from "react-loader-spinner";
import {
  CalendarJOB,
  InternalNotes,
  OneOffContract,
  RecurringContract,
  Team,
} from "../../../../components/Contract Component/Index";
import GetProductsForContract from "../../../../components/Products/GetProductsForContract";
import InputText from "../../../../components/InputFields/InputText";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Previous from "../../../../assets/image/icons/Previous.png";
import GetProducts from "../../../../components/Products/GetProducts";

const AddContract = ({
  lineItems,
  isProperty,
  setIsProperty,
  setIsCustomer,
  isCustomer,
  setPropertyData,
  setCustomersData,
  mail,
  setMail,
  customersData,
  propertyData,
  formik,
  handleSaveQuote,
  toggle,
  dropdownOpen,
  setLoading,
  loading,
  Total,
  taxAmount,
  showTax,
  setShowTax,
  showDiscount,
  setShowDiscount,
  discountAmount,
  deleteLineItem,
  subTotal,
  addLineItem,
  showCosts,
  setShowCosts,
  setMenuIsOpen,
  menuIsOpen,
  handleSelectChange,
  setLineItems,
  activeTab,
  setActiveTab,
  handleCahngeIds,
  selectedTeams,
  setSelectedTeams,
  isCalendarVisible,
  setIsCalendarVisible,
  isNumChange,
  setIsNumChange,
  handleContractNumberChange,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <Button
        style={{
          color: "#fff",
          marginRight: "10px",
          width: "50px",
          height: "40px",
          padding: "0px 0px",
          borderRadius: "4px",
        }}
        onClick={() => {
          navigate(-1);
        }}
        className="text-capitalize bg-button-blue-color back-button"
      >
        <img src={Previous} style={{ width: "20px", height: "20px" }} />
      </Button>
      <div className="justify-content-center align-items-center mb-3 mt-3 quotes job">
        <Card
          className="my-2 col-12 p-4 border-blue-color"
          style={{ borderRadius: "20px" }}
        >
          <CardTitle
            className="text-blue-color contract-for"
            style={{
              fontSize: "27px",
              display: "flex",
              alignItems: "center",
              fontWeight: 600,
            }}
          >
            Contract for
            <span
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIsCustomer(true);
              }}
            >
              <u className="text-blue-color" style={{ paddingLeft: "5px" }}>
                {customersData?.FirstName
                  ? `${customersData?.FirstName} ${customersData?.LastName}`
                  : "Customer Name"}
              </u>
              {!customersData?.FirstName && (
              <Button
                className="mx-3 bg-button-blue-color"
                style={{
                  height: "20px",
                  width: "30px",
                  minWidth: "30px",
                  padding: "0",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                +
              </Button>
              )}
            </span>
          </CardTitle>
          <div className="d-lg-flex d-md-block my-3 gap-4">
            <div className="col-lg-6 col-md-12 col-sm-12 d-block">
              {" "}
              <div>
                <Label
                  className="text-blue-color"
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                  for="exampleEmail"
                >
                  Contract title
                </Label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <InputText
                    value={formik.values?.Title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="Title"
                    id="exampleEmail"
                    placeholder="Enter Title here..."
                    label="Title"
                    type="Title"
                    className="text-blue-color w-100"
                    fieldHeight="56px"
                  />

                  <InputText
                    value={formik.values?.Description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="Description"
                    id="title"
                    placeholder="Enter Description here..."
                    label="Description"
                    type="text"
                    className="text-blue-color w-100"
                    fieldHeight="56px"
                  />
                </div>
              </div>
              {customersData?.FirstName && propertyData?.City && (
                <div
                  className="d-flex mt-5 gap-3"
                  style={{ color: "rgba(6, 49, 100, 1)" }}
                >
                  <div className="col-6 text-left ">
                    <h6>
                      <b>Property address</b>
                    </h6>
                    <p>
                      {propertyData?.Address || "-"} <br />
                      {propertyData?.City || "-"}, {propertyData?.State || "-"},{" "}
                      {propertyData?.Zip || "-"}
                      <br />
                      {propertyData?.Country || "-"} <br />
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setIsCustomer(true);
                        }}
                        style={{ color: "green", cursor: "pointer" }}
                      >
                        {" "}
                        Change
                      </a>
                    </p>
                  </div>
                  <div className="col-6 text-left">
                    <h6>Contact details</h6>
                    <p>
                      {customersData?.PhoneNumber || "-"}
                      <br />
                      {customersData?.EmailAddress || "-"}
                    </p>
                  </div>
                </div>
              )}
            </div>
            {/* <div className="col-6 d-flex order-2 changesinput">
              <div className="col-6 my-4 mx-3 text-left">
                <h5
                  className="text-blue-color"
                  style={{
                    fontWeight: 600,
                    marginBlock: 0,
                    fontSize: "15px",
                  }}
                >
                  Contract details
                </h5>
                {!isNumChange ? (
                  <p style={{ fontSize: "13px" }} className="text-blue-color">
                    Contract number #{formik.values.ContractNumber}
                  </p>
                ) : (
                  <input
                    id="ContractNumber"
                    name="ContractNumber"
                    type="text"
                    value={formik.values.ContractNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                )}
              </div>
              <div className="col-3 my-4 text-center changeText">
                {!isNumChange ? (
                  <u
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsNumChange(!isNumChange)}
                  >
                    Change
                  </u>
                ) : (
                  <Button onClick={() => setIsNumChange(!isNumChange)}>
                    Cancel
                  </Button>
                )}
              </div>
            </div> */}

            <div className="col-6 d-flex order-2 changesinput">
              <div className="col-6 my-3 mx-3 text-left">
                {!isNumChange ? (
                  <p
                    style={{ fontSize: "13px", marginTop: "20px" }}
                    className="text-blue-color"
                  >
                    Conract number #{formik.values.ContractNumber}
                  </p>
                ) : (
                  // <input
                  //   id="ContractNumber"
                  //   name="ContractNumber"
                  //   type="text"
                  //   value={formik.values.ContractNumber}
                  //   onChange={formik.handleChange}
                  //   onBlur={formik.handleBlur}
                  // />
                  <InputText
                    value={formik.values.ContractNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="ContractNumber"
                    id="ContractNumber"
                    label="Contract Number"
                    type="number"
                    className="text-blue-color w-100 my-3"
                    fieldHeight="53px"
                  />
                )}
              </div>
              <div className="col-3 my-4 text-center changeText">
                {!isNumChange ? (
                  <u
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsNumChange(true)}
                  >
                    Change
                  </u>
                ) : (
                  <Button
                    onClick={handleContractNumberChange}
                    className="buttons outline-button-blue-color outline selectclientaddquote bg-blue-color"
                  >
                    Done
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex col-12 schedule-section-main">
            <div className="col-6 ">
              <h5
                className="text-blue-color"
                style={{
                  fontWeight: 600,
                  fontSize: "20px",
                  marginTop: "30px",
                  marginBottom: "20px",
                }}
              >
                Type <HelpOutlineOutlinedIcon className="mx-1" />
              </h5>
              <div className="">
                <div className="toggle-button" style={{ width: "fit-content" }}>
                  <div className="" style={{ width: "auto" }}>
                    <Nav
                      className="toggle-button-nav"
                      tabs
                      style={{
                        borderRadius: "15px",
                        border: "1px solid #063164",
                        marginBottom: "15px",
                      }}
                    >
                      <NavItem className="on-off-contract outline">
                        <NavLink
                          className={`${
                            activeTab === 1 ? "active" : ""
                          } outline contract`}
                          onClick={() => setActiveTab(1)}
                          style={{
                            backgroundColor:
                              activeTab === 1 ? "red" : "transparent",
                            color: activeTab === 2 ? "#063164" : "black",
                            borderTopLeftRadius: "11px",
                            borderBottomLeftRadius: "11px",
                            borderTopRightRadius: "0",
                            borderBottomRightRadius: "0",
                            marginBottom: 0,
                            width: "100%",
                          }}
                        >
                          One-off Contract
                        </NavLink>
                      </NavItem>
                      <NavItem className="recurring">
                        <NavLink
                          className={`${
                            activeTab === 2 ? "active" : ""
                          } outline contract`}
                          onClick={() => setActiveTab(2)}
                          style={{
                            backgroundColor:
                              activeTab === 2 ? "red" : "transparent",
                            color: activeTab === 1 ? "#063164" : "black",
                            borderTopRightRadius: "11px",
                            borderBottomRightRadius: "11px",
                            borderTopLeftRadius: "0",
                            borderBottomLeftRadius: "0",
                            width: "100%",
                          }}
                        >
                          Recurring Contract
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </div>
                {activeTab === 1 && (
                  <OneOffContract
                    formik={formik}
                    isCalendarVisible={isCalendarVisible}
                    setIsCalendarVisible={setIsCalendarVisible}
                  />
                )}
                {activeTab === 2 && (
                  <RecurringContract
                    formik={formik}
                    isCalendarVisible={isCalendarVisible}
                    setIsCalendarVisible={setIsCalendarVisible}
                  />
                )}
              </div>
            </div>
            <div className="col-6 mx-2 my-3 mb-0 first-tab">
              {isCalendarVisible && (
                <CalendarJOB
                  isCalendarVisible={isCalendarVisible}
                  setIsCalendarVisible={setIsCalendarVisible}
                  selectedTeams={selectedTeams}
                  setSelectedTeams={setSelectedTeams}
                />
              )}
              {!isCalendarVisible && (
                <div style={{ marginTop: "19.8%" }}>
                  <Team
                    setIds={handleCahngeIds}
                    _ids={formik.values.selectedTeams}
                  />
                </div>
              )}
            </div>
          </div>
          {isCalendarVisible && (
            <div className="col-6">
              <Team />
            </div>
          )}

          {activeTab === 2 ? (
            <Card
              className="p-3 my-3"
              style={{
                paddingRight: "30px",
                border: "1px solid #063164",
                marginBottom: "15px",
              }}
            >
              <h4 className="text-blue-color" style={{ fontWeight: 600 }}>
                Invoicing
              </h4>
              <div className="d-flex row">
                <div className="col-lg-5">
                  <div>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        marginBottom: "0px",
                      }}
                    >
                      How do you want to invoice?
                    </p>
                    <div className="d-flex gap-4">
                      <div
                        className="text-blue-color"
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          alignItems: "center",
                        }}
                      >
                        <Input type="radio" name="radio" value="yes" /> Per
                        Visit
                      </div>
                      <div
                        className="text-blue-color"
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          alignItems: "center",
                        }}
                      >
                        <Input type="radio" name="radio" value="no" /> Fixed
                        Price
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        marginBottom: "0px",
                      }}
                    >
                      When do you want to invoice?
                    </p>
                    <div className="mt-1">
                      <Input
                        type="select"
                        style={{
                          color: "#063164",
                          fontSize: "14px",
                          border: "1px solid rgba(6, 49, 100, 30%)",
                          borderRadius: "10px",
                          fontSize: "12px",
                          width: "70%",
                        }}
                      >
                        <option value="">
                          Monthly on the last day of mont
                        </option>
                      </Input>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Label
                      className="my-2"
                      for="exampleEmail"
                      style={{
                        color: "#000000",
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      Visits
                    </Label>
                    <div className="d-flex">
                      <div
                        className="col-3"
                        style={{
                          borderRight: "0.5px solid rgba(6,49,100, 0.8)",
                        }}
                      >
                        <p
                          className="mb-0"
                          color="rgba(6, 49, 100, 30%)"
                          style={{ fontSize: "12px", marginRight: "0px" }}
                        >
                          First
                        </p>
                        <p
                          className="mb-0"
                          color="rgba(6, 49, 100, 30%)"
                          style={{ fontSize: "12px" }}
                        >
                          May 21, 2024
                        </p>
                      </div>
                      <div
                        className="col-3 mx-0"
                        style={{
                          borderRight: "0.5px solid rgba(6, 49, 100, 30%)",
                        }}
                      >
                        <p
                          className="mb-0"
                          color="rgba(6, 49, 100, 30%)"
                          style={{ fontSize: "12px", marginLeft: "5px" }}
                        >
                          Last
                        </p>
                        <p
                          className="mb-0"
                          color="rgba(6, 49, 100, 30%)"
                          style={{ fontSize: "12px", marginLeft: "5px" }}
                        >
                          May 21, 2024
                        </p>
                      </div>
                      <div className="col-3 mx-1">
                        <p
                          className="mb-0"
                          color="rgba(6, 49, 100, 30%)"
                          style={{ fontSize: "12px" }}
                        >
                          Total
                        </p>
                        <p
                          className="mb-0"
                          color="rgba(6, 49, 100, 30%)"
                          style={{ fontSize: "12px" }}
                        >
                          27
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div style={{ width: "100%" }}>
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginBottom: "0px",
                        }}
                      >
                        Automatically pay invoices
                      </p>
                      <div style={{ marginRight: "18%" }}>
                        <FormGroup switch className="my-3">
                          <Input type="switch" style={{ cursor: "pointer" }} />
                        </FormGroup>
                      </div>
                    </div>
                    <div>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          marginTop: "5px",
                        }}
                      >
                        Automatic payments will be enabled as soon as your
                        Customer adds a payment method. Learn more in{" "}
                        <span
                          style={{
                            fontSize: "16px",
                            textDecoration: "underline",
                          }}
                        >
                          Help Center
                        </span>
                        .
                      </p>
                    </div>
                  </div>
                  <div style={{ width: "60%" }}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        marginBottom: "0px",
                      }}
                    >
                      No payment methods on file
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        marginTop: "5px",
                      }}
                    >
                      Your customer can save a payment method when they make
                      their first payment.
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          ) : null}
          <Card
            className="p-3 my-4"
            style={{
              paddingRight: "30px",
              border: "1px solid #063164",
              marginBottom: "15px",
            }}
          >
            <h4 className="text-blue-color" style={{ fontWeight: 600 }}>
              Line Items
            </h4>

            {lineItems.map((item, index) => (
              <React.Fragment key={index}>
                <GetProducts
                  item={item}
                  index={index}
                  handleSelectChange={handleSelectChange}
                  lineItems={lineItems}
                  setLineItems={setLineItems}
                  showCosts={showCosts}
                  setShowCosts={setShowCosts}
                  menuIsOpen={menuIsOpen}
                  setMenuIsOpen={setMenuIsOpen}
                  deleteLineItem={deleteLineItem}
                  // isError={isError}
                  // setIsError={setIsError}
                  // productRef={productRef}
                />
              </React.Fragment>
            ))}

            <div
              className="d-flex justify-content-between align-items-center mb-0 pb-0 newlineitem"
              style={{ marginTop: "20px", background: "none", border: "none" }}
            >
              <Button
                className="bg-button-blue-color addnewline"
                outline
                style={{
                  padding: "0 14px 0 14px",
                  fontSize: "12px",
                  marginTop: 0,
                  height: "32px",
                  color: "#fff",
                  fontWeight: "400",
                }}
                onClick={addLineItem}
              >
                New Line Item
              </Button>
              <div>
                <div className="d-flex align-items-center  line-items-total ">
                  <p style={{ fontWeight: 600 }} className=" text-blue-color">
                    Total price
                  </p>
                  <p
                    className="mx-2 text-blue-color"
                    style={{ fontWeight: 600 }}
                  >
                    ${subTotal}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="col-7 mt-3  order-sm-2 ">
                <Table>
                  <tbody>
                    <tr>
                      <td
                        className="text-blue-color  "
                        style={{ height: "50px" }}
                      >
                        Subtotal
                      </td>
                      <td className="text-end text-blue-color">
                        ${subTotal?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="text-blue-color"
                        style={{ height: "50px" }}
                      >
                        Discount
                      </td>
                      <td
                        className={`d-flex ${
                          !showDiscount
                            ? "justify-content-end"
                            : "justify-content-between"
                        }`}
                      >
                        {showDiscount && (
                          <span
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <Input
                              id="Discount"
                              name="Discount"
                              placeholder="0.00"
                              className="text-blue-color"
                              style={{
                                fontSize: "14px",
                                paddingLeft: "15px",
                              }}
                              value={formik.values.Discount}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                              }}
                              className="text-blue-color"
                            >
                              %
                            </span>
                          </span>
                        )}
                        <u
                          className="text-blue-color"
                          style={{
                            fontWeight: 600,
                            cursor: "pointer",
                            textAlign: "end",
                            marginBottom: "9px",
                          }}
                          onClick={() => setShowDiscount(!showDiscount)}
                        >
                          {discountAmount > 0
                            ? `$${discountAmount?.toFixed(2)}`
                            : "Add Discount"}
                        </u>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="text-blue-color"
                        style={{ height: "50px" }}
                      >
                        Tax
                      </td>
                      <td
                        className={`d-flex ${
                          !showTax
                            ? "justify-content-end"
                            : "justify-content-between"
                        }`}
                      >
                        {showTax && (
                          <span
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <Input
                              id="Tax"
                              name="Tax"
                              placeholder="0.00"
                              className="text-blue-color"
                              style={{
                                fontSize: "14px",
                                paddingLeft: "15px",
                              }}
                              value={formik.values.Tax}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <span
                              className="text-blue-color"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                              }}
                            >
                              %
                            </span>
                          </span>
                        )}
                        <u
                          className="text-blue-color"
                          style={{
                            fontWeight: 600,
                            cursor: "pointer",
                            marginBottom: "9px",
                          }}
                          onClick={() => setShowTax(!showTax)}
                        >
                          {taxAmount > 0
                            ? `$${taxAmount?.toFixed(2)}`
                            : "Add Tax"}
                        </u>
                      </td>
                    </tr>
                    <tr className="last-row border-0">
                      <td className="border-0 text-blue-color">
                        <b>Total</b>
                      </td>
                      <td className="text-end border-0 text-blue-color">
                        <b>${Total}</b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Card>

          {/* <Team /> */}

          <InternalNotes
            Total={0}
            notes={formik.values.Notes}
            setNotes={(value) => formik.setFieldValue("Notes", value)}
            attachments={formik.values.Attachment}
            setAttachments={(value) =>
              formik.setFieldValue("Attachment", value)
            }
          />
          <div
            className="d-flex justify-content-between button-responsive"
            style={{ marginTop: "80px", gap: "10px" }}
          >
            <Button
              outline
              className="footer-buttons"
              style={{ fontSize: "16px" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <div>
              {Object.keys(customersData).length > 0 ? (
                loading ? (
                  <div className="d-flex justify-content-center">
                    <ColorRing
                      height="30"
                      width="30"
                      colors={["#000", "#000", "#000", "#000", "#000"]}
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className="gap-3 d-flex sec-button-section">
                    <Button
                      outline
                      className="buttons outline-button-blue-color outline text-blue-color"
                      style={{ fontSize: "16px", height: "38px" }}
                      onClick={() => {
                        setLoading(true);
                        handleSaveQuote(false);
                      }}
                    >
                      {location.state?.id ? " Save Contract" : "Save Contract"}
                    </Button>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle className="bg-blue-color" caret>
                        Save &{" "}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => setMail(true)}>
                          Save & Send Mail
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )
              ) : (
                <Button
                  outline
                  className="buttons outline-button-blue-color outline selectclientaddquote bg-blue-color"
                  style={{
                    fontSize: "16px",
                    color: "white",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsCustomer(true);
                  }}
                >
                  Select Customer
                </Button>
              )}
            </div>
          </div>
        </Card>
      </div>
      <CustomerModal
        isCustomer={isCustomer}
        setIsCustomer={setIsCustomer}
        isProperty={isProperty}
        setIsProperty={setIsProperty}
        setFieldValue={formik.setFieldValue}
        values={formik.values}
        lineItems={lineItems}
        propertyData={propertyData}
        setPropertyData={setPropertyData}
        customersData={customersData}
        setCustomersData={setCustomersData}
        formik={formik}
      />
      <ContractMail
        modal={mail}
        setModal={setMail}
        customerData={customersData}
        propertyData={propertyData}
        Attachment={formik.values.Attachment}
        handleSubmit={handleSaveQuote}
      />
      <Toaster />
    </>
  );
};

export default AddContract;
