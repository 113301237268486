import React from "react";
import { Circles, ColorRing } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Toaster } from "react-hot-toast";
import {
  DisplayImage,
  FileModal,
} from "../../../../components/Files/DisplayFiles";
import QuoteMail from "../QuoteMail";

const QuotesDetails = ({
  loader,
  Previous,
  toggle,
  setMail,
  companyName,
  handleEditClick,
  dropdownOpen,
  moreActiontoggle,
  quotesData,
  edit,
  generatePDF,
  quotteImage,
  Copy,
  handleCopy,
  options,
  open,
  setOpen,
  file,
  cdnUrl,
  setFile,
  moment,
  mail,
  downloadPdf,
  requestData,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%", width: "100%", marginTop: "25%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </div>
      ) : (
        <div className="Quote-details">
          <div>
            <div className="d-flex justify-content-between buttonGroup">
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    color: "#fff",
                    marginRight: "10px",
                    width: "50px",
                    height: "40px",
                    marginBottom: "0px",
                    padding: "0px 0px",
                    borderRadius: "4px",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="text-capitalize bg-button-blue-color"
                >
                  <img
                    src={Previous}
                    style={{ width: "20px", height: "20px" }}
                  />
                </Button>
                {/* <NavigatorNav /> */}
              </div>
              <div className="d-flex justify-content-end gap-2 buttonGroupthree">
                <Button
                  className="bg-button-blue-color"
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    setMail(true);
                  }}
                >
                  <MailOutlineOutlinedIcon style={{ marginRight: "8px" }} />
                  Send Email
                </Button>
                {companyName && (
                  <Button
                    className="outline-button-blue-color tect-blue-color"
                    outline
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditClick();
                    }}
                  >
                    <img src={edit} />
                    &nbsp; Edit
                  </Button>
                )}
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={moreActiontoggle}
                  style={{ zIndex: 1 }}
                >
                  <DropdownToggle
                    className="text-blue-color outline"
                    style={{
                      background: "none",
                      border: "1px solid #063164",
                    }}
                  >
                    {" "}
                    <MoreHorizIcon /> More Actions
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #063164",
                    }}
                  >
                    <DropdownItem
                      className="text-blue-color"
                      onClick={() => {
                        navigate(`/${companyName}/add-contract`, {
                          state: {
                            navigats: [
                              ...location.state.navigats,
                              "/add-contract",
                            ],
                            QuoteId: location.state.id,
                            formData: quotesData,
                            products: quotesData?.products,
                          },
                        });
                      }}
                    >
                      Convert to contract
                    </DropdownItem>
                    {/* <DropdownItem className="text-blue-color" onClick={toggle}>
                      Collect Signature
                    </DropdownItem> */}
                    <DropdownItem
                      className="text-blue-color"
                      onClick={() => {
                        window.open("https://www.dropbox.com/home", "_blank");
                        toggle(); // If you want to toggle the dropdown as well
                      }}
                    >
                      Collect Signature
                    </DropdownItem>

                    <DropdownItem
                      className="text-blue-color"
                      onClick={() => {
                        downloadPdf();
                      }}
                    >
                      Download PDF
                    </DropdownItem>
                    <DropdownItem
                      className="text-blue-color"
                      onClick={() => {
                        generatePDF(quotesData, "print");
                      }}
                    >
                      Print
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            {/* <div> */}
            <div
              className="p-3 my-4"
              style={{ border: "1px solid #063164", borderRadius: "12px" }}
            >
              <div className="justify-content-between d-flex align-items-center">
                <div className="d-flex align-items-center">
                  {/* <SentimentSatisfiedAltIcon
                    className="text-blue-color"
                    style={{ fontSize: "26px" }}
                  /> */}
                  <img src={quotteImage} />
                  <p
                    className="mb-0 mx-2 text-blue-color "
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      backgroundColor:
                        quotesData?.status === "Awaiting Response"
                          ? "rgba(255, 165, 0, 0.2)"
                          : quotesData?.status === "Approved"
                          ? "rgba(88, 230, 88, 0.2)"
                          : quotesData?.status === "Draft"
                          ? "rgba(0, 0, 255, 0.2)"
                          : quotesData?.status === "Request changed"
                          ? "#FFDFF6"
                          : "",
                      alignItems: "center",
                      padding: "7px 20px 7px",
                      borderRadius: "25px",
                    }}
                  >
                    <span
                      className=""
                      style={{
                        backgroundColor:
                          quotesData?.status === "Awaiting Response"
                            ? "orange"
                            : quotesData?.status === "Approved"
                            ? "#58cc58"
                            : quotesData?.status === "Draft"
                            ? "rgb(6, 49, 100)"
                            : quotesData?.status === "Request changed"
                            ? "#FF33C6"
                            : "",
                        borderRadius: "50%",
                        padding: "6px",
                        marginRight: "10px",
                        marginBottom: 0,
                      }}
                    ></span>
                    {quotesData?.status}
                  </p>
                </div>
                <p
                  className="text-blue-color"
                  style={{ fontSize: "14px", fontWeight: "600" }}
                >
                  Quote :#{quotesData?.QuoteNumber}
                </p>
              </div>
              <div>
                <h3
                  className="my-4 mb-0 text-blue-color"
                  style={{ fontWeight: 700 }}
                >
                  {quotesData?.customer?.FirstName || ""}{" "}
                  {quotesData?.customer?.LastName || ""}
                  <img
                    src={Copy}
                    style={{ cursor: "pointer" }}
                    onClick={handleCopy}
                    alt="Copy Icon"
                    className="mx-3"
                  />
                </h3>
                <p className="text-blue-color" style={{ fontSize: "14px" }}>
                  {quotesData?.Title}
                </p>
              </div>
              <div className="d-flex property-address">
                <div className="col-8">
                  <p
                    className="mb-0 Sub-title text-blue-color"
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Property Address
                  </p>
                  <p
                    className="mb-0 text-data text-blue-color"
                    style={{ fontSize: "14px", width: "80%" }}
                  >
                    {quotesData?.location?.Address || ""}{" "}
                    {quotesData?.location?.City || ""}{" "}
                    {quotesData?.location?.State || ""}{" "}
                    {quotesData?.location?.Country || ""}{" "}
                    {quotesData?.location?.Zip || ""}
                  </p>
                </div>
                <div className="col-2 ">
                  <p
                    className="mb-0 Sub-title text-blue-color"
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      marginLeft: "-14px",
                    }}
                  >
                    Quote details
                  </p>
                  <p
                    className="mb-0 text-data text-blue-color"
                    style={{
                      fontSize: "14px",
                      marginLeft: "-14px",
                    }}
                  >
                    Created
                  </p>
                </div>
                <div className="col-2">
                  <p
                    className="mb-0 my-3 text-data text-blue-color"
                    style={{
                      fontSize: "14px",
                      marginLeft: "2px",
                    }}
                  >
                    {new Date(quotesData?.updatedAt).toLocaleDateString(
                      "en-US",
                      options
                    )}
                  </p>
                </div>
              </div>
              <hr />
              {quotesData?.products?.length > 0 &&
                quotesData?.products?.map((item, index) => (
                  <div className="d-flex" key={index}>
                    <div className="col-4">
                      {index === 0 && (
                        <p
                          className="mb-3 Sub-heading text-blue-color"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          Product/ Service
                        </p>
                      )}
                      <div className="d-flex justify-content-between products mb-3">
                        <div>
                          <p
                            className="mb-0 Sub-title text-blue-color"
                            style={{
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            {item?.Name}
                          </p>
                          <p
                            className="mb-0 text-data text-blue-color"
                            style={{ fontSize: "14px" }}
                          >
                            {item?.Description}
                          </p>
                        </div>
                        <FileModal open={open} setOpen={setOpen} file={file} />
                      </div>
                    </div>
                    <div className="col-2">
                      {index === 0 && (
                        <p
                          className="mb-3 Sub-heading text-blue-color"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Product/Service Image
                        </p>
                      )}
                      <p style={{ textAlign: "center" }}>
                        {item.Attachment ? (
                          <img
                            style={{
                              height: "40px",
                              marginBottom: "30px",
                              cursor: "pointer",
                            }}
                            src={`${cdnUrl}/upload/${item.Attachment}`}
                            onClick={() => {
                              setFile(item.Attachment);
                              setOpen(true);
                            }}
                            alt="Thumbnail"
                          />
                        ) : (
                          "-"
                        )}
                      </p>
                    </div>
                    <div className="col-2">
                      {index === 0 && (
                        <p
                          className="mb-3 Sub-heading text-blue-color"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Unit/Hour
                        </p>
                      )}
                      <p
                        className="mb-0 text-data text-blue-color unit"
                        style={{
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        {item?.Unit
                          ? item?.Unit
                          : item?.Square
                          ? item?.Square
                          : item?.Hour}
                      </p>
                    </div>
                    <div className="col-2">
                      {index === 0 && (
                        <p
                          className="mb-3 Sub-heading text-blue-color"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            textAlign: "end",
                          }}
                        >
                          Unit Cost/Hour Cost
                        </p>
                      )}
                      <p
                        className="mb-0 text-data text-blue-color per-unit"
                        style={{
                          fontSize: "14px",
                          textAlign: "end",
                        }}
                      >
                        $
                        {item?.CostPerHour
                          ? Number(item.CostPerHour).toFixed(2)
                          : item?.CostPerSquare
                          ? Number(item.CostPerSquare).toFixed(2)
                          : Number(item?.CostPerUnit).toFixed(2)}
                      </p>
                    </div>
                    <div className="col-2 text-end">
                      {index === 0 && (
                        <p
                          className="mb-3 Sub-heading text-blue-color totalInput"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          Total
                        </p>
                      )}
                      <p
                        className="mb-0 text-data text-blue-color total-pro"
                        style={{ fontSize: "14px" }}
                      >
                        $
                        {Number(item?.Total)
                          ? Number(item.Total).toFixed(2)
                          : ""}
                      </p>
                    </div>
                  </div>
                ))}
              <hr />
              <div className="d-flex products-colum">
                <div className="col-8 contc">
                  <p
                    className="mb-2 text-blue-color"
                    style={{ fontSize: "14px" }}
                  >
                    {quotesData?.CustomerMessage}
                  </p>
                  <p
                    className="text-data text-blue-color"
                    style={{ fontSize: "14px", width: "70%" }}
                  >
                    {quotesData?.ContractDisclaimer}
                  </p>
                </div>
                <div className="col-4 contc">
                  <div className="d-flex justify-content-between">
                    <p className="text-blue-color" style={{ fontSize: "14px" }}>
                      Subtotal
                    </p>
                    <p className="text-blue-color" style={{ fontSize: "14px" }}>
                      ${quotesData?.SubTotal}
                    </p>
                  </div>

                  {quotesData?.Discount ? (
                    <div className="d-flex justify-content-between">
                      <p
                        className=""
                        style={{ fontSize: "14px", color: "#063164" }}
                      >
                        Discount
                      </p>
                      <p style={{ fontSize: "14px", color: "#063164" }}>
                        {quotesData?.Discount} %
                      </p>
                    </div>
                  ) : null}
                  {quotesData?.Tax ? (
                    <div className="d-flex justify-content-between">
                      <p
                        className=""
                        style={{ fontSize: "14px", color: "#063164" }}
                      >
                        Tax
                      </p>
                      <p style={{ fontSize: "14px", color: "#063164" }}>
                        {quotesData?.Tax} %
                      </p>
                    </div>
                  ) : null}
                  <hr className="my-0 mb-2" />
                  <div className="d-flex justify-content-between">
                    <p
                      className="mb-2 text-blue-color"
                      style={{ fontSize: "14px" }}
                    >
                      <b>Total</b>
                    </p>
                    <p className="text-blue-color" style={{ fontSize: "14px" }}>
                      <b>${quotesData?.Total}</b>
                    </p>
                  </div>
                </div>
              </div>

              {quotesData?.signature ? (
                <div className="col-7 d-flex align-items-center signature-main">
                  <div className="col-8 text-center sign-first">
                    <img
                      src={
                        quotesData.signature
                          ? `${cdnUrl}/upload/` + quotesData.signature
                          : ""
                      }
                      style={{ border: "none", height: "80px" }}
                    />
                    <hr />
                    <p> Signature </p>
                  </div>
                  <div
                    className="col-4 text-center mx-3 sign-first"
                    style={{ marginTop: "40px" }}
                  >
                    <p> {moment(quotesData?.approvedAt).format("ll")} </p>
                    <hr />
                    <p className="mb-0"> Date </p>
                  </div>
                </div>
              ) : null}
            </div>
            <div
              className="p-3 my-4"
              style={{ border: "1px solid #063164", borderRadius: "12px" }}
            >
              <p className="text-blue-color" style={{ fontWeight: 600 }}>
                Internal notes and attachments <HelpOutlineOutlinedIcon />
              </p>
              <div
                className=""
                style={{
                  border: "0.5px solid rgba(6, 49, 100, 80%)",
                  padding: "15px",
                  borderRadius: "10px",
                }}
              >
                <p>{quotesData?.Notes || "No Notes Available"}</p>
              </div>
              <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                {quotesData?.Attachment && (
                  <DisplayImage
                    files={quotesData?.Attachment}
                    IsDeleted={false}
                  />
                )}
              </div>
            </div>

            <div
              className="p-3 my-4"
              style={{ border: "1px solid #063164", borderRadius: "12px" }}
            >
              <div>
                <div className="d-flex align-items-center">
                  <p
                    className="bg-blue-color"
                    style={{
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                      borderRadius: "50%",
                    }}
                  >
                    {quotesData?.customer?.FirstName?.charAt(0)?.toUpperCase()}
                    {quotesData?.customer?.LastName?.charAt(0)?.toUpperCase()}
                  </p>
                  <div className="mx-2">
                    <p className="mb-0" style={{ fontSize: "12px" }}>
                      <b>
                        {quotesData?.customer?.FirstName || ""}{" "}
                        {quotesData?.customer?.LastName || ""}
                      </b>
                    </p>
                    <p style={{ fontSize: "12px" }} className="">
                      Created :{" "}
                      {new Date(quotesData?.updatedAt).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </p>
                  </div>
                </div>
                <i>
                  {/* {requestData.RequestMessage && (
                    <p style={{ fontSize: "15px" }}>
                      <b>Client Request: </b> {requestData.RequestMessage}
                      {new Date(requestData?.updatedAt).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </p>
                  )} */}
                  {Array.isArray(requestData.RequestMessage) ? (
                    requestData?.RequestMessage.map((message, index) => (
                      <p key={index} style={{ fontSize: "15px" }}>
                        <b>Client Request {index + 1} : </b> {message}
                        {requestData.createdAt && (
                          <>
                            {" "}
                            -{" "}
                            {new Date(requestData.createdAt).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </>
                        )}
                      </p>
                    ))
                  ) : (
                    <p style={{ fontSize: "15px" }}>
                      <b>Client Request: </b> {requestData.RequestMessage}
                      {requestData.createdAt && (
                        <>
                          {" "}
                          -{" "}
                          {new Date(requestData.createdAt).toLocaleDateString(
                            "en-US",
                            options
                          )}
                        </>
                      )}
                    </p>
                  )}
                </i>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
      <Toaster />
      <QuoteMail
        modal={mail}
        setModal={setMail}
        customerData={quotesData?.customer}
        Total={quotesData?.Total}
        QuoteNumber={quotesData?.QuoteNumber}
        Attachment={quotesData?.Attachment}
        quotesData={quotesData}
      />
    </>
  );
};

export default QuotesDetails;
