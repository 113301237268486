import React, { useEffect, useState } from "react";
import edit from "../../../assets/image/icons/edit.svg";
import Copy from "../../../assets/image/icons/copy.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import axios from "axios";
import Previous from "../../../assets/image/icons/Previous.png";
import toast, { Toaster } from "react-hot-toast";
import { generateContractPDF } from "../../../components/Files/ContractPDF";
import Contract from "../../../assets/Blue-sidebar-icon/Contract.svg";
import AxiosInstance from "../../AxiosInstance";
import ContractDetails from "../../Admin/Contract/views/ContractDetails";

const options = { year: "numeric", month: "short", day: "numeric" };

function WorkerContractDetails() {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();

  const baseUrl = process.env.REACT_APP_BASE_API;
  const cdnUrl = process.env.REACT_APP_CDN_API;

  const [contractData, setContractData] = useState({});
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const [mail, setMail] = useState(false);
  const [savedSignature, setSavedSignature] = useState(null);
  const [signatureSaved, setSignatureSaved] = useState(false);
  const [modal, setModal] = useState(false);
  const [tokenDecode, setTokenDecode] = useState({});

  //2529 handleAuth ma CompanyId get
  const CompanyId = tokenDecode.companyId;

  const fetchDatas = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchDatas();
  }, []);

  let fetchData = async () => {
    try {
      const res = await AxiosInstance.get(
        `/contract/contract_details/${location.state.id}`
      );
      if (res.data.statusCode === 200) {
        const labourRes = await AxiosInstance.get(
          `/labour/${location.state.id}/${
            localStorage.getItem("CompanyId") || tokenDecode?.companyId
          }`
        );
        const expenseRes = await AxiosInstance.get(
          `/expenses/${location.state.id}/${
            localStorage.getItem("CompanyId") || tokenDecode?.companyId
          }`
        );
        const visitsRes = await AxiosInstance.get(
          `/visits/${location.state.id}/${
            localStorage.getItem("CompanyId") || tokenDecode?.companyId
          }`
        );
        setContractData({
          ...res.data.data,
          laborData: labourRes.data.data,
          expenseData: expenseRes.data.result,
          visitsData: visitsRes.data.data,
        });
      }
    } catch (error) {
      console.error("Error: ", error.messae);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [tokenDecode]);

  //2529 handleAuth ma CompanyId get

  const handleCopy = () => {
    const fullName = `${contractData?.customer?.FirstName || ""} ${
      contractData?.customer?.LastName || ""
    }`;
    navigator.clipboard.writeText(fullName).catch((err) => {});
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const moreActiontoggle = () => setDropdownOpen((prevState) => !prevState);

  const [signatureRef, setSignatureRef] = useState(null);
  const clearSignature = () => {
    signatureRef?.clear();
  };

  const saveSignature = async () => {
    let image = "";
    try {
      const signatureCanvas = signatureRef;
      const signatureDataURL = signatureCanvas.toDataURL();
      const signatureBlob = await fetch(signatureDataURL).then((res) =>
        res.blob()
      );

      const imageData = new FormData();
      imageData.append("files", signatureBlob, "signature.png");

      const url = `${cdnUrl}/upload`;
      const result = await axios.post(url, imageData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSavedSignature(signatureDataURL);
      image = result.data.files[0].filename;
    } catch (error) {
      console.error(error, "");
    }

    setSignatureSaved(true);

    const quoteApiUrl = `${baseUrl}/quotes/clientdashboard/${contractData.quote_id}`;
    try {
      setLoader(true);
      const response = await AxiosInstance.put(quoteApiUrl, {
        signature: image || "",
      });

      if (response.data.statusCode === 200) {
        fetchData();
        setModal(false);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        console.error("Failed to update quote status:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating quote status:", error);
      toast.error(error);
    } finally {
      setLoader(false);
      setModal(false);
    }
  };

  const handleEditClick = () => {
    navigate(`/staff-member/worker-contract-details`, {
      state: {
        id: location.state.id,
        navigats: [...location.state.navigats, "/worker-contract-details"],
      },
    });
  };

  const toggle = () => setModal(!modal);
  const [isTimeEmptyModalOpen, setIsTimeEmptyModalOpen] = useState({
    isOpen: false,
    propertyData: null,
  });

  const handleTimeEmptyModalOpen = () => {
    setIsTimeEmptyModalOpen({ isOpen: true, propertyData: null });
  };

  const [isExpanseModalOpen, setIsExpanseModalOpen] = useState({
    isOpen: false,
    propertyData: null,
  });

  const handleExpanseModalOpen = () => {
    setIsExpanseModalOpen({ isOpen: true, propertyData: null });
  };

  const [isVisitModalOpen, setIsVisitModalOpen] = useState({
    isOpen: false,
    propertyData: null,
  });

  const handleVisitModalOpen = () => {
    setIsVisitModalOpen({ isOpen: true, propertyData: null });
  };

  const downloadPdf = async () => {
    try {
      const res = await AxiosInstance.post(
        `/contract/contractpdf/${location.state.id}`
      );
      if (res.data.statusCode === 200) {
        // The URL of the PDF
        const url = `https://app.cloudjobmanager.com/cdn/upload/${res.data.fileName}`;

        // const link = document.createElement("a");
        // link.href = url;
        // link.download = "contract_document.pdf";
        // document.body.appendChild(link);
        // link.click();

        fetch(url)
          .then((response) => {
            if (!response.ok) throw new Error("Network response was not ok");
            return response.blob(); // Get the file as a Blob
          })
          .then((blob) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob); // Create a URL for the blob
            link.download = "contract_document.pdf"; // Set the file name
            document.body.appendChild(link);
            link.click(); // Programmatically click the link to trigger the download
            document.body.removeChild(link); // Clean up
          })
          .catch((error) =>
            console.error(
              "There was a problem with the fetch operation:",
              error
            )
          );
        // document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };

  return (
    <>
      <ContractDetails
        loader={loader}
        Previous={Previous}
        toggle={toggle}
        setMail={setMail}
        companyName={companyName}
        handleEditClick={handleEditClick}
        dropdownOpen={dropdownOpen}
        moreActiontoggle={moreActiontoggle}
        edit={edit}
        Copy={Copy}
        handleCopy={handleCopy}
        options={options}
        open={open}
        setOpen={setOpen}
        file={file}
        cdnUrl={cdnUrl}
        setFile={setFile}
        mail={mail}
        Contract={Contract}
        contractData={contractData}
        generateContractPDF={generateContractPDF}
        downloadPdf={downloadPdf}
        handleTimeEmptyModalOpen={handleTimeEmptyModalOpen}
        handleVisitModalOpen={handleVisitModalOpen}
        modal={modal}
        handleExpanseModalOpen={handleExpanseModalOpen}
        clearSignature={clearSignature}
        setSignatureRef={setSignatureRef}
        saveSignature={saveSignature}
        isTimeEmptyModalOpen={isTimeEmptyModalOpen}
        setIsTimeEmptyModalOpen={setIsTimeEmptyModalOpen}
        fetchData={fetchData}
        isExpanseModalOpen={isExpanseModalOpen}
        setIsExpanseModalOpen={setIsExpanseModalOpen}
        isVisitModalOpen={isVisitModalOpen}
        setIsVisitModalOpen={setIsVisitModalOpen}
        handleTimeEmptyeditModalOpen ={handleTimeEmptyModalOpen}
        // 2529 companyID
        CompanyId={CompanyId}
        // 2529 companyID
      />
      <Toaster />
    </>
  );
}

export default WorkerContractDetails;
