// import React from "react";
// import { useDispatch } from "react-redux";
// import { setCustomColor } from "./themeActions";

// const ColorPicker = () => {
//   const dispatch = useDispatch();

//   const handleColorChange = (colorType) => (event) => {
//     const colorValue = event.target.value;
//     dispatch(setCustomColor(colorType, colorValue));
//   };

//   return (
//     <div>
//       <h3>Select Custom Colors:</h3>
//       <div style={{ marginBottom: "1rem" }}>
//         <label htmlFor="background-color-picker">Background Color:</label>
//         <input
//           id="background-color-picker"
//           type="color"
//           onChange={handleColorChange("backgroundColor")}
//           aria-label="Pick background color"
//           style={{ marginLeft: "1rem", cursor: "pointer" }}
//         />
//       </div>
//       <div>
//         <label htmlFor="text-color-picker">Text Color:</label>
//         <input
//           id="text-color-picker"
//           type="color"
//           onChange={handleColorChange("textColor")}
//           aria-label="Pick text color"
//           style={{ marginLeft: "1rem", cursor: "pointer" }}
//         />
//       </div>
//     </div>
//   );
// };

// export default ColorPicker;
