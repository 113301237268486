import React from "react";
import { Circles, ColorRing } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SignatureCanvas from "react-signature-canvas";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabContent,
  Table,
} from "reactstrap";
import { Toaster } from "react-hot-toast";
import {
  DisplayImage,
  FileModal,
} from "../../../../components/Files/DisplayFiles";
// import QuoteMail from "../..";
import TimeEmpty from "../../../../components/Contract Component/TimeEmpty";
import Expances from "../../../../components/Contract Component/Expances";
import ContractMail from "../ContractMail";
import moment from "moment";
import Visit from "../../../../components/Contract Component/Visit";

const ContractDetails = ({
  loader,
  Previous,
  toggle,
  setMail,
  companyName,
  handleEditClick,
  dropdownOpen,
  moreActiontoggle,
  edit,
  Copy,
  handleCopy,
  options,
  open,
  setOpen,
  file,
  cdnUrl,
  setFile,
  mail,
  Contract,
  contractData,
  generateContractPDF,
  handleTimeEmptyModalOpen,
  handleVisitModalOpen,
  modal,
  handleExpanseModalOpen,
  clearSignature,
  setSignatureRef,
  saveSignature,
  isTimeEmptyModalOpen,
  setIsTimeEmptyModalOpen,
  fetchData,
  isExpanseModalOpen,
  setIsExpanseModalOpen,
  isVisitModalOpen,
  setIsVisitModalOpen,
  CompanyId
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%", width: "100%", marginTop: "25%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </div>
      ) : (
        <div className="Quote-details">
          <div>
            <div className="d-flex justify-content-between buttonGroup">
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    color: "#fff",
                    marginRight: "10px",
                    width: "50px",
                    height: "40px",
                    marginBottom: "0px",
                    padding: "0px 0px",
                    borderRadius: "4px",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="text-capitalize bg-button-blue-color"
                >
                  <img
                    src={Previous}
                    style={{ width: "20px", height: "20px" }}
                  />
                </Button>
                {/* <NavigatorNav /> */}
              </div>
              <div className="d-flex justify-content-end gap-2 buttonGroupthree">
                <Button
                  className="bg-button-blue-color"
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    setMail(true);
                  }}
                >
                  <MailOutlineOutlinedIcon style={{ marginRight: "8px" }} />
                  Send Email
                </Button>
                {companyName && (
                  <Button
                    className="outline-button-blue-color tect-blue-color"
                    outline
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditClick();
                    }}
                  >
                    <img src={edit} />
                    &nbsp; Edit
                  </Button>
                )}
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={moreActiontoggle}
                  style={{ zIndex: 1 }}
                >
                  <DropdownToggle
                    className="text-blue-color outline"
                    style={{
                      background: "none",
                      border: "1px solid #063164",
                    }}
                  >
                    {" "}
                    <MoreHorizIcon /> More Actions
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #063164",
                    }}
                  >
                    <DropdownItem className="text-blue-color" onClick={toggle}>
                      Collect Signature
                    </DropdownItem>
                    <DropdownItem
                      className="text-blue-color"
                      onClick={() => {
                        generateContractPDF(contractData, "download");
                      }}
                    >
                      Download PDF
                    </DropdownItem>
                    {/* <DropdownItem
                    className="text-blue-color"
                    onClick={() => {
                      generatePDF(contractData, "print");
                    }}
                  >
                    Print
                  </DropdownItem> */}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div>
              <Card
                className="p-3 my-4"
                style={{ border: "1px solid #063164", borderRadius: "12px" }}
              >
                <div className="justify-content-between d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <img src={Contract} />
                    <p
                      className="mb-0 mx-2 text-blue-color"
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        backgroundColor:
                          contractData?.Status === "Unscheduled"
                            ? "#FFC6C6"
                            : contractData?.Status === "Today"
                            ? "#DBECFF"
                            : contractData?.Status === "Upcoming"
                            ? "AEF6D3"
                            : contractData?.Status === "Scheduled"
                            ? "#FFE9BC"
                            : "",
                        alignItems: "center",
                        padding: "7px 20px 7px",
                        borderRadius: "25px",
                      }}
                    >
                      <p
                        className=""
                        style={{
                          backgroundColor:
                            contractData?.Status === "Unscheduled"
                              ? "#FF0000"
                              : contractData?.Status === "Today"
                              ? "#3595FF"
                              : contractData?.Status === "Upcoming"
                              ? "#089F57"
                              : contractData?.Status === "Scheduled"
                              ? "#FFAF0B"
                              : "",
                          borderRadius: "50%",
                          padding: "6px",
                          marginRight: "10px",
                          marginBottom: 0,
                        }}
                      ></p>
                      {contractData?.Status}
                    </p>
                  </div>
                  <p
                    className="text-blue-color"
                    style={{ fontSize: "14px", fontWeight: "600" }}
                  ></p>
                </div>
                <div className="d-flex contract-main-first">
                  <div className="col-lg-9 col-md-6 col-sm-6">
                    <h3
                      className="my-4 mb-0 text-blue-color"
                      style={{ fontWeight: 700 }}
                    >
                      {contractData?.customer?.FirstName || ""}{" "}
                      {contractData?.customer?.LastName || ""}{" "}
                      {/* James Anderson */}
                      <img
                        src={Copy}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCopy()}
                      />
                    </h3>
                    <p className="text-blue-color" style={{ fontSize: "14px" }}>
                      {contractData?.Title}
                      {/* Office Cleaning */}
                    </p>
                    <div className="col-8">
                      <p
                        className="mb-0 Sub-title text-blue-color"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        Property Address
                      </p>
                      <p
                        className="mb-0 text-data text-blue-color"
                        style={{ fontSize: "14px", width: "80%" }}
                      >
                        {contractData?.location?.Address || ""}{" "}
                        {contractData?.location?.City || ""}{" "}
                        {contractData?.location?.State || ""}{" "}
                        {contractData?.location?.Country || ""}{" "}
                        {contractData?.location?.Zip || ""}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <p
                      className="mb-0 Sub-title text-blue-color"
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      Contract details
                    </p>
                    <hr />
                    <Table borderless>
                      <tbody className="Contract-table-detail">
                        <tr>
                          <td>Contract Type</td>
                          <td>
                            {contractData?.IsOneoffJob
                              ? "One-off Job"
                              : contractData?.IsRecuringJob
                              ? "Recurring Job"
                              : "Neither"}
                          </td>
                        </tr>
                        <tr>
                          <td>Starts On</td>
                          <td>
                            {moment(
                              contractData?.IsOneoffJob &&
                                contractData?.OneoffJob.StartDate
                                ? contractData?.OneoffJob.StartDate
                                : contractData?.IsRecuringJob &&
                                  contractData?.RecuringJob.StartDate
                                ? contractData?.RecuringJob.StartDate
                                : "No Start Date"
                            ).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td>Lasts For</td>
                          <td>
                            {moment(
                              contractData?.IsOneoffJob &&
                                contractData?.OneoffJob.EndDate
                                ? contractData?.OneoffJob.EndDate
                                : contractData?.IsRecuringJob &&
                                  contractData?.RecuringJob.EndDate
                                ? contractData?.RecuringJob.EndDate
                                : "No Start Date"
                            ).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td>Schedule</td>
                          <td>
                            {contractData?.IsOneoffJob &&
                            contractData?.OneoffJob.Repeats
                              ? contractData?.OneoffJob.Repeats
                              : contractData?.IsRecuringJob &&
                                contractData?.RecuringJob.Repeats
                              ? contractData?.RecuringJob.Repeats
                              : "No Start Date"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
                <hr />

                {contractData?.Items?.length > 0 &&
                  contractData?.Items?.map((item, index) => (
                    <div className="items-contract" key={index}>
                      <div className="d-flex ">
                        <div className="col-4">
                          {index === 0 && (
                            <p
                              className="mb-3 Sub-heading text-blue-color"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              Product/ Service
                            </p>
                          )}
                          <div className="d-flex justify-content-between products mb-4">
                            <div>
                              <p
                                className="mb-0 Sub-title text-blue-color"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                {item?.Name}
                                {/* Network Switches */}
                              </p>
                              <p
                                className="mb-0 text-data text-blue-color"
                                style={{ fontSize: "14px" }}
                              >
                                Labor charge for the setup and configuration of
                                network switches for improved connectivity and
                                network management.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          {index === 0 && (
                            <p
                              className="mb-3 Sub-heading text-blue-color"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              Image
                            </p>
                          )}
                          <p
                            className="mb-0 text-data text-blue-color unit"
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            {item?.Attachment ? (
                              <img
                                style={{
                                  height: "40px",
                                  marginBottom: "30px",
                                  cursor: "pointer",
                                }}
                                src={`${cdnUrl}/upload/${item?.Attachment}`}
                                onClick={() => {
                                  setFile(item?.Attachment);
                                  setOpen(true);
                                }}
                                alt="Thumbnail"
                              />
                            ) : "-"}

                            <FileModal
                              open={open}
                              setOpen={setOpen}
                              file={file}
                            />
                            {/* 43 */}
                          </p>
                        </div>
                        <div className="col-2">
                          {index === 0 && (
                            <p
                              className="mb-3 Sub-heading text-blue-color"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              Unit/Hour
                            </p>
                          )}
                          <p
                            className="mb-0 text-data text-blue-color unit"
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            {item?.Unit
                              ? item?.Unit
                              : item?.Square
                              ? item?.Square
                              : item?.Hour}
                          </p>
                        </div>
                        <div className="col-2">
                          {index === 0 && (
                            <p
                              className="mb-3 Sub-heading text-blue-color"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              Unit Cost/Hour Cost
                            </p>
                          )}
                          <p
                            className="mb-0 text-data text-blue-color per-unit"
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            $
                            {item?.CostPerHour
                              ? Number(item?.CostPerHour).toFixed(2)
                              : item?.CostPerSquare
                              ? Number(item.CostPerSquare).toFixed(2)
                              : Number(item?.CostPerUnit).toFixed(2)}
                          </p>
                        </div>
                        <div className="col-2 text-end">
                          {index === 0 && (
                            <p
                              className="mb-3 Sub-heading text-blue-color totalInput"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              Total
                            </p>
                          )}
                          <p
                            className="mb-0 text-data text-blue-color total-pro"
                            style={{ fontSize: "14px" }}
                          >
                            {Number(item?.Total)
                              ? Number(item?.Total).toFixed(2)
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                <hr />
                <div className="d-flex products-colum">
                  <div className="col-8 contc">
                    <p
                      className="mb-2 text-blue-color"
                      style={{ fontSize: "14px" }}
                    >
                      Contract/ Disclaimer This quote is valid for the next 30
                      days, after which values may be subject to change.
                    </p>
                  </div>
                  <div className="col-4 contc">
                    <div className="d-flex justify-content-between">
                      <p
                        className="text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        Subtotal
                      </p>
                      <p
                        className="text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        ${contractData?.Total}
                      </p>
                    </div>

                    <hr className="my-0 mb-2" />
                    <div className="d-flex justify-content-between">
                      <p
                        className="mb-2 text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        <b>Total</b>
                      </p>
                      <p
                        className="text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        <b>${contractData?.Total?.toFixed(2)}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
              <Card
                className="p-3 my-4"
                style={{ border: "1px solid #063164", borderRadius: "12px" }}
              >
                <div className="d-flex justify-content-between">
                  <p
                    className="text-blue-color"
                    style={{ fontWeight: 600, fontSize: "19px" }}
                  >
                    Labour
                  </p>
                  <Button
                    size="sm"
                    outline
                    className="outline-button-blue-color border-blue-color outline text-blue-color"
                    style={{ height: "32px", borderRadius: "8px" }}
                    onClick={handleTimeEmptyModalOpen}
                  >
                    + New Time Entry
                  </Button>
                </div>
                {contractData?.laborData &&
                contractData?.laborData.length > 0 ? (
                  <div className="w-100">
                    {/* <table className="w-100">
                    <tr>
                      <th className="text-blue-color">TEAM</th>
                      <th className="text-blue-color">NOTES</th>
                      <th className="text-blue-color">DATE</th>
                      <th className="text-blue-color">HOURS</th>
                      <th className="text-right text-blue-color">COST</th>
                    </tr>
                    {contractData?.laborData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-blue-color">
                            {item?.AssignPersonId?.FullName}
                          </td>
                          <td className="text-blue-color">{item?.Notes}</td>
                          <td className="text-blue-color">
                            {moment(item?.DatePicker).format("DD-MM-YYYY")}
                            <br />{" "}
                            {moment(item?.StartTime, "HH:mm:ss").format(
                              "hh:mm A"
                            )}{" "}
                            -{" "}
                            {moment(item?.EndTime, "HH:mm:ss").format(
                              "hh:mm A"
                            )}
                          </td>
                          <td className="text-blue-color">{item?.Hours}</td>
                          <td className="text-right">{item?.LabourCost}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan={5}>
                        <hr className="p-0 my-2 w-100" />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}></td>
                      <td>
                        {contractData?.laborData.reduce(
                          (acc, item) => acc + Number(item?.Hours || 0),
                          0
                        )}
                      </td>
                      <td className="text-right">
                        {contractData?.laborData.reduce(
                          (acc, item) => acc + Number(item?.LabourCost || 0),
                          0
                        )}
                      </td>
                    </tr>
                  </table> */}

                    <div className="row   py-2 text-left text-blue-color ">
                      <th className="col font-weight-bold">TEAM</th>
                      <th className="col font-weight-bold">NOTES</th>
                      <th className="col font-weight-bold">DATE</th>
                      <th className="col font-weight-bold">HOURS</th>
                      <th className="col text-right text-blue-color font-weight-bold">
                        COST
                      </th>
                    </div>
                    {contractData?.laborData.map((item, index) => (
                      <div className="row  py-2 text-left" key={index}>
                        <div className="col">
                          {item?.AssignPersonId?.FullName}
                        </div>
                        <div className="col">{item?.Notes}</div>
                        <div className="col">
                          {moment(item?.DatePicker).format("DD-MM-YYYY")}
                          <br />
                          {moment(item?.StartTime, "HH:mm:ss").format(
                            "hh:mm A"
                          )}{" "}
                          -{" "}
                          {moment(item?.EndTime, "HH:mm:ss").format("hh:mm A")}
                        </div>
                        <div className="col">{item?.Hours}</div>
                        <div className="col text-right">{item?.LabourCost}</div>
                      </div>
                    ))}
                    <div className="row"></div>
                    <div className="row font-weight-bold text-left border-top">
                      <div className="col"></div>
                      <div className="col"></div>
                      <div className="col"></div>
                      <div className="col">
                        {contractData?.laborData.reduce(
                          (acc, item) => acc + Number(item?.Hours || 0),
                          0
                        )}
                      </div>
                      <div className="col text-right">
                        {contractData?.laborData.reduce(
                          (acc, item) => acc + Number(item?.LabourCost || 0),
                          0
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <i>
                    <p
                      className="mb-0 text-blue-color"
                      style={{ fontSize: "12px" }}
                    >
                      Time tracked to this job by you or your team will show
                      here
                    </p>
                  </i>
                )}
              </Card>
              <Card
                className="p-3 my-4"
                style={{ border: "1px solid #063164", borderRadius: "12px" }}
              >
                <div className="d-flex justify-content-between">
                  <p
                    className="text-blue-color"
                    style={{ fontWeight: 600, fontSize: "19px" }}
                  >
                    Expenses
                  </p>
                  <Button
                    size="sm"
                    outline
                    className="outline-button-blue-color border-blue-color outline text-blue-color"
                    style={{ height: "32px", borderRadius: "8px" }}
                    onClick={handleExpanseModalOpen}
                  >
                    + New Expense
                  </Button>
                </div>
                {contractData?.expenseData &&
                contractData?.expenseData.length > 0 ? (
                  <div className="w-100">
                    <table className="w-100">
                      <tr>
                        <th className="text-blue-color">TEAM</th>
                        <th className="text-blue-color">ITEM</th>
                        <th className="text-blue-color">Description</th>
                        <th className="text-blue-color">DATE</th>
                        <th className="text-blue-color text-right">Amount</th>
                      </tr>
                      {contractData?.expenseData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-blue-color">
                              {item?.AssignPersonId?.FullName}
                            </td>
                            <td className="text-blue-color">
                              {item?.ItemName}
                            </td>
                            <td className="text-blue-color">
                              {item?.Description}
                            </td>
                            <td className="text-blue-color">
                              {moment(item?.Date).format("DD-MM-YYYY")}
                            </td>
                            <td className="text-blue-color text-right">
                              {item?.Total}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={5}>
                          <hr className="p-0 my-2 w-100" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}></td>
                        <td className="text-right">
                          {contractData?.expenseData.reduce(
                            (acc, item) => acc + Number(item?.Total || 0),
                            0
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : (
                  <i>
                    <p
                      className="mb-0 text-blue-color"
                      style={{ fontSize: "12px" }}
                    >
                      Get an accurate picture of various job costs by recording
                      expenses
                    </p>
                  </i>
                )}
              </Card>
              <Card
                className="p-3 my-4"
                style={{ border: "1px solid #063164", borderRadius: "12px" }}
              >
                <div className="d-flex justify-content-between">
                  <p
                    className="text-blue-color"
                    style={{ fontWeight: 600, fontSize: "19px" }}
                  >
                    Visits
                  </p>
                  <Button
                    size="sm"
                    outline
                    className="outline-button-blue-color border-blue-color outline text-blue-color"
                    style={{ height: "32px", borderRadius: "8px" }}
                    onClick={handleVisitModalOpen}
                  >
                    + New Visit
                  </Button>
                </div>
                {contractData?.visitsData &&
                contractData?.visitsData.length > 0 ? (
                  <div className="w-100">
                    <table className="w-100">
                      <tr>
                        <th className="text-blue-color">TEAM</th>
                        <th className="text-blue-color">ITEM</th>
                        <th className="text-blue-color">Description</th>
                        <th className="text-blue-color">DATE</th>
                        <th className="text-blue-color text-right">Amount</th>
                      </tr>
                      {contractData?.expenseData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-blue-color">
                              {item?.AssignPersonId?.FullName}
                            </td>
                            <td className="text-blue-color">
                              {item?.ItemName}
                            </td>
                            <td className="text-blue-color">
                              {item?.Description}
                            </td>
                            <td className="text-blue-color">
                              {moment(item?.Date).format("DD-MM-YYYY")}
                            </td>
                            <td className="text-blue-color text-right">
                              {item?.Total}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={5}>
                          <hr className="p-0 my-2 w-100" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}></td>
                        <td className="text-right">
                          {contractData?.expenseData.reduce(
                            (acc, item) => acc + Number(item?.Total || 0),
                            0
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : (
                  <i>
                    <p
                      className="mb-0 text-blue-color"
                      style={{ fontSize: "12px" }}
                    >
                      Get an accurate picture of various job costs by recording
                      expenses
                    </p>
                  </i>
                )}
              </Card>
              <Card
                className="p-3 my-4"
                style={{ border: "1px solid #063164", borderRadius: "12px" }}
              >
                <p className="text-blue-color" style={{ fontWeight: 600 }}>
                  Internal notes and attachments <HelpOutlineOutlinedIcon />
                </p>
                <div
                  className=""
                  style={{
                    border: "0.5px solid rgb(203, 213, 224)",
                    padding: "15px",
                    borderRadius: "10px",
                  }}
                >
                  <p>{contractData?.Notes || "No Notes Available"}</p>
                </div>
                <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                  {contractData?.Attachment && (
                    <DisplayImage
                      files={contractData?.Attachment}
                      IsDeleted={false}
                    />
                  )}
                </div>
              </Card>
              <Card
                className="p-3 my-4"
                style={{ border: "1px solid #063164", borderRadius: "12px" }}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <p
                      className="bg-blue-color"
                      style={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        borderRadius: "50%",
                      }}
                    >
                      {contractData?.customer?.FirstName?.charAt(
                        0
                      )?.toUpperCase()}
                      {contractData?.customer?.LastName?.charAt(
                        0
                      )?.toUpperCase()}
                    </p>
                    <div className="mx-2">
                      <p className="mb-0" style={{ fontSize: "12px" }}>
                        <b>
                          {contractData?.customer?.FirstName || ""}{" "}
                          {contractData?.customer?.LastName || ""}
                        </b>
                      </p>
                      <p style={{ fontSize: "12px" }} className="">
                        Created :{" "}
                        {new Date(contractData?.updatedAt).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader toggle={toggle} className="text-blue-color">
          Signature Pad
        </ModalHeader>
        <ModalBody>
          <TabContent
            className="text-start my-3 mx-0"
            style={{ padding: "2px" }}
          >
            {/* {/ Draw Signature /} */}
            <Col sm="12">
              <Card
                className="mx-0"
                style={{
                  width: "100%",
                  border: "1px solid #063164",
                  borderRadius: "10px",
                }}
              >
                <div className="p-3">
                  <div className="text-end" style={{}}>
                    <Button
                      className="outline-button-color"
                      outline
                      style={{
                        padding: "2px 18px 2px 18px",
                        fontSize: "14px",
                      }}
                      onClick={clearSignature}
                    >
                      Clear
                    </Button>
                  </div>
                  <SignatureCanvas
                    ref={(ref) => setSignatureRef(ref)}
                    canvasProps={{
                      width: 450,
                      height: 130,
                      className: "signature-canvas",
                    }}
                  />
                  <div
                    className="text-center p-2 text-blue-color"
                    style={{
                      borderTop: "2px dashed #063164",
                      fontSize: "14px",
                    }}
                  >
                    Write signature here...
                  </div>
                </div>
              </Card>
              <p
                className="text-blue-color"
                style={{
                  fontSize: "12px",
                  marginTop: "30px",
                }}
              >
                <i>
                  By signing below, you agree to this quote, the associated
                  costs, and any applicable terms and conditions.
                </i>
              </p>
            </Col>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button
            className="text-blue-color outline"
            onClick={toggle}
            style={{
              border: "1px solid #063164",
              background: "transparent",
            }}
          >
            Cancel
          </Button>
          <Button
            className="bg-button-blue-color"
            color="primary"
            onClick={saveSignature}
            style={{ border: "none" }}
          >
            {loader ? "Loading..." : "Submit"}
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <Toaster />
      <TimeEmpty
        open={isTimeEmptyModalOpen}
        setOpen={setIsTimeEmptyModalOpen}
        ContractId={contractData?.ContractId}
        fetchData={fetchData}
        CompanyId={CompanyId}
      />
      <Expances
        open={isExpanseModalOpen}
        setOpen={setIsExpanseModalOpen}
        ContractId={contractData?.ContractId}
        fetchData={fetchData}
        CompanyId={CompanyId}
        
      />
      <Visit
        open={isVisitModalOpen}
        setOpen={setIsVisitModalOpen}
        fetchData={fetchData}
        contractData={contractData}
        CompanyId={CompanyId}
        ContractId={contractData?.ContractId}
      />
      <ContractMail
        modal={mail}
        setModal={setMail}
        customerData={contractData?.customer}
        propertyData={contractData?.location}
        Attachment={contractData?.Attachment}
        contractData={contractData}
      />
    </>
  );
};

export default ContractDetails;
