import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import swal from "sweetalert";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth.js";
import moment from "moment";
import AxiosInstance from "../../AxiosInstance.js";
import Quote from "./Views/Quote.js";

const Quotes = () => {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [quotesData, setQuotesData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);

  const getData = async () => {
    try {
      const res = await AxiosInstance.get(
        `/quote/get_quotes/${localStorage.getItem("CompanyId")}`,
        {
          params: {
            pageSize: rowsPerPage,
            pageNumber: page,
            search: search || "",
          },
        }
      );
      setQuotesData(res.data.data);
      setCountData(res.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search]);

  const handleEditClick = (id) => {
    navigate(`/${companyName}/add-quotes`, {
      state: {
        id,
        navigats: [...location.state.navigats, "/add-quotes"],
      },
    });
  };

  const handleDelete = (id) => {
    swal("Are you sure you want to delete?", {
      buttons: ["No", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await AxiosInstance.delete(`/quote/${id}`);
          if (response.data.statusCode === 200) {
            setTimeout(() => {
              toast.success(response.data.message);
            }, 500);
            getData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error(error.message || "An error occurred");
        }
      }
    });
  };

  const cellData = quotesData?.map((item, index) => {
    return {
      key: item.QuoteId,
      value: [
        page * rowsPerPage + index + 1,
        `${item?.customer?.FirstName} ${item?.customer?.LastName}`,
        <>
          #{item?.QuoteNumber}
          <br />
          {item?.Title}
        </>,
        `${item?.location?.Address || ""} ${item?.location?.City || ""} ${
          item?.location?.State || ""
        } ${item?.location?.Country || ""} ${item?.location?.Zip || ""}`,
        moment(item.updatedAt).format("DD/MM/YYYY"),
        <b
          style={{
            color:
              item?.status === "Awaiting Response"
                ? "orange"
                : item?.status === "Approved"
                ? "#58cc58"
                : "",
          }}
        >
          {item?.status}
        </b>,
        `${item?.Total}`,
        <>
          <img
            src={Edit}
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(item.QuoteId);
            }}
          />
          <img
            className="mx-1"
            src={Delete}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item.QuoteId);
            }}
          />
        </>,
      ],
    };
  });

  const collapseData = quotesData?.map((item) => ({
    createdAt: item.createdAt || "No details provided",
  }));

  return (
    <>
      <Quote
        loader={loader}
        search={search}
        setSearch={setSearch}
        cellData={cellData}
        collapseData={collapseData}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        companyName={companyName}
        countData={countData}
        rowsPerPage={rowsPerPage}
      />
       <Toaster />
    </>
  );
};

export default Quotes;
