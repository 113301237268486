import React, { useState, useEffect } from "react";
import { formatDistanceToNow } from "date-fns";
import "./QuoteNotification.css";
import { handleAuth } from "../../../src/components/Login/Auth";
import AxiosInstance from "../../Views/AxiosInstance";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import quote from "../../assets/Blue-sidebar-icon/Quote.svg";
import contract from "../../assets/Blue-sidebar-icon/Contract.svg"; 
import customerIcon from "../../assets/Blue-sidebar-icon/Customer.svg";

const QuoteNotification = ({ onClick, notification }) => {
  const formattedTime =
    notification?.createdAt &&
    formatDistanceToNow(new Date(notification?.createdAt), {
      addSuffix: true,
    });

  return (
    <div
      className="notificationCard"
      onClick={() =>
        onClick(notification?.QuoteId, notification?.NotificationId, "quote")
      }
      style={{ cursor: "pointer" }}
    >
      <div className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={quote} alt="Quote Icon" />
      </div>
      <div className="notificationContent" style={{ cursor: "pointer" }}>
        <span className="title">
          {notification?.Company?.ownerName} created a quote - $
          {notification?.Quote?.Total}
        </span>
        <div className="description">
          <span
            className="quoteNumber"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            Quote #{notification?.Quote?.QuoteNumber} -{" "}
            {notification?.Quote?.Title}
          </span>
          <span className="additionalInfo">
            {notification?.Customer?.FirstName} {notification?.Customer?.LastName}
          </span>
        </div>
        <span className="time">{formattedTime}</span>
      </div>
    </div>
  );
};

const ContractNotification = ({ onClick, notification }) => {
  const formattedTime =
    notification?.createdAt &&
    formatDistanceToNow(new Date(notification?.createdAt), {
      addSuffix: true,
    });

  return (
    <div
      className="notificationCard"
      onClick={() =>
        onClick(notification?.ContractId, notification?.NotificationId, "contract")
      }
      style={{ cursor: "pointer" }}
    >
      <div className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={contract} alt="Contract Icon" />
      </div>
      <div className="notificationContent" style={{ cursor: "pointer" }}>
        <span className="title">
          {notification?.Company?.ownerName} created a contract - $
          {notification?.Contract?.Total}
        </span>
        <div className="description">
          <span
            className="quoteNumber"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            Contract #{notification?.Contract?.ContractNumber} -{" "}
            {notification?.Contract?.Title}
          </span>
          <span className="additionalInfo">
            {notification?.Customer?.FirstName} {notification?.Customer?.LastName}
          </span>
        </div>
        <span className="time">{formattedTime}</span>
      </div>
    </div>
  );
};

const CustomerNotification = ({ onClick, notification }) => {
  const formattedTime =
    notification?.createdAt &&
    formatDistanceToNow(new Date(notification?.createdAt), {
      addSuffix: true,
    });

  return (
    <div
      className="notificationCard"
      onClick={() =>
        onClick(notification?.CustomerId, notification?.NotificationId, "customer")
      }
      style={{ cursor: "pointer" }}
    >
      <div className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={customerIcon} alt="Customer Icon" />
      </div>
      <div className="notificationContent" style={{ cursor: "pointer" }}>
        <span className="title">
          {notification?.Company?.ownerName} added a new customer:{" "}
          {notification?.Customer?.FirstName} {notification?.Customer?.LastName}
        </span>
        <div className="description">
          <span
            className="quoteNumber"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            {notification?.Customer?.Email}
          </span>
        </div>
        <span className="time">{formattedTime}</span>
      </div>
    </div>
  );
};

const NotificationsPage = ({ handleCloseNotifications }) => {
  const { companyName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [tokenDecode, setTokenDecode] = useState({});
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await handleAuth(navigate, location);
        setTokenDecode(res.data.companyId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (tokenDecode) {
      fetchNotifications();
    }
  }, [tokenDecode]);

  const fetchNotifications = async () => {
    try {
      const response = await AxiosInstance.get(`/notifications/${tokenDecode}`);
      if (response.data.statusCode === 200) {
        const filteredNotifications = response.data.notifications.filter(
          (notification) =>
            (notification?.Quote && notification?.Quote?.QuoteNumber) ||
            (notification?.Contract && notification?.Contract?.ContractNumber) ||
            (notification?.Customer && notification?.Customer?.FirstName)
        );
        setNotifications(filteredNotifications || []);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        console.error("Company id is not found!");
      } else {
        console.error("Error fetching notifications:", error.message);
      }
    } finally {
      setLoader(false);
    }
  };

  const handleNotificationClick = async (id, notificationId, type) => {
    try {
      await AxiosInstance.put(`/notifications/${notificationId}`);
      handleCloseNotifications();
      fetchNotifications();
      const route = type === "quote" ? "quotes-detail" : type === "contract" ? "contractdetails" : "customerdetails";
      navigate(`/${companyName}/${route}`, {
        state: {
          id: id,
          navigats: ["/index", `/${route}`],
        },
      });
    } catch (error) {
      console.error("Error marking notification as read:", error.message);
    }
  };

  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "75%", width: "100%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            visible={loader}
          />
        </div>
      ) : (
        <div className="notificationsContainer quotes-notification">
          {notifications.length > 0 ? (
            notifications.map((notification) =>
              notification?.Quote ? (
                <QuoteNotification
                  key={notification._id}
                  notification={notification}
                  onClick={handleNotificationClick}
                />
              ) : notification?.Contract ? (
                <ContractNotification
                  key={notification._id}
                  notification={notification}
                  onClick={handleNotificationClick}
                />
              ) : notification?.Customer ? (
                <CustomerNotification
                  key={notification._id}
                  notification={notification}
                  onClick={handleNotificationClick}
                />
              ) : null
            )
          ) : (
            <div className="mt-4 text-center" style={{ color: "#063164" }}>
              No notifications available
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NotificationsPage;
