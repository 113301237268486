import toast from "react-hot-toast";

const sendToast = (message) => {
  toast.custom((t) => (
    <div
      style={{
        background: "#fff",
        color: "#063164",
        padding: "10px 8px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid #063164",
        borderRadius: "8px",
      }}
    >
      <span>{message}</span>
      <button
        style={{
          background: "#063164",
          color: "#fff",
          borderRadius: "4px",
          padding: "2px 5px",
          marginLeft: "12px",
          cursor: "pointer",
        }}
        onClick={() => toast.dismiss(t.id)}
      >
        OK
      </button>
    </div>
  ));
};

export default sendToast;
