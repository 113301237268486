import Login from "./components/Login";
import SignUp from "./components/Login/SignUp";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import SuperadminDashboard from "./Views/Dashboard/Superadmin";
import AdminDashboard from "./Views/Dashboard/Admin";
import client from "./assets/image/icons/client.svg";
import Company from "./Views/Superadmin/Company/Index";
import Plan from "./Views/Admin/Plan/Index";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ClientDetails from "./Views/Admin/Client/CustomerDetails";
import Client from "./Views/Admin/Client/Customer";
import AddClient from "./Views/Admin/Client/AddClient";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import Quotes from "./Views/Admin/Quotes/Quote";
import Addquotes from "./Views/Admin/Quotes/Addquotes";
import ForgotPassword from "./components/Login/ForgotPassword";
import NewPassword from "./components/Login/ResetPssword";
import Setting from "./components/Setting/SettingSidebar";
import ProductService from "./Views/Admin/Setting/Product&service/index";
import PropertyDetails from "./Views/Admin/Client/Customer-Property/PropertyDetails";
import AddContract from "./Views/Admin/Contract/AddContract";
import ProductAndServiceSuperadmin from "./Views/Superadmin/Product and Service/index";
import Job from "./Views/Admin/Contract/Contract";
import ClientDashboard from "./Views/Dashboard/Client";
import ClientQuotes from "./Views/Customer/Quotes";
import ClientQuotesdetail from "./Views/Customer/Quotesdetail";
import ResetPassword from "./components/Login/NewPassword";
import QuotesDetail from "./Views/Admin/Quotes/QuotesDetail";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import Profile from "./Views/Admin/Setting/Profile/index";
import ContractDetails from "./Views/Admin/Contract/Contractdetails";
import ManageTeamTable from "./Views/Admin/Setting/Manage Team/ManageTeamTable";
import AddUser from "./Views/Admin/Setting/Manage Team/AddUser";
import SuperadminPlan from "./Views/Superadmin/Plan/Plan";
import UserDetails from "./Views/Admin/Setting/Manage Team/UserDetails";
import client2 from "./assets/image/icons/create/client-blue.svg";
import StaffMember from "./Views/Dashboard/StaffMember";
import FirstTimeNewPassword from "./Views/Staff Member/FirstTimeNewPassword";
import SuperadminNmiKeys from "./Views/Superadmin/NmiKeys/NmiKeys";
import WorkerContract from "./Views/Staff Member/Worker Contract/WorkerContract";
import WorkerAddContract from "./Views/Staff Member/Worker Contract/WorkerAddContract";
import WorkerContractDetails from "./Views/Staff Member/Worker Contract/WorkerContractDetails";
import InvoiceDetailsClient from "./Views/Customer/Invoice/InvoiceDetails";

import PermissionSteps from "./Views/Superadmin/PermissionSteps/AddPermision";
import Permission from "./Views/Superadmin/PermissionSteps/Permission";
import CompanySchedual from "./Views/Admin/Schedual/Schedual/index";
import Invoice from "./Views/Admin/Invoice/Invoice";
import AddInvoice from "./Views/Admin/Invoice/AddInvoice";
import WorkerQuotes from "./Views/Staff Member/Worker Quotes/WorkerQuotes";
import WorkerAddQuotes from "./Views/Staff Member/Worker Quotes/WorkerAddQuote";
import WorkerDetailQuotes from "./Views/Staff Member/Worker Quotes/WorkerQuotesDetail";
import WorkerCustomer from "./Views/Staff Member/Worker Customer/WorkerCustomer";
import WorkerCustomerDetail from "./Views/Staff Member/Worker Customer/WorkerCustomerDetail";
import PlanPurchase from "./Views/Admin/Plan/PlanPurchase";
import InvoiceTable from "./Views/Admin/Invoice/InvoiceTable";
import InvoiceDetails from "./Views/Admin/Invoice/InvoiceDetails";
import Account from "./Views/Admin/Account/index";
import Email from "./Views/Superadmin/Mail service/index";
import ContracrClient from "./Views/Customer/Contract/Contract";
import ContracrDetailsClient from "./Views/Customer/Contract/ContractDetails";
import InvoiceClient from "./Views/Customer/Invoice/Invoice";
import Appoiments from "./Views/Customer/Appoiment/Index";
import AppoimentConformation from "./Views/Customer/Appoiment/AppoimentConformation";

// Admin sidebar icons
import DashboardBlue from "./assets/Blue-sidebar-icon/Home.svg";
import DashboardWhite from "./assets/White-sidebar-icon/Home.svg";
import ScheduleBlue from "./assets/Blue-sidebar-icon/Schedule.svg";
import ScheduleWhite from "./assets/White-sidebar-icon/Schedule.svg";
import ClientBlue from "./assets/Blue-sidebar-icon/Customer.svg";
import ClientWhite from "./assets/White-sidebar-icon/Customer.svg";
import QuoteBlue from "./assets/Blue-sidebar-icon/Quote.svg";
import QuoteWhite from "./assets/White-sidebar-icon/Quote.svg";
import ContractBlue from "./assets/Blue-sidebar-icon/Contract.svg";
import ContractWhite from "./assets/White-sidebar-icon/Contract.svg";
import InvoiceBlue from "./assets/Blue-sidebar-icon/Invoice.svg";
import InvoiceWhite from "./assets/White-sidebar-icon/Invoice.svg";
import AppoinmentBlue from "./assets/Blue-sidebar-icon/Appoinment-blue.svg";
import AppoinmentWhite from "./assets/White-sidebar-icon/Appoinment-white.svg";

// Superadmin Sidebar Icons

import PlanBlue from "./assets/Blue-sidebar-icon/Plan.svg";
import PlanWhite from "./assets/White-sidebar-icon/Plan.svg";
import CompanyBlue from "./assets/Blue-sidebar-icon/Company.svg";
import CompanyWhite from "./assets/White-sidebar-icon/Company.svg";
import ProductBlue from "./assets/Blue-sidebar-icon/Product & Services.svg";
import ProductWhite from "./assets/White-sidebar-icon/Product & Services.svg";
import PermissionBlue from "./assets/Blue-sidebar-icon/Permission.svg";
import PermissionWhite from "./assets/White-sidebar-icon/Permission.svg";
import NMIBlue from "./assets/Blue-sidebar-icon/NMI Keys.svg";
import NMIWhite from "./assets/White-sidebar-icon/NMI Keys.svg";
import EmailBlue from "./assets/Blue-sidebar-icon/Mail Service.svg";
import EmailWhite from "./assets/White-sidebar-icon/Mail Service.svg";
import InvoicePayment from "./Views/Admin/Invoice/InvoicePayment";
import LayoutTable from "./Views/Admin/Setting/Layout/LayoutTable";
import ColorPicker from "./Views/Admin/Setting/Layout/ColorPicker";
import WorkerInvoice from "./Views/Staff Member/Worker Invoice/WorkerInvoice";
import WorkerAddInvoice from "./Views/Staff Member/Worker Invoice/WorkerAddInvoice";
import WorkerInvoiceTable from "./Views/Staff Member/Worker Invoice/WorkerInvoicetable";
import WorkerInvoiceDetail from "./Views/Staff Member/Worker Invoice/WorkerInvoiceDetail";

var routes = [
  // =============== AUTH ===============
  {
    path: "/login",
    name: "Login",
    component: <Login />,
    layout: "/auth",
    isDisplay: true,
  },
  {
    path: "/signup",
    name: "Sign-Up",
    component: <SignUp />,
    layout: "/auth",
    isDisplay: true,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: <ForgotPassword />,
    layout: "/auth",
    isDisplay: true,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: <NewPassword />,
    layout: "/auth",
    isDisplay: true,
  },
  {
    path: "/new-password?email",
    name: "New Password",
    component: <NewPassword />,
    layout: "/auth",
    isDisplay: true,
  },
  {
    path: "/new-password",
    name: "New Password",
    component: <ResetPassword />,
    layout: "/auth",
    isDisplay: true,
  },
  {
    path: "/firsttimenewpassword",
    name: "FirstTimeNewPassword",
    icon: <HomeRoundedIcon />,
    component: <FirstTimeNewPassword />,
    layout: "/auth",
  },
  // =============== SUPER ADMIN ===============
  {
    path: "/index",
    name: "Dashboard",
    icon: <img src={DashboardWhite} />,
    icon2: <img src={DashboardBlue} />,
    component: <SuperadminDashboard />,
    layout: "/superadmin",
    isDisplay: true,
    module: "superadmin",
  },
  {
    path: "/plan",
    name: "Plan",
    icon: <img src={PlanWhite} />,
    icon2: <img src={PlanBlue} />,
    component: <SuperadminPlan />,
    layout: "/superadmin",
    isDisplay: true,
    module: "Plan",
  },
  // {
  //   path: "/product&service",
  //   name: "Product & Service",
  //   icon: <CategoryOutlinedIcon />,
  //   component: <ProductService />,
  //   layout: "/superadmin",
  //   isDisplay: false,
  // },
  // {
  //   path: "/manageteam",
  //   name: "Manage Team",
  //   icon: <CategoryOutlinedIcon />,
  //   component: <ManageTeamTable />,
  //   layout: "/superadmin",
  //   isDisplay: false,
  // },
  // {
  //   path: "/profile",
  //   name: "Profile",
  //   component: <Profile />,
  //   layout: "/superadmin",
  //   isDisplay: false,
  // },
  // {
  //   path: "/property-details",
  //   name: "Property Details",
  //   component: <SuperadminPropertyDetails />,
  //   layout: "/superadmin",
  //   isDisplay: false,
  // },
  // {
  //   path: "/customer",
  //   name: "Customer Details",
  //   component: <SuperadminClientDetails />,
  //   layout: "/superadmin",
  //   isDisplay: false,
  // },
  {
    path: "/company",
    name: "Company",
    icon: <img src={CompanyWhite} />,
    icon2: <img src={CompanyBlue} />,
    component: <Company />,
    layout: "/superadmin",
    isDisplay: true,
    module: "Company",
  },
  {
    path: "/product-service",
    name: "Product & Service",
    icon: <img src={ProductWhite} />,
    icon2: <img src={ProductBlue} />,
    component: <ProductAndServiceSuperadmin />,
    layout: "/superadmin",
    isDisplay: true,
    module: "Product & Service",
  },
  // {
  //   path: "/quotes",
  //   name: "Quotes",
  //   icon: <SentimentSatisfiedOutlinedIcon />,
  //   icon2: <SentimentSatisfiedOutlinedIcon style={{ color: "#063164" }} />,
  //   component: <SuperadminQuotes />,
  //   layout: "/superadmin",
  //   isDisplay: true,
  // },
  // {
  //   path: "/quotes-detail",
  //   name: "Quotes-Detail",
  //   icon: <SentimentSatisfiedOutlinedIcon />,
  //   component: <QuotesDetail />,
  //   layout: "/superadmin",
  //   isDisplay: false,
  // },

  // {
  //   path: "/access",
  //   name: "Access",
  //   icon: <MonetizationOnOutlinedIcon />,
  //   component: <SuperadminAccess />,
  //   layout: "/superadmin",
  //   isDisplay: false,
  // },
  // {
  //   path: "/team_access",
  //   name: "Team Access",
  //   icon: <MonetizationOnOutlinedIcon />,
  //   component: <SuperadminTeamAccess />,
  //   layout: "/superadmin",
  //   isDisplay: false,
  // },
  {
    path: "/add-permission",
    name: "Add Permission",
    icon: <MonetizationOnOutlinedIcon />,
    component: <PermissionSteps />,
    layout: "/superadmin",
    isDisplay: false,
    module: "Permission",
  },
  {
    path: "/permission",
    name: "Permission",
    icon: <img src={PermissionWhite} />,
    icon2: <img src={PermissionBlue} />,
    component: <Permission />,
    layout: "/superadmin",
    isDisplay: true,
    module: "Permission",
  },
  {
    path: "/nmikeys",
    name: "NMI Keys",
    icon: <img src={NMIWhite} />,
    icon2: <img src={NMIBlue} />,
    component: <SuperadminNmiKeys />,
    layout: "/superadmin",
    isDisplay: true,
    module: "NMI Keys ",
  },
  {
    path: "/email",
    name: "Mail Service",
    component: <Email />,
    icon: <img src={EmailWhite} />,
    icon2: <img src={EmailBlue} />,
    layout: "/superadmin",
    isDisplay: true,
  },
  // {
  //   path: "/Payment",
  //   name: "Payment",
  //   component: <Payment />,
  //   icon: <img src={PaymentWhite} />,
  //   icon2: <img src={PaymentBlue} />,
  //   layout: "/superadmin",
  //   isDisplay: true,
  // },
  // =============== COMPANY ===============
  {
    path: "/setting",
    name: "Setting",
    icon: <img src={client} />,
    component: <Setting />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Setting",
  },
  {
    path: "/add-user",
    name: "Add User",
    icon: <img src={client} />,
    component: <AddUser />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Setting",
  },
  {
    path: "/accout-billing",
    name: "Account",
    component: <Account />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Account",
  },
  {
    path: "/manageteam",
    name: "Manage Team",
    icon: <CategoryOutlinedIcon />,
    component: <ManageTeamTable />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Setting",
  },
  // {
  //   path: "/layout-table",
  //   name: "Layout Table",
  //   icon: <CategoryOutlinedIcon />,
  //   component: <LayoutTable />,
  //   layout: "/:companyName",
  //   isDisplay: false,
  //   module: "Setting",
  // },
  {
    path: "/product&service",
    name: "Product & Service",
    icon: <CategoryOutlinedIcon />,
    component: <ProductService />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Setting",
  },

  {
    path: "/contractdetails",
    name: "Contract-Details",
    component: <ContractDetails />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Contract",
  },
  {
    path: "/userdetails",
    name: "User Details",
    component: <UserDetails />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Setting",
  },
  {
    path: "/index",
    name: "Home",
    icon: <img src={DashboardWhite} />,
    icon2: <img src={DashboardBlue} />,
    component: <AdminDashboard />,
    layout: "/:companyName",
    isDisplay: true,
    module: "Home",
  },
  {
    path: "/schedule",
    name: "Schedule",
    icon: <img src={ScheduleWhite} />,
    icon2: <img src={ScheduleBlue} />,
    component: <CompanySchedual />,
    layout: "/:companyName",
    isDisplay: true,
    module: "Schedule",
  },
  {
    path: "/customer",
    name: "Customer",
    icon: <img src={ClientWhite} />,
    icon2: <img src={ClientBlue} />,
    component: <Client />,
    layout: "/:companyName",
    isDisplay: true,
    module: "Customer",
  },
  {
    path: "/quotesdetail",
    name: "Quotes",
    layout: "/:companyName",
    isDisplay: false,
    module: "Quote",
  },
  {
    path: "/quotes",
    name: "Quotes",
    icon: <img src={QuoteWhite} />,
    icon2: <img src={QuoteBlue} />,
    component: <Quotes />,
    layout: "/:companyName",
    isDisplay: true,
    module: "Quote",
  },
  {
    path: "/add-customer",
    name: "Add Customer",
    icon: <img src={ContractWhite} />,
    icon2: <img src={ContractBlue} />,
    component: <AddClient />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Customer",
  },
  {
    path: "/plan-purchase",
    name: "Plan Purchase",
    component: <PlanPurchase />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Account",
  },
  {
    path: "/add-contract",
    name: "Add Contract",
    icon: <img src={client} />,
    component: <AddContract />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Contract",
  },
  {
    path: "/add-quotes",
    name: "Add Quotes",
    icon: <SentimentSatisfiedOutlinedIcon />,
    component: <Addquotes />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Quote",
  },
  {
    path: "/quotes-detail",
    name: "Quotes-Detail",
    icon: <SentimentSatisfiedOutlinedIcon />,
    component: <QuotesDetail />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Quote",
  },
  {
    path: "/property-details",
    name: "Property Details",
    component: <PropertyDetails />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Customer",
  },
  {
    path: "/contract",
    name: "Contract",
    icon: <img src={ContractWhite} />,
    icon2: <img src={ContractBlue} />,
    component: <Job />,
    layout: "/:companyName",
    isDisplay: true,
    module: "Contract",
  },
  {
    path: "/plan",
    name: "Plan",
    icon: <MonetizationOnOutlinedIcon />,
    icon2: <MonetizationOnOutlinedIcon style={{ color: "#063164" }} />,
    component: <Plan />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Account",
  },
  {
    path: "/customerdetails",
    name: "Customer Details",
    component: <ClientDetails />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Customer",
  },
  {
    path: "/profile",
    name: "Profile",
    component: <Profile />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Setting",
  },
  {
    path: "/invoice",
    name: "Invoice",
    icon: <img src={InvoiceWhite} />,
    icon2: <img src={InvoiceBlue} />,
    component: <Invoice />,
    layout: "/:companyName",
    isDisplay: true,
    module: "Invoice",
  },
  {
    path: "/addinvoice",
    name: "Add Invoice",
    component: <AddInvoice />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Invoice",
  },
  {
    path: "/invoicetable",
    name: "Invoice Table",
    component: <InvoiceTable />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Invoice",
  },
  {
    path: "/invoice-details",
    name: "Invoice Details",
    component: <InvoiceDetails />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Invoice",
  },
  {
    path: "/invoice-payment",
    name: "Invoice Payment",
    component: <InvoicePayment />,
    layout: "/:companyName",
    isDisplay: false,
    module: "Invoice",
  },

  // =============== CUSTOMER ===============
  {
    path: "/add-quotes",
    name: "Add Quotes",
    icon: <SentimentSatisfiedOutlinedIcon />,
    component: <Addquotes />,
    layout: "/customers",
    isDisplay: false,
    module: "Quote",
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: <HomeRoundedIcon />,
    icon2: <HomeRoundedIcon style={{ color: "#063164" }} />,
    component: <ClientDashboard />,
    layout: "/customers",
    isDisplay: true,
    module: "Dashboard",
  },
  {
    path: "/add-customer",
    name: "Add Customer",
    icon: <img src={client} />,
    component: <AddClient />,
    layout: "/customers",
    isDisplay: false,
    module: "Customer",
  },
  {
    path: "/quotes",
    name: "Quotes",
    icon: <img src={QuoteWhite} />,
    icon2: <img src={QuoteBlue} />,
    component: <ClientQuotes />,
    layout: "/customers",
    isDisplay: true,
    module: "Quote",
  },
  {
    path: "/quotes-details",
    name: "Quotes Details",
    icon: <SentimentSatisfiedOutlinedIcon />,
    component: <ClientQuotesdetail />,
    layout: "/customers",
    isDisplay: false,
    module: "Quote",
  },
  {
    path: "/contract",
    name: "Contract",
    icon: <img src={ContractWhite} />,
    icon2: <img src={ContractBlue} />,
    component: <ContracrClient />,
    layout: "/customers",
    isDisplay: true,
    module: "Contract",
  },
  {
    path: "/contract-details",
    name: "Contract",
    icon: <img src={ContractWhite} />,
    icon2: <img src={ContractBlue} />,
    component: <ContracrDetailsClient />,
    layout: "/customers",
    isDisplay: false,
    module: "Contract",
  },
  {
    path: "/invoice",
    name: "Invoice",
    icon: <img src={InvoiceWhite} />,
    icon2: <img src={InvoiceBlue} />,
    component: <InvoiceClient />,
    layout: "/customers",
    isDisplay: true,
    module: "Invoice",
  },
  {
    path: "/invoice-details",
    name: "Invoice Details",
    icon: <img src={InvoiceWhite} />,
    icon2: <img src={InvoiceBlue} />,
    component: <InvoiceDetailsClient />,
    layout: "/customers",
    isDisplay: false,
    module: "Invoice",
  },
  {
    path: "/appointment",
    name: "Appointment",
    icon: <img src={AppoinmentWhite} />,
    icon2: <img src={AppoinmentBlue} />,
    component: <Appoiments />,
    layout: "/customers",
    isDisplay: true,
    module: "Appointment",
  },
  {
    path: "/appointment-confirm",
    name: "Confirm Appointment",
    icon: <img src={AppoinmentWhite} />,
    icon2: <img src={AppoinmentBlue} />,
    component: <AppoimentConformation />,
    layout: "/customers",
    isDisplay: false,
    module: "Appointment",
  },

  // =============== STAFF MEMBER ===============
  {
    path: "/index",
    name: "Dashboard",
    icon: <HomeRoundedIcon />,
    icon2: <HomeRoundedIcon style={{ color: "#063164" }} />,
    component: <StaffMember />,
    layout: "/staff-member",
    isDisplay: true,
    module: "Quote",
  },
  {
    path: "/add-customer",
    name: "Add Customer",
    icon: <img src={client} />,
    component: <AddClient />,
    layout: "/staff-member",
    isDisplay: false,
    module: "Add Customer ",
  },
  {
    path: "/workercustomer",
    name: "Customer",
    icon: <img src={ClientWhite} />,
    icon2: <img src={ClientBlue} />,
    component: <WorkerCustomer />,
    layout: "/staff-member",
    isDisplay: false,
    module: "Customer",
  },
  {
    path: "/WorkerQuotes",
    name: "Quotes",
    icon: <SentimentSatisfiedOutlinedIcon />,
    icon2: <SentimentSatisfiedOutlinedIcon style={{ color: "#063164" }} />,
    component: <WorkerQuotes />,
    layout: "/staff-member",
    isDisplay: true,
    module: "Quotes",
  },
  {
    path: "/add-quotes",
    name: "Add Quotes",
    icon: <SentimentSatisfiedOutlinedIcon />,
    component: <WorkerAddQuotes />,
    layout: "/staff-member",
    isDisplay: false,
    module: "Quotes",
  },
  {
    path: "/worker-quotes-details",
    name: "Quote Detail",
    icon: <img src={client} />,
    component: <WorkerDetailQuotes />,
    layout: "/staff-member",
    isDisplay: false,
    module: "Quotes",
  },
  {
    path: "/WorkerCustomerDetail",
    name: "Customer Detail",
    icon: <img src={client} />,
    component: <WorkerCustomerDetail />,
    layout: "/staff-member",
    isDisplay: false,
    module: "Customer",
  },
  {
    path: "/workercontract",
    name: "Contract",
    icon: <img src={ContractWhite} />,
    icon2: <img src={ContractBlue} />,
    component: <WorkerContract />,
    layout: "/staff-member",
    isDisplay: true,
    module: "Contract",
  },
  {
    path: "/add-contract",
    name: "Add Contract",
    icon: <img src={client} />,
    component: <WorkerAddContract />,
    layout: "/staff-member",
    isDisplay: true,
    module: "Contract",
  },
  {
    path: "/worker-contract-details",
    name: "Contract Detail",
    icon: <img src={client} />,
    component: <WorkerContractDetails />,
    layout: "/staff-member",
    isDisplay: false,
    module: "Contract",
  },
  //2529 workerinvoice
  {
    path: "/workerinvoice",
    name: "Invoice",
    icon: <img src={InvoiceWhite} />,
    icon2: <img src={InvoiceBlue} />,
    component: <WorkerInvoice />,
    layout: "/staff-member",
    isDisplay: true,
    module: "Invoice",
  },
  {
    path: "/workeraddinvoice",
    name: "Add-Invoice",
    icon: <img src={InvoiceWhite} />,
    icon2: <img src={InvoiceBlue} />,
    component: <WorkerAddInvoice />,
    layout: "/staff-member",
    isDisplay: true,
    module: "Invoice",
  },
  {
    path: "/workerinvoicetable",
    name: "Invoice Table",
    component: <WorkerInvoiceTable />,
    layout: "/staff-member",
    isDisplay: false,
    module: "Invoice",
  },
  {
    path: "/workerinvoicedetail",
    name: "Invoice-detail",
    icon: <img src={InvoiceWhite} />,
    icon2: <img src={InvoiceBlue} />,
    component: <WorkerInvoiceDetail />,
    layout: "/staff-member",
    isDisplay: true,
    module: "Invoice",
  },
  {
    path: "/invoice-payment",
    name: "Invoice Payment",
    component: <InvoicePayment />,
    layout: "/staff-member",
    isDisplay: true,
    module: "Invoice",
  },
];

export default routes;
