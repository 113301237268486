import AxiosInstance from "../../Views/AxiosInstance";
import React, { useEffect, useState } from "react";
import { Card, Input, Label } from "reactstrap";
import PermissionBox from "./PermissionBox";

const defaultPermission = {
  Title: "Limited worker",
  Description:
    " Can view their schedule, mark work complete, and track their time",
  Schedule: {
    ViewTheirOwnSchedule: false,
    ViewAndCompleteTheirOwnSchedule: false,
    EditTheirOwnSchedule: false,
    EditEveryonesSchedule: false,
    EditAndDeleteEveryonesSchedule: false,
  },
  TimeTrackingAndTimesheets: {
    ViewAndRecordTheirOwn: false,
    ViewRecordAndEditTheirOwn: false,
    ViewRecordAndEditEveryones: false,
  },
  Notes: {
    ViewNotesOnJobsAndVisitsOnly: false,
    ViewAllNotes: false,
    ViewAndEditAll: false,
    ViewEditAndDeleteAll: false,
  },
  Expenses: {
    ViewRecordAndEditTheirOwn: false,
    ViewRecordAndEditEveryones: false,
  },
  ShowPricing: {
    AllowsEditingOfQuotesInvoicesAndLineItemsOnJobs: false,
  },
  ClientsProperties: {
    ViewClientNameAndAddressOnly: false,
    ViewFullClientAndPropertyInfo: false,
    ViewAndEditFullClientAndPropertyInfo: false,
    ViewEditAndDeleteFullClientAndPropertyInfo: false,
  },
  Quotes: {
    ViewOnly: false,
    ViewCreateAndEdit: false,
    ViewCreateEditAndDelete: false,
  },
  Contract: {
    ViewOnly: false,
    ViewCreateAndEdit: false,
    ViewCreateEditAndDelete: false,
  },
  Reports: {
    UsersWillOnlyBeAbleToSeeReportsAvailableToThemBasedOnTheirOtherPermissions: false,
    Users: false,
  },
};

const deepEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const Permissions = ({ data, setData }) => {
  const baseUrl = process.env.REACT_APP_BASE_API;

  const [permissions, setPermissions] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState(data);
  const [isCustomChecked, setIsCustomChecked] = useState(false); // Track custom checkbox state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await AxiosInstance.get(`${baseUrl}/permissionsteps/get`);
        const permissionData = res.data.data;
        const permissionsData = permissionData.map((item) => {
          const result = {
            Title: item?.Title,
            Description: item?.Description,
          };

          for (const [key, value] of Object.entries(item.permissions)) {
            if (!result[key]) {
              result[key] = {};
            }
            result[key] = value;
          }

          return result;
        });
        setPermissions(permissionsData);
        if (!data) {
          setData(permissionsData[0]);
          setSelectedPermission(permissionsData[0]);
        }
      } catch (error) {
        console.error("Error: ", error.message);
      }
    };
    fetchData();
  }, []);

  const handleChange = (item) => {
    setData(item);
    setSelectedPermission(item);
    setIsCustomChecked(false); // Uncheck custom checkbox if selecting a predefined permission
  };

  useEffect(() => {
    if (
      !permissions.find((item) =>
        deepEqual(
          { ...selectedPermission, Title: "", Description: "" },
          { ...item, Title: "", Description: "" }
        )
      )
    ) {
      setData(() => {
        return {
          ...selectedPermission,
          Title: "Custom",
          Description: "",
        };
      });
    } else {
      setData(() =>
        permissions.find((item) =>
          deepEqual(
            { ...selectedPermission, Title: "", Description: "" },
            { ...item, Title: "", Description: "" }
          )
        )
      );
    }
  }, [selectedPermission]);

  return (
    <div>
      <Card
        style={{
          padding: "40px",
          marginTop: "10px",
          borderRadius: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <p
          className="text-blue-color"
          style={{ fontWeight: 700, fontSize: "30px" }}
        >
          Permissions
        </p>
        <p
          className="text-blue-color mb-2 heading1"
          style={{ fontWeight: 700, fontSize: "22px" }}
        >
          Preset Permission Levels
        </p>
        <p className="text-blue-color mb-2 text1" style={{ fontSize: "14px" }}>
          Start with a predefined permission setting and modify it according to
          your needs.
        </p>
        <div>
          {permissions?.length > 0 &&
            permissions.map((item) => (
              <div key={item.Title} className="d-flex gap-2">
                <Input
                  type="checkbox"
                  className="border-blue-color"
                  style={{
                    height: "15px",
                    width: "15px",
                    marginTop: "7px",
                  }}
                  name="Title"
                  checked={deepEqual(item, data)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleChange(item);
                    } else {
                      setData({});
                      setSelectedPermission({});
                    }
                  }}
                />
                <Label check className="ml-2">
                  <span
                    className="text-blue-color"
                    style={{ fontSize: "14px" }}
                  >
                    {item?.Title}
                  </span>
                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(6, 49, 100, 50%)",
                    }}
                    name="Description"
                  >
                    {item?.Description}
                  </p>
                </Label>
              </div>
            ))}

          <div className="d-flex gap-2">
            <Input
              type="checkbox"
              className="border-blue-color"
              style={{
                height: "15px",
                width: "15px",
                marginTop: "7px",
                cursor: "pointer",
              }}
              checked={isCustomChecked}
              onChange={(e) => {
                if (e.target.checked) {
                  setIsCustomChecked(true);
                  setData(defaultPermission); // Show all permissions when custom is checked
                  setSelectedPermission(defaultPermission);
                } else {
                  setIsCustomChecked(false);
                  setData({}); // Clear permissions when unchecked
                  setSelectedPermission({});
                }
              }}
            />
            <Label check className="ml-2">
              <span className="text-blue-color" style={{ fontSize: "14px" }}>
                Custom
              </span>
            </Label>
          </div>
        </div>
        {isCustomChecked && // Show permissions only if Custom is checked
          Object.entries(defaultPermission).length > 0 &&
          selectedPermission &&
          Object.entries(selectedPermission).length > 0 &&
          Object.entries(defaultPermission).map(
            ([key, value], index) =>
              key !== "Title" &&
              key !== "Description" && (
                <PermissionBox
                  key={index}
                  option={key}
                  optionValue={value}
                  data={selectedPermission}
                  setData={setSelectedPermission}
                />
              )
          )}
      </Card>
    </div>
  );
};

export default Permissions;
