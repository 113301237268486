import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import swal from "sweetalert";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../AxiosInstance.js";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postFile } from "../../../components/Files/Functions";
import InvoiceViews from "../../Admin/Invoice/Views/Invoice.js";
import { handleAuth } from "../../../components/Login/Auth.js";

const Invoice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_API;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState(true);
  const [isCustomer, setIsCustomer] = useState(false);
  const [source, setModalSource] = useState(false);
  const [countData, setCountData] = useState(0);
  const [isProperty, setIsProperty] = useState(false);
  const [propertyData, setPropertyData] = useState({});
  const [customersData, setCustomersData] = useState({});
  const [loading, setLoading] = useState(false);
  const [tokenDecode, setTokenDecode] = useState({});

  const [lineItems, setLineItems] = useState([
    {
      Description: "",
      Name: "",
      Type: "",
      Units: "",
      Attachment: "",
      CostPerUnit: "",
      Cost: "",
      Markup: "",
      Total: "",
      isNew: true,
    },
  ]);

  const fetchData = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [invoiceData, setInvoiceData] = useState([]);
  const getData = async () => {
    if (tokenDecode?.companyId) {
      try {
        const res = await AxiosInstance.get(
          `/invoice/${tokenDecode?.companyId}`,
          {
            params: {
              pageSize: rowsPerPage,
              pageNumber: page,
              search: search || "",
            },
          }
        );
        if (res.data.statusCode === 200) {
          setInvoiceData(res.data.data);
          setCountData(res.data.totalCount);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search, tokenDecode]);

  // const handleEditClick = (id) => {
  //   if (id) {
  //     navigate("/staff-member/workeraddinvoice", {
  //       state: {
  //         invoiceId: id,
  //         navigats: [...location.state.navigats, "/workeraddinvoice"],
  //       },
  //     });
  //   }
  // };

  const handleEditClick = (id) => {
    if (id) {
      navigate("/" + "staff-member" + "/workeraddinvoice", {
        state: {
          invoiceId: id,
          navigats: [...location.state.navigats, "/workeraddinvoice"],
        },
      });
    }
  };

  // Delete
  //2529 delete refresh issue
  const handleDelete = (id) => {
    swal("Are you sure you want to delete?", {
      buttons: ["No", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await AxiosInstance.delete(`/invoice/${id}`);
          if (response.data.statusCode === 200) {
            setInvoiceData((prevData) =>
              prevData.filter((item) => item.InvoiceId !== id)
            );
            setTimeout(() => {
              toast.success(response.data.message);
            }, 500);
            getData();
          } else {
            toast.error("", response.data.message, "error");
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error(error);
        }
      }
    });
  };
  //2529 delete refresh issue

  const formik = useFormik({
    initialValues: {
      Title: "",
      InvoiceNumber: 1,
      CustomerId: "",
      CompanyId: localStorage.getItem("CompanyId"),
      LocationId: "",
      CustomerMessage: "",
      ContractDisclaimer:
        "Contract/ Disclaimer\nThis quote is valid for the next 30 days, after which values may be subject to change.",
      Attachment: [],
      Discount: "",
    },
    validationSchema: Yup.object({
      Title: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const strings = [];
        for (let item of values.Attachment) {
          if (typeof item !== "string") {
            const string = await postFile(item);
            strings.push(string);
          } else {
            strings.push(item);
          }
        }

        for (let item of lineItems) {
          if (typeof item?.Attachment !== "string") {
            const string = await postFile(item.Attachment);
            item.Attachment = string;
          }
        }
        const object = {
          ...values,
          details: lineItems,
          Attachment: strings,
        };
        let response;
        if (!location.state?.id) {
          response = await AxiosInstance.post(`${baseUrl}/invoice`, object);
        } else {
          response = await AxiosInstance.put(
            `${baseUrl}/invoice/${location.state?.id}`,
            object
          );
        }
        toast.success(response.message);

        if (response.status === 200) {
          toast.success(response.message);
          navigate(`/staff-member/workerinvoice`, {
            state: {
              navigats: location.state.navigats.filter(
                (item) => item !== "/workeraddinvoice"
              ),
            },
          });
        }
      } catch (error) {
        toast.error("", error.message, "error");

        console.error("Error: ", error);
      }
    },
  });

  // const cellData = invoiceData?.map((item, index) => {
  //   return {
  //     key: item?.InvoiceId,
  //     value: [
  //       page * rowsPerPage + index + 1,
  //       <>
  //         {item?.customer?.FirstName} {item?.customer?.LastName}
  //       </>,
  //       <>
  //         #{item?.InvoiceNumber}
  //         <br />
  //         {item?.Subject}
  //       </>,
  //       <>
  //         <br />
  //         {item?.location &&
  //           Object.entries(item?.location)
  //             .map(([key, value]) => value || "")
  //             .join(", ")}
  //       </>,
  //       item.DueDate || "-",
  //       `$${item.Total}` || "-",
  //       `$${item.invoiceAccount}` || "",
  //       <span
  //         style={{
  //           color:
  //             item.Status === "Paid"
  //               ? "#089F57"
  //               : item.Status === "Unpaid"
  //               ? "#F82C50"
  //               : "#E88C44",
  //           fontWeight: 500,
  //         }}
  //       >
  //         {item.Status}
  //       </span>,
  //       <>
  //         <img
  //           src={Edit}
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             handleEditClick(item.InvoiceId);
  //           }}
  //         />
  //         <img
  //           className="mx-1"
  //           src={Delete}
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             handleDelete(item.InvoiceId);
  //           }}
  //         />
  //       </>,
  //     ],
  //   };
  // });
  const [staffData, setStaffData] = useState(undefined);
  useEffect(() => {
    const fetchStaffData = async () => {
      try {
        const response = await AxiosInstance.get(
          `/worker/get/${localStorage.getItem("worker_id")}`
        );
        setStaffData(response.data.data.permissions);
      } catch (error) {}
    };
    if (location.pathname.includes("/staff-member")) {
      fetchStaffData();
    }
  }, []);
  // const cellData = invoiceData?.map((item, index) => {
  //   return staffData?.Invoice?.ViewCreateAndEdit ||
  //     staffData?.Invoice?.ViewCreateEditAndDelete
  //     ? {
  //       key: item?.InvoiceId,
  //       value: [
  //         page * rowsPerPage + index + 1,
  //         <>
  //           {item?.customer?.FirstName} {item?.customer?.LastName}
  //         </>,
  //         <>
  //           #{item?.InvoiceNumber}
  //           <br />
  //           {item?.Subject}
  //         </>,
  //         <>
  //           <br />
  //           {item?.location &&
  //             Object.entries(item?.location)
  //               .map(([key, value]) => value || "")
  //               .join(", ")}
  //         </>,
  //         item.DueDate || "-",
  //         `$${item.Total}` || "-",
  //         `$${item.invoiceAccount}` || "",
  //         <span
  //           style={{
  //             color:
  //               item.Status === "Paid"
  //                 ? "#089F57"
  //                 : item.Status === "Unpaid"
  //                 ? "#F82C50"
  //                 : "#E88C44",
  //             fontWeight: 500,
  //           }}
  //         >
  //           {item.Status}
  //         </span>,
  //         <>
  //           <img
  //             src={Edit}
  //             onClick={(e) => {
  //               e.stopPropagation();
  //               handleEditClick(item.InvoiceId);
  //             }}
  //           />
  //           <img
  //             className="mx-1"
  //             src={Delete}
  //             onClick={(e) => {
  //               e.stopPropagation();
  //               handleDelete(item.InvoiceId);
  //             }}
  //           />
  //         </>,
  //       ],
  //       }
  //     : {
  //       key: item?.InvoiceId,
  //       value: [
  //         page * rowsPerPage + index + 1,
  //         <>
  //           {item?.customer?.FirstName} {item?.customer?.LastName}
  //         </>,
  //         <>
  //           #{item?.InvoiceNumber}
  //           <br />
  //           {item?.Subject}
  //         </>,
  //         <>
  //           <br />
  //           {item?.location &&
  //             Object.entries(item?.location)
  //               .map(([key, value]) => value || "")
  //               .join(", ")}
  //         </>,
  //         item.DueDate || "-",
  //         `$${item.Total}` || "-",
  //         `$${item.invoiceAccount}` || "",
  //         <span
  //           style={{
  //             color:
  //               item.Status === "Paid"
  //                 ? "#089F57"
  //                 : item.Status === "Unpaid"
  //                 ? "#F82C50"
  //                 : "#E88C44",
  //             fontWeight: 500,
  //           }}
  //         >
  //           {item.Status}
  //         </span>,

  //       ],
  //       };
  // });

  const cellData = invoiceData?.map((item, index) => {
    const isEditable = item.Status !== "Canceled"; // Check if the invoice is editable

    return staffData?.Invoice?.ViewCreateAndEdit ||
      staffData?.Invoice?.ViewCreateEditAndDelete
      ? {
          key: item?.InvoiceId,
          value: [
            page * rowsPerPage + index + 1,
            <>
              {item?.customer?.FirstName} {item?.customer?.LastName}
            </>,
            <>
              #{item?.InvoiceNumber}
              <br />
              {item?.Subject}
            </>,
            <>
              <br />
              {item?.location &&
                Object.entries(item?.location)
                  .map(([key, value]) => value || "")
                  .join(", ")}
            </>,
            item.DueDate || "-",
            `$${item.Total}` || "-",
            `$${item.invoiceAccount}` || "",
            <span
              style={{
                color:
                  item.Status === "Paid"
                    ? "#089F57"
                    : item.Status === "Unpaid"
                    ? "#F82C50"
                    : "#E88C44",
                fontWeight: 500,
              }}
            >
              {item.Status}
            </span>,
            <>
            <div className="text-center">
              {staffData?.Invoice?.ViewCreateAndEdit ||
              staffData?.Invoice?.ViewCreateEditAndDelete ||
              !staffData ? (
                <img
                  src={Edit}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item?.Status !== "Canceled") {
                      handleEditClick(item.InvoiceId);
                    }
                  }}
                  style={{
                    opacity: item?.Status === "Canceled" ? 0.5 : 1,
                    cursor:
                      item?.Status === "Canceled" ? "not-allowed" : "pointer",
                  }}
                  alt="edit"
                />
              ) : null}
           
              {staffData?.Invoice?.ViewCreateEditAndDelete || !staffData ? (
                <img
                  className="mx-1"
                  alt=""
                  src={Delete}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(item?.InvoiceId);
                  }}
                />
              ) : null}
                 </div>
            </>,
          ],
        }
      : {
          key: item?.InvoiceId,
          value: [
            page * rowsPerPage + index + 1,
            <>
              {item?.customer?.FirstName} {item?.customer?.LastName}
            </>,
            <>
              #{item?.InvoiceNumber}
              <br />
              {item?.Subject}
            </>,
            <>
              <br />
              {item?.location &&
                Object.entries(item?.location)
                  .map(([key, value]) => value || "")
                  .join(", ")}
            </>,
            item.DueDate || "-",
            `$${item.Total}` || "-",
            `$${item.invoiceAccount}` || "",
            <span
              style={{
                color:
                  item.Status === "Paid"
                    ? "#089F57"
                    : item.Status === "Unpaid"
                    ? "#F82C50"
                    : "#E88C44",
                fontWeight: 500,
              }}
            >
              {item.Status}
            </span>,
          ],
        };
  });

  return (
    <>
      <InvoiceViews
        loader={loader}
        search={search}
        setSearch={setSearch}
        cellData={cellData}
        setIsCustomer={setIsCustomer}
        setModalSource={setModalSource}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        companyName={companyName}
        countData={countData}
        rowsPerPage={rowsPerPage}
        isCustomer={isCustomer}
        formik={formik}
        lineItems={lineItems}
        propertyData={propertyData}
        setPropertyData={setPropertyData}
        isProperty={isProperty}
        setIsProperty={setIsProperty}
        customersData={customersData}
        setCustomersData={setCustomersData}
        source={source}
        staffData={staffData}
      />
      <Toaster />
    </>
  );
};

export default Invoice;
