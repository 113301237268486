// import { Autocomplete, Grid, Input,  } from "@mui/material";
import React, { useState } from "react";
import SignUp1 from "../../assets/image/sign_up1.png";
import SignUp2 from "../../assets/image/sign_up2.png";
import appLogo from "../../assets/image/CMS_LOGO.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../Views/AxiosInstance";
import {
  Autocomplete,
  TextField,
  FormControl,
  Grid,
  FormGroup,
} from "@mui/material";

import Select from "react-select";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import InputText from "../InputFields/InputText";
import sendToast from "../../components/Toast/sendToast";

const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    color: "rgba(153, 153, 153, 1)",
    fontSize: "16px",
    fontWeight: "400",
    height: "48px",
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins, sans-serif",
  }),
  control: (provided, state) => ({
    ...provided,
    border: "1px solid rgba(121, 116, 126, 1)",
    borderRadius: "8px",
    marginTop: "15px",
    color: "rgba(28, 27, 31, 1)",
    fontSize: "16px",
    fontWeight: "400",
    height: "48px",
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins, sans-serif",
    boxShadow: state.isFocused ? "none" : provided.boxShadow,
    "&:hover": {
      borderColor: "rgba(121, 116, 126, 1)",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "16px",
    color: state.selectProps ? "rgba(28, 27, 31, 1)" : "rgba(153, 153, 153, 1)",
    fontWeight: "400",
    height: "48px",
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins, sans-serif",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Poppins, sans-serif",
    backgroundColor: state.isFocused && "rgba(51, 53, 71, 1)",
    color: state.isFocused && "#fff",
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Poppins, sans-serif",
    backgroundColor: "#fff",
  }),
};

const Steps = ({ primaryEmailAddress, Password }) => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [industry, setIndustry] = useState([]);
  const [teamSize, setTeamSize] = useState([]);
  const [revenue, setRevenue] = useState([]);

  const fetchData = async (currentStepa) => {
    if (currentStepa === 1) {
      try {
        const res = await AxiosInstance.get(`${baseUrl}/industry/industry`);
        if (res.data.statusCode === 200) {
          setIndustry(() => {
            return res.data.data.map((item) => {
              return { label: item.industry, value: item.industryId };
            });
          });
        }
      } catch (error) {
        sendToast("Unable to connect to the server. Please try again later.");
      }
    } else {
      try {
        const res = await AxiosInstance.get(`${baseUrl}/revenue/revenue`);
        const res2 = await AxiosInstance.get(`${baseUrl}/teamsize/teamsize`);
        if (res.data.statusCode === 200 && res2.data.statusCode === 200) {
          const data1 = res.data.data?.map((item) => {
            return { label: item.revenue, value: item.revenueId };
          });
          const data2 = res2.data.data?.map((item) => {
            return { label: item.teamSize, value: item.teamSizeId };
          });
          setRevenue(data1);
          setTeamSize(data2);
        }
      } catch (error) {
        sendToast("Unable to connect to the server. Please try again later.");
      }
    }
  };

  useState(() => {
    fetchData(1);
  }, []);

  const formik = useFormik({
    initialValues: {
      ownerName: "",
      phoneNumber: "",
      primaryEmailAddress: "",
      industry: {
        label: "Select industry type here...",
        value: "",
      },
      companyName: "",
      teamSize: {
        label: "Select your team size (including yourself) here...",
        value: "",
      },
      revenue: {
        label: "Select your estimated annual revenue here...",
        value: "",
      },
    },
    validationSchema:
      currentStep === 1
        ? Yup.object({
            ownerName: Yup.string().required("Required"),
            phoneNumber: Yup.number().required("Required"),
            industry: Yup.object({
              label: Yup.string().required("Required"),
              value: Yup.string().required("Required"),
            }).required("Required"),
          })
        : Yup.object({
            companyName: Yup.string().required("Required"),
            teamSize: Yup.object({
              label: Yup.string().required("Required"),
              value: Yup.string().required("Required"),
            }).required("Required"),
            revenue: Yup.object({
              label: Yup.string().required("Required"),
              value: Yup.string().required("Required"),
            }).required("Required"),
          }),
    onSubmit: (values) => {
      if (currentStep === 1) {
        setCurrentStep(currentStep + 1);
        fetchData(currentStep + 1);
      } else {
        handleSubmit(values);
      }
    },
  });

  const [loader, setLoader] = useState(false);
  const handleSubmit = async (values) => {
    values.primaryEmailAddress = primaryEmailAddress;
    values.Password = Password;
    values.industryId = values.industry.value;
    values.teamSizeId = values.teamSize.value;
    values.revenueId = values.revenue.value;
    try {
      setLoader(true);
      const res = await AxiosInstance.post(
        `${baseUrl}/company/register`,
        values
      );
      if (res.data.statusCode === 201) {
        toast.success(res.data.message);
        setTimeout(() => {
          navigate("/auth");
        }, 500);
      } else {
        sendToast(res.data.message);
      }
    } catch (error) {
      sendToast("Unable to connect to the server. Please try again later.");
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Grid container spacing={2} className="vh-100" style={{}}>
        <Grid item xs={12} md={8}>
          <div className="steps">
            <div className="my-steps mt-3">
              <div className="app-logos mb-5" style={{ marginLeft: "-20px" }}>
                <Link to="/auth/login">
                  <img src={appLogo} alt="logo" />
                </Link>
              </div>
              <div className="row main w-100">
                <div
                  className="progress px-0 mx-1 mt-2 col-3"
                  style={{ height: "8px", borderRadius: "20px" }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: currentStep > 0 ? "100%" : "0%" }}
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div
                  className="progress px-0 mx-1 mt-2 col-3"
                  style={{ height: "8px", borderRadius: "20px" }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: currentStep > 1 ? "100%" : "0%" }}
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div
                className="content mb-2"
                style={{
                  fontSize: "40px",
                  color: "rgba(51, 53, 71, 1)",
                  lineHeight: "60px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  width: "316px",
                }}
              >
                {currentStep === 1 && "Your free trial is now active !"}
                {currentStep === 2 && "Set up your business !"}
              </div>

              <form id="multi-step-form" onSubmit={formik.handleSubmit}>
                {currentStep === 1 && (
                  <div className="step step-1">
                    <h3
                      style={{
                        fontSize: "16px",
                        color: "rgba(51, 53, 71, 1)",
                        lineHeight: "24px",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                      }}
                    >
                      Let's get you started.
                    </h3>
                    <FormGroup className="mb-3">
                      <FormGroup
                        style={{ width: "100%" }}
                        className="fullnemae mb-3"
                      >
                        <InputText
                          value={formik.values?.ownerName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.ownerName &&
                            Boolean(formik.errors.ownerName)
                          }
                          helperText={
                            formik.touched.ownerName && formik.errors.ownerName
                          }
                          name="ownerName"
                          placeholder="John Due"
                          label="Enter full name here..."
                          type="text"
                          className="text-blue-color w-100"
                          fieldHeight="56px"
                        />
                      </FormGroup>
                      <FormGroup style={{ width: "100%" }}>
                        <InputText
                          value={formik.values?.phoneNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.phoneNumber &&
                            Boolean(formik.errors.phoneNumber)
                          }
                          helperText={
                            formik.touched.phoneNumber &&
                            formik.errors.phoneNumber
                          }
                          name="phoneNumber"
                          placeholder="(555) 555-1234"
                          label="Enter phone here..."
                          type="text"
                          className="text-blue-color w-100"
                          fieldHeight="56px"
                        />
                      </FormGroup>

                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        options={industry}
                        placeholder="Select industry type here..."
                        defaultValue={{
                          label:
                            "Select your team size (including yourself) here...",
                          value: "",
                        }}
                        styles={customStyles}
                        value={formik.values.industry}
                        onChange={(selectedOption) =>
                          formik.setFieldValue(
                            "industry",
                            selectedOption
                              ? selectedOption
                              : {
                                  label:
                                    "Select your team size (including yourself) here...",
                                  value: "",
                                }
                          )
                        }
                      />
                      <div className="w-100 text-danger mt-0 pt-0">
                        {formik.touched?.industry?.value &&
                        formik.errors?.industry?.value
                          ? formik.errors?.industry?.value
                          : ""}
                      </div>
                    </FormGroup>
                    <button
                      type="button"
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                      className="btn btn-circle bg-orange-color"
                    >
                      <ArrowForwardRoundedIcon />
                    </button>
                  </div>
                )}

                {currentStep === 2 && (
                  <div className="step step-1">
                    <h3
                      style={{
                        fontSize: "16px",
                        color: "rgba(51, 53, 71, 1)",
                        lineHeight: "24px",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                      }}
                    >
                      This will help us customize your experience at contractor
                      management system.
                    </h3>
                    <FormGroup className="mb-3 mt-3">
                      <FormGroup style={{ width: "100%" }}>
                        <InputText
                          value={formik.values?.companyName}
                          onChange={(e) => {
                            const name = e.target.value
                              .replace(/\s+/g, "")
                              .toLowerCase();
                            formik.setFieldValue("companyName", name);
                          }}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.companyName &&
                            Boolean(formik.errors.companyName)
                          }
                          helperText={
                            formik.touched.companyName &&
                            formik.errors.companyName
                          }
                          name="companyName"
                          placeholder="TechWave Solutions"
                          label="Enter company name here..."
                          type="text"
                          className="text-blue-color w-100"
                          fieldHeight="56px"
                        />
                      </FormGroup>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        options={teamSize}
                        placeholder="Select your team size (including yourself) here..."
                        defaultValue={{
                          label:
                            "Select your team size (including yourself) here...",
                          value: "",
                        }}
                        styles={customStyles}
                        value={formik.values.teamSize}
                        onChange={(selectedOption) =>
                          formik.setFieldValue(
                            "teamSize",
                            selectedOption
                              ? selectedOption
                              : {
                                  label:
                                    "Select your team size (including yourself) here...",
                                  value: "",
                                }
                          )
                        }
                      />
                      <div className="w-100 text-danger mt-0 pt-0">
                        {formik.touched?.teamSize &&
                        formik.errors?.teamSize?.value
                          ? formik.errors?.teamSize?.value
                          : ""}
                      </div>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        options={revenue}
                        placeholder="Select your estimated annual revenue here..."
                        defaultValue={{
                          label: "Select your estimated annual revenue here...",
                          value: "",
                        }}
                        styles={customStyles}
                        value={formik.values.revenue}
                        onChange={(selectedOption) =>
                          formik.setFieldValue(
                            "revenue",
                            selectedOption
                              ? selectedOption
                              : {
                                  label:
                                    "Select your estimated annual revenue here...",
                                  value: "",
                                }
                          )
                        }
                      />
                      <div className="w-100 text-danger mt-0 pt-0">
                        {formik.touched?.revenue?.value &&
                        formik.errors?.revenue?.value
                          ? formik.errors?.revenue?.value
                          : ""}
                      </div>
                    </FormGroup>
                    <button
                      type="button"
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                      className="btn  btn-square bg-orange-color"
                      disabled={loader}
                    >
                      {loader ? (
                        <ColorRing
                          height="30"
                          width="30"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                          ariaLabel="circles-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      ) : (
                        "Start"
                      )}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} className="main-imageeee">
          {currentStep === 1 ? (
            <img
              src={SignUp1}
              alt="SignUp1"
              style={{ maxHeight: "100%", height: "100vh" }}
            />
          ) : (
            <img
              src={SignUp2}
              alt="SignUp2"
              style={{ maxHeight: "100%", height: "100vh" }}
            />
          )}
        </Grid>
      </Grid>
      <Toaster />
    </>
  );
};

export default Steps;
