import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import toast from "react-hot-toast";
import { addCardDetails } from "../../plugins/ApiHandler";
import AddCardForm from "../../components/AddPayment/AddCardForm";

const AddCardDetailsForm = (props) => {
  const addCardInfo = async (data) => {
    const CompanyId = localStorage.getItem("CompanyId");
    const CustomerIds = props.CustomerId;

    data.CompanyId = CompanyId || "";
    data.CustomerId = CustomerIds || "";
    data.type = "card";
    try {
      await addCardDetails(data);
      props.onHide();
      toast.success("Card details added successfully");
      props.fetchData();
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <Dialog
        open={props.show}
        onClose={props.onHide}
        fullWidth
        maxWidth="md"
        scroll="paper"
      >
        <DialogTitle>
          <Typography variant="h5" className="text-lg">
            User Information
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!props.scriptGenerating && !props.scriptError && (
                <AddCardForm
                  addCardInfo={addCardInfo}
                  onHide={props.onHide}
                  CustomerId={props.CustomerId}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCardDetailsForm;
