import {
  Button,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import login from "../../assets/image/icons/login.png";
import AppLogo from "../../assets/image/CMS_LOGO.svg";
import "./style.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../Views/AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import InputText from "../InputFields/InputText";
import sendToast from "../Toast/sendToast";

const getToken = () => {
  const adminToken = localStorage.getItem("adminToken");
  if (adminToken) {
    return adminToken;
  }
  const workerToken = localStorage.getItem("workerToken");
  if (workerToken) {
    return workerToken;
  }
  const customerToken = localStorage.getItem("customerToken");
  if (customerToken) {
    return customerToken;
  }
};

const Login = () => {
  const baseUrl = process.env.REACT_APP_BASE_API;

  const navigate = useNavigate();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      Password: "",
      primaryEmailAddress: "",
    },
    validationSchema: Yup.object().shape({
      primaryEmailAddress: Yup.string()
        .email("Invalid email")
        .required("Email is required")
        .matches(/^[^@]+@[^@]+\.[^@]+$/, "Email must contain '@' and '.'"),
      Password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [loader, setLoader] = useState(false);
  const handleSubmit = async (values) => {
    try {
      setLoader(true);
      const res = await AxiosInstance.post(`${baseUrl}/company/login`, {
        ...values,
      });
      if (res.data.statusCode === 301) {
        localStorage.setItem("adminToken", res.data.token);
        localStorage.setItem("CompanyId", res.data.data.id);
        toast.success(res.data.message);
        setTimeout(() => {
          navigate(`/${res.data.data.companyName}/index`, {
            state: { navigats: ["/index"] },
          });
        }, 1000);
      } else if (res.data.statusCode === 300) {
        localStorage.setItem("adminToken", res.data.token);
        localStorage.setItem("admin_id", res.data.data.id);
        setTimeout(() => {
          navigate("/superadmin/index", {
            state: { navigats: ["/index"] },
          });
        }, 1000);
        // navigate("/superadmin/index", { state: { navigats: ["/index"] } });
        toast.success(res.data.message);
      } else if (res.data.statusCode === 302) {
        localStorage.setItem("workerToken", res.data.token);
        localStorage.setItem("worker_id", res.data.data.id);
        setTimeout(() => {
          navigate("/staff-member/index", {
            state: { navigats: ["/index"] },
          });
        }, 1000);
        // navigate("/staff-member/index", { state: { navigats: ["/index"] } });
        toast.success(res.data.message);
      } else if (res.data.statusCode === 303) {
        localStorage.setItem("customerToken", res.data.token);
        localStorage.setItem("CustomerId", res.data.data.id);
        setTimeout(() => {
          navigate("/customers/index", {
            state: { navigats: ["/index"] },
          });
        }, 1000);
        // navigate(`/customers/index`, { state: { navigats: ["/index"] } });
        toast.success(res.data.message);
      } else if (res.data.statusCode === 201) {
        sendToast(res.data.message);
      } else if (res.data.statusCode === 202) {
        sendToast(res.data.message);
      } else if (res.data.statusCode === 204) {
        sendToast(res.data.message);
      }
    } catch (error) {
      if (error.response) {
        sendToast(error.response.data.message || "An error occurred");
      } else {
        sendToast("Something went wrong. Please try again later.");
      }
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = getToken();
      if (token) {
        try {
          const res = await AxiosInstance.post(`/company/token_data`, {
            token,
          });
          if (res.data.statusCode !== 200) {
            localStorage.clear();
            navigate("/auth/login");
          } else {
            if (
              res.data.data.role === "Superadmin" &&
              !location.pathname.includes("/superadmin")
            ) {
              localStorage.setItem("admin_id", res.data.data.superAdminId);
              navigate("/superadmin/index", {
                state: { navigats: ["/index"] },
              });
            } else if (
              res.data.data.role === "client" &&
              !location.pathname.includes("/customers")
            ) {
              localStorage.setItem("CustomerId", res.data.data.CustomerId);
              navigate("/customers/index", {
                state: { navigats: ["/index"] },
              });
            } else if (
              res.data.data.role === "Company" &&
              !location.pathname.includes(`/${res.data.data.companyName}`)
            ) {
              localStorage.setItem("CompanyId", res.data.data.companyId);
              navigate(`/${res.data.data.companyName}/index`, {
                state: { navigats: ["/index"] },
              });
            } else if (
              res.data.data.role === "worker" &&
              !location.pathname.includes(`/staff-member`)
            ) {
              localStorage.setItem("worker_id", res.data.data.WorkerId);
              navigate(`/staff-member/index`, {
                state: { navigats: ["/index"] },
              });
            }
          }
        } catch (err) {
          if (err.response) {
            if (err.response.status === 401) {
              sendToast("Session is expire. Please log in again.");
              localStorage.clear();
            } else if (err.response.status === 404) {
              sendToast("Session not found. Please log in again.");
              localStorage.clear();
            } else {
              sendToast("Unauthorized access. Please log in again.");
            }
          } else {
            sendToast(
              "Unable to connect to the server. Please try again later."
            );
            localStorage.clear();
          }
          navigate("/auth/login");
        }
      }
    };
    fetchData();
  }, [navigate, location.pathname]);

  return (
    <div className="login">
      <Grid container spacing={2} className="vh-100">
        <Grid item xs={12} md={6} className="d-flex flex-direction-column pt-3">
          <Typography
            className="app-logo"
            style={{
              paddingRight: "60px",
              paddingLeft: "39px",
              paddingTop: "60px",
              paddingBottom: "60px",
            }}
          >
            <img src={AppLogo} alt="logo"></img>
          </Typography>
          <div
            style={{
              width: "100%",
              height: "90%",
              paddingTop: "40px",
              paddingRight: "50px",
              paddingLeft: "50px",
            }}
            className="d-flex flex-direction-column align-items-center loginformcontent"
          >
            <form
              className="loginform"
              style={{ width: "70%", height: "90%" }}
              onSubmit={formik.handleSubmit}
            >
              <Typography className="text text-blue-color">Login</Typography>
              <Typography className="text2">
                Please login to access your account.
              </Typography>
              <FormGroup
                className="text-boxes"
                style={{ width: "100%", marginTop: "24px" }}
              >
                <InputText
                  value={formik.values?.primaryEmailAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.primaryEmailAddress &&
                    Boolean(formik.errors.primaryEmailAddress)
                  }
                  helperText={
                    formik.touched.primaryEmailAddress &&
                    formik.errors.primaryEmailAddress
                  }
                  name="primaryEmailAddress"
                  label="Primary Email "
                  type="text"
                  className="text-blue-color w-100"
                  fieldHeight="56px"
                  InputLabelProps={{
                    shrink:
                      Boolean(formik.values?.primaryEmailAddress) ||
                      formik.touched.primaryEmailAddress,
                  }}
                />
              </FormGroup>

              <FormGroup
                className="text-boxes"
                style={{ width: "100%", marginTop: "24px" }}
              >
                <InputText
                  value={formik.values?.Password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.Password && Boolean(formik.errors.Password)
                  }
                  helperText={formik.touched.Password && formik.errors.Password}
                  name="Password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  className="text-blue-color w-100 m-0 mb-3"
                  fieldHeight="56px"
                  InputLabelProps={{
                    shrink:
                      Boolean(formik.errors.Password) ||
                      formik.touched.Password,
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormGroup>
              <Typography
                className="text-orange-color"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "21px",
                  textAlign: "right",
                  marginTop: "13px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/auth/forgot-password")}
              >
                Forgot Password?
              </Typography>
              <FormGroup style={{ width: "100%", marginTop: "15px" }}>
                <Button
                  style={{
                    backgroundColor: "rgba(51, 53, 71, 1)",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "21px",
                    borderRadius: "4px",
                    gap: "4px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  type="submit"
                  disabled={loader}
                >
                  {loader ? (
                    <ColorRing
                      height="30"
                      width="30"
                      colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    "Login"
                  )}
                </Button>
              </FormGroup>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "21px",
                  textAlign: "center",
                  marginTop: "13px",
                }}
              >
                Do you not have an account?{" "}
                <span
                  className="text-orange-color"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/auth/signup")}
                >
                  Sign up
                </span>
              </Typography>
              {/* <Typography
                className="d-flex justify-content-between align-items-center"
                style={{ width: "100%", marginTop: "15px" }}
              >
                <hr
                  style={{
                    width: "auto",
                    height: "1px",
                    opacity: "25%",
                    backgroundColor: "rgba(49, 49, 49, 1)",
                  }}
                  className="flex-grow-1"
                />
                <p
                  style={{
                    color: "rgba(49, 49, 49, 1)",
                    opacity: "50%",
                    fontSize: "14px",
                  }}
                  className="flex-grow-2 my-auto mx-1"
                >
                  Or login with
                </p>
                <hr
                  style={{
                    width: "auto",
                    height: "1px",
                    opacity: "25%",
                    backgroundColor: "rgba(49, 49, 49, 1)",
                  }}
                  className="flex-grow-1"
                />
              </Typography>
              {/* <FormGroup
                style={{
                  width: "100%",
                  marginTop: "15px",
                  border: "2px solid red",
                }}
              >
                <Button
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "21px",
                    borderRadius: "4px",
                    gap: "16px",
                    height: "56px",
                    border: "1px solid rgba(81, 93, 239, 1)",
                  }}
                >
                  <img src={googlee} alt="logo"></img>
                </Button>
                 <GoogleLogin
                  onSuccess={responseMessage}
                  onError={errorMessage}
                />
                <GoogleLogin
                  clientId="109485462398-08b9d741r0n23gjll3hpq7qsfr0eoe9r.apps.googleusercontent.com"
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                  className="google-login"
                />
              </FormGroup> */}
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "21px",
                  textAlign: "center",
                  marginTop: "13px",
                  color: "rgba(49, 49, 49, 1)",
                }}
              >
                "This website is safeguarded by reCAPTCHA, and your use is
                subject to Google's{" "}
                <span style={{ fontWeight: "600" }}>Privacy Policy</span> and{" "}
                <span style={{ fontWeight: "600" }}>Terms of Service."</span>
              </Typography>
            </form>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center img"
        >
          <img src={login} alt="logo" className="login-image"></img>
        </Grid>
      </Grid>
      <Toaster />
    </div>
  );
};

export default Login;
