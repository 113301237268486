import React from "react";
import { Circles } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";

import {
  JobberPagination,
  JobberSearch,
  JobberTable,
} from "../../../../components/MuiTable";
import CustomerModal from "../../Quotes/CustomerModal";

const Invoice = ({
  loader,
  search,
  setSearch,
  cellData,
  setIsCustomer,
  setModalSource,
  page,
  setPage,
  setRowsPerPage,
  companyName,
  countData,
  rowsPerPage,
  isCustomer,
  formik,
  lineItems,
  propertyData,
  setPropertyData,
  isProperty,
  setIsProperty,
  customersData,
  setCustomersData,
  source,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className="justify-content-center align-items-center mb-3">
        <div className="d-flex justify-content-between mb-2 align-items-center">
          <h3 className="text-blue-color" style={{ fontWeight: 700 }}>
            Invoice
          </h3>
          <Button
            style={{ color: "#fff" }}
            onClick={(e) => {
              e.preventDefault();
              setIsCustomer(true);
              setModalSource("Invoice");
            }}
            className="text-capitalize bg-button-blue-color"
          >
            Add Invoice
          </Button>
        </div>
        <Card
          style={{
            borderRadius: "20px",
            border: "2px solid #063164",
            padding: 0,
          }}
        >
          <CardHeader
            className="d-flex justify-content-between align-items-center table-header bg-blue-color"
            style={{
              borderBottom: "2px solid #063164",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <h5 className="custe text-light">Invoice</h5>
            <div className="customer d-flex">
              <JobberSearch
                search={search}
                setSearch={setSearch}
                style={{ background: "transparant", color: "white" }}
              />
            </div>
          </CardHeader>
          {loader ? (
            <div className="d-flex justify-content-center align-items-center my-5 text-blue-color">
              <Circles
                height="50"
                width="50"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loader}
                color="#063164"
              />
            </div>
          ) : (
            <CardBody style={{ padding: "10px 0px" }}>
              <JobberTable
                headerData={[
                  "Sr No.",
                  "Invoice Number",
                  "Customer Name ",
                  "Due Date",
                  "Total Amount",
                  "Due Amount",
                  "Status",
                  "Action",
                ]}
                cellData={cellData}
                isCollapse={false}
                page={page}
                isNavigate={true}
                navigatePath={`/${companyName}/invoice-details`}
              />
            </CardBody>
          )}
          <CardFooter
            className="bg-orange-color"
            style={{
              borderTop: "2px solid #063164",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <JobberPagination
              totalData={countData}
              currentData={rowsPerPage}
              dataPerPage={rowsPerPage}
              pageItems={[10, 25, 50]}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </CardFooter>
        </Card>
        <CustomerModal
          isCustomer={isCustomer}
          setIsCustomer={setIsCustomer}
          isProperty={isProperty}
          setIsProperty={setIsProperty}
          setFieldValue={formik.setFieldValue}
          values={formik.values}
          lineItems={lineItems}
          propertyData={propertyData}
          setPropertyData={setPropertyData}
          customersData={customersData}
          setCustomersData={setCustomersData}
          formik={formik}
          source={source}
        />
      </div>
    </>
  );
};

export default Invoice;
