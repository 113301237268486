import React, { useEffect, useState } from "react";
// import "../Client/style.css";
import { handleAuth } from "../../../components/Login/Auth";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Previous from "../../../assets/image/icons/Previous.png";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// import "./style.css";
import {
  Button,
  Card,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Nav,
  NavItem,
  Table,
} from "reactstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postFile } from "../../../components/Files/Functions";
import AxiosInstance from "../../AxiosInstance";
import toast, { Toaster } from "react-hot-toast";
import { Label } from "recharts";
import InputText from "../../../components/InputFields/InputText";
import {
  CalendarJOB,  
  InternalNotes,
  OneOffContract,
  RecurringContract,
  Team,
} from "../../../components/Contract Component/Index";
import GetProducts from "../../../components/Products/GetProducts";
import { ColorRing } from "react-loader-spinner";
import CustomerModal from "../../Admin/Quotes/CustomerModal";
import AddContract from "../../Admin/Contract/views/AddContract";

function WorkerAddContract() {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const { companyName } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const [isProperty, setIsProperty] = useState(false);
  const [isNumChange, setIsNumChange] = useState(false);
  const [isCalendarVisible, setIsCalendarVisible] = React.useState(true);
  const [contractData, setContractData] = useState({});
  const [propertyData, setPropertyData] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [isCustomer, setIsCustomer] = useState(false);
  const [customersData, setCustomersData] = useState({});
  const [lineItems, setLineItems] = useState([
    {
      Description: "",
      Name: "",
      Attachment: "",
      Type: "",
      Unit: "",
      CostPerUnit: "",
      Hour: "",
      CostPerHour: "",
      Square: "",
      CostPerSquare: "",
      Total: "",
      isNew: true,
    },
  ]);

  //2529 handleAuth
  const [companyId, setCompanyId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await handleAuth(navigate, location);
        setCompanyId(res.data.companyId);
        setContractData(res.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //2529 handleAuth
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const formik = useFormik({
    initialValues: {
      Title: "",
      QuoteId: "",
      Description: "",
      ContractNumber: 1,
      CustomerId: "",
      LocationId: "",
      contract_disclaimer:
        "Contract/ Disclaimer\nThis quote is valid for the next 30 days, after which values may be subject to change.",
      Notes: "",
      Attachment: [],
      OneoffJob: {
        StartDate: "",
        EndDate: "",
        StartTime: "",
        EndTime: "",
        ScheduleLetter: false,
        Repeats: "",
      },
      RecuringJob: {
        StartDate: "",
        StarTime: "",
        EndTime: "",
        Repeats: "",
        Duration: "",
      },
      selectedTeams: [],
      subTotal: "",
    },
    validationSchema: Yup.object({
      Title: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        const attachmentURLs = [];
        for (let file of formik.values.Attachment) {
          if (typeof file !== "string") {
            const uploadedFilePath = await postFile(file);
            attachmentURLs.push(uploadedFilePath);
          } else {
            attachmentURLs.push(file);
          }
        }

        for (let item of lineItems) {
          if (typeof item?.Attachment !== "string") {
            const string = await postFile(item.Attachment);
            item.Attachment = string;
          }
        }

        const object = {
          ...values,
          Attachment: attachmentURLs,
          products: lineItems,
          selectedTeams: selectedTeams,
          IsOneoffJob: activeTab === 1 ? true : false,
          IsRecuringJob: activeTab === 2 ? true : false,
          Total: Total,
          subTotal: subTotal,
          CompanyId: companyId,
        };

        if (activeTab === 1) {
          delete object.IsRecuringJob;
        } else if (activeTab === 2) {
          delete object.OneoffJob;
        }

        let response;
        if (!location.state?.id) {
          response = await AxiosInstance.post(`/contract`, object);
        } else {
          response = await AxiosInstance.put(
            `/contract/${location.state?.id}`,
            object
          );
        }

        //2529 workercontract ma navigate
        if (response.data.statusCode === 200) {
          toast.success(response.data.message);
          navigate(`/staff-member/workercontract`, {
            state: {
              navigats: ["/index", "/workercontract"],
            },
          });
        }
        return response.data;
      } catch (error) {
        console.error("Error: ", error);
      } finally {
        setLoading(false);
      }
    },
  });
    //2529 workercontract ma navigate

  const [showCosts, setShowCosts] = useState(
    new Array(lineItems.length).fill(false)
  );
  const [menuIsOpen, setMenuIsOpen] = useState(
    new Array(lineItems.length).fill(false)
  );
  const handleSelectChange = (index, selectedOption) => {
    const newLineItems = [...lineItems];
    newLineItems[index] = {
      ...newLineItems[index],
      ...selectedOption,
      Total: selectedOption?.Unit
        ? Number(selectedOption?.Unit || 0) *
          Number(selectedOption?.CostPerUnit || 0)
        : selectedOption?.Square
        ? Number(selectedOption?.Square || 0) *
          Number(selectedOption?.CostPerSquare || 0)
        : Number(selectedOption?.Hour || 0) *
          Number(selectedOption?.CostPerHour || 0),
    };
    setLineItems(newLineItems);
    setMenuIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  const addLineItem = () => {
    setLineItems([...lineItems, {}]);
  };
  const deleteLineItem = (index) => {
    const newLineItems = lineItems.filter((_, i) => i !== index);
    setLineItems(newLineItems);
  };

  const [selectedTeams, setSelectedTeams] = useState([]);

  const handleSaveQuote = async () => {
    try {
      await formik.submitForm();
    } catch (error) {
      console.error("Error saving quote: ", error);
    }
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (location.state?.id) {
        try {
          const res = await AxiosInstance.get(
            `/contract/contract_details/${location.state.id}`
          );
          if (res.data?.statusCode === 200) {
            setLoading(true);
            const data = res.data?.data;
            formik.setValues({
              Title: data?.Title,
              Firstname: data?.customer?.Firstname,
              LastName: data?.customer?.LastName,
              ContractNumber: data?.ContractNumber,
              CustomerId: data?.CustomerId,
              CompanyId: data.CompanyId,
              LocationId: data?.LocationId,
              CustomerMessage: data?.CustomerMessage,
              ContractDisclaimer: data?.ContractDisclaimer,
              Notes: data?.Notes,
              Attachment: data?.Attachment,
              Discount: data?.Discount,
              Tax: data?.Tax,
              subTotal: data?.subTotal,
              Description: data?.Description,
              OneoffJob: data?.OneoffJob,
              RecuringJob: data?.RecuringJob,
            });
            setContractData(data);
            setLineItems(
              data?.Items || [
                {
                  Description: "",
                  Name: "",
                  Type: "",
                  Unit: "",
                  Attachment: "",
                  CostPerUnit: "",
                  Hour: "",
                  CostPerHour: "",
                  Square: "",
                  CostPerSquare: "",
                  Total: "",
                  isNew: true,
                },
              ]
            );
          }
        } catch (error) {
          console.error("Error: ", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Contract Number
    const getNumber = async () => {
      try {
        if (!location.state?.id) {
          const res = await AxiosInstance.get(
            `/contract/get_number/${companyId}`
          );
          if (res.data?.statusCode === 200) {
            const nextContractNumber = res.data?.contractNumber + 1;
            formik.setValues((values) => {
              return {
                ...values,
                ContractNumber: nextContractNumber,
              };
            });
          }
        }
      } catch (error) {
        console.error("Error fetching quote number: ", error);
      }
    }; 
    

    const initialize = async () => {
      if (location.state?.previewData) {
        formik.setValues(location.state.previewData?.values);
        setLineItems(
          location.state.previewData?.lineItems || [
            {
              Description: "",
              Name: "",
              Type: "",
              Unit: "",
              Attachment: "",
              CostPerUnit: "",
              Hour: "",
              CostPerHour: "",
              Square: "",
              CostPerSquare: "",
              Total: "",
              isNew: true,
              subTotal: "",
            },
          ]
        );
        formik.setFieldValue("CustomerId", location.state.CustomerId);
        formik.setFieldValue("LocationId", location.state.LocationId);
        window.history.replaceState(
          {
            id: location.state?.previewData?.id
              ? location.state?.previewData?.id
              : null,
          },
          ""
        );
      } else if (location.state?.id) {
        await fetchData();
      } else if (location?.state?.formData) {
        formik.setValues(location?.state?.formData);
        setLineItems(location?.state?.products);
      }
      await getNumber();
    };


    //2529 companyId
    initialize();
    fetchData();
    return () => {
      formik.resetForm();
      setLineItems([
        {
          FirstName: "",
          LastName: "",
          Description: "",
          Name: "",
          Type: "",
          Unit: "",
          Attachment: "",
          CostPerUnit: "",
          Hour: "",
          CostPerHour: "",
          Square: "",
          CostPerSquare: "",
          Total: "",
          isNew: true,
          subTotal: "",
        },
      ]);
    };
  }, [companyId]);

  //2529 companyId

  // useEffect(() => {
  //   getNumber()
  // }, []);

  const calculateSubTotal = () => {
    const Total = lineItems?.reduce(
      (sum, item) => sum + Number(item.Total || 0),
      0
    );
    return Total;
  };

  const subTotal = calculateSubTotal();
  const handleCahngeIds = (value) => {};
  const [mail, setMail] = useState(false);

  const handleContractNumberChange = async () => {
    const enteredContractNumber = Number(formik.values.ContractNumber);
    // const companyId = localStorage.getItem("CompanyId");
    try {
      const res = await AxiosInstance.post(
        `/contract/check_number/${companyId}`,
        {
          ContractNumber: enteredContractNumber,
        }
      );
      if (res.data?.statusCode === 200) {
        toast.success("Contract number is valid and added successfully.");
        setIsNumChange(false);
      } else if (res.data?.statusCode === 203) {
        toast.error(
          "Contract number already exists. Please choose a different number."
        );
        formik.setFieldValue("ContractNumber", "");
      } else {
        toast.error("Failed to check Contract number. Please try again.");
      }
    } catch (error) {
      console.error(
        "Error checking Contract number: ",
        error.response || error.message
      );
      toast.error("An error occurred while checking the Contract number.");
    }
  };

  const [showDiscount, setShowDiscount] = useState(false);

  const [showTax, setShowTax] = useState(false);

  const discountAmount = formik.values.Discount
    ? (Number(formik.values.Discount) * subTotal) / 100
    : 0;
  const discountedTotal = subTotal - discountAmount;
  const taxAmount = formik.values.Tax
    ? (Number(formik.values.Tax) * discountedTotal) / 100
    : 0;
  const Total = (discountedTotal + taxAmount)?.toFixed(2);

  return (
    <>
      {" "}
      <AddContract
        lineItems={lineItems}
        isProperty={isProperty}
        setIsProperty={setIsProperty}
        setIsCustomer={setIsCustomer}
        isCustomer={isCustomer}
        setPropertyData={setPropertyData}
        setCustomersData={setCustomersData}
        mail={mail}
        setMail={setMail}
        customersData={customersData}
        contractData={contractData}
        propertyData={propertyData}
        formik={formik}
        handleSaveQuote={handleSaveQuote}
        toggle={toggle}
        dropdownOpen={dropdownOpen}
        setLoading={setLoading}
        loading={loading}
        Total={Total}
        taxAmount={taxAmount}
        showTax={showTax}
        setShowTax={setShowTax}
        showDiscount={showDiscount}
        setShowDiscount={setShowDiscount}
        discountAmount={discountAmount}
        deleteLineItem={deleteLineItem}
        subTotal={subTotal}
        addLineItem={addLineItem}
        showCosts={showCosts}
        setShowCosts={setShowCosts}
        setMenuIsOpen={setMenuIsOpen}
        menuIsOpen={menuIsOpen}
        handleSelectChange={handleSelectChange}
        setLineItems={setLineItems}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleCahngeIds={handleCahngeIds}
        selectedTeams={selectedTeams}
        setSelectedTeams={setSelectedTeams}
        isCalendarVisible={isCalendarVisible}
        setIsCalendarVisible={setIsCalendarVisible}
        isNumChange={isNumChange}
        setIsNumChange={setIsNumChange}
        handleContractNumberChange={handleContractNumberChange}
      />
    </>
  );
}

export default WorkerAddContract;
