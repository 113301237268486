import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardText } from "reactstrap";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import AxiosInstance from "../../AxiosInstance";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import "./style.css";
import { Circles } from "react-loader-spinner";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function Quotes() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [quotesData, setQuotesData] = useState([]);
  const [tokenDecode, setTokenDecode] = useState({});

  const fetchData = async () => {
    setLoader(true);
    setLoader(true);
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching token decode data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const CompanyId = tokenDecode.CompanyId;
  const CustomerId = tokenDecode.CustomerId;

  useEffect(() => {
    if (CompanyId && CustomerId) {
      setLoader(true);
      AxiosInstance.get(`/visits/customer_visits/${CompanyId}/${CustomerId}`)
        .then((response) => {
          if (response.data.statusCode === 200) {
            setQuotesData(response.data.data);
          } else {
            console.error("Error fetching visits data");
          }
        })
        .catch((error) => {
          console.error("API error:", error);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [CompanyId, CustomerId]);

  const categorizeVisits = (visits) => {
    const today = moment().startOf("day");
    const todayVisits = [];
    const upcomingVisits = [];
    const pastVisits = [];

    visits.forEach((visit) => {
      const visitDate = moment(visit.StartDate);
      if (visitDate.isSame(today, "day")) {
        todayVisits.push(visit);
      } else if (visitDate.isAfter(today)) {
        upcomingVisits.push(visit);
      } else {
        pastVisits.push(visit);
      }
    });

    return {
      todayVisits,
      upcomingVisits,
      pastVisits,
    };
  };

  const { todayVisits, upcomingVisits, pastVisits } =
    categorizeVisits(quotesData);

  const renderVisitCards = (visits) => (
    <div className="visit-card-container">
      {visits.map((quote) => (
        <Card
          key={quote._id}
          className="invoice-card mb-2"
          onClick={() =>
            navigate(`/customers/appointment-confirm`, {
              state: {
                id: quote.VisitId,
                contractId: quote.ContractId,
                navigats: [...location.state.navigats, "/appointment-confirm"],
              },
            })
          }
        >
          <CardHeader className="invoice-card-header text-end">
            <CalendarMonthOutlinedIcon className="invoice-icon" /> Appointment:
            &nbsp;
            {moment(quote.StartDate).format("DD/MM/YYYY")}
          </CardHeader>
          <CardBody>
            <div className="text-end mb-2">
              {quote.IsConfirm && (
                <Button
                  className="Appoiment text-blue-color"
                  style={{
                    border: "none",
                    background: "#FFCDD2",
                    borderRadius: "20px",
                    fontWeight: 400,
                  }}
                >
                  Confirmed
                </Button>
              )}
            </div>
            <CardText className="invoice-card-text">
              <AccessTimeIcon className="map-icon"  style={{ marginRight: "5px" }}/>{" "}
              {moment(quote.StartTime, "HH:mm").format("hh:mm A")}
            </CardText>
            <CardText className="invoice-card-text">
              <MyLocationOutlinedIcon
                className="map-icon"
                style={{ marginRight: "5px" }}
              />{" "}
              {quote.Location?.Address} {quote.Location?.City} <br />
              {quote.Location?.State}, {quote.Location?.Country}
            </CardText>
          </CardBody>
        </Card>
      ))}
    </div>
  );

  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center my-5 text-blue-color"
          style={{ height: "80vh" ,marginTop:"50%"}}
        >
          <Circles
            height="50"
            width="50"
            ariaLabel="circles-loading"
            visible={loader}
            color="#063164"
          />
        </div>
      ) : quotesData.length > 0 ? (
        <div className="row">
          <p className="invoice-header mb-4">Your Appointment</p>
          {todayVisits.length > 0 && (
            <div className="col text-center appointment-status-section">
              <h5 className="appointment-status-header">Today</h5>
              {renderVisitCards(todayVisits)}
            </div>
          )}

          {upcomingVisits.length > 0 && (
            <div className="col text-center appointment-status-section">
              <h5 className="appointment-status-header">Upcoming</h5>
              {renderVisitCards(upcomingVisits)}
            </div>
          )}

          {pastVisits.length > 0 && (
            <div className="col text-center appointment-status-section">
              <h5 className="appointment-status-header">Past</h5>
              {renderVisitCards(pastVisits)}
            </div>
          )}
        </div>
      ) : (
        <div className="text-center">
          <p>Appointment Not Available.</p>
        </div>
      )}
    </>
  );
}

export default Quotes;
