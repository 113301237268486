import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import home from "../../../../assets/image/icons/home.svg";
import { Button, Col, Input, Row } from "reactstrap";
import { Country, State, City } from "country-state-city";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../../AxiosInstance";
import Address from "../../../../components/Address";

const CustomerProperty = ({ open, setOpen, data, getData }) => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const { companyName } = useParams();
  const CustomerId = data?.CustomerId;

  var companyId = localStorage.getItem("CompanyId");

  const formik = useFormik({
    initialValues: {
      CompanyId: "",
      CompanyId: "",
      Address: "",
      City: "",
      State: "",
      Zip: "",
      Country: "",
    },
    validationSchema: Yup.object({
      Address: Yup.string().required("Adress is required"),
      City: Yup.string().required("City is required"),
      State: Yup.string().required("State is required"),
      Zip: Yup.string().required("Zipcode is required"),
      Country: Yup.string().required("Country is required"),
    }),
    onSubmit: async (values) => {
      if (open.propertyData) {
        try {
          const response = await AxiosInstance.put(
            `${baseUrl}/location/${open.propertyData.LocationId}`,
            values
          );
          if (response.data.statusCode === 200) {
            toast.success(response.data.message);
            getData();
            setOpen({ isOpen: false, propertyData: null });
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error(error.message);
          console.error("There was an error submitting the form!", error);
        }
      } else {
        try {
          values["CompanyId"] = companyId;
          values["CustomerId"] = CustomerId;
          const response = await AxiosInstance.post(
            `${baseUrl}/location`,
            values
          );

          if (response.data.statusCode === 200) {
            toast.success(response.data.message);
            getData();
            setOpen({ isOpen: false, propertyData: "" });
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error(error.message);
          console.error("There was an error submitting the form!", error);
        }
      }
    },
  });

  useEffect(() => {
    if (open.propertyData) {
      formik.setValues(open.propertyData);
    } else {
      formik.resetForm();
    }
  }, [open.propertyData]);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    setCountries(Country.getAllCountries());
    if (open.propertyData) {
      setSelectedCountry(() => {
        const country = Country.getAllCountries().find(
          (item) => item.name === open.propertyData.Country
        );
        return country;
      });
    }
  }, [open.propertyData]);

  useEffect(() => {
    if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry.isoCode));
      if (open.propertyData) {
        setSelectedState(() => {
          const state = State.getStatesOfCountry(selectedCountry.isoCode).find(
            (item) => item.name === open.propertyData.State
          );
          return state;
        });
      } else {
        setSelectedState(null);
        setCities([]);
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      setCities(
        City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode)
      );
      if (open.propertyData) {
        setSelectedCity(() => {
          const state = City.getCitiesOfState(
            selectedCountry.isoCode,
            selectedState.isoCode
          ).find((item) => item.name === open.propertyData.City);
          return state;
        });
      } else {
        setSelectedCity(null);
      }
    }
  }, [selectedState]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "Country") {
      formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(name, type === "checkbox" ? checked : value);
    }
  };

  return (
    <Dialog
      open={open.isOpen}
      onClose={() => {
        setOpen({ isOpen: false, propertyData: null });
        setSelectedCity([]);
        setSelectedState([]);
        setSelectedCountry([]);
      }}
      className="client"
    >
      <DialogTitle>
        <div className="w-100 d-flex justify-content-start align-items-center">
          <div
            className="bg-blue-color"
            style={{
              width: "42px",
              height: "42px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
            }}
          >
            <img src={home} alt="home icon" />
          </div>
          <h4
            className="text-blue-color text-property"
            style={{
              justifyContent: "center",
              display:"flex",
              alignItems: "center",
              height: "42px",
              margin: "0 10px",
              marginLeft: "10px",
            }}
          >
            {!open.propertyData && "New"} Property for &nbsp; 
            <u>
              {" "}
              {data?.FirstName ? data?.FirstName : ""}&nbsp;
              {data?.LastName ? data?.LastName : ""}
            </u>
          </h4>
        </div>
      </DialogTitle>
      <Divider
        style={{
          height: "1px",
          color: "rgba(42, 79, 97, 0.8)",
          backgroundColor: "rgba(42, 79, 97, 0.8)",
          margin: "13px 20px",
        }}
      />
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Address
            setSelectedCountry={setSelectedCountry}
            selectedCountry={selectedCountry}
            countries={countries}
            handleChange={handleChange}
            formik={formik}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <div className="mb-3 mb-left">
          <Button
            className="cancelButton"
            style={{
              margin: "0 10px",
              backgroundColor: "#fff",
              height: "43px",
              border: "1px solid rgba(6, 49, 100, 0.8)",
              color: "rgba(6, 49, 100, 1)",
            }}
            onClick={() => setOpen({ isOpen: false, propertyData: null })}
          >
            Cancel
          </Button>
          <Button
            className="bg-button-blue-color createBotton "
            style={{
              margin: "0 10px",
              height: "43px",
              color: "#fff",
            }}
            onClick={formik.handleSubmit}
          >
            {open.propertyData ? "Update Property" : "Create Property"}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerProperty;
