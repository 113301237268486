import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Autocomplete,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  FormGroup,
} from "@mui/material";
import dayjs from "dayjs"; // Import Dayjs

import { Button, Card, Col, Input, Label } from "reactstrap";
import { Country, State, City } from "country-state-city";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./style.css";
import AxiosInstance from "../../Views/AxiosInstance";
import Address from "../../components/Address"; // Assuming this is a separate component
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import InputText from "../InputFields/InputText";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputDropdown from "../InputFields/InputDropdown";

const Expances = ({ open, setOpen, data, ContractId, fetchData ,CompanyId }) => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  var companyId = localStorage.getItem("CompanyId");

  const formik = useFormik({
    initialValues: {
      ItemName: "",
      AccountingCode: "",
      AssignPersonId: "",
      Description: "",
      Date: dayjs(),
      Total: "",
      ReimburseTo: "",
    },
    validationSchema: Yup.object({
      ItemName: Yup.string().required("ItemName is required"),
      Date: Yup.string().required("Date is required"),
      Total: Yup.string().required("Total is required"),
    }),
    onSubmit: async (values) => {
      try {
        values["CompanyId"] = CompanyId;
        values["ContractId"] = ContractId;
        const response = await AxiosInstance.post(
          `${baseUrl}/expenses`,
          values
        );
        if (response.data.statusCode === 200) {
          toast.success(response.data.message);
          setOpen(false);
          fetchData();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while submitting the form.");
      }
    },
  });

  useEffect(() => {
    if (open?.propertyData) {
      formik.setValues(open?.propertyData);
    } else {
      formik.resetForm();
    }
  }, [open?.propertyData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "Country") {
      formik.setFieldValue(name, value.name);
    } else {
      formik.setFieldValue(name, type === "checkbox" ? checked : value);
    }
  };

  const [selectedPerson, setselectedPerson] = useState(null);
  const [teamData, setTeamData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AxiosInstance.get(
          `/contract/assign_team/${CompanyId}`
        );
        if (response.status === 200) {
          const teamDataOptions = response.data.data.map((item) => ({
            name: item.FullName,
            code: item.AssignPersonId,
          }));
          setTeamData(teamDataOptions);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Dialog
      fullWidth
      open={open?.isOpen}
      onClose={() => setOpen({ isOpen: false, propertyData: null })}
      className="client"
    >
      <DialogTitle>
        <div className=" d-flex justify-content-start align-items-center">
          <h4
            className="text-blue-color text-property"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "42px",
              margin: "0 10px",
            }}
          >
            New Time Expanse
          </h4>
        </div>
      </DialogTitle>
      <Divider
        style={{ height: "1px", backgroundColor: "rgba(42, 79, 97, 0.8)" }}
      />
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-2">
            <div>
              <InputText
                value={formik.values?.ItemName}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.ItemName && Boolean(formik.errors.ItemName)
                }
                helperText={formik.touched.ItemName && formik.errors.ItemName}
                name="ItemName"
                placeholder="Enter Item Name here..."
                label="Item Name"
                type="text"
                className="text-blue-color w-100 mb-2 mx-0"
                fieldHeight="56px"
              />
            </div>
          </div>
          <div></div>
          <div className="col-12">
            <InputText
              value={formik.values?.Description}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.Description && Boolean(formik.errors.Description)
              }
              helperText={
                formik.touched.Description && formik.errors.Description
              }
              name="Description"
              id="title"
              placeholder="Enter Description Address here..."
              label="Description"
              type="text"
              className="text-blue-color w-100"
              fieldHeight="56px"
            />
          </div>
          <div className="col-12 time-compo my-2">
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              style={{ maxWidth: "100%" }}
            >
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Date"
                  value={formik.values?.Date}
                  onChange={(value) => formik.setFieldValue("Date", value)}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiInputBase-input": {
                      color: "#063164",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#063164",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#063164",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>

          <div className="mb-2">
            <div>
              <InputText
                value={formik.values?.Total}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.Total && Boolean(formik.errors.Total)}
                helperText={formik.touched.Total && formik.errors.Total}
                name="Total"
                placeholder="Enter Total here..."
                label="Total"
                type="text"
                className="text-blue-color w-100 mb-2 mx-0"
                fieldHeight="56px"
              />
            </div>
          </div>
          <div className="col-12">
            <FormControl fullWidth>
              <InputDropdown
                onChange={(_, newValue) => {
                  const selectedPersonId = newValue ? newValue.code : "";
                  formik.setFieldValue("AssignPersonId", selectedPersonId);
                  setselectedPerson(newValue);
                }}
                textFieldProps={formik.getFieldProps("AssignPersonId")}
                options={teamData}
                value={selectedPerson || null}
                inputValue={selectedPerson ? selectedPerson.name : ""}
                onTextFieldChange={formik.handleChange}
                onBlur={formik.handleBlur}
                getOptionLabel={(option) => option.name || ""}
                error={
                  formik.touched.AssignPersonId &&
                  Boolean(formik.errors.AssignPersonId)
                }
                helperText={
                  formik.touched.AssignPersonId && formik.errors.AssignPersonId
                }
                filterOptions={(options, state) => {
                  return options?.filter((option) =>
                    option?.name
                      ?.toLowerCase()
                      ?.includes(state?.inputValue?.toLowerCase() || "")
                  );
                }}
                name="Employee"
                label="Employee"
                type="text"
              />
            </FormControl>
          </div>
          <div>
            <div className="file-upload">
              <input type="file" id="file-input" style={{ display: "none" }} />
              <label
                htmlFor="file-input"
                className="text-blue-color"
                style={{
                  cursor: "pointer",
                  display: "block",
                  padding: "13px",
                  borderRadius: "10px",
                  textAlign: "center",
                }}
              >
                <span className="mb-2">Add Receipt</span>
                <br /> or drag an image here to upload
              </label>
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          className="cancelButton"
          style={{
            backgroundColor: "#fff",
            border: "1px solid rgba(6, 49, 100, 0.8)",
            color: "rgba(6, 49, 100, 1)",
          }}
          onClick={() => setOpen({ isOpen: false, propertyData: null })}
        >
          Cancel
        </Button>
        <Button
          className="bg-button-blue-color createButton"
          style={{ color: "#fff" }}
          onClick={formik.handleSubmit}
        >
          Save Time Entry
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Expances;
