import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader, CardText } from "reactstrap";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import AxiosInstance from "../AxiosInstance";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { handleAuth } from "../../components/Login/Auth";
import "./style.css";
import { Circles } from "react-loader-spinner";

function Quotes() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    handleAuth(navigate, location);
  }, [navigate, location]);

  const baseUrl = process.env.REACT_APP_BASE_API;
  const [quotes, setquotes] = useState({
    Paid: [],
    UnPaid: [],
    Pending: [],
  });
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(null);

  const CustomerId = localStorage.getItem("CustomerId");

  useEffect(() => {
    const fetchquotes = async () => {
      try {
        const response = await AxiosInstance.get(`/quote/quotes/${CustomerId}`);
        if (response.data.statusCode === 200) {
          setquotes(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoader(false);
      }
    };

    fetchquotes();
  }, [CustomerId]);

  const renderquotes = (status) => {
    return quotes[status].map((quote) => (
      <Card
        className="invoice-card"
        key={quote.QuoteId}
        onClick={() =>
          navigate(`/customers/quotes-details`, {
            state: {
              id: quote.QuoteId,
              navigats: [...location.state.navigats, "/quotes-details"],
            },
          })
        }
      >
        <CardHeader className="invoice-card-header">
          quote #{quote.QuoteNumber}
        </CardHeader>
        <CardBody>
          <CardText className="invoice-card-text">
            <CalendarMonthOutlinedIcon className="invoice-icon" /> Sent{" "}
            {moment(quote.createdAt).format("ll")}
          </CardText>
          <CardText className="invoice-card-text">
            <MyLocationOutlinedIcon className="invoice-icon" />{" "}
            {quote?.address?.Address} {quote?.address?.City} <br />
            {quote?.address?.State}, {quote?.property?.Country}
          </CardText>
        </CardBody>
        <CardFooter className="invoice-card-footer">
          <b>Total : ${quote.Total}</b>
        </CardFooter>
      </Card>
    ));
  };

  const hasQuotes = Object.keys(quotes).some((status) => quotes[status].length > 0);

  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center my-5 text-blue-color"
          style={{ height: "100vh" }}
        >
          <Circles
            height="50"
            width="50"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
            color="#063164"
          />
        </div>
      ) : (
        <div>
          <p className="invoice-header">Your quotes</p>
          <div className="invoice-grid">
            {hasQuotes ? (
              Object.keys(quotes).map(
                (status) =>
                  quotes[status].length > 0 && (
                    <div key={status} className="invoice-status-section">
                      <p className="invoice-status-header">{status}</p>
                      <div className="invoice-status-cards">
                        {renderquotes(status)}
                      </div>
                    </div>
                  )
              )
            ) : (
              <p className="no-data-found">No Quotes Found</p>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Quotes;
