import React, { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";

import creditCardType from "credit-card-type";
import axios from "axios";

import CardImage from "../../../../assets/image/icons/CreditCard.png";
import AxiosInstance from "../../../AxiosInstance";
import sendToast from "../../../../components/Toast/sendToast";
import AddCard from "../../Account/AddCard";

const CardPayment = ({
  paymentFormik,
  selectedCard,
  setSelectedCard,
  CustomerId,
}) => {
  const [billingDetails, setBillingDetails] = useState([]);
  const [paymentMethodLoader, setPaymentMethodLoader] = useState(true);
  const fetchCardData = async () => {
    if (!CustomerId) {
      sendToast("Something went wrong, please try again.");
      return;
    }
    try {
      const res = await AxiosInstance.post("/nmi/get-billing-customer-vault", {
        CompanyId: localStorage.getItem("CompanyId"),
        type: "card",
        CustomerId,
      });
      if (
        res.data.status === 200 &&
        res.data.data &&
        res.data.data.customer &&
        res.data.data.customer.billing
      ) {
        const billingData = [];
        paymentFormik.setFieldValue(
          "customer_vault_id",
          res.data.data.customer["@attributes"].id
        );
        if (Array.isArray(res.data.data.customer.billing)) {
          for (const elem of res.data.data.customer.billing) {
            const image = await getCardImage(elem["@attributes"].id);
            billingData.push({ ...elem, image: image || "" });
          }
        } else {
          const image = await getCardImage(
            res.data.data.customer.billing["@attributes"].id
          );
          billingData.push({
            ...res.data.data.customer.billing,
            image: image || "",
          });
        }
        setBillingDetails(billingData);
      } else {
        setBillingDetails([]);
      }
    } catch (error) {
      sendToast("Something went wrong, please try again.");
    } finally {
      setPaymentMethodLoader(false);
    }
  };

  useEffect(() => {
    if (billingDetails?.length === 0) {
      fetchCardData();
    }
  }, []);

  const getCardImage = async (CardNumber) => {
    try {
      const sanitizedValue = CardNumber.replace(/\D/g, "");
      const cardType = creditCardType(sanitizedValue)[0];

      if (cardType && cardType.type) {
        const sanitizedCardType = cardType.type
          .replace(/[-\s]/g, "")
          .toLowerCase();

        const response = await axios.get(
          `https://logo.clearbit.com/${sanitizedCardType}.com`
        );

        if (response.status === 200) {
          return response.config.url;
        } else {
          return CardImage;
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="mb-3 px-2 d-flex flex-column"
      style={{ background: "#06316412", borderRadius: "8px" }}
    >
      {paymentMethodLoader ? (
        <div
          className="d-flex justify-content-center align-items-center my-2"
          style={{ height: "100%", width: "100%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            visible={paymentMethodLoader}
          />
        </div>
      ) : billingDetails && billingDetails?.length > 0 ? (
        billingDetails?.map((item, index) => (
          <div className="d-flex align-items-center justify-content-start w-100 mt-3">
            <div className="d-flex align-items-center w-100">
              <div className="d-flex align-items-center mx-2 accounts">
                <input
                  type="radio"
                  checked={selectedCard === item}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedCard(item);
                      paymentFormik.setFieldValue(
                        "billing_id",
                        item["@attributes"].id
                      );
                    } else {
                      setSelectedCard("");
                    }
                  }}
                  style={{
                    color: "#063164",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: "55px",
                    height: "55px",
                    background: "#fff",
                    borderRadius: "50%",
                    padding: "8px 5px",
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img
                    src={item.image || CardImage}
                    alt=""
                    width="90%"
                    height="90%"
                  />
                </div>
                <div className="d-flex flex-column justify-conent-center mx-2">
                  <span
                    className="text-blue-color"
                    style={{ fontSize: "18px" }}
                  >
                    {item.cc_number}
                  </span>
                  <span className="text-blue-color">
                    {item.first_name} {item.last_name}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-start w-100 my-3">
            <div className="d-flex align-items-center w-100">
              <div className="d-flex align-items-center w-100">
                <div
                  style={{
                    width: "44px",
                    height: "44px",
                    background: "#F0F0F0",
                    borderRadius: "50%",
                    padding: "8px 5px",
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img src={CardImage} alt="" width="90%" height="90%" />
                </div>
                <div className="d-flex flex-column justify-conent-center mx-2">
                  <span className="text-blue-color">No Credit Card</span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mx-2">
              <button
                className="p-2 bg-blue-color"
                style={{
                  color: "#fff",
                  borderRadius: "4px",
                  background: "#fff",
                  fontSize: "12px",
                }}
                onClick={() => setIsOpen(true)}
              >
                <span className="full-sentence">Add Card</span>
              </button>
            </div>
          </div>
        </>
      )}
      {billingDetails && billingDetails?.length > 0 && (
        <div className="d-flex justify-content-end mb-3 mx-2">
          <button
            className="p-2 bg-blue-color"
            style={{
              color: "#fff",
              borderRadius: "4px",
              background: "#fff",
              fontSize: "12px",
            }}
            onClick={() => setIsOpen(true)}
          >
            <span className="full-sentence">Add Card</span>
          </button>
        </div>
      )}
      <AddCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isBilling={billingDetails?.length > 0}
        vaultId={paymentFormik.values.customer_vault_id}
        fetchData={fetchCardData}
        CustomerId={CustomerId}
      />
    </div>
  );
};

export default CardPayment;
