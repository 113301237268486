import {
  Button,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import login from "../../assets/image/icons/signup.png";
import AppLogo from "../../assets/image/CMS_LOGO.svg";
import googlee from "../../assets/image/icons/googlee.svg";
import "./style.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../Views/AxiosInstance";
import Steps from "./Steps";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import InputText from "../InputFields/InputText";
import sendToast from "../../components/Toast/sendToast";

const SignUp = () => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [signSteps, setSignSteps] = useState(false);
  const formik = useFormik({
    initialValues: {
      Password: "",
      cpassword: "",
      primaryEmailAddress: "",
    },
    validationSchema: Yup.object().shape({
      primaryEmailAddress: Yup.string()
        .email("Invalid email")
        .required("Email is required")
        .matches(/^[^@]+@[^@]+\.[^@]+$/, "Email must contain '@' and '.'"),
      Password: Yup.string().required("Password is required"),
      cpassword: Yup.string()
        .oneOf([Yup.ref("Password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [loader, setLoader] = useState(false);
  const handleSubmit = async (values) => {
    try {
      setLoader(true);
      const res = await AxiosInstance.post(`/company/check_user`, {
        primaryEmailAddress: values.primaryEmailAddress,
      });
      if (res.data.statusCode === 200) {
        setSignSteps(true);
      } else {
        sendToast(res.data.message);
      }
    } catch (error) {
      sendToast("Unable to connect to the server. Please try again later.");
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="login signup">
        {!signSteps ? (
          <Grid container spacing={2} className="vh-100">
            <Grid
              item
              xs={12}
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <img src={login} alt="logo" className="signup-image"></img>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className="d-flex flex-direction-column pt-3"
            >
              <Typography className="app-logo signup-logo px-3">
                <Link to="/auth/login">
                  <img src={AppLogo} alt="logo" className=""></img>
                </Link>
              </Typography>
              <div
                style={{ width: "100%", height: "90%", paddingTop: "40px" }}
                className="d-flex inout-contener"
              >
                <form
                  style={{ width: "70%", height: "90%" }}
                  onSubmit={formik.handleSubmit}
                  className="formmm"
                >
                  <Typography className="text">Sign Up</Typography>
                  <Typography className="text2">
                    Let's proceed with setting you up to access your personal
                    account.
                  </Typography>
                  <FormGroup style={{ width: "100%", marginTop: "24px" }}>
                    <InputText
                      value={formik.values?.primaryEmailAddress}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.primaryEmailAddress &&
                        Boolean(formik.errors.primaryEmailAddress)
                      }
                      helperText={
                        formik.touched.primaryEmailAddress &&
                        formik.errors.primaryEmailAddress
                      }
                      name="primaryEmailAddress"
                      label="Enter Email"
                      type="text"
                      className="text-blue-color w-100"
                      fieldHeight="56px"
                      placeholder="abc@example.com"
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ width: "100%", marginTop: "24px" }}
                    className="textboxes"
                  >
                    <InputText
                      value={formik.values?.Password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.Password &&
                        Boolean(formik.errors.Password)
                      }
                      helperText={
                        formik.touched.Password && formik.errors.Password
                      }
                      name="Password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      placeholder="abc@123"
                      className="text-blue-color w-100"
                      fieldHeight="56px"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ width: "100%", marginTop: "24px" }}
                    className="reenterpassword"
                  >
                    <InputText
                      value={formik.values?.cpassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.cpassword &&
                        Boolean(formik.errors.cpassword)
                      }
                      helperText={
                        formik.touched.cpassword && formik.errors.cpassword
                      }
                      name="cpassword"
                      label="Enter Re-Password"
                      placeholder="abc@123"
                      type={showCPassword ? "text" : "password"}
                      className="text-blue-color w-100"
                      fieldHeight="56px"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowCPassword(!showCPassword)}
                            edge="end"
                          >
                            {showCPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormGroup>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                      marginTop: "13px",
                    }}
                  >
                    Do you have an account?{" "}
                    <span
                      style={{
                        color: "rgba(255, 134, 130, 1)",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/auth/login")}
                    >
                      Login
                    </span>
                  </Typography>
                  <FormGroup style={{ width: "100%", marginTop: "15px" }}>
                    <Button
                      style={{
                        backgroundColor: "rgba(51, 53, 71, 1)",
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "21px",
                        borderRadius: "4px",
                        gap: "4px",
                        height: "48px",
                      }}
                      type="submit"
                      disabled={loader}
                    >
                      {loader ? (
                        <ColorRing
                          height="30"
                          width="30"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                          ariaLabel="circles-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      ) : (
                        "Sign Up"
                      )}
                    </Button>
                  </FormGroup>
                  {/* <Typography
                    className="d-flex justify-content-between"
                    style={{ width: "100%", marginTop: "15px" }}
                  >
                    <hr
                      style={{
                        width: "auto",
                        height: "1px",
                        opacity: "25%",
                        backgroundColor: "rgba(49, 49, 49, 1)",
                      }}
                      className="flex-grow-1"
                    />
                    <p
                      style={{
                        color: "rgba(49, 49, 49, 1)",
                        opacity: "50%",
                        fontSize: "14px",
                      }}
                      className="flex-grow-2 signupwith my-auto mx-1"
                    >
                      Or sign up with
                    </p>
                    <hr
                      style={{
                        width: "auto",
                        height: "1px",
                        opacity: "25%",
                        backgroundColor: "rgba(49, 49, 49, 1)",
                      }}
                      className="flex-grow-1"
                    />
                  </Typography> */}
                  {/* <FormGroup style={{ width: "100%", marginTop: "15px" }}>
                    <Button
                      className="google-buttom-main"
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "21px",
                        borderRadius: "4px",
                        gap: "16px",
                        height: "56px",
                        border: "1px solid rgba(81, 93, 239, 1)",
                      }}
                    >
                      <img src={googlee} alt="logo"></img>
                    </Button>
                  </FormGroup> */}
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                      textAlign: "center",
                      marginTop: "13px",
                      color: "rgba(49, 49, 49, 1)",
                    }}
                  >
                    You can receive marketing emails, SMS, news, and resources
                    from our contractor management system. You can unsubscribe
                    at any time. Please view our Privacy Policy{" "}
                    <span style={{ fontWeight: "600" }}>Privacy Policy</span>{" "}
                    for more information.
                  </Typography>
                </form>
              </div>
            </Grid>
          </Grid>
        ) : (
          <>
            <Steps
              primaryEmailAddress={formik.values.primaryEmailAddress}
              Password={formik.values.Password}
            />
          </>
        )}
      </div>
      <Toaster />
    </>
  );
};

export default SignUp;
