import React, { useEffect, useState } from "react";
import edit from "../../../assets/image/icons/edit.svg";
import Copy from "../../../assets/image/icons/copy.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import axios from "axios";
import Previous from "../../../assets/image/icons/Previous.png";
import toast, { Toaster } from "react-hot-toast";
import { generateContractPDF } from "../../../components/Files/ContractPDF";
import Contract from "../../../assets/Blue-sidebar-icon/Contract.svg";
import AxiosInstance from "../../AxiosInstance";
import ContractDetailsViews from "./views/ContractDetails";

const options = { year: "numeric", month: "short", day: "numeric" };

function ContractDetails() {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();

  const baseUrl = process.env.REACT_APP_BASE_API;
  const cdnUrl = process.env.REACT_APP_CDN_API;

  const [contractData, setContractData] = useState({});
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const [mail, setMail] = useState(false);
  const [savedSignature, setSavedSignature] = useState(null);
  const [signatureSaved, setSignatureSaved] = useState(false);
  const [modal, setModal] = useState(false);

  const CompanyId = localStorage.getItem("CompanyId");

  let fetchData = async () => {
    try {
      const res = await AxiosInstance.get(
        `/contract/contract_details/${location.state.id}`
      );
      if (res.data.statusCode === 200) {
        const labourRes = await AxiosInstance.get(
          `/labour/${location.state.id}/${localStorage.getItem("CompanyId")}`
        );
        const expenseRes = await AxiosInstance.get(
          `/expenses/${location.state.id}/${localStorage.getItem("CompanyId")}`
        );
        const visitsRes = await AxiosInstance.get(
          `/visits/${location.state.id}/${localStorage.getItem("CompanyId")}`
        );
        setContractData({
          ...res.data.data,
          laborData: labourRes.data.data,
          expenseData: expenseRes.data.result,
          visitsData: visitsRes.data.result,
        });
      }
    } catch (error) {
      console.error("Error: ", error.messae);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCopy = () => {
    const fullName = `${contractData?.customer?.FirstName || ""} ${
      contractData?.customer?.LastName || ""
    }`;
    navigator.clipboard.writeText(fullName).catch((err) => {});
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const moreActiontoggle = () => setDropdownOpen((prevState) => !prevState);

  const [signatureRef, setSignatureRef] = useState(null);
  const clearSignature = () => {
    signatureRef?.clear();
  };

  const saveSignature = async () => {
    let image = "";
    try {
      const signatureCanvas = signatureRef;
      const signatureDataURL = signatureCanvas.toDataURL();
      const signatureBlob = await fetch(signatureDataURL).then((res) =>
        res.blob()
      );

      const imageData = new FormData();
      imageData.append("files", signatureBlob, "signature.png");

      const url = `${cdnUrl}/upload`;
      const result = await axios.post(url, imageData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSavedSignature(signatureDataURL);
      image = result.data.files[0].filename;
    } catch (error) {
      console.error(error, "");
    }

    setSignatureSaved(true);

    const quoteApiUrl = `${baseUrl}/quotes/clientdashboard/${contractData.quote_id}`;
    try {
      setLoader(true);
      const response = await AxiosInstance.put(quoteApiUrl, {
        signature: image || "",
      });

      if (response.data.statusCode === 200) {
        fetchData();
        setModal(false);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        console.error("Failed to update quote status:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating quote status:", error);
      toast.error(error);
    } finally {
      setLoader(false);
      setModal(false);
    }
  };

  const handleEditClick = () => {
    navigate(`/${companyName}/add-contract`, {
      state: {
        id: location.state.id,
        navigats: [...location.state.navigats, "/add-contract"],
      },
    });
  };

  const toggle = () => setModal(!modal);
  const [isTimeEmptyModalOpen, setIsTimeEmptyModalOpen] = useState({
    isOpen: false,
    propertyData: null,
  });

  const handleTimeEmptyModalOpen = () => {
    setIsTimeEmptyModalOpen({ isOpen: true, propertyData: null });
  };

  const [isExpanseModalOpen, setIsExpanseModalOpen] = useState({
    isOpen: false,
    propertyData: null,
  });

  const handleExpanseModalOpen = () => {
    setIsExpanseModalOpen({ isOpen: true, propertyData: null });
  };

  const [isVisitModalOpen, setIsVisitModalOpen] = useState({
    isOpen: false,
    propertyData: null,
  });

  const handleVisitModalOpen = () => {
    setIsVisitModalOpen({ isOpen: true, propertyData: null });
  };

  return (
    <>
      <ContractDetailsViews
        loader={loader}
        Previous={Previous}
        toggle={toggle}
        setMail={setMail}
        companyName={companyName}
        handleEditClick={handleEditClick}
        dropdownOpen={dropdownOpen}
        moreActiontoggle={moreActiontoggle}
        edit={edit}
        Copy={Copy}
        handleCopy={handleCopy}
        options={options}
        open={open}
        setOpen={setOpen}
        file={file}
        cdnUrl={cdnUrl}
        setFile={setFile}
        mail={mail}
        Contract={Contract}
        contractData={contractData}
        generateContractPDF={generateContractPDF}
        handleTimeEmptyModalOpen={handleTimeEmptyModalOpen}
        handleVisitModalOpen={handleVisitModalOpen}
        modal={modal}
        handleExpanseModalOpen={handleExpanseModalOpen}
        clearSignature={clearSignature}
        setSignatureRef={setSignatureRef}
        saveSignature={saveSignature}
        isTimeEmptyModalOpen={isTimeEmptyModalOpen}
        setIsTimeEmptyModalOpen={setIsTimeEmptyModalOpen}
        fetchData={fetchData}
        isExpanseModalOpen={isExpanseModalOpen}
        setIsExpanseModalOpen={setIsExpanseModalOpen}
        isVisitModalOpen={isVisitModalOpen}
        setIsVisitModalOpen={setIsVisitModalOpen}
        CompanyId={CompanyId}
      />
      <Toaster />
    </>
  );
}

export default ContractDetails;
