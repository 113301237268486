import { TextField } from "@mui/material";
import React from "react";

const InputText = React.forwardRef(
  (
    {
      name,
      label,
      value,
      placeholder,
      onChange,
      error,
      helperText,
      endAdornment,
      fieldHeight,
      select,
      children,
      defaultValue,
      disable,
      ...props
    },
    ref
  ) => {
    return (
      <TextField
        id={name}
        name={name}
        value={value}
        label={label}
        placeholder={placeholder}
        inputRef={ref}
        onChange={onChange}
        select={select}
        defaultValue={defaultValue}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#063164",
              height: fieldHeight || "auto",
              borderRadius: "8px",
            },
            "&:hover fieldset": {
              borderColor: "#063164",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#063164",
            },
          },
          "& .MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            height: "100%",
            textAlign: "center",
            padding: "0 14px", // Adjust padding if necessary
            boxSizing: "border-box", // Ensure padding and border are included in the height
          },
          "& .MuiInputLabel-root": {
            color: "#063164",
            fontSize: "15px",
          },
        }}
        InputProps={{
          readOnly: false,
          endAdornment,
        }}
        InputLabelProps={{
          style: { color: "#063164", fontSize: "15px" },
        }}
        error={error}
        helperText={helperText}
        {...props}
        disabled={disable}
      >
        {children}
      </TextField>
    );
  }
);

export default InputText;
