import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import axios from "axios";
import AxiosInstance from "../../../Views/AxiosInstance";
import moment from "moment";
import "../style.css";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { FormControl } from "react-bootstrap";
import html2canvas from "html2canvas";
import toast, { Toaster } from "react-hot-toast";
import { generateContractCustomerPDF } from "../../../components/Files/ContractCustomerPDF";
import { TableHead } from "@mui/material";
import { Circles } from "react-loader-spinner";

function ContractsDetails() {
  const cdnUrl = process.env.REACT_APP_CDN_API;

  const [signatureRef, setSignatureRef] = useState(null);
  const navigate = useNavigate();

  const clearSignature = () => {
    signatureRef?.clear();
  };

  const [typedSignature, setTypedSignature] = useState("");

  // ----------------------------------------------------------------------
  const baseUrl = process.env.REACT_APP_BASE_API;
  const location = useLocation();

  const [modal, setModal] = useState(false);
  const [modall, setModall] = useState(false);

  const togglee = () => setModall(!modall);
  const setTabId = (tabId) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
  };
  const [activeTab, setActiveTab] = useState(1);

  const [contractDetail, setcontractDetail] = useState("");
  let fetchData = async () => {
    try {
      const res = await AxiosInstance.get(
        `/contract/contract_details/${location.state.id}`
      );
      setcontractDetail(res.data.data);
    } catch (error) {
      console.error("Error: ", error.message);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // -------------------------------------------------

  const [savedSignature, setSavedSignature] = useState(null);
  const [signatureSaved, setSignatureSaved] = useState(false);
  const [loader, setLoader] = useState(true);
  const [changeRequestReason, setChangeRequestReason] = useState("");

  const saveSignature = async () => {
    const labelElement = document.getElementById("typeSignatureLabel") || "";
    let image = "";
    let changeRequest = {
      CustomerId: "11062024191201",
      reason: changeRequestReason,
      date: moment().utcOffset(330).format("YYYY-MM-DD HH:mm:ss"),
    };
    if (activeTab === 2 && typedSignature && labelElement) {
      try {
        const canvas = await html2canvas(labelElement);
        const signatureData = canvas.toDataURL();

        const signatureBlob = dataURLtoBlob(signatureData);
        const imageData = new FormData();
        imageData.append("files", signatureBlob, "signature.png");

        const url = `${cdnUrl}/upload`;
        const result = await axios.post(url, imageData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setSavedSignature(signatureData);
        image = result.data.files[0].filename;
      } catch (error) {
        console.error(error, "imgs");
      }
    } else if (activeTab === 1 && signatureRef) {
      try {
        const signatureCanvas = signatureRef;
        const signatureDataURL = signatureCanvas.toDataURL();
        const signatureBlob = await fetch(signatureDataURL).then((res) =>
          res.blob()
        );

        const imageData = new FormData();
        imageData.append("files", signatureBlob, "signature.png");

        const url = `${cdnUrl}/upload`;
        const result = await axios.post(url, imageData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setSavedSignature(signatureDataURL);
        image = result.data.files[0].filename;
      } catch (error) {
        console.error(error, "imgs");
      }
    } else {
      // Handle case where neither condition is met
    }
    setSignatureSaved(true);

    const contractApiUrl = `${baseUrl}/contracts/clientdashboard/${contractDetail.contract_id}`;
    try {
      setLoader(true);
      const response = await AxiosInstance.put(contractApiUrl, {
        signature: image || "",
        change_request: changeRequest || "",
      });

      if (response.data.statusCode === 200) {
        fetchData();
        setModal(false);
        setModall(false);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        console.error(
          "Failed to update contract status:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error updating contract status:", error);
      toast.error(error);
    } finally {
      setLoader(false);
      setModal(false);
      setModall(false);
    }
  };

  function dataURLtoBlob(dataurl) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const toggle = () => setModal(!modal);

  const downloadPdf = async () => {
    try {
      const res = await AxiosInstance.post(
        `/contract/contractpdf/${location.state.id}`
      );
      if (res.data.statusCode === 200) {
        // The URL of the PDF
        const url = `https://app.cloudjobmanager.com/cdn/upload/${res.data.fileName}`;

        // const link = document.createElement("a");
        // link.href = url;
        // link.download = "contract_document.pdf";
        // document.body.appendChild(link);
        // link.click();

        fetch(url)
          .then((response) => {
            if (!response.ok) throw new Error("Network response was not ok");
            return response.blob(); // Get the file as a Blob
          })
          .then((blob) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob); // Create a URL for the blob
            link.download = "contract_document.pdf"; // Set the file name
            document.body.appendChild(link);
            link.click(); // Programmatically click the link to trigger the download
            document.body.removeChild(link); // Clean up
          })
          .catch((error) =>
            console.error(
              "There was a problem with the fetch operation:",
              error
            )
          );
        // document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };
  return (
    <>
      <div>
        {loader ? (
          <div className="d-flex justify-content-center align-items-center my-5 text-blue-color">
            <Circles
              height="50"
              width="50"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={loader}
              color="#063164"
            />
          </div>
        ) : (
          <div>
            <div
              className="col-12 d-flex download-sec "
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor:
                  contractDetail?.Status === "Unscheduled"
                    ? "white"
                    : contractDetail?.Status === "Today"
                    ? "white"
                    : contractDetail?.Status === "Upcoming"
                    ? "white"
                    : contractDetail?.Status === "Scheduled"
                    ? "white"
                    : "",
              }}
            >
              <Button className="bg-blue-color" onClick={() => navigate(-1)}>
                <ArrowBackIcon /> Back
              </Button>{" "}
              <Button
                className="bg-blue-color"
                // onClick={() => {
                //   generateContractCustomerPDF(contractDetail, "download");
                // }}
                onClick={() => {
                  downloadPdf();
                }}
              >
                <FileDownloadOutlinedIcon /> Download Pdf
              </Button>
            </div>
            <div className="d-flex client-cliend-details">
              <div
                className="col-12"
                style={{
                  backgroundColor:
                    contractDetail?.Status === "Unscheduled"
                      ? "white"
                      : contractDetail?.Status === "Today"
                      ? "white"
                      : contractDetail?.Status === "Upcoming"
                      ? "white"
                      : contractDetail?.Status === "Scheduled"
                      ? "white"
                      : "",
                }}
              >
                <Card className="my-4" style={{ border: "1px solid #063164" }}>
                  <CardHeader
                    className="p-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "none",
                    }}
                  >
                    <p
                      className="mb-0 text-blue-color"
                      style={{ fontSize: "19px" }}
                    >
                      Contract No{" "}
                      {contractDetail?.ContractNumber
                        ? "#" + contractDetail?.ContractNumber
                        : ""}
                    </p>
                    <p
                      className="mb-0 text-blue-color"
                      style={{
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px 24px 10px",
                        borderRadius: "25px",
                        backgroundColor:
                          contractDetail?.Status === "Unscheduled"
                            ? "#FF0000"
                            : contractDetail?.Status === "Today"
                            ? "#DBECFF"
                            : contractDetail?.Status === "Upcoming"
                            ? "AEF6D3"
                            : contractDetail?.Status === "Scheduled"
                            ? "#FFE9BC"
                            : "",
                      }}
                    >
                      <p
                        style={{
                          backgroundColor:
                            contractDetail?.Status === "Unscheduled"
                              ? "#FFC6C6"
                              : contractDetail?.Status === "Today"
                              ? "#3595FF"
                              : contractDetail?.Status === "Upcoming"
                              ? "#089F57"
                              : contractDetail?.Status === "Scheduled"
                              ? "#FFAF0B"
                              : "",
                          borderRadius: "50%",
                          padding: "6px",
                          marginRight: "10px",
                          marginBottom: 0,
                        }}
                      ></p>
                      {contractDetail?.Status}
                    </p>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex sec-sec">
                      <div
                        className="col-8 p-3"
                        style={{
                          border: "1px solid #063164",
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",

                          //   borderRight: "none",
                        }}
                      >
                        <h5
                          className="text-blue-color"
                          style={{
                            fontSize: "20px",
                            fontWeight: 600,
                          }}
                        >
                          {contractDetail?.FirstName} {contractDetail?.LastName}
                        </h5>
                        <p
                          className="text-blue-color"
                          style={{ fontSize: "15px" }}
                        >
                          {contractDetail?.location?.Address
                            ? contractDetail?.location?.Address + "/"
                            : ""}{" "}
                          {contractDetail?.location?.City
                            ? contractDetail?.location?.City + ","
                            : ""}{" "}
                          {contractDetail?.location?.State
                            ? contractDetail?.location?.State + " - "
                            : ""}
                          {contractDetail?.location?.Zip
                            ? contractDetail?.location?.Zip
                            : ""}
                        </p>
                        <p
                          style={{ fontSize: "15px" }}
                          className="mb-0 text-blue-color"
                        >
                          {contractDetail?.PhoneNumber}
                        </p>
                      </div>
                      <div
                        className="col-4 p-3"
                        style={{
                          border: "1px solid #063164",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderLeft: "none",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{ fontSize: "15px" }}
                          className="mb-0 text-blue-color"
                        >
                          <b>Sent on :</b>
                        </p>
                        <p
                          style={{ fontSize: "15px" }}
                          className="mb-0 text-blue-color"
                        >
                          {moment(contractDetail?.createdAt).format("ll")}
                        </p>
                      </div>
                    </div>
                    {/* 5150 start  */}
                    <div
                      className="col-12 border-blue-color"
                      style={{
                        marginTop: "16px",
                        border: "2px solid ",
                        paddingLeft: "15px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Row>
                        <Col
                          xl="6"
                          lg="6"
                          md="6"
                          sm="12"
                          className="text-blue-color"
                          style={{
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          Product/Service
                        </Col>
                        <Col
                          xl="2"
                          lg="2"
                          md="2"
                          sm="12"
                          className="text-blue-color"
                          style={{
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          Qty.
                        </Col>
                        <Col
                          xl="2"
                          lg="2"
                          md="2"
                          sm="12"
                          className="text-blue-color"
                          style={{
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          Unit Price
                        </Col>
                        <Col
                          xl="2"
                          lg="2"
                          md="2"
                          sm="12"
                          className="text-blue-color"
                          style={{
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          Total
                        </Col>
                      </Row>

                      <div>
                        {contractDetail?.Items?.length > 0 ? (
                          contractDetail.Items.map((product, index) => (
                            <Row key={index}>
                              <Col
                                xl="6"
                                lg="6"
                                md="6"
                                sm="12"
                                className="text-blue-color"
                                style={{
                                  padding: "10px",
                                  border: "none",
                                }}
                              >
                                <h5>{product.Name}</h5>
                                {product.Description}
                              </Col>
                              <Col
                                xl="2"
                                lg="2"
                                md="2"
                                sm="12"
                                className="text-blue-color"
                                style={{
                                  padding: "10px",
                                  border: "none",
                                }}
                              >
                                {/* {product.Unit} */}
                                {product?.Unit
                                  ? product?.Unit
                                  : product?.Square
                                  ? product?.Square
                                  : product?.Hour}
                              </Col>
                              <Col
                                xl="2"
                                lg="2"
                                md="2"
                                sm="12"
                                className="text-blue-color"
                                style={{
                                  padding: "10px",
                                  border: "none",
                                }}
                              >
                                {/* ${product?.CostPerUnit} */}
                                {product?.CostPerHour
                                  ? Number(product.CostPerHour).toFixed(2)
                                  : product?.CostPerSquare
                                  ? Number(product.CostPerSquare).toFixed(2)
                                  : Number(product?.CostPerUnit).toFixed(2)}
                              </Col>
                              <Col
                                xl="2"
                                lg="2"
                                md="2"
                                sm="12"
                                className="text-blue-color"
                                style={{
                                  padding: "10px",
                                  border: "none",
                                }}
                              >
                                $
                                {/* {(product?.CostPerUnit * product?.Unit).toFixed(
                                  2
                                )} */}
                                {Number(product?.Total)
                                  ? Number(product.Total).toFixed(2)
                                  : ""}
                              </Col>
                            </Row>
                          ))
                        ) : (
                          <Row>
                            <Col colSpan="4">No products available.</Col>
                          </Row>
                        )}
                      </div>
                    </div>
                    {/* 5150 end  */}
                    {/* total, sub-total, discount */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "15px",
                      }}
                    >
                      <div
                        className="col-3"
                        style={{
                          border: "1px solid #063164",
                          borderRadius: "5px",
                          padding: "15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-0 text-blue-color">
                            <b>Sub Total</b>
                          </p>
                          <p className="mb-0 text-blue-color">
                            <b>
                              {contractDetail?.subTotal
                                ? "$" + contractDetail?.subTotal
                                : ""}
                            </b>
                          </p>
                        </div>
                        <div>
                          {contractDetail?.Discount ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p className="mb-0 text-blue-color">
                                <b>Discount</b>
                              </p>
                              <p className="mb-0 text-blue-color">
                                <b>
                                  -{" "}
                                  {contractDetail?.Discount
                                    ? contractDetail?.Discount + "%"
                                    : ""}
                                </b>
                              </p>
                            </div>
                          ) : null}

                          {contractDetail?.Tax ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p className="mb-0 text-blue-color">
                                <b>Tax</b>
                              </p>
                              <p className="mb-0 text-blue-color">
                                <b>
                                  +{" "}
                                  {contractDetail?.Tax
                                    ? contractDetail?.Tax + "%"
                                    : ""}
                                </b>
                              </p>
                            </div>
                          ) : null}
                        </div>

                        <hr className="my-1 mb-1 text-blue-color" />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-0 text-blue-color">
                            <b>Total</b>
                          </p>
                          <p className="mb-0 text-blue-color">
                            <b>
                              {contractDetail?.Total
                                ? "$" + contractDetail?.Total
                                : ""}
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="my-5">
                      <p
                        className="mb-0 text-blue-color"
                        style={{ fontWeight: 600 }}
                      >
                        {contractDetail?.CustomerMessage}
                      </p>
                      <p className="mb-0 text-blue-color">
                        {contractDetail?.ContractDisclaimer}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        )}
        <Toaster />
      </div>
    </>
  );
}

export default ContractsDetails;
