import React, { useEffect, useState } from "react";
import truck from "../../../../assets/image/icons/truck.svg";
import schedule from "../../../../assets/image/icons/schedule.svg";
import {
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { Circles } from "react-loader-spinner";
import moment from "moment";
import AxiosInstance from "../../../AxiosInstance";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TableHead } from "@mui/material";

const PropertyDetails = () => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const { companyName } = useParams();
  const location = useLocation();

  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      if (location.state.id) {
        try {
          const res = await AxiosInstance.get(
            `${baseUrl}/location/properties/${location.state.id}`
          );
          setData(res.data.data);
        } catch (error) {
          console.error("Error: ", error.message);
        } finally {
          setLoader(false);
        }
      }
    };
    fetchData();
  }, [location.state.id, baseUrl]);

  const [activeTabId, setActiveTabId] = useState(1);
  const handleClick = (tabId) => {
    setActiveTabId(tabId);
  };

  const [quotes, setQuotes] = useState([]);
  useEffect(() => {
    const fetchQuote = async () => {
      if (data && data.CustomerId && data.LocationId) {
        try {
          const response = await AxiosInstance.get(
            `${baseUrl}/quote/get_quotes_customer_property/${localStorage.getItem(
              "CompanyId"
            )}/${data.CustomerId}/${data.LocationId}`
          );
          if (response.data.statusCode === 200) {
            setQuotes(response.data.data);
          }
        } catch (err) {
          console.error("Error: ", err.message);
        }
      }
    };

    fetchQuote();
  }, [data]);

  const [contract, setContract] = useState([]);

  useEffect(() => {
    const fetchContract = async () => {
      if (data && data.CustomerId && data.LocationId) {
        try {
          const response = await AxiosInstance.get(
            `${baseUrl}/contract/get_contract_customer_property/${localStorage.getItem(
              "CompanyId"
            )}/${data.CustomerId}/${data.LocationId}`
          );
          setContract(response.data.data);
        } catch (err) {
          console.error("Error: ", err.message);
        }
      }
    };
    fetchContract();
  }, [data]);

  const [invoice, setInvoice] = useState([]);

  useEffect(() => {
    const fetchInvoice = async () => {
      if (data && data.CustomerId && data.LocationId) {
        try {
          const response = await AxiosInstance.get(
            `${baseUrl}/invoice/get_invoice_customer_property/${localStorage.getItem(
              "CompanyId"
            )}/${data.CustomerId}/${data.LocationId}`
          );
          if (response.data.statusCode === 200) {
            setInvoice(response.data.data);
          }
        } catch (err) {
          console.error("Error: ", err.message);
        }
      }
    };
    fetchInvoice();
  }, [data]);

  const handleNavigate = (quoteId) => {
    navigate(`/${companyName}/quotes-detail`, {
      state: {
        id: quoteId,
        navigats: [`/index`, `/quotes-detail`],
      },
    });
  };

  const handleContractNavigate = (id) => {
    navigate(`/${companyName}/contractdetails`, {
      state: {
        id,
        navigats: [`/index`, `/contractdetails`],
      },
    });
  };

  const handleInvoiceNavigate = (id) => {
    navigate(`/${companyName}/invoice-details`, {
      state: {
        id,
        navigats: [`/index`, `/invoice-details`],
      },
    });
  };

  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%", width: "100%", marginTop: "25%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </div>
      ) : (
        <div className="justify-content-center align-items-center mb-3 mt-5 client">
          <div
            className="username text-blue-color"
            style={{
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
              fontSize: "38px",
            }}
          >
            Property Details
          </div>
          <div>
            <div
              className="my-2 detail-card "
              style={{ border: "none", gap: "12px" }}
            >
              <div className="col-12 first-card">
                <div
                  className="address"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    border: "0.5px solid rgba(6, 49, 100, 30%)",
                    paddingRight: "20px",
                  }}
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center p-2"
                    style={{ border: "none" }}
                  >
                    <h5 className="text-blue-color" style={{ fontWeight: 600 }}>
                      Location
                    </h5>
                  </CardHeader>
                  <CardBody style={{ padding: "10px 0px" }}>
                    <Table borderless>
                      <TableHead>
                        <tr>
                          <th style={{ paddingLeft: "20px" }}>Address</th>
                          <th className="text-start text-blue-color">City</th>
                          <th className="text-center text-blue-color">State</th>
                          <th className="text-center text-blue-color">
                            Country
                          </th>
                          <th className="text-end text-blue-color">Zip</th>
                        </tr>
                      </TableHead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "20px",
                            }}
                          >
                            <MyLocationIcon style={{ marginRight: "15px" }} />
                            <span
                              className="text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {data?.Address ? data?.Address : "-"}{" "}
                            </span>
                          </td>
                          <td
                            className="text-start text-blue-color"
                            style={{ fontSize: "14px" }}
                          >
                            {data?.City ? data?.City : "-"}
                          </td>
                          <td
                            className="text-center text-blue-color"
                            style={{ fontSize: "14px" }}
                          >
                            {data?.State ? data?.State : "-"}
                          </td>
                          <td
                            className="text-center text-blue-color"
                            style={{ fontSize: "14px" }}
                          >
                            {data?.Country ? data?.Country : "-"}
                          </td>
                          <td
                            className="text-end text-blue-color"
                            style={{ fontSize: "14px", paddingRight: "" }}
                          >
                            {data?.Zip ? data?.Zip : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </div>
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    border: "0.5px solid rgba(6, 49, 100, 30%)",
                  }}
                  className="my-4"
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center p-2"
                    style={{ border: "none" }}
                  >
                    <h5 className="text-blue-color" style={{ fontWeight: 600 }}>
                      Overview
                    </h5>
                  </CardHeader>
                  <CardBody style={{ padding: "10px 0px" }}>
                    <div className="text-start">
                      <Nav
                        className="bg-orange-color"
                        tabs
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <NavItem>
                          <NavLink
                            className={activeTabId === 1 ? "active" : ""}
                            onClick={() => handleClick(1)}
                            style={{ cursor: "pointer" }}
                          >
                            All
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 2 ? "active" : ""}
                            onClick={() => handleClick(2)}
                            style={{ cursor: "pointer" }}
                          >
                            Active Work
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 3 ? "active" : ""}
                            onClick={() => handleClick(3)}
                            style={{ cursor: "pointer" }}
                          >
                            Quotes
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 5 ? "active" : ""}
                            onClick={() => handleClick(5)}
                            style={{ cursor: "pointer" }}
                          >
                            Contract
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 6 ? "active" : ""}
                            onClick={() => handleClick(6)}
                            style={{ cursor: "pointer" }}
                          >
                            Invoice
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={activeTabId}
                        className="text-start"
                      >
                        <TabPane tabId={1}>
                          <Row>
                            <Col
                              sm="12"
                              className="d-flex flex-column"
                              // style={{
                              //   gap: "10px",
                              //   maxHeight:
                              //     quotes?.length +
                              //       invoice?.length +
                              //       contract?.length >
                              //     3
                              //       ? "300px"
                              //       : "auto",
                              //   overflowY:
                              //     quotes?.length +
                              //       invoice?.length +
                              //       contract?.length >
                              //     3
                              //       ? "auto"
                              //       : "visible",
                              // }}

                              //2529 scrollbar
                              style={{
                                gap: "10px",
                                maxHeight:
                                  quotes?.length > 3 ? "300px" : "auto",
                                overflowY:
                                  quotes?.length > 3 ? "auto" : "visible",
                              }}
                               //2529 scrollbar
                            >
                              {(quotes && quotes?.length > 0) ||
                              (contract && contract?.length > 0) ||
                              (invoice && invoice.length > 0) ? (
                                <>
                                  {quotes &&
                                    quotes?.length > 0 &&
                                    quotes.map((data, index) => (
                                      <div
                                        key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleNavigate(data?.QuoteId)
                                        }
                                      >
                                        <div className="mx-3">
                                          <div
                                            className="row align-items-start pt-2 p-3  text-blue-color"
                                            style={{
                                              border: "1px solid #063164",
                                              fontSize: "12px",
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              borderRadius: "8px",
                                              border:
                                                "0.5px solid rgba(6, 49, 100, 0.8)",
                                            }}
                                          >
                                            <div className="col">
                                              <p
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <strong>
                                                  Quote #{data?.QuoteNumber}
                                                </strong>
                                              </p>
                                              <span
                                                style={{
                                                  color:
                                                    data?.status === "Approved"
                                                      ? "rgb(88, 204, 88)"
                                                      : data?.status ===
                                                        "Awaiting Response"
                                                      ? "orange"
                                                      : "#063164",
                                                }}
                                              >
                                                <strong>{data?.status}</strong>
                                              </span>
                                            </div>
                                            <div className="col text-center">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                TITLE
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {data?.Title}
                                              </p>
                                            </div>
                                            <div className="col text-center">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                ADDRESS
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {data?.location?.Address || "-"}
                                                , {data?.location?.City || "-"},{" "}
                                                {data?.location?.State || "-"}
                                              </p>
                                            </div>
                                            <div className="col text-right">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                CREATED ON
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {moment(data.createdAt).format(
                                                  "ll"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  {contract &&
                                    contract?.length > 0 &&
                                    contract.map((data, index) => (
                                      <div
                                        key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleContractNavigate(
                                            data?.ContractId
                                          )
                                        }
                                      >
                                        <div className="mx-3">
                                          <div
                                            className="row align-items-start pt-2 p-3 text-blue-color"
                                            style={{
                                              border: "1px solid #063164",
                                              fontSize: "12px",
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              borderRadius: "8px",
                                              border:
                                                "0.5px solid rgba(6, 49, 100, 0.8)",
                                            }}
                                          >
                                            <div className="col">
                                              <p
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <strong>
                                                  Contract #
                                                  {data?.ContractNumber}
                                                </strong>
                                              </p>
                                              <span
                                                style={{
                                                  color:
                                                    data?.Status ===
                                                    "Unscheduled"
                                                      ? "#E88C44"
                                                      : data?.Status === "Today"
                                                      ? "#089F57"
                                                      : data?.Status ===
                                                        "Upcoming"
                                                      ? "#089F57"
                                                      : data?.Status ===
                                                        "Scheduled"
                                                      ? "#C8CC00"
                                                      : "",
                                                }}
                                              >
                                                <strong>{data?.Status}</strong>
                                              </span>
                                            </div>
                                            <div className="col text-center">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                TITLE
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {data?.Title}
                                              </p>
                                            </div>
                                            <div className="col text-center">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                ADDRESS
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {data?.location?.Address || "-"}
                                                , {data?.location?.City || "-"},{" "}
                                                {data?.location?.State || "-"}
                                              </p>
                                            </div>
                                            <div className="col text-right">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                CREATED ON
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {moment(data.createdAt).format(
                                                  "ll"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  {invoice &&
                                    invoice.length > 0 &&
                                    invoice.map((data, index) => (
                                      <div
                                        key={index}
                                        style={{ cursor: "" }}
                                        onClick={() =>
                                          handleInvoiceNavigate(data?.InvoiceId)
                                        }
                                      >
                                        <div className="mx-3">
                                          <div
                                            className="row align-items-start pt-2 p-3  text-blue-color"
                                            style={{
                                              border: "1px solid #063164",
                                              fontSize: "12px",
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              borderRadius: "8px",
                                              border:
                                                "0.5px solid rgba(6, 49, 100, 0.8)",
                                            }}
                                          >
                                            <div className="col">
                                              <p
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <strong>
                                                  Invoice #{data?.InvoiceNumber}
                                                </strong>
                                              </p>
                                              <span
                                                style={{
                                                  color:
                                                    data?.status === "Approved"
                                                      ? "rgb(88, 204, 88)"
                                                      : data?.status ===
                                                        "Awaiting Response"
                                                      ? "orange"
                                                      : "#063164",
                                                }}
                                              >
                                                <strong> {data?.Status}</strong>
                                              </span>
                                            </div>
                                            <div className="col text-center">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                Subject
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {data?.Subject}
                                              </p>
                                            </div>
                                            <div className="col text-center">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                ADDRESS
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {data?.location?.Address || "-"}
                                                , {data?.location?.City || "-"},{" "}
                                                {data?.location?.State || "-"}
                                              </p>
                                            </div>
                                            <div className="col text-right">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                CREATED ON
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {moment(data.createdAt).format(
                                                  "ll"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              ) : (
                                <Col sm="12" className="d-flex">
                                  <div
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 30%)",
                                      padding: "15px",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                  >
                                    <img src={truck} alt="Truck Icon" />
                                  </div>
                                  <div className="mx-2">
                                    <h6
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      All
                                    </h6>
                                    <p
                                      className="text-blue-color"
                                      style={{ fontSize: "12px" }}
                                    >
                                      No active Work, Quotes, Contracts and
                                      Invoices this Customer yet
                                    </p>
                                  </div>
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Row>
                            <Col sm="12" className="d-flex">
                              <div
                                style={{
                                  backgroundColor: "rgba(6, 49, 100, 30%)",
                                  padding: "15px",
                                  borderRadius: "50%",
                                  height: "50px",
                                  width: "50px",
                                }}
                              >
                                <img src={truck} />
                              </div>
                              <div className="mx-2">
                                <h6
                                  className="mb-0 my-2 text-blue-color"
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Active Work
                                </h6>
                                <p
                                  className="text-blue-color"
                                  style={{ fontSize: "12px" }}
                                >
                                  No active Works this Customer yet
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Row>
                            <Col
                              sm="12"
                              className="d-flex flex-column"
                              style={{
                                gap: "10px",
                                maxHeight:
                                  quotes?.length > 3 ? "300px" : "auto",
                                overflowY:
                                  quotes?.length > 3 ? "auto" : "visible",
                              }}
                            >
                              {quotes?.length > 0 ? (
                                quotes.map((data) => (
                                  <div
                                    key={data?.QuoteNumber}
                                    onClick={() =>
                                      handleNavigate(data?.QuoteId)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="mx-3">
                                      <div
                                        className="row align-items-start pt-2 p-3"
                                        style={{
                                          border: "1px solid #063164",
                                          fontSize: "12px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                          borderRadius: "8px",
                                          border:
                                            "0.5px solid rgba(6, 49, 100, 0.8)",
                                          color: "#063164",
                                        }}
                                      >
                                        <div className="col">
                                          <p style={{ marginBottom: "5px" }}>
                                            <strong>
                                              Quote #{data?.QuoteNumber}
                                            </strong>
                                          </p>
                                          <span
                                            style={{
                                              color:
                                                data?.status === "Approved"
                                                  ? "rgb(88, 204, 88)"
                                                  : data?.status ===
                                                    "Awaiting Response"
                                                  ? "orange"
                                                  : "#063164",
                                            }}
                                          >
                                            <strong>{data?.status}</strong>
                                          </span>
                                        </div>
                                        <div className="col text-center">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            TITLE
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {data?.Title}
                                          </p>
                                        </div>
                                        <div className="col text-center">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            ADDRESS
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {data?.location?.Address || "-"},{" "}
                                            {data?.location?.City || "-"},{" "}
                                            {data?.location?.State || "-"}
                                          </p>
                                        </div>
                                        <div className="col text-right">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            CREATED ON
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {moment(data.createdAt).format(
                                              "ll"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <Col sm="12" className="d-flex">
                                  <div
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 30%)",
                                      padding: "15px",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                  >
                                    <img src={truck} alt="Truck Icon" />
                                  </div>
                                  <div className="mx-2">
                                    <h6
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Quotes
                                    </h6>
                                    <p
                                      className="text-blue-color"
                                      style={{ fontSize: "12px" }}
                                    >
                                      No active Quotes this Customer yet
                                    </p>
                                  </div>
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={5}>
                          <Row>
                            <Col
                              sm="12"
                              className="d-flex flex-column"
                              style={{
                                gap: "10px",
                                maxHeight:
                                  contract?.length > 3 ? "300px" : "auto",
                                overflowY:
                                  contract?.length > 3 ? "auto" : "visible",
                              }}
                            >
                              {contract?.length > 0 ? (
                                contract.map((data) => (
                                  <div
                                    key={data?.ContractNumber}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleContractNavigate(data?.ContractId)
                                    }
                                  >
                                    <div className="mx-3">
                                      <div
                                        className="row align-items-start pt-2 p-3 text-blue-color"
                                        style={{
                                          border: "1px solid #063164",
                                          fontSize: "12px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                          borderRadius: "8px",
                                          border:
                                            "0.5px solid rgba(6, 49, 100, 0.8)",
                                        }}
                                      >
                                        <div className="col">
                                          <p style={{ marginBottom: "5px" }}>
                                            <strong>
                                              Contract #{data?.ContractNumber}
                                            </strong>
                                          </p>
                                          <span
                                            style={{
                                              color:
                                                data?.Status === "Unscheduled"
                                                  ? "#E88C44"
                                                  : data?.Status === "Today"
                                                  ? "#089F57"
                                                  : data?.Status === "Upcoming"
                                                  ? "#089F57"
                                                  : data?.Status === "Scheduled"
                                                  ? "#C8CC00"
                                                  : "",
                                            }}
                                          >
                                            <strong>{data?.Status}</strong>
                                          </span>
                                        </div>
                                        <div className="col text-center">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            TITLE
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {data?.Title}
                                          </p>
                                        </div>
                                        <div className="col text-center">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            ADDRESS
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {data?.location?.Address || "-"},{" "}
                                            {data?.location?.City || "-"},{" "}
                                            {data?.location?.State || "-"}
                                          </p>
                                        </div>
                                        <div className="col text-right">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            CREATED ON
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {moment(data.createdAt).format(
                                              "ll"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <Col sm="12" className="d-flex">
                                  <div
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 30%)",
                                      padding: "15px",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                  >
                                    <img src={truck} alt="Truck Icon" />
                                  </div>
                                  <div className="mx-2">
                                    <h6
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Contract
                                    </h6>
                                    <p
                                      className="text-blue-color"
                                      style={{ fontSize: "12px" }}
                                    >
                                      No active contrats for this Customer yet
                                    </p>
                                  </div>
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={6}>
                          <Row>
                            {/* <Col sm="12" className="d-flex">
                              <div
                                style={{
                                  backgroundColor: "rgba(6, 49, 100, 30%)",
                                  padding: "15px",
                                  borderRadius: "50%",
                                  height: "50px",
                                  width: "50px",
                                }}
                              >
                                <img src={truck} />
                              </div>
                              <div className="mx-2">
                                <h6
                                  className="mb-0 my-2 text-blue-color"
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Invoice
                                </h6>
                                <p
                                  className="text-blue-color"
                                  style={{ fontSize: "12px" }}
                                >
                                  No invoices for this
                                  Customer yet
                                </p>
                              </div>
                            </Col> */}
                          
                            <Col
                              sm="12"
                              className="d-flex flex-column"
                              style={{
                                gap: "10px",
                                maxHeight:
                                  invoice?.length > 3 ? "300px" : "auto",
                                overflowY:
                                  invoice?.length > 3 ? "auto" : "visible",
                              }}
                            >
                              {invoice?.length > 0 ? (
                                invoice?.map((data) => (
                                  <div
                                    key={data?.InvoiceNumber}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleInvoiceNavigate(data?.InvoiceId)
                                    }
                                  >
                                    <div className="mx-3">
                                      <div
                                        className="row align-items-start pt-2 p-3 text-blue-color"
                                        style={{
                                          border: "1px solid #063164",
                                          fontSize: "12px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                          borderRadius: "8px",
                                          border:
                                            "0.5px solid rgba(6, 49, 100, 0.8)",
                                        }}
                                      >
                                        <div className="col">
                                          <p style={{ marginBottom: "5px" }}>
                                            <strong>
                                              Invoice #{data?.InvoiceNumber}
                                            </strong>
                                          </p>
                                          <span
                                            style={{
                                              color:
                                                data?.status === "Approved"
                                                  ? "rgb(88, 204, 88)"
                                                  : data?.status ===
                                                    "Awaiting Response"
                                                  ? "orange"
                                                  : "#063164",
                                            }}
                                          >
                                            <strong> {data?.Status}</strong>
                                          </span>
                                        </div>
                                        <div className="col text-center">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            Subject
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {data?.Subject}
                                          </p>
                                        </div>
                                        <div className="col text-center">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            ADDRESS
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {data?.location?.Address || "-"},{" "}
                                            {data?.location?.City || "-"},{" "}
                                            {data?.location?.State || "-"}
                                          </p>
                                        </div>
                                        <div className="col text-right">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            CREATED ON
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {moment(data.createdAt).format(
                                              "ll"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <Col sm="12" className="d-flex">
                                  <div
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 30%)",
                                      padding: "15px",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                  >
                                    <img src={truck} alt="Truck Icon" />
                                  </div>
                                  <div className="mx-2">
                                    <h6
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Invoice
                                    </h6>
                                    <p
                                      className="text-blue-color"
                                      style={{ fontSize: "12px" }}
                                    >
                                      No active invoices for this Customer yet
                                    </p>
                                  </div>
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </div>
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    border: "0.5px solid rgba(6, 49, 100, 30%)",
                  }}
                  className="my-4"
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center p-2"
                    style={{ border: "none" }}
                  >
                    <h5 className="text-blue-color" style={{ fontWeight: 600 }}>
                      Schedule
                    </h5>
                  </CardHeader>
                  <CardBody style={{ padding: "10px 0px" }}>
                    <Row>
                      <Col
                        sm="12"
                        className="d-flex"
                        style={{ paddingLeft: "30px" }}
                      >
                        <div
                          className="icon-section"
                          style={{
                            backgroundColor: "rgba(6, 49, 100, 30%)",
                            borderRadius: "50%",
                            height: "50px",
                            width: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img src={schedule} />
                        </div>
                        <div className="mx-2">
                          <h6
                            className="mb-0 my-2 text-blue-color"
                            style={{ fontSize: "14px", fontWeight: 600 }}
                          >
                            No scheduled items
                          </h6>
                          <p
                            className="text-blue-color"
                            style={{ fontSize: "12px" }}
                          >
                            Nothing is scheduled for this Customer yet{" "}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PropertyDetails;
