import React from "react";
import { Circles, ColorRing } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  Button,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Table,
} from "reactstrap";
import { Toaster } from "react-hot-toast";
import { TextField } from "@mui/material";
import InputText from "../../../../components/InputFields/InputText";
import GetProducts from "../../../../components/Products/GetProducts";
import { DisplayImage } from "../../../../components/Files/DisplayFiles";
import { Files } from "../../../../components/Files";
import CustomerModal from "../CustomerModal";
import QuoteMail from "../QuoteMail";

const Addquotes = ({ 
    loader,
    customersData,
    formik,
    setIsCustomer,
    setTitleRef,
    propertyData,
    quotesData,
    Previous,
    isNumChange,
    setIsNumChange,
    handleQuoteNumberChange,
    lineItems,
    handleSelectChange,
    setLineItems,
    showCosts,
    setShowCosts,
    menuIsOpen,
    setMenuIsOpen,
    deleteLineItem,
    isError,
    setIsError,
    productRef,
    addLineItem,
    subTotal,
    showDiscount,
    setShowDiscount,
    discountAmount,
    showTax,
    setShowTax,
    taxAmount,
    Total,
    loading,
    setLoading,
    handleSaveQuote,
    dropdown,
    toggle,
    modal,
    setModal,
    titleRef,
    handleConvertJob,
    isCustomer,
    isProperty,
    setIsProperty,
    setPropertyData,
    setCustomersData,
 }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%", width: "100%", marginTop: "25%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </div>
      ) : (
        <div>
          <Button
          className="text-white-color"
            style={{
              backgroundColor: "#063164",
              marginRight: "10px",
              width: "50px",
              height: "40px",
              padding: "0px 0px",
              borderRadius: "4px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={Previous} style={{ width: "20px", height: "20px" }} />
          </Button>
          <div>
            <div className="card justify-content-center align-items-center mb-3 mt-3 quotes">
              <div
                className="my-2 col-12 p-4 border-blue-color"
                style={{ borderRadius: "20px" }}
              >
                <CardTitle
                  style={{
                    fontSize: "27px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 600,
                  }}
                  className="cardtitle text-blue-color"
                >
                  Quote for
                  <span
                    className="d-flex align-items-center cardtitle"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsCustomer(true);
                    }}
                  >
                    <u
                      className="text-blue-color"
                      style={{ paddingLeft: "5px" }}
                    >
                      {customersData?.FirstName
                        ? `${customersData?.FirstName} ${customersData?.LastName}`
                        : "Customer Name"}
                    </u>
                    {!Object.keys(customersData).length > 0 ? (
                      <Button
                        className="mx-3 bg-button-blue-color"
                        size="sm"
                        style={{
                          height: "20px",
                          paddingBottom: "3px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        +
                      </Button>
                    ) : null}
                  </span>
                </CardTitle>
                <div
                  className="d-flex  my-3 gap-4 job-title"
                  style={{ color: "rgba(6, 49, 100, 1)" }}
                >
                  <div className="col-6 mt-3 order-1 jobinput ">
                    <div>
                      <TextField
                        ref={setTitleRef}
                        value={formik.values?.Title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.Title && Boolean(formik.errors.Title)
                        }
                        helperText={formik.touched.Title && formik.errors.Title}
                        name="Title"
                        id="title"
                        placeholder="Enter Job Title here..."
                        label="Job Title"
                        type="text"
                        className="text-blue-color w-100 "
                      />
                    </div>
                    {customersData?.FirstName && propertyData?.City && (
                      <div
                        className="d-flex mt-5 gap-3"
                        style={{ color: "rgba(6, 49, 100, 1)" }}
                      >
                        <div className="col-6 text-left">
                          <h6>
                            <strong>Property address</strong>
                          </h6>
                          <p>
                            {propertyData?.Address || "-"} <br />
                            {propertyData?.City || "-"},{" "}
                            {propertyData?.State || "-"},{" "}
                            {propertyData?.Zip || "-"}
                            <br />
                            {propertyData?.Country || "-"} <br />
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                setIsCustomer(true);
                              }}
                              href="#Customer"
                              style={{ color: "green", cursor: "pointer" }}
                            >
                              Change
                            </a>
                          </p>
                        </div>
                        <div className="col-6 text-left">
                          <h6>Contact details</h6>
                          <p>
                            {customersData?.PhoneNumber || "-"}
                            <br />
                            {customersData?.EmailAddress || "-"}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-6 d-flex order-2 changesinput ">
                    <div className="col-6 my-3 mx-3 text-left">
                      {!isNumChange ? (
                        <p
                          style={{ fontSize: "13px", marginBottom: "16px" }}
                          className="text-blue-color"
                        >
                          Quote number #{formik.values.QuoteNumber}
                        </p>
                      ) : (
                        <InputText
                          value={formik.values.QuoteNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="QuoteNumber"
                          id="QuoteNumber"
                          label="Quote Number"
                          type="number"
                          className="text-blue-color w-100"
                          fieldHeight="46px"
                        />
                      )}
                    </div>
                    <div className="col-3 my-3 text-center changeText">
                      {!isNumChange ? (
                        <u
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsNumChange(true)}
                        >
                          Change
                        </u>
                      ) : (
                        <Button
                          onClick={handleQuoteNumberChange}
                          className="buttons outline-button-blue-color outline selectclientaddquote bg-blue-color"
                        >
                          Done
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                {lineItems?.length > 0 &&
                  lineItems?.map((item, index) => (
                    <React.Fragment key={index}>
                      <GetProducts
                        item={item}
                        index={index}
                        handleSelectChange={handleSelectChange}
                        lineItems={lineItems}
                        setLineItems={setLineItems}
                        showCosts={showCosts}
                        setShowCosts={setShowCosts}
                        menuIsOpen={menuIsOpen}
                        setMenuIsOpen={setMenuIsOpen}
                        deleteLineItem={deleteLineItem}
                        isError={isError}
                        setIsError={setIsError}
                        productRef={productRef}
                      />
                    </React.Fragment>
                  ))}

                <div className="d-flex">
                  <div className="col-5 d-flex gap-4" style={{ width: "auto" }}>
                    <Button
                      className="bg-button-blue-color top-button"
                      style={{
                        padding: "4px 10px",
                        fontSize: "14px",
                      }}
                      onClick={addLineItem}
                    >
                      + Add Line Item
                    </Button>
                  </div>
                </div>
                <div className="d-flex my-4 client-message">
                  <div className="col-5 order-sm-1 messageinput">
                    <div>
                      <TextField
                        value={formik.values.CustomerMessage}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name={`CustomerMessage`}
                        id="client_message"
                        placeholder="Enter Client Message here..."
                        label="Client Message"
                        type="text"
                        className="text-blue-color mx-0 w-100"
                        multiline
                        rows={4}
                      />
                    </div>
                    <div className="my-3">
                      <Input
                        id="ContractDisclaimer"
                        name="ContractDisclaimer"
                        placeholder="Contract/ Disclaimer"
                        type="textarea"
                        className="text-blue-color contractDis"
                        style={{
                          fontSize: "14px",
                          paddingLeft: "15px",
                          height: "96px",
                        }}
                        value={formik.values.ContractDisclaimer}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-7 order-sm-2 totalinput">
                    <Table>
                      <tbody>
                        <tr>
                          <td
                            className="text-blue-color  "
                            style={{ height: "50px" }}
                          >
                            Subtotal
                          </td>
                          <td className="text-end text-blue-color">
                            ${subTotal?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="text-blue-color"
                            style={{ height: "50px" }}
                          >
                            Discount
                          </td>
                          <td
                            className={`d-flex ${
                              !showDiscount
                                ? "justify-content-end"
                                : "justify-content-between"
                            }`}
                          >
                            {showDiscount && (
                              <span
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <Input
                                  id="Discount"
                                  name="Discount"
                                  placeholder="0.00"
                                  className="text-blue-color"
                                  style={{
                                    fontSize: "14px",
                                    paddingLeft: "15px",
                                  }}
                                  value={formik.values.Discount}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                <span
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    pointerEvents: "none",
                                  }}
                                  className="text-blue-color"
                                >
                                  %
                                </span>
                              </span>
                            )}
                            <u
                              className="text-blue-color"
                              style={{
                                fontWeight: 600,
                                cursor: "pointer",
                                textAlign: "end",
                                marginBottom: "9px",
                              }}
                              onClick={() => setShowDiscount(!showDiscount)}
                            >
                              {discountAmount > 0
                                ? `$${discountAmount?.toFixed(2)}`
                                : "Add Discount"}
                            </u>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="text-blue-color"
                            style={{ height: "50px" }}
                          >
                            Tax
                          </td>
                          <td
                            className={`d-flex ${
                              !showTax
                                ? "justify-content-end"
                                : "justify-content-between"
                            }`}
                          >
                            {showTax && (
                              <span
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <Input
                                  id="Tax"
                                  name="Tax"
                                  placeholder="0.00"
                                  className="text-blue-color"
                                  style={{
                                    fontSize: "14px",
                                    paddingLeft: "15px",
                                  }}
                                  value={formik.values.Tax}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                <span
                                  className="text-blue-color"
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    pointerEvents: "none",
                                  }}
                                >
                                  %
                                </span>
                              </span>
                            )}
                            <u
                              className="text-blue-color"
                              style={{
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "9px",
                              }}
                              onClick={() => setShowTax(!showTax)}
                            >
                              {taxAmount > 0
                                ? `$${taxAmount?.toFixed(2)}`
                                : "Add Tax"}
                            </u>
                          </td>
                        </tr>
                        <tr className="last-row border-0">
                          <td className="border-0 text-blue-color">
                            <b>Total</b>
                          </td>
                          <td className="text-end border-0 text-blue-color">
                            <b>${Total}</b>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      border: "0.5px solid rgba(6, 49, 100, 80%)",
                      padding: "15px",
                      borderRadius: "10px",
                    }}
                  >
                    <p className="text-blue-color" style={{ fontWeight: 600 }}>
                      Internal notes and Attachments <HelpOutlineOutlinedIcon />
                    </p>

                    <TextField
                      value={formik.values.Notes}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.Notes && Boolean(formik.errors.Notes)
                      }
                      helperText={formik.touched.Notes && formik.errors.Notes}
                      name="Notes"
                      id="outlined-multiline-static"
                      placeholder="Enter A  Notes here..."
                      label="Enter Notes "
                      type="text"
                      className="w-100"
                      multiline
                      rows={3}
                    />
                    <DisplayImage
                      files={formik?.values?.Attachment}
                      setFiles={(value) =>
                        formik.setFieldValue("Attachment", value)
                      }
                      IsDeleted={true}
                    />
                    <Files
                      files={formik?.values?.Attachment}
                      setFiles={(value) =>
                        formik.setFieldValue("Attachment", value)
                      }
                    />
                    <hr />
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between button-responsive"
                  style={{ marginTop: "80px", gap: "10px" }}
                >
                  <div>
                    <Button
                      outline
                      className="footer-buttons"
                      style={{ fontSize: "16px" }}
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </div>

                  {Object.keys(customersData).length > 0 ? (
                    //5206 code start : "Loader"
                    loading ? (
                      <div className="d-flex justify-content-center">
                      <Circles
                        height="20"
                        width="20"
                        color="#063164"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        visible={loading}
                      />
                    </div>
                    ) : (
                      <div className="gap-3 d-flex sec-button-section">
                        <Button
                          outline
                          className="buttons outline-button-blue-color outline text-blue-color"
                          style={{ fontSize: "16px", height: "38px" }}
                          onClick={() => {
                            setLoading(true);
                            handleSaveQuote(false);
                          }}
                        //5206 code start : "Loader"
                        >
                          {location.state?.id ? " Update Quote" : "Save Quote"}
                          {/* Save Quote */}
                        </Button>
                        <Dropdown isOpen={dropdown} toggle={toggle}>
                          <DropdownToggle
                            caret
                            className="bg-blue-color"
                            disabled={
                              !(
                                formik.values.Title &&
                                lineItems.length > 0 &&
                                lineItems[0].Name !== ""
                              )
                            }
                            style={{
                              opacity:
                                formik.values.Title &&
                                lineItems.length > 0 &&
                                lineItems[0].Name !== ""
                                  ? 1
                                  : 0.5,
                              pointerEvents:
                                formik.values.Title &&
                                lineItems.length > 0 &&
                                lineItems[0].Name !== ""
                                  ? "auto"
                                  : "none",
                            }}
                          >
                            {location.state?.id
                              ? "Update And..."
                              : "Save And ..."}
                          </DropdownToggle>
                          <DropdownMenu className="mb-2">
                            <DropdownItem
                              onClick={() => {
                                if (
                                  lineItems.length > 0 &&
                                  lineItems[0].Name !== "" &&
                                  formik.values.Title
                                ) {
                                  setModal(true);
                                } else if (!formik.values.Title) {
                                  setLoading(false);
                                  titleRef.focus();
                                } else {
                                  setLoading(false);
                                  setIsError(true);
                                  productRef.current.focus();
                                }
                              }}
                            >
                              Save and send mail
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setLoading(true);
                                handleConvertJob();
                              }}
                            >
                              Convert To Contract
                            </DropdownItem>
                            <DropdownItem onClick={() => handleSaveQuote(true)}>
                              Mark as awaiting response
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    )
                  ) : (
                    <Button
                      outline
                      className="buttons outline-button-blue-color outline selectclientaddquote bg-blue-color"
                      style={{
                        fontSize: "16px",
                        color: "white",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsCustomer(true);
                      }}
                    >
                      Select Customer
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <CustomerModal
        isCustomer={isCustomer}
        setIsCustomer={setIsCustomer}
        isProperty={isProperty}
        setIsProperty={setIsProperty}
        setFieldValue={formik.setFieldValue}
        values={formik.values}
        lineItems={lineItems}
        propertyData={propertyData}
        setPropertyData={setPropertyData}
        customersData={customersData}
        setCustomersData={setCustomersData}
        formik={formik}
      />
      <QuoteMail
        modal={modal}
        setModal={setModal}
        customerData={customersData}
        quotesData={quotesData}
        Total={Total}
        QuoteNumber={formik.values.QuoteNumber}
        Attachment={formik.values.Attachment}
        handleSubmit={() => {
          handleSaveQuote(true);
        }}
      />
      <Toaster />
    </>
  );
};

export default Addquotes;
