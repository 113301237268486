import React, { useEffect, useState } from "react";
import edit from "../../../assets/image/icons/edit.svg";
import Copy from "../../../assets/image/icons/copy.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import Previous from "../../../assets/image/icons/Previous.png";
import { Toaster } from "react-hot-toast";
import moment from "moment";
import { generatePDF } from "../../../components/Files/Functions";
import AxiosInstance from "../../AxiosInstance";
import quotteImage from "../../../assets/Blue-sidebar-icon/Quote.svg";
import QuotesDetails from "./Views/QuoteDetails";

const options = { year: "numeric", month: "short", day: "numeric" };

function QuotesDetail() {
  const cdnUrl = process.env.REACT_APP_CDN_API;

  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();

  const [quotesData, setQuotesData] = useState({});
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [mail, setMail] = useState(false);

  let fetchData = async () => {
    try {
      handleAuth(navigate, location);
      const res = await AxiosInstance.get(
        `/quote/quote_details/${location.state.id}`
      );
      setQuotesData(res.data.data);
    } catch (error) {
      console.error("Error: ", error.messae);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCopy = () => {
    const firstName = quotesData?.customer?.FirstName || "";
    const lastName = quotesData?.customer?.LastName || "";
    const fullName = `${firstName} ${lastName}`.trim();
    navigator.clipboard.writeText(fullName);
  };

  const moreActiontoggle = () => setDropdownOpen((prevState) => !prevState);

  const handleEditClick = () => {
    navigate(`/${companyName}/add-quotes`, {
      state: {
        id: location.state.id,
        navigats: [...location.state.navigats, "/add-quotes"],
      },
    });
  };

  const toggle = () => setModal(!modal);

  return (
    <>
      <QuotesDetails
        loader={loader}
        Previous={Previous}
        toggle={toggle}
        setMail={setMail}
        companyName={companyName}
        handleEditClick={handleEditClick}
        dropdownOpen={dropdownOpen}
        moreActiontoggle={moreActiontoggle}
        quotesData={quotesData}
        edit={edit}
        generatePDF={generatePDF}
        quotteImage={quotteImage}
        Copy={Copy}
        handleCopy={handleCopy}
        options={options}
        open={open}
        setOpen={setOpen}
        file={file}
        cdnUrl={cdnUrl}
        setFile={setFile}
        moment={moment}
        mail={mail}
      />
      <Toaster />
    </>
  );
}

export default QuotesDetail;
