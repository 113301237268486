import React, { useEffect } from "react";
import PlanCard from "../../components/Dashboard/PlanCard";
import { handleAuth } from "../../components/Login/Auth";
import { useLocation, useNavigate } from "react-router-dom";

const SuperadminDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  return (
    <div className="mt-5">
      <PlanCard />
    </div>
  );
};

export default SuperadminDashboard;
