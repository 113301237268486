import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabContent,
} from "reactstrap";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import edit from "../../../assets/image/icons/edit.svg";
import Copy from "../../../assets/image/icons/copy.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import axios from "axios";
import {
  DisplayImage,
  FileModal,
} from "../../../components/Files/DisplayFiles";
import Previous from "../../../assets/image/icons/Previous.png";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SignatureCanvas from "react-signature-canvas";
import { generatePDF } from "../../../components/Files/Functions";
import { Circles } from "react-loader-spinner";
import CloseIcon from "@mui/icons-material/Close";
import AxiosInstance from "../../AxiosInstance";
import QuoteMail from "../../Admin/Quotes/QuoteMail";

const options = { year: "numeric", month: "short", day: "numeric" };

function WorkerQuotesDetail() {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_API;
  const cdnUrl = process.env.REACT_APP_CDN_API;
  const [quotesData, setQuotesData] = useState({});
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const [mail, setMail] = useState(false);

  let fetchData = async () => {
    try {
      const res = await AxiosInstance.get(
        `${baseUrl}/quote/quote_details/${location.state.id}`
      );
      setQuotesData(res.data.data);
    } catch (error) {
      console.error("Error: ", error.messae);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCopy = () => {
    const fullName = `${quotesData?.customer?.FirstName || ""} ${
      quotesData?.customer?.LastName || ""
    }`;
    navigator.clipboard.writeText(fullName).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const moreActiontoggle = () => setDropdownOpen((prevState) => !prevState);

  // ----------------------------------------------------------------------------

  const [modal, setModal] = useState(false);
  // -------------------------------------------------
  const [signatureRef, setSignatureRef] = useState(null);

  const clearSignature = () => {
    signatureRef?.clear();
  };

  const [savedSignature, setSavedSignature] = useState(null);
  const [signatureSaved, setSignatureSaved] = useState(false);
  const [changeRequestReason, setChangeRequestReason] = useState("");

  const saveSignature = async () => {
    const labelElement = document.getElementById("typeSignatureLabel") || "";
    let image = "";
    try {
      const signatureCanvas = signatureRef;
      const signatureDataURL = signatureCanvas.toDataURL();
      const signatureBlob = await fetch(signatureDataURL).then((res) =>
        res.blob()
      );

      const imageData = new FormData();
      imageData.append("files", signatureBlob, "signature.png");

      const url = `${cdnUrl}/upload`;
      const result = await axios.post(url, imageData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSavedSignature(signatureDataURL);
      image = result.data.files[0].filename;
    } catch (error) {
      console.error(error, "imgs");
    }

    setSignatureSaved(true);

    const quoteApiUrl = `${baseUrl}/quotes/clientdashboard/${quotesData.quote_id}`;
    try {
      setLoader(true);
      const response = await AxiosInstance.put(quoteApiUrl, {
        signature: image || "",
      });

      if (response.data.statusCode === 200) {
        fetchData();
        setModal(false);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        console.error("Failed to update quote status:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating quote status:", error);
      toast.error(error);
    } finally {
      setLoader(false);
      setModal(false);
    }
  };

  const handleEditClick = () => {
    navigate(`/staff-member/add-quotes`, {
      state: {
        id: location.state.id,
        navigats: [...location.state.navigats, "/add-quotes"],
      },
    });
  };

  const toggle = () => setModal(!modal);

  const total = quotesData?.Total;
  const formattedTotal =
    total != null && !isNaN(total) ? Number(total).toFixed(2) : "N/A";

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const downloadPdf = async () => {
    try {
      const res = await AxiosInstance.post(
        `/quote/quotepdf/${location.state.id}`
      );
      if (res.data.statusCode === 200) {
        // The URL of the PDF
        const url = `https://app.cloudjobmanager.com/cdn/upload/${res.data.fileName}`;

        // const link = document.createElement("a");
        // link.href = url;
        // link.download = "contract_document.pdf";
        // document.body.appendChild;
        // link.click();

        fetch(url)
          .then((response) => {
            if (!response.ok) throw new Error("Network response was not ok");
            return response.blob(); // Get the file as a Blob
          })
          .then((blob) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob); // Create a URL for the blob
            link.download = "quotes_document.pdf"; // Set the file name
            document.body.appendChild(link);
            link.click(); // Programmatically click the link to trigger the download
            document.body.removeChild(link); // Clean up
          })
          .catch((error) =>
            console.error(
              "There was a problem with the fetch operation:",
              error
            )
          );
        // document.body.removeChild;
      }
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };

  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%", width: "100%", marginTop: "25%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </div>
      ) : (
        <div className="Quote-details">
          <div>
            <div className="d-flex justify-content-between buttonGroup">
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    color: "#fff",
                    marginRight: "10px",
                    width: "50px",
                    height: "40px",
                    marginBottom: "0px",
                    padding: "0px 0px",
                    borderRadius: "4px",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="text-capitalize bg-button-blue-color"
                >
                  <img
                    src={Previous}
                    style={{ width: "20px", height: "20px" }}
                  />
                </Button>
                {/* <NavigatorNav /> */}
              </div>
              <div className="d-flex justify-content-end gap-2 buttonGroupthree">
                <Button
                  className="bg-button-blue-color"
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    setMail(true);
                  }}
                >
                  <MailOutlineOutlinedIcon style={{ marginRight: "8px" }} />
                  Send Email
                </Button>{" "}
                <Button
                  className="outline-button-blue-color tect-blue-color"
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditClick();
                  }}
                >
                  <img src={edit} />
                  &nbsp; Edit
                </Button>
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={moreActiontoggle}
                  style={{ zIndex: 1 }}
                >
                  <DropdownToggle
                    className="text-blue-color outline"
                    style={{
                      background: "none",
                      border: "1px solid #063164",
                    }}
                  >
                    {" "}
                    <MoreHorizIcon /> More Actions
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #063164",
                    }}
                  >
                    <DropdownItem
                      className="text-blue-color"
                      onClick={() => {
                        navigate(`/staff-member/add-contract`, {
                          state: {
                            navigats: [
                              ...location.state.navigats,
                              "/add-contract",
                            ],
                            QuoteId: location.state.id,
                            formData: quotesData,
                            products: quotesData?.products,
                          },
                        });
                      }}
                    >
                      Convert to contract
                    </DropdownItem>
                    <DropdownItem className="text-blue-color" onClick={toggle}>
                      Collect Signature
                    </DropdownItem>
                    <DropdownItem
                      className="text-blue-color"
                      onClick={() => {
                        downloadPdf();
                      }}
                    >
                      Download PDF
                    </DropdownItem>
                    <DropdownItem
                      className="text-blue-color"
                      onClick={() => {
                        generatePDF(quotesData, "print");
                      }}
                    >
                      Print
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div>
              <Card
                className="p-3 my-4"
                style={{ border: "1px solid #063164", borderRadius: "12px" }}
              >
                <div className="justify-content-between d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <SentimentSatisfiedAltIcon
                      className="text-blue-color"
                      style={{ fontSize: "26px" }}
                    />
                    <p
                      className="mb-0 mx-2 text-blue-color "
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        backgroundColor: "#F8D5B9",
                        alignItems: "center",
                        padding: "7px 20px 7px",
                        borderRadius: "25px",
                      }}
                    >
                      <p
                        className="bg-blue-color"
                        style={{
                          borderRadius: "50%",
                          padding: "6px",
                          marginRight: "10px",
                          marginBottom: 0,
                        }}
                      ></p>
                      Lead
                    </p>
                  </div>
                  <p
                    className="text-blue-color"
                    style={{ fontSize: "14px", fontWeight: "600" }}
                  >
                    Quote :#{quotesData?.QuoteNumber}
                  </p>
                </div>
                <div>
                  <h3
                    className="my-4 mb-0 text-blue-color"
                    style={{ fontWeight: 700 }}
                  >
                    {quotesData?.customer?.FirstName || ""}{" "}
                    {quotesData?.customer?.LastName || ""}{" "}
                    <img
                      src={Copy}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleCopy()}
                    />
                  </h3>
                  <p className="text-blue-color" style={{ fontSize: "14px" }}>
                    {quotesData?.Title}
                  </p>
                </div>
                <div className="d-flex property-address">
                  <div className="col-8">
                    <p
                      className="mb-0 Sub-title text-blue-color"
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Property Address
                    </p>
                    <p
                      className="mb-0 text-data text-blue-color"
                      style={{ fontSize: "14px", width: "80%" }}
                    >
                      {quotesData?.location?.Address || ""}{" "}
                      {quotesData?.location?.City || ""}{" "}
                      {quotesData?.location?.State || ""}{" "}
                      {quotesData?.location?.Country || ""}{" "}
                      {quotesData?.location?.Zip || ""}
                    </p>
                  </div>
                  <div className="col-2 ">
                    <p
                      className="mb-0 Sub-title text-blue-color"
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        marginLeft: "-14px",
                      }}
                    >
                      Quote details
                    </p>
                    <p
                      className="mb-0 text-data text-blue-color"
                      style={{
                        fontSize: "14px",
                        marginLeft: "-14px",
                      }}
                    >
                      Created
                    </p>
                  </div>
                  <div className="col-2">
                    <p
                      className="mb-0 my-3 text-data text-blue-color"
                      style={{
                        fontSize: "14px",
                        marginLeft: "2px",
                      }}
                    >
                      {new Date(quotesData?.updatedAt).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </p>
                  </div>
                </div>
                <hr />
                {quotesData?.products?.length > 0 &&
                  quotesData?.products?.map((item, index) => (
                    <div className="d-flex" key={index}>
                      <div className="col-4">
                        {index === 0 && (
                          <p
                            className="mb-3 Sub-heading text-blue-color"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                            }}
                          >
                            Product/ Service
                          </p>
                        )}
                        <div className="d-flex justify-content-between products">
                          <div>
                            <p
                              className="mb-0 Sub-title text-blue-color"
                              style={{
                                fontSize: "14px",
                                fontWeight: 600,
                              }}
                            >
                              {item?.Name}
                            </p>
                            <p
                              className="mb-0 text-data text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {item?.Description}
                            </p>
                          </div>
                          <FileModal
                            open={open}
                            setOpen={setOpen}
                            file={file}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        {index === 0 && (
                          <p
                            className="mb-3 Sub-heading text-blue-color"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            Product/Service Image
                          </p>
                        )}
                        <p style={{ textAlign: "center" }}>
                          {item.Attachment ? (
                            <img
                              style={{
                                height: "40px",
                                marginBottom: "30px",
                                cursor: "pointer",
                              }}
                              src={`${cdnUrl}/upload/${item.Attachment}`}
                              onClick={() => {
                                setFile(item.Attachment);
                                setOpen(true);
                              }}
                              alt="Thumbnail"
                            />
                          ) : (
                            "-"
                          )}
                        </p>
                      </div>
                      <div className="col-2">
                        {index === 0 && (
                          <p
                            className="mb-3 Sub-heading text-blue-color"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            Unit/Hour
                          </p>
                        )}
                        <p
                          className="mb-0 text-data text-blue-color unit"
                          style={{
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >
                          {item?.Unit
                            ? item?.Unit
                            : item?.Square
                            ? item?.Square
                            : item?.Hour}
                        </p>
                      </div>
                      <div className="col-2">
                        {index === 0 && (
                          <p
                            className="mb-3 Sub-heading text-blue-color"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              textAlign: "end",
                            }}
                          >
                            Unit Cost/Hour Cost
                          </p>
                        )}
                        <p
                          className="mb-0 text-data text-blue-color per-unit"
                          style={{
                            fontSize: "14px",
                            textAlign: "end",
                          }}
                        >
                          $
                          {item?.CostPerHour
                            ? Number(item.CostPerHour).toFixed(2)
                            : item?.CostPerSquare
                            ? Number(item.CostPerSquare).toFixed(2)
                            : Number(item?.CostPerUnit).toFixed(2)}
                        </p>
                      </div>
                      <div className="col-2 text-end">
                        {index === 0 && (
                          <p
                            className="mb-3 Sub-heading text-blue-color totalInput"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                            }}
                          >
                            Total
                          </p>
                        )}
                        <p
                          className="mb-0 text-data text-blue-color total-pro"
                          style={{ fontSize: "14px" }}
                        >
                          $
                          {Number(item?.Total)
                            ? Number(item.Total).toFixed(2)
                            : ""}
                        </p>
                      </div>
                    </div>
                  ))}
                <hr />
                <div className="d-flex products-colum">
                  <div className="col-8 contc">
                    <p
                      className="mb-2 text-blue-color"
                      style={{ fontSize: "14px" }}
                    >
                      {quotesData?.CustomerMessage}
                    </p>
                    <p
                      className="text-data text-blue-color"
                      style={{ fontSize: "14px", width: "70%" }}
                    >
                      {quotesData?.ContractDisclaimer}
                    </p>
                  </div>
                  <div className="col-4 contc">
                    <div className="d-flex justify-content-between">
                      <p
                        className="text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        Subtotal
                      </p>
                      <p
                        className="text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        ${quotesData?.SubTotal}
                      </p>
                    </div>

                    {quotesData.Discount ? (
                      <div className="d-flex justify-content-between">
                        <p
                          className=""
                          style={{ fontSize: "14px", color: "#063164" }}
                        >
                          Discount
                        </p>
                        <p style={{ fontSize: "14px", color: "#063164" }}>
                          {quotesData?.Discount} %
                        </p>
                      </div>
                    ) : null}
                    {quotesData.Tax ? (
                      <div className="d-flex justify-content-between">
                        <p
                          className=""
                          style={{ fontSize: "14px", color: "#063164" }}
                        >
                          Tax
                        </p>
                        <p style={{ fontSize: "14px", color: "#063164" }}>
                          {quotesData?.Tax} %
                        </p>
                      </div>
                    ) : null}
                    <hr className="my-0 mb-2" />
                    <div className="d-flex justify-content-between">
                      <p
                        className="mb-2 text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        <b>Total</b>
                      </p>
                      <p
                        className="text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        <b>${quotesData.Total}</b>
                      </p>
                    </div>
                  </div>
                </div>

                {quotesData.signature ? (
                  <div className="col-7 d-flex align-items-center signature-main">
                    <div className="col-8 text-center sign-first">
                      <img
                        src={
                          quotesData.signature
                            ? `${cdnUrl}/upload/` + quotesData.signature
                            : ""
                        }
                        style={{ border: "none", height: "80px" }}
                      />
                      <hr />
                      <p> Signature </p>
                    </div>
                    <div
                      className="col-4 text-center mx-3 sign-first"
                      style={{ marginTop: "40px" }}
                    >
                      <p> {moment(quotesData?.approvedAt).format("ll")} </p>
                      <hr />
                      <p className="mb-0"> Date </p>
                    </div>
                  </div>
                ) : null}
              </Card>
              <Card
                className="p-3 my-4"
                style={{ border: "1px solid #063164", borderRadius: "12px" }}
              >
                <p className="text-blue-color" style={{ fontWeight: 600 }}>
                  Internal notes and attachments <HelpOutlineOutlinedIcon />
                </p>
                <div
                //2529 div change
                  className=""
                  style={{
                    border: "0.5px solid rgba(6, 49, 100, 80%)",
                    padding: "15px",
                    borderRadius: "10px",
                  }}
                >
                  <textarea
                    className="note-details"
                    placeholder="Note details"
                    value={quotesData?.Notes}
                    style={{ border: "none" }}
                  ></textarea>
                  
                </div>
                {/* 2529 div change */}
                
                <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                  {quotesData?.Attachment && (
                    <DisplayImage
                      files={quotesData?.Attachment}
                      IsDeleted={false}
                    />
                  )}
                </div>
              </Card>
              <Card
                className="p-3 my-4"
                style={{ border: "1px solid #063164", borderRadius: "12px" }}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <p
                      className="bg-blue-color"
                      style={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        borderRadius: "50%",
                      }}
                    >
                      {quotesData?.customer?.FirstName?.charAt(
                        0
                      )?.toUpperCase()}
                      {quotesData?.customer?.LastName?.charAt(0)?.toUpperCase()}
                    </p>
                    <div className="mx-2">
                      <p className="mb-0" style={{ fontSize: "12px" }}>
                        <b>
                          {quotesData?.customer?.FirstName || ""}{" "}
                          {quotesData?.customer?.LastName || ""}
                        </b>
                      </p>
                      <p style={{ fontSize: "12px" }} className="">
                        Created :{" "}
                        {new Date(quotesData?.updatedAt).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </p>
                    </div>
                  </div>
                  <p
                    className="mb-0 text-blue-color"
                    style={{ fontSize: "14px" }}
                  >
                    {quotesData?.customer?.companyName}
                  </p>
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader toggle={toggle} className="text-blue-color">
          Signature Pad
        </ModalHeader>
        <ModalBody>
          <TabContent
            className="text-start my-3 mx-0"
            style={{ padding: "2px" }}
          >
            {/* {/ Draw Signature /} */}
            <Col sm="12">
              <Card
                className="mx-0"
                style={{
                  width: "100%",
                  border: "1px solid #063164",
                  borderRadius: "10px",
                }}
              >
                <div className="p-3">
                  <div className="text-end" style={{}}>
                    <Button
                      className="outline-button-color"
                      outline
                      style={{
                        padding: "2px 18px 2px 18px",
                        fontSize: "14px",
                      }}
                      onClick={clearSignature}
                    >
                      Clear
                    </Button>
                  </div>
                  <SignatureCanvas
                    ref={(ref) => setSignatureRef(ref)}
                    canvasProps={{
                      width: 450,
                      height: 130,
                      className: "signature-canvas",
                    }}
                  />
                  <div
                    className="text-center p-2 text-blue-color"
                    style={{
                      borderTop: "2px dashed #063164",
                      fontSize: "14px",
                    }}
                  >
                    Write signature here...
                  </div>
                </div>
              </Card>
              <p
                className="text-blue-color"
                style={{
                  fontSize: "12px",
                  marginTop: "30px",
                }}
              >
                <i>
                  By signing below, you agree to this quote, the associated
                  costs, and any applicable terms and conditions.
                </i>
              </p>
            </Col>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button
            className="text-blue-color outline"
            onClick={toggle}
            style={{
              border: "1px solid #063164",
              background: "transparent",
            }}
          >
            Cancel
          </Button>
          <Button
            className="bg-button-blue-color"
            color="primary"
            onClick={saveSignature}
            style={{ border: "none" }}
          >
            {loader ? "Loading..." : "Submit"}
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <Toaster />
      <QuoteMail
        modal={mail}
        setModal={setMail}
        customerData={quotesData?.customer}
        Total={quotesData?.Total}
        QuoteNumber={quotesData?.QuoteNumber}
        Attachment={quotesData?.Attachment}
        quotesData={quotesData}
      />
    </>
  );
}

export default WorkerQuotesDetail;
