//5027 code start add quote in staff
import React, { useEffect, useRef, useState } from "react";
import "../../Customer/style.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postFile } from "../../../components/Files/Functions";
import toast, { Toaster } from "react-hot-toast";
import AxiosInstance from "../../AxiosInstance";
import Previous from "../../../assets/image/icons/Previous.png";
import { handleAuth } from "../../../components/Login/Auth";
import sendToast from "../../../components/Toast/sendToast";
import Addquotes from "../../Admin/Quotes/Views/Addquotes";

function WorkerAddQuotes() {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_API;
  const { companyName } = useParams();
  const [redirectToContract, setRedirectToContract] = useState(false);
  const [isNumChange, setIsNumChange] = useState(false);
  const [isError, setIsError] = useState(false);
  const productRef = useRef(null);
  const [titleRef, setTitleRef] = useState(null);
  const [showDiscount, setShowDiscount] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isProperty, setIsProperty] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tokenDecode, setTokenDecode] = useState({});
  const [loading, setLoading] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const toggle = () => setDropdown(!dropdown);
  const [modal, setModal] = useState(false);
  const [customersData, setCustomersData] = useState({});
  const [propertyData, setPropertyData] = useState({});
  const [showTax, setShowTax] = useState(false);
  const [ quotesData, setQuotesData] = useState(null);

  const [companyId, setCompanyId] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await handleAuth(navigate, location);
        setCompanyId(res.data.companyId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [lineItems, setLineItems] = useState([
    {
      Description: "",
      Name: "",
      Attachment: "",
      Type: "",
      Unit: "",
      CostPerUnit: "",
      Hour: "",
      CostPerHour: "",
      Square: "",
      CostPerSquare: "",
      Total: "",
      isNew: true,
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await handleAuth(navigate, location);
        setTokenDecode(res.data.role);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [showCosts, setShowCosts] = useState(
    new Array(lineItems?.length).fill(false)
  );
  const [menuIsOpen, setMenuIsOpen] = useState(
    new Array(lineItems?.length).fill(false)
  );

  const addLineItem = () => {
    setLineItems([
      ...lineItems,
      {
        ProductId: "",
        Description: "",
        Name: "",
        Type: "",
        Unit: 1,
        Attachment: "",
        CostPerUnit: "",
        Hour: "",
        CostPerHour: "",
        Square: "",
        CostPerSquare: "",
        Total: "",
        isNew: true,
      },
    ]);
  };

  const deleteLineItem = (index) => {
    const newLineItems = lineItems.filter((_, i) => i !== index);
    setLineItems(newLineItems);
  };

  const handleSelectChange = (index, selectedOption) => {
    const newLineItems = [...lineItems];

    newLineItems[index] = {
      ...newLineItems[index],
      ProductId: selectedOption.ProductId,
      Description: selectedOption.Description,
      Name: selectedOption.Name,
      Type: selectedOption.Type,
      Unit: selectedOption.Unit,
      Attachment: selectedOption.Attachment,
      CostPerUnit: selectedOption.CostPerUnit,
      Hour: selectedOption.Hour,
      CostPerHour: selectedOption.CostPerHour,
      Square: selectedOption.Square,
      CostPerSquare: selectedOption.CostPerSquare,
      isNew: true,
      Total: selectedOption?.Unit
        ? Number(selectedOption?.Unit || 0) *
          Number(selectedOption?.CostPerUnit || 0)
        : selectedOption?.Square
        ? Number(selectedOption?.Square || 0) *
          Number(selectedOption?.CostPerSquare || 0)
        : Number(selectedOption?.Hour || 0) *
          Number(selectedOption?.CostPerHour || 0),
    };

    setLineItems(newLineItems);
    setMenuIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  const formik = useFormik({
    initialValues: {
      Title: "",
      QuoteNumber: 1,
      CustomerId: "",
      Notes: "",
      LocationId: "",
      CustomerMessage: "",
      ContractDisclaimer:
        "Contract/ Disclaimer\nThis quote is valid for the next 30 days, after which values may be subject to change.",
      Attachment: [],
      Discount: "",
    },
    validationSchema: Yup.object({
      Title: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        const strings = [];

        for (let item of values.Attachment) {
          if (typeof item !== "string") {
            const string = await postFile(item);
            strings.push(string);
          } else {
            strings.push(item);
          }
        }

        for (let item of lineItems) {
          if (typeof item?.Attachment !== "string") {
            const string = await postFile(item.Attachment);
            item.Attachment = string;
          }
        }

        const object = {
          ...values,
          details: lineItems,
          SubTotal: calculateSubTotal(),
          Total: Total,
          Attachment: strings,
          role: tokenDecode,
          CompanyId: companyId,
        };

        let response;
        if (!location.state?.id) {
          response = await AxiosInstance.post(`/quote`, object);
        } else {
          response = await AxiosInstance.put(
            `/quote/${location.state?.id}`,
            object
          );
        }

        if (response.data.statusCode === 200) {
          toast.success(response.data.message);
          if (redirectToContract) {
            navigate(`/staff-member/add-contract`, {
              state: {
                formData: values,
                products: lineItems,
                navigats: [...location.state.navigats, "/add-contract"],
              },
            });
          } else {
            navigate(`/staff-member/WorkerQuotes`, {
              state: {
                navigats: ["/index", "/WorkerQuotes"],
              },
            });
          }
        } else {
          if (location.state?.id) {
            sendToast(
              "There was an issue generating quote. Please try again later."
            );
          } else {
            sendToast(
              "There was an issue updating quote. Please try again later."
            );
          }
        }
      } catch (error) {
        toast.error(error.message, "Error");
        console.error("Error: ", error);
      } finally {
        setRedirectToContract(false);
      }
    },
  });

  const handleSaveQuote = async (status) => {
    if (
      lineItems.length > 0 &&
      lineItems[0].Name !== "" &&
      formik.values.Title
    ) {
      if (status) {
        await formik.setFieldValue("status", "Awaiting Response");
      } else {
        await formik.setFieldValue("status", "Draft");
      }
      try {
        await formik.submitForm();
      } catch (error) {
        console.error("Error saving quote: ", error);
      }
    } else if (!formik.values.Title) {
      setLoading(false);
      titleRef.focus();
    } else {
      setLoading(false);
      setIsError(true);
      productRef.current.focus();
    }
  };

  const handleConvertJob = async () => {
    if (lineItems.length > 0 && lineItems[0].Name !== "") {
      await formik.setFieldValue("status", "Approved");
      try {
        setRedirectToContract(true);
        await formik.submitForm();
      } catch (error) {
        console.error("Error converting job: ", error);
      }
    } else {
      setLoading(false);
      setIsError(true);
      productRef.current.focus();
    }
  };

  useEffect(() => {
    if (lineItems.length > 0 && lineItems[0].Name !== "") {
      setIsError(false);
    }
  }, [lineItems]);

  useEffect(() => {
    const fetchData = async () => {
      if (location.state.id) {
        try {
          const res = await AxiosInstance.get(
            `/quote/quote_details/${location.state.id}`
          );
          if (res.data?.statusCode === 200) {
            setLoading(true);
            const data = res.data?.data;
            formik.setValues({
              Title: data?.Title,
              Firstname: data?.customer?.Firstname,
              LastName: data?.customer?.LastName,
              QuoteNumber: data?.QuoteNumber,
              CustomerId: data?.CustomerId,
              CompanyId: data.CompanyId,
              LocationId: data?.LocationId,
              CustomerMessage: data?.CustomerMessage,
              ContractDisclaimer: data?.ContractDisclaimer,
              Notes: data?.Notes,
              Attachment: data?.Attachment || null,
              Discount: data?.Discount,
              Tax: data?.Tax,
            });
            setQuotesData(data);
            setLineItems(
              data?.products || [
                {
                  Description: "",
                  Name: "",
                  Type: "",
                  Unit: "",
                  Attachment: "",
                  CostPerUnit: "",
                  Hour: "",
                  CostPerHour: "",
                  Square: "",
                  CostPerSquare: "",
                  Total: "",
                  isNew: true,
                },
              ]
            );
          }
        } catch (error) {
          console.error("Error: ", error);
        } finally {
          setLoading(false);
        }
      }
    };
    const getNumber = async () => {
      try {
        const res = await AxiosInstance.get(
          `/quote/get_number/${companyId}`
        );
        if (res?.data?.statusCode === 200) {
          const nextQuoteNumber = Number(res?.data?.quoteNumber) + 1;
          formik.setValues({
            ...formik.values,
            QuoteNumber: nextQuoteNumber,
          });
        }
      } catch (error) {
        console.error("Error fetching quote number: ", error);
      }
    };

    const initialize = async () => {
      await getNumber();
      if (location.state?.previewData) {
        formik.setValues(location.state.previewData?.values);
        setLineItems(
          location.state.previewData?.lineItems || [
            {
              Description: "",
              Name: "",
              Type: "",
              Unit: "",
              Attachment: "",
              CostPerUnit: "",
              Hour: "",
              CostPerHour: "",
              Square: "",
              CostPerSquare: "",
              Total: "",
              isNew: true,
            },
          ]
        );
        formik.setFieldValue("CustomerId", location.state.CustomerId);
        formik.setFieldValue("LocationId", location.state.LocationId);
        window.history.replaceState(
          {
            id: location.state?.previewData?.id
              ? location.state?.previewData?.id
              : null,
          },
          ""
        );
      } else if (location.state?.id) {
        await fetchData();
      }
    };

    initialize();
    fetchData();
    return () => {
      formik.resetForm();
      setLineItems([
        {
          FirstName: "",
          LastName: "",
          Description: "",
          Name: "",
          Attachment: "",
          Type: "",
          Unit: "",
          CostPerUnit: "",
          Hour: "",
          CostPerHour: "",
          Square: "",
          CostPerSquare: "",
          Total: "",
          isNew: true,
        },
      ]);
    };
  }, [location, companyId]);

  const calculateSubTotal = () => {
    const Total = lineItems?.reduce(
      (sum, item) => sum + Number(item.Total || 0),
      0
    );
    return Total;
  };

  const subTotal = calculateSubTotal();
  const discountAmount = formik.values.Discount
    ? (Number(formik.values.Discount) * subTotal) / 100
    : 0;
  const discountedTotal = subTotal - discountAmount;
  const taxAmount = formik.values.Tax
    ? (Number(formik.values.Tax) * discountedTotal) / 100
    : 0;

  const Total = (discountedTotal + taxAmount)?.toFixed(2);

  const handleQuoteNumberChange = async () => {
    const enteredQuoteNumber = Number(formik.values.QuoteNumber);
    // const companyId = localStorage.getItem("CompanyId");

    try {
      const res = await AxiosInstance.post(`/quote/check_number/${companyId}`, {
        QuoteNumber: enteredQuoteNumber,
      });

      if (res.data?.statusCode === 200) {
        toast.success("Quote number is valid and added successfully.");
        setIsNumChange(false);
      } else if (res.data?.statusCode === 203) {
        toast.error(
          "Quote number already exists. Please choose a different number."
        );
        formik.setFieldValue("QuoteNumber", "");
      } else {
        toast.error("Failed to check quote number. Please try again.");
      }
    } catch (error) {
      console.error(
        "Error checking quote number: ",
        error.response || error.message
      );
      toast.error("An error occurred while checking the quote number.");
    }
  };

  return (
    <>
      <Addquotes
        quotesData={quotesData}
        loader={loader}
        customersData={customersData}
        formik={formik}
        setIsCustomer={setIsCustomer}
        setTitleRef={setTitleRef}
        propertyData={propertyData}
        Previous={Previous}
        isNumChange={isNumChange}
        setIsNumChange={setIsNumChange}
        handleQuoteNumberChange={handleQuoteNumberChange}
        lineItems={lineItems}
        handleSelectChange={handleSelectChange}
        setLineItems={setLineItems}
        showCosts={showCosts}
        setShowCosts={setShowCosts}
        menuIsOpen={menuIsOpen}
        setMenuIsOpen={setMenuIsOpen}
        deleteLineItem={deleteLineItem}
        isError={isError}
        setIsError={setIsError}
        productRef={productRef}
        addLineItem={addLineItem}
        subTotal={subTotal}
        showDiscount={showDiscount}
        setShowDiscount={setShowDiscount}
        discountAmount={discountAmount}
        showTax={showTax}
        setShowTax={setShowTax}
        taxAmount={taxAmount}
        Total={Total}
        loading={loading}
        setLoading={setLoading}
        handleSaveQuote={handleSaveQuote}
        dropdown={dropdown}
        toggle={toggle}
        modal={modal}
        setModal={setModal}
        titleRef={titleRef}
        handleConvertJob={handleConvertJob}
        isCustomer={isCustomer}
        isProperty={isProperty}
        setIsProperty={setIsProperty}
        setPropertyData={setPropertyData}
        setCustomersData={setCustomersData}
      />
      <Toaster />
    </>
  );
}

export default WorkerAddQuotes;
//5027 code end quote in staff