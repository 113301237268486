import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import { Input } from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AxiosInstance from "../../AxiosInstance";
import { Circles } from "react-loader-spinner";
import QuoteUser from "../../../assets/Blue-sidebar-icon/Customer.svg";

const CustomerModal = ({
  isCustomer,
  setIsCustomer,
  isProperty,
  setIsProperty,
  setFieldValue,
  values,
  lineItems,
  setPropertyData,
  setCustomersData,
  formik,
  source,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const [customerData, setCustomerData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [loader, setLoader] = useState(true);
  const [companyId, setCompanyId] = useState();

  const fetchTokenData = async () => {
    if (!companyId) {
      try {
        const token =
          localStorage.getItem("adminToken") ||
          localStorage.getItem("workerToken");

        if (!token) {
          console.error("Token not found in localStorage");
          return;
        }
        const res = await AxiosInstance.post(`/company/token_data`, {
          token,
        });
        if (res.data.data.companyId) {
          setCompanyId(res.data.data.companyId);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchTokenData();
  }, []);

  const handleClose = (id) => {
    navigate(`/${companyName}/invoicetable`, {
      state: {
        CustomerId: id,
        navigats: [...location.state.navigats, "/invoicetable"],
      },
    });
  };

  const fetchData = async () => {
    try {
      const res = await AxiosInstance.get(
        `/customer/get_customer/${companyId}`
      );
      if (res.data.statusCode === 200) {
        setCustomerData(res.data.data);
      }
    } catch (error) {
      console.error("Error: ", error.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [companyId]);

  useEffect(() => {
    if (
      formik?.values?.CustomerId &&
      formik?.values?.LocationId &&
      customerData?.length > 0
    ) {
      const data = customerData.find(
        (item) => item.CustomerId === formik.values.CustomerId
      );
      setCustomersData(data);
      const location = data?.location?.find(
        (item) => item.LocationId === formik.values.LocationId
      );
      setPropertyData(location);
    }
  }, [formik.values, customerData]);

  const filteredCustomers = !isProperty
    ? customerData?.filter((customer) =>
        `${customer.FirstName} ${customer.LastName}`
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      )
    : locationData?.filter((location) =>
        `${location.Address} ${location.City} ${location.State} ${location.Country}`
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );

  useEffect(() => {
    const updateData = () => {
      if (location.state?.Customer) {
        setCustomersData(location.state.Customer);
        setFieldValue("CustomerId", location.state.Customer.CustomerId);
        if (
          location.state.Customer.location &&
          location.state.Customer.location.length === 1
        ) {
          setPropertyData(location.state.Customer);
          setFieldValue(
            "LocationId",
            location.state.Customer.location[0].LocationId
          );
        } else {
          setLocationData(location.state.Customer.location);
          setIsCustomer(true);
          setIsProperty(true);
        }
      }
    };

    updateData();
  }, [location.state?.Customer, customerData]);

  return (
    <>
      {/* <Dialog
        open={isCustomer}
        onClose={() => {
          setIsCustomer(false);
          setIsProperty(false);
        }}
        style={{ height: "100%" }}
      >
        <DialogTitle className="text-blue-color">
          Select or Create a Customer
          <Typography gutterBottom className="px-3 pt-3 text-blue-color">
            Which Customer would you like to create this for?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsCustomer(!isCustomer);
              setIsProperty(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            className="mx-3"
            sx={{
              border: "1px solid rgba(6, 49, 100, 80%)",
              borderRadius: "5px",
            }}
          >
            {loader ? (
              <div className="d-flex justify-content-center align-items-center my-5 text-blue-color">
                <Circles
                  height="50"
                  width="50"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loader}
                  color="#063164"
                />
              </div>
            ) : (
              <DialogContent dividers style={{ border: 0 }}>
                <div
                  className=" mb-3 selectcreatebtns"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <div
                    className="flex-grow-1 me-2 mb-2"
                    style={{ minWidth: "0", width: "150px" }}
                  >
                    <Input
                      id="search"
                      name="search"
                      placeholder="Search clients"
                      type="text"
                      className="text-blue-color w-100 searchclientmodal"
                      style={{
                        fontSize: "14px",
                        paddingTop: "7px",
                        paddingBottom: "7px",
                      }}
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                  <div>
                    <span className="mt-2 mb-2" style={{ textAlign: "center" }}>
                      or
                    </span>
                  </div>
                  <div
                    className="btn bg-button-blue-color text-white-color flex-grow-1 ms-2 mb-2 cratenclientmodal"
                    style={{ minWidth: "0", fontSize: "14px" }}
                    onClick={() => {
                      navigate(`/${companyName}/add-customer`, {
                        state: {
                          previewPage: location.pathname,
                          previewData: {
                            values,
                            lineItems,
                            id: location.state?.id || null,
                          },
                          navigats: [
                            ...location.state?.navigats,
                            "/add-customer",
                          ],
                        },
                      });
                    }}
                  >
                    Create new Customer
                  </div>
                </div>

                <span
                  className="mt-2 "
                  style={{ color: "blue", paddingLeft: "30px" }}
                >
                  Leads
                </span>

                <div
                  style={{
                    marginLeft: "-15px",
                    marginRight: "-15px",
                    marginTop: "-10px",
                  }}
                >
                  <hr />
                </div>

                {!isProperty
                  ? filteredCustomers?.length > 0 &&
                    filteredCustomers.map((item, index) => (
                      <div
                        className="w-100 mt-3 d-flex justify-content-between text-blue-color"
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (item.location?.length === 1) {
                            setFieldValue("CustomerId", item.CustomerId);
                            setFieldValue(
                              "LocationId",
                              item.location[0].LocationId
                            );
                            setIsProperty(false);
                            setIsCustomer(false);
                            if (source === "Invoice") {
                              handleClose(item.CustomerId);
                            }
                          } else {
                            setLocationData(
                              item.location.map((location) => ({
                                ...location,
                                CustomerId: item.CustomerId,
                              }))
                            );
                            setFieldValue("CustomerId", item.CustomerId);
                            setIsProperty(true);
                          }
                        }}
                      >
                        <div style={{ width: "8%" }}>
                          <img src={QuoteUser} />
                        </div>
                        <div
                          className="w-100 d-flex justify-content-between propertydetailsmodal"
                          style={{ fontSize: "14px" }}
                        >
                          <div className="px-2 w-100">
                            <h6
                              className="py-0 my-0"
                              style={{ fontSize: "12px" }}
                            >
                              {item.FirstName} {item.LastName}
                            </h6>
                            {item.location?.length}{" "}
                            {item.location?.length === 1
                              ? "Property"
                              : "Properties"}{" "}
                            | {item.PhoneNumber}
                          </div>
                        </div>
                      </div>
                    ))
                  : filteredCustomers?.length > 0 &&
                    filteredCustomers.map((location, index) => (
                      <div
                        onClick={() => {
                          setFieldValue("LocationId", location.LocationId);
                          setIsProperty(false);
                          setIsCustomer(false);
                          if (source === "Invoice") {
                            handleClose(location.CustomerId);
                          }
                        }}
                        className="py-2"
                        style={{
                          borderTop: index !== 0 ? "1px solid #333" : undefined,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        key={index}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div style={{ width: "8%" }}>
                            <DomainAddOutlinedIcon />
                          </div>
                          <div
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            <span>{location.Address || ""} </span>, &nbsp;
                            <span>{location.City || ""} </span>, &nbsp;
                            <br />
                            <span>{location.State || ""} </span>, &nbsp;
                            <span>{location.Country || ""} </span>
                          </div>
                        </div>
                        <ChevronRightIcon style={{ color: "#958e8edd" }} />
                      </div>
                    ))}
              </DialogContent>
            )}
          </Box>
        </DialogContent>
      </Dialog> */}

      <Dialog
        open={isCustomer}
        onClose={() => {
          setIsCustomer(false);
          setIsProperty(false);
        }}
        style={{ height: "100%" }}
      >
        <DialogTitle className="text-blue-color">
          Select or Create a Customer
          <Typography gutterBottom className="px-3 pt-3 text-blue-color">
            Which Customer would you like to create this for?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsCustomer(!isCustomer);
              setIsProperty(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            className="mx-3"
            sx={{
              border: "1px solid rgba(6, 49, 100, 80%)",
              borderRadius: "5px",
            }}
          >
            {loader ? (
              <div className="d-flex justify-content-center align-items-center my-5 text-blue-color">
                <Circles
                  height="50"
                  width="50"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loader}
                  color="#063164"
                />
              </div>
            ) : (
              <DialogContent dividers style={{ border: 0 }}>
                <div
                  className="mb-3 selectcreatebtns"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <div
                    className="flex-grow-1 me-2 mb-2"
                    style={{ minWidth: "0", width: "150px" }}
                  >
                    <Input
                      id="search"
                      name="search"
                      placeholder="Search clients"
                      type="text"
                      className="text-blue-color w-100 searchclientmodal"
                      style={{
                        fontSize: "14px",
                        paddingTop: "7px",
                        paddingBottom: "7px",
                      }}
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                  <div>
                    <span className="mt-2 mb-2" style={{ textAlign: "center" }}>
                      or
                    </span>
                  </div>
                  <div
                    className="btn bg-button-blue-color text-white-color flex-grow-1 ms-2 mb-2 cratenclientmodal"
                    style={{ minWidth: "0", fontSize: "14px" }}
                    onClick={() => {
                      navigate(`/${companyName}/add-customer`, {
                        state: {
                          previewPage: location.pathname,
                          previewData: {
                            values,
                            lineItems,
                            id: location.state?.id || null,
                          },
                          navigats: [
                            ...location.state?.navigats,
                            "/add-customer",
                          ],
                        },
                      });
                    }}
                  >
                    Create new Customer
                  </div>
                </div>

                {filteredCustomers?.length === 0 && (
                  <div
                    className="text-center mt-3 text-blue-color"
                    style={{ fontSize: "14px" }}
                  >
                    No customers found
                  </div>
                )}

                {filteredCustomers?.length > 0 && (
                  <>
                    <span
                      className="mt-2"
                      style={{ color: "blue", paddingLeft: "30px" }}
                    >
                      Leads
                    </span>

                    <div
                      style={{
                        marginLeft: "-15px",
                        marginRight: "-15px",
                        marginTop: "-10px",
                      }}
                    >
                      <hr />
                    </div>
                  </>
                )}

                {!isProperty
                  ? filteredCustomers?.length > 0 &&
                    filteredCustomers.map((item, index) => (
                      <div
                        className="w-100 mt-3 d-flex justify-content-between text-blue-color"
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (item.location?.length === 1) {
                            setFieldValue("CustomerId", item.CustomerId);
                            setFieldValue(
                              "LocationId",
                              item.location[0].LocationId
                            );
                            setIsProperty(false);
                            setIsCustomer(false);
                            if (source === "Invoice") {
                              handleClose(item.CustomerId);
                            }
                          } else {
                            setLocationData(
                              item.location.map((location) => ({
                                ...location,
                                CustomerId: item.CustomerId,
                              }))
                            );
                            setFieldValue("CustomerId", item.CustomerId);
                            setIsProperty(true);
                          }
                        }}
                      >
                        <div style={{ width: "8%" }}>
                          <img src={QuoteUser} />
                        </div>
                        <div
                          className="w-100 d-flex justify-content-between propertydetailsmodal"
                          style={{ fontSize: "14px" }}
                        >
                          <div className="px-2 w-100">
                            <h6
                              className="py-0 my-0"
                              style={{ fontSize: "12px" }}
                            >
                              {item.FirstName} {item.LastName}
                            </h6>
                            {item.location?.length}{" "}
                            {item.location?.length === 1
                              ? "Property"
                              : "Properties"}{" "}
                            | {item.PhoneNumber}
                          </div>
                        </div>
                      </div>
                    ))
                  : filteredCustomers?.length > 0 &&
                    filteredCustomers.map((location, index) => (
                      <div
                        onClick={() => {
                          setFieldValue("LocationId", location.LocationId);
                          setIsProperty(false);
                          setIsCustomer(false);
                          if (source === "Invoice") {
                            handleClose(location.CustomerId);
                          }
                        }}
                        className="py-2"
                        style={{
                          borderTop: index !== 0 ? "1px solid #333" : undefined,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        key={index}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div style={{ width: "8%" }}>
                            <DomainAddOutlinedIcon />
                          </div>
                          <div
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            <span>{location.Address || ""} </span>, &nbsp;
                            <span>{location.City || ""} </span>, &nbsp;
                            <br />
                            <span>{location.State || ""} </span>, &nbsp;
                            <span>{location.Country || ""} </span>
                          </div>
                        </div>
                        <ChevronRightIcon style={{ color: "#958e8edd" }} />
                      </div>
                    ))}
              </DialogContent>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomerModal;
