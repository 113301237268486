import {
  Box,
  FormGroup,
  Popover,
  Table,
  TextField,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import AxiosInstance from "../../Views/AxiosInstance";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../components/Login/Auth";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabContent,
  TabPane,
} from "reactstrap";
import InputText from "../InputFields/InputText";
import { DisplayImage } from "../Files/DisplayFiles";
import { Files } from "../Files";
// 5150 start
const InternalNotes = ({
  notes,
  setNotes,
  attachments,
  setAttachments,
  noBorder,
}) => {
  const handleNoteChange = (event) => {
    setNotes(event.target.value);
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    setAttachments((prev) => [...prev, ...files]);
  };
  return (
    <>
      <Card
        style={{
          border: noBorder ? "none" : "1px solid black",
          padding: "15px",
          borderRadius: "10px",
          borderColor: "rgba(6, 49, 100, 30%)",
        }}
      >
        <p className="text-blue-color mx-3" style={{ fontWeight: 600 }}>
          Internal notes and attachments <HelpOutlineOutlinedIcon />
        </p>
        <div
          class=""
          style={{
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <TextField
            className="note-details text-blue-color"
            id="note"
            name="note"
            label="Note details"
            value={notes}
            onChange={handleNoteChange}
            defaultValue=""
            placeholder="Enter notes here..."
            type="text"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#063164",
                },
                "&:hover fieldset": {
                  borderColor: "#063164",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#063164",
                },
                "& input": {
                  height: "70px",
                  alignItems: "start",
                },
              },
            }}
            InputProps={{
              readOnly: false,
            }}
            style={{ width: "100%", paddingTop: "0px" }}
            InputLabelProps={{
              style: { fontSize: "15px" },
            }}
          />
          <Files
            files={attachments}
            setFiles={(value) => setAttachments(value)}
          />
          <DisplayImage
            files={attachments}
            setFiles={(value) => setAttachments(value)}
            IsDeleted={true}
          />
          <hr />
          <div>
            <p style={{ fontSize: "14px" }} className="text-blue-color">
              Link note to related
            </p>
            <FormGroup check>
              <div className="d-flex gap-2 align-items-center">
                <Input
                  type="checkbox"
                  style={{ color: "#063164", marginTop: 0 }}
                />{" "}
                <Label
                  className="text-blue-color"
                  style={{ fontSize: "15px", marginBottom: 0 }}
                  check
                >
                  Invoices
                </Label>
              </div>
            </FormGroup>
          </div>
        </div>
      </Card>
    </>
  );
};
// 5150 end

const OneOffContract = ({
  formik,
  isCalendarVisible,
  setIsCalendarVisible,
}) => {
  const toggleCalendarVisibility = () => {
    setIsCalendarVisible(!isCalendarVisible);
  };

  const handleStartDateChange = (e) => {
    const startDate = e.target.value;
    formik.setFieldValue("OneoffJob.StartDate", startDate);

    if (
      formik.values.OneoffJob?.EndDate &&
      startDate > formik.values.OneoffJob.EndDate
    ) {
      formik.setFieldValue("OneoffJob.EndDate", startDate);
    }
  };

  const handleEndDateChange = (e) => {
    const endDate = e.target.value;
    if (
      formik.values.OneoffJob?.StartDate &&
      endDate >= formik.values.OneoffJob.StartDate
    ) {
      formik.setFieldValue("OneoffJob.EndDate", endDate);
    } else if (
      formik.values.OneoffJob?.StartDate &&
      endDate < formik.values.OneoffJob.StartDate
    ) {
      formik.setFieldValue(
        "OneoffJob.EndDate",
        formik.values.OneoffJob.StartDate
      );
    }
  };

  const handleStartTimeChange = (e) => {
    const startTime = e.target.value;
    formik.setFieldValue("OneoffJob.StartTime", startTime);

    if (
      formik.values.OneoffJob?.EndTime &&
      startTime > formik.values.OneoffJob.EndTime
    ) {
      formik.setFieldValue("OneoffJob.EndTime", startTime);
    }
  };

  const handleEndTimeChange = (e) => {
    const endTime = e.target.value;
    if (
      formik.values.OneoffJob?.StartTime &&
      endTime >= formik.values.OneoffJob.StartTime
    ) {
      formik.setFieldValue("OneoffJob.EndTime", endTime);
    } else if (
      formik.values.OneoffJob?.StartTime &&
      endTime < formik.values.OneoffJob.StartTime
    ) {
      formik.setFieldValue(
        "OneoffJob.EndTime",
        formik.values.OneoffJob.StartTime
      );
    }
  };

  return (
    <div className="col-md-4 col-lg-12 col-sm-12 first-tab jobs">
      <TabContent
        activeTab={1}
        className="text-start my-3 mx-0"
        style={{ padding: "2px" }}
      >
        <TabPane tabId={1}>
          <Col sm="12" className="d-flex">
            <Card className="mx-0" style={{ width: "100%" }}>
              <CardHeader
                className="d-flex justify-content-between"
                style={{
                  width: "100%",
                  alignItems: "center",
                  padding: "0 10px 0 10px",
                  borderBottom: "none",
                }}
              >
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                  className="my-3 text-blue-color"
                >
                  One off Contract Schedule
                </p>
                <Button
                  className="bg-button-blue-color hide-calendar"
                  style={{
                    padding: "0 14px 0 14px",
                    fontSize: "12px",
                    marginTop: 0,
                    height: "32px",
                    color: "#fff",
                    borderRadius: "2px",
                  }}
                  onClick={toggleCalendarVisibility}
                >
                  {isCalendarVisible ? "Hide Calendar" : "Show Calendar"}
                </Button>
              </CardHeader>
              <CardBody>
                <div className="d-flex row start-end-date mb-2">
                  {/* <FormGroup className="col-6 end-date">
                    <Label
                      htmlFor="startDate"
                      style={{
                        color: "#000000",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Start Date 
                    </Label>
                    <Input
                      id="startDate"
                      placeholder="with a placeholder"
                      type="date"
                      name="OneoffJob.StartaDate"
                      onChange={handleStartDateChange}
                      value={formik?.values?.OneoffJob?.StartaDate}
                      style={{ fontSize: "12px", height: "40px" }}
                    />
                  </FormGroup> */}
                  <FormGroup className="col-6 start-date">
                    <Label
                      for="startDate"
                      style={{
                        color: "#000000",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Start Date
                    </Label>
                    <Input
                      id="startDate"
                      placeholder="with a placeholder"
                      type="date"
                      name="OneoffJob.StartDate"
                      onChange={formik.handleChange}
                      value={formik?.values?.OneoffJob?.StartDate}
                      style={{ fontSize: "12px", height: "40px" }}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 end-date">
                    <Label
                      htmlFor="endDate"
                      style={{
                        color: "#000000",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      End Date (optional)
                    </Label>
                    <Input
                      id="endDate"
                      placeholder="with a placeholder"
                      type="date"
                      name="OneoffJob.EndDate"
                      onChange={handleEndDateChange}
                      value={formik?.values?.OneoffJob?.EndDate}
                      style={{ fontSize: "12px", height: "40px" }}
                    />
                  </FormGroup>
                </div>

                <Label
                  htmlFor="startTime"
                  style={{
                    color: "#000000",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                  className="my-2"
                >
                  Start Time & End Time
                </Label>

                <div className="d-flex time-section mb-2">
                  <Col
                    className="col-6"
                    style={{
                      borderBottomLeftRadius: "10px",
                      border: "1px solid rgba(6, 49, 100, 30%)",
                      borderTopLeftRadius: "10px",
                    }}
                  >
                    <Input
                      id="startTime"
                      placeholder="Start Time"
                      type="time"
                      name="OneoffJob.StartTime"
                      onChange={handleStartTimeChange}
                      value={formik?.values?.OneoffJob?.StartTime}
                      style={{
                        fontSize: "14px",
                        border: "none",
                        borderBottomLeftRadius: "10px",
                        borderTopLeftRadius: "10px",
                        height: "40px",
                      }}
                    />
                  </Col>
                  <Col
                    className="col-6"
                    style={{
                      borderBottomRightRadius: "10px",
                      borderTopRightRadius: "10px",
                      border: "1px solid rgba(6, 49, 100, 30%)",
                      borderLeft: "none",
                    }}
                  >
                    <Input
                      id="endTime"
                      type="time"
                      name="OneoffJob.EndTime"
                      onChange={handleEndTimeChange}
                      value={formik.values?.OneoffJob?.EndTime}
                      placeholder="End Time"
                      style={{
                        fontSize: "14px",
                        border: "none",
                        borderBottomRightRadius: "10px",
                        borderTopRightRadius: "10px",
                        height: "40px",
                      }}
                    />
                  </Col>
                </div>

                {/* <Label
                  className="my-2"
                  style={{
                    color: "#000000",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  Repeats
                </Label>
                <Input
                  type="select"
                  name="OneoffJob.Repeats"
                  className="text-blue-color"
                  onChange={formik.handleChange}
                  value={formik.values?.OneoffJob?.Repeats}
                  style={{
                    fontSize: "14px",
                    border: "1px solid rgba(6, 49, 100, 30%)",
                    borderRadius: "10px",
                    fontSize: "12px",
                    height: "40px",
                  }}
                >
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </Input> */}
                <Input
                  type="select"
                  name="OneoffJob.Repeats"
                  className="text-blue-color"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  value={formik.values?.OneoffJob?.Repeats}
                  style={{
                    fontSize: "14px",
                    border: "1px solid rgba(6, 49, 100, 30%)",
                    borderRadius: "10px",
                    fontSize: "12px",
                    height: "40px",
                  }}
                >
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </Input>

                <div>
                  <FormGroup className="my-3 mb-0 d-flex">
                    <Input
                      type="checkbox"
                      name="OneoffJob.ScheduleLetter"
                      onChange={formik.handleChange}
                      checked={formik.values?.OneoffJob?.ScheduleLetter}
                    />{" "}
                    <Label
                      style={{
                        fontSize: "12px",
                        color: "rgba(6,49,100,0.7)",
                        fontWeight: "400",
                        marginTop: "5px",
                      }}
                    >
                      Schedule letter
                    </Label>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Col>
        </TabPane>
      </TabContent>
    </div>
  );
};

// const RecurringContract = ({
//   search,
//   setSearch,
//   formik,
//   isCalendarVisible,
//   setIsCalendarVisible,
// }) => {
//   const [activeTab, setActiveTab] = useState(2);
//   const [isCardVisible, setIsCardVisible] = useState(true);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const setTabId = (tabId) => {
//     if (activeTab !== tabId) {
//       setActiveTab(tabId);
//     }
//   };
//   const toggleCalendarVisibility = () => {
//     setIsCalendarVisible(!isCalendarVisible);
//     setIsCardVisible(!isCardVisible);
//   };
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };
//   const currentDay = moment().format("dddd");
//   const currentDate = moment().format("Do");
//   const formattedDate = moment().format("MMMM D, YYYY");
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };
//   const handleStartTimeChange = (e) => {
//     const StartTime = e.target.value;
//     formik.setFieldValue("RecuringJob.StartTime", StartTime);

//     if (
//       formik.values.RecuringJob?.EndTime &&
//       StartTime > formik.values.RecuringJob.EndTime
//     ) {
//       formik.setFieldValue("RecuringJob.EndTime", StartTime);
//     }
//   };

//   const handleEndTimeChange = (e) => {
//     const EndTime = e.target.value;

//     formik.setFieldValue("RecuringJob.EndTime", EndTime);

//     if (
//       formik.values.RecuringJob?.StartTime &&
//       EndTime < formik.values.RecuringJob.StartTime
//     ) {
//       formik.setFieldValue("RecuringJob.StartTime", EndTime);
//     }
//   };

//   const [Repeats, setRepeats] = useState("");
//   const [duration, setDuration] = React.useState("month(s)");
//   const [value, setValue] = React.useState(6);
//   const calculateLastVisitDate = (startDate, repeat) => {
//     if (!startDate) return "";

//     const start = moment(startDate);
//     let nextVisitDate = "";

//     switch (repeat) {
//       case `Weekly on ${formik.values?.RecuringJob?.StartDate}`:
//         nextVisitDate = start.add(1, "week").format("MMMM DD, YYYY");
//         break;
//       case `Every 2 Weeks on ${formik.values?.RecuringJob?.StartDate}`:
//         nextVisitDate = start.add(2, "weeks").format("MMMM DD, YYYY");
//         break;
//       case `Monthly on the ${start.format("Do")} of the month`:
//         nextVisitDate = start.add(1, "month").format("MMMM DD, YYYY");
//         break;
//       default:
//         nextVisitDate = "";
//     }

//     return nextVisitDate;
//   };
//   const handleRepeatChange = (e) => {
//     const selectedValue = e.target.value;
//     setRepeats(selectedValue);
//     formik.setFieldValue("RecuringJob.Repeats", selectedValue);
//   };

//   const [lastDate, setLastDate] = useState("");

//   const handleDurationChange = (event) => {
//     const newDuration = event.target.value;
//     setDuration(newDuration);
//     calculateFutureDate(
//       value,
//       newDuration,
//       formik.values?.RecuringJob?.StartDate
//     );
//     formik.setFieldValue("RecuringJob.Duration", newDuration);
//   };

//   const calculateFutureDate = (num, dur, startDate) => {
//     if (num && dur) {
//       const durationNumber = parseInt(num, 10);
//       let futureDate;

//       switch (dur) {
//         case "day(s)":
//           futureDate = moment(startDate).add(durationNumber, "days");
//           break;
//         case "week(s)":
//           futureDate = moment(startDate).add(durationNumber, "weeks");
//           futureDate.subtract(1, "days");
//           break;
//         case "month(s)":
//           futureDate = moment(startDate).add(durationNumber, "months");
//           futureDate.subtract(1, "days");
//           break;
//         case "year(s)":
//           futureDate = moment(startDate).add(durationNumber, "years");
//           futureDate.subtract(1, "days");
//           break;
//         default:
//           futureDate = moment(startDate);
//       }

//       setLastDate(futureDate.format("MMMM DD, YYYY"));
//     } else {
//       setLastDate("");
//     }
//   };

//   const calculateTotalVisits = (startDate, lastDate, Repeats) => {
//     if (
//       !startDate ||
//       !lastDate ||
//       !moment(startDate).isValid() ||
//       !moment(lastDate).isValid()
//     ) {
//       return 0;
//     }

//     const start = moment(startDate);
//     const end = moment(lastDate);

//     let totalVisits = 0
//     switch (Repeats) {
//       case "Weekly on":
//         totalVisits = Math.floor(end.diff(start, "weeks", true)) + 1;
//         break;
//       case "Every 2 Weeks on":
//         totalVisits = Math.floor(end.diff(start, "weeks", true) / 2) + 1;
//         break;
//       case "Monthly on the date":
//         totalVisits = Math.floor(end.diff(start, "months", true)) + 1;
//         break;
//       default:
//         totalVisits = 0;
//     }

//     return totalVisits < 1 ? 1 : totalVisits;
//   };

//   const totalVisits = calculateTotalVisits(
//     formik.values?.RecuringJob?.StartDate,
//     lastDate,
//     Repeats
//   );

//   const handleValueChange = (event) => {
//     const newValue = Number(event.target.value);

//     if (!isNaN(newValue) && newValue >= 0) {
//       setValue(newValue);
//       formik.setFieldValue("RecuringJob.Frequency", newValue);
//     } else {
//       console.error("Invalid input: ", event.target.value);
//     }
//   };

//   return (
//     <>
//       <div className="col-md-4 col-lg-12 col-sm-12 first-tab">
//         <TabContent
//           activeTab={2}
//           className="text-start my-3 mx-0"
//           style={{ padding: "2px" }}
//         >
//           <TabPane tabId={2}>
//             <Col sm="12" className="d-flex">
//               <Card className="mx-0" style={{ width: "100%" }}>
//                 <CardHeader
//                   className="d-flex justify-content-between"
//                   style={{
//                     width: "100%",
//                     alignItems: "center",
//                     padding: "0 10px 0 10px",
//                     borderBottom: "none",
//                   }}
//                 >
//                   <p
//                     style={{ fontSize: "24px", fontWeight: 600 }}
//                     className="my-3 text-blue-color"
//                   >
//                     Recurring Schedule
//                   </p>
//                   <Button
//                     className="bg-button-blue-color"
//                     style={{
//                       padding: "0 14px 0 14px",
//                       fontSize: "12px",
//                       marginTop: 0,
//                       height: "32px",
//                       color: "#fff",
//                       borderRadius: "2px",
//                     }}
//                     onClick={toggleCalendarVisibility}
//                   >
//                     {isCalendarVisible ? "Hide Calendar" : "Show Calendar"}
//                   </Button>
//                 </CardHeader>
//                 <CardBody>
//                   <FormGroup>
//                     <Label
//                       htmlFor="startDate"
//                       className="mb-2"
//                       style={{
//                         color: "#000000",
//                         fontWeight: 600,
//                         fontSize: "12px",
//                       }}
//                     >
//                       Start Date
//                     </Label>
//                     <Input
//                       id="startDate"
//                       type="date"
//                       style={{ fontSize: "12px", height: "40px" }}
//                       name="RecuringJob.StartDate"
//                       onChange={formik.handleChange}
//                       value={formik.values?.RecuringJob?.StartDate}
//                     />
//                   </FormGroup>
//                   <Label
//                     htmlFor="exampleEmail"
//                     style={{
//                       color: "#000000",
//                       fontWeight: 600,
//                       fontSize: "12px",
//                     }}
//                     className="my-2"
//                   >
//                     Start Time & End Time
//                   </Label>
//                   <div className="d-flex" style={{ height: "" }}>
//                     <Col
//                       className="col-6"
//                       style={{
//                         borderBottomLeftRadius: "10px",
//                         border: "1px solid rgba(6, 49, 100, 30%)",
//                         borderTopLeftRadius: "10px",
//                       }}
//                     >
//                       <Input
//                         id="startTime"
//                         placeholder="Start Time"
//                         type="time"
//                         name="RecuringJob.StartTime"
//                         onChange={handleStartTimeChange}
//                         value={formik?.values?.RecuringJob?.StartTime}
//                         style={{
//                           fontSize: "14px",
//                           border: "none",
//                           borderBottomLeftRadius: "10px",
//                           borderTopLeftRadius: "10px",
//                           height: "40px",
//                         }}
//                       />
//                     </Col>
//                     <Col
//                       className="col-6"
//                       style={{
//                         borderBottomRightRadius: "10px",
//                         borderTopRightRadius: "10px",
//                         border: "1px solid rgba(6, 49, 100, 30%)",
//                         borderLeft: "none",
//                       }}
//                     >
//                       <Input
//                         id="EndTime"
//                         type="time"
//                         name="RecuringJob.EndTime"
//                         onChange={handleEndTimeChange}
//                         value={formik.values?.RecuringJob?.EndTime}
//                         placeholder="End Time"
//                         style={{
//                           fontSize: "14px",
//                           border: "none",
//                           borderBottomRightRadius: "10px",
//                           borderTopRightRadius: "10px",
//                           height: "40px",
//                         }}
//                       />
//                     </Col>
//                   </div>
//                   <Label
//                     htmlFor="Repeats"
//                     style={{
//                       color: "#000000",
//                       fontWeight: 600,
//                       fontSize: "12px",
//                     }}
//                     className="my-2"
//                   >
//                     Repeats
//                   </Label>

//                   <Input
//                     type="select"
//                     className="text-blue-color"
//                     name="RecuringJob.Repeats"
//                     onChange={handleRepeatChange}
//                     value={Repeats}
//                     style={{
//                       fontSize: "14px",
//                       border: "1px solid rgba(6, 49, 100, 30%)",
//                       borderRadius: "10px",
//                       height: "40px",
//                     }}
//                   >
//                     <option value="">As needed - we won't prompt you</option>
//                     <option value={`Weekly on`}>
//                       Weekly on{" "}
//                       {moment(formik.values?.RecuringJob?.StartDate).format(
//                         "dddd"
//                       )}
//                     </option>
//                     <option value={`Every 2 Weeks on`}>
//                       Every 2 Weeks on{" "}
//                       {moment(formik.values?.RecuringJob?.StartDate).format(
//                         "dddd"
//                       )}
//                     </option>
//                     <option value={`Monthly on the date`}>
//                       Monthly on the{" "}
//                       {moment(formik.values?.RecuringJob?.StartDate).format(
//                         "Do"
//                       )}{" "}
//                       of the month
//                     </option>
//                   </Input>

//                   <Label
//                     htmlFor="duration"
//                     style={{
//                       color: "#000000",
//                       fontWeight: 600,
//                       fontSize: "12px",
//                     }}
//                     className="my-2"
//                   >
//                     Duration
//                   </Label>
//                   <div className="recurring mt-2">
//                     <Box display="flex" flexDirection="column">
//                       <TextField
//                         label=""
//                         type="number"
//                         value={formik.values.RecuringJob.Frequency} // Use Formik state
//                         onChange={(e) => {
//                           const newValue = Number(e.target.value);
//                           if (!isNaN(newValue) && newValue >= 0) {
//                             formik.setFieldValue(
//                               "RecuringJob.Frequency",
//                               newValue
//                             ); // Update Formik state
//                           }
//                         }}
//                         InputProps={{
//                           endAdornment: (
//                             <InputAdornment
//                               position="end"
//                               style={{ width: "100%" }}
//                             >
//                               <Select
//                                 style={{ width: "100%" }}
//                                 value={formik.values.RecuringJob.Duration}
//                                 onChange={(e) => {
//                                   handleDurationChange(e);
//                                   formik.setFieldValue(
//                                     "RecuringJob.Duration",
//                                     e.target.value
//                                   ); // Update Formik state
//                                 }}
//                                 variant="outlined"
//                               >
//                                 <MenuItem value="day(s)">day(s)</MenuItem>
//                                 <MenuItem value="week(s)">week(s)</MenuItem>
//                                 <MenuItem value="month(s)">month(s)</MenuItem>
//                                 <MenuItem value="year(s)">year(s)</MenuItem>
//                               </Select>
//                             </InputAdornment>
//                           ),
//                         }}
//                         variant="outlined"
//                       />
//                     </Box>
//                   </div>

//                   <Label
//                     className="my-2"
//                     htmlFor="visits"
//                     style={{
//                       color: "#000000",
//                       fontWeight: 600,
//                       fontSize: "12px",
//                     }}
//                   >
//                     Visits
//                   </Label>
//                   <div className="d-flex">
//                     <div
//                       className="col-4"
//                       style={{
//                         borderRight: "0.5px solid rgba(6,49,100, 0.8)",
//                       }}
//                     >
//                       <p
//                         className="mb-0"
//                         color="rgba(6, 49, 100, 30%)"
//                         style={{ fontSize: "12px" }}
//                       >
//                         First
//                       </p>
//                       <p
//                         className="mb-0"
//                         color="rgba(6, 49, 100, 30%)"
//                         style={{ fontSize: "12px" }}
//                       >
//                         {formik.values?.RecuringJob?.StartDate
//                           ? moment(
//                               formik.values?.RecuringJob?.StartDate
//                             ).format("MMMM DD, YYYY")
//                           : ""}
//                       </p>
//                     </div>
//                     <div
//                       className="col-4 mx-1"
//                       style={{
//                         borderRight: "0.5px solid rgba(6, 49, 100, 0.8)",
//                       }}
//                     >
//                       <p
//                         className="mb-0"
//                         color="rgba(6, 49, 100, 30%)"
//                         style={{ fontSize: "12px" }}
//                       >
//                         Last
//                       </p>
//                       <p
//                         className="mb-0"
//                         color="rgba(6, 49, 100, 30%)"
//                         style={{ fontSize: "12px" }}
//                       >
//                         {lastDate}
//                       </p>
//                     </div>
//                     <div className="col-4 mx-1">
//                       <p
//                         className="mb-0"
//                         color="rgba(6, 49, 100, 30%)"
//                         style={{ fontSize: "12px" }}
//                       >
//                         Total
//                       </p>
//                       <p
//                         className="mb-0"
//                         color="rgba(6, 49, 100, 30%)"
//                         style={{ fontSize: "12px" }}
//                       >
//                         {totalVisits}
//                       </p>
//                     </div>
//                   </div>
//                   {/* <div className="col-4">
//                     <p className="mb-0" style={{ fontSize: "12px" }}>
//                       Last Visit Date
//                     </p>
//                     <p className="mb-0" style={{ fontSize: "12px" }}>
//                       {lastDate}
//                     </p>
//                   </div>
//                   <div className="col-4">
//                     <p className="mb-0" style={{ fontSize: "12px" }}>
//                       Total Visits
//                     </p>
//                     <p className="mb-0" style={{ fontSize: "12px" }}>
//                       {totalVisits}
//                     </p>
//                   </div> */}
//                 </CardBody>
//               </Card>
//             </Col>
//           </TabPane>
//         </TabContent>
//       </div>
//     </>
//   );
// };

// const CalendarJOB = ({ isCalendarVisible, setIsCalendarVisible }) => {
//   const localizer = momentLocalizer(moment);
//   const [events, setEvents] = useState([]);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const editEvent = (event) => {
//     const newTitle = prompt("Edit event name", event.title);
//     if (newTitle) {
//       setEvents(
//         events.map((ev) => (ev === event ? { ...ev, title: newTitle } : ev))
//       );
//     }
//   };
//   const addEvent = (title, date) => {
//     const newEvent = {
//       id: events.length,
//       title,
//       start: date,
//       end: date,
//       allDay: true,
//     };
//     setEvents([...events, newEvent]);
//   };

//   const CustomToolbar = ({ label, onNavigate }) => {
//     const goToBack = () => {
//       onNavigate("PREV");
//     };

//     const goToNext = () => {
//       onNavigate("NEXT");
//     };

//     return (
//       <div className="rbc-toolbar">
//         <button type="button" onClick={goToBack}>
//           Back
//         </button>
//         <span className="rbc-toolbar-label">{label}</span>

//         <button type="button" onClick={goToNext}>
//           Next
//         </button>
//       </div>
//     );
//   };

//   const toggleCalendarVisibility = () => {
//     setIsCalendarVisible(!isCalendarVisible);
//     setIsCardVisible(!isCardVisible);
//   };
//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };
//   const [isCardVisible, setIsCardVisible] = useState(true);

//   return (
//     <div className="col-12 mx-2 my-3 mb-0 first-tab">
//       <div>
//         {isCalendarVisible && (
//           <div>
//             <Calendar
//               localizer={localizer}
//               events={events}
//               startAccessor="start"
//               endAccessor="end"
//               style={{ height: 434, marginTop: "19.8%" }}
//               selectable
//               onSelectEvent={(event) => {
//                 if (
//                   window.confirm(
//                     `Do you want to edit the event: ${event.title}?`
//                   )
//                 ) {
//                   editEvent(event);
//                 }
//               }}
//               onSelectSlot={(slotInfo) => {
//                 const title = prompt("New Event name");
//                 if (title) {
//                   const date = slotInfo.start;
//                   addEvent(title, date);
//                 }
//               }}
//               components={{
//                 toolbar: CustomToolbar,
//               }}
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// const RecurringContract = ({
//   formik,
//   isCalendarVisible,
//   setIsCalendarVisible,
// }) => {
//   const [activeTab, setActiveTab] = useState(2);
//   const [isCardVisible, setIsCardVisible] = useState(true);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [Repeats, setRepeats] = useState("");
//   const [duration, setDuration] = useState("month(s)");
//   const [value, setValue] = useState(6); // This is the value for the duration number input
//   const [lastDate, setLastDate] = useState("");

//   const toggleCalendarVisibility = () => {
//     setIsCalendarVisible(!isCalendarVisible);
//     setIsCardVisible(!isCardVisible);
//   };

//   const handleStartTimeChange = (e) => {
//     const StartTime = e.target.value;
//     formik.setFieldValue("RecuringJob.StartTime", StartTime);

//     if (
//       formik.values.RecuringJob?.EndTime &&
//       StartTime > formik.values.RecuringJob.EndTime
//     ) {
//       formik.setFieldValue("RecuringJob.EndTime", StartTime);
//     }
//   };

//   const handleEndTimeChange = (e) => {
//     const EndTime = e.target.value;
//     formik.setFieldValue("RecuringJob.EndTime", EndTime);

//     if (
//       formik.values.RecuringJob?.StartTime &&
//       EndTime < formik.values.RecuringJob.StartTime
//     ) {
//       formik.setFieldValue("RecuringJob.StartTime", EndTime);
//     }
//   };

//   const handleRepeatChange = (e) => {
//     const selectedValue = e.target.value;
//     setRepeats(selectedValue);
//     formik.setFieldValue("RecuringJob.Repeats", selectedValue);
//   };

//   const handleDurationChange = (e) => {
//     const newDuration = e.target.value;
//     setDuration(newDuration);
//     calculateFutureDate(
//       value,
//       newDuration,
//       formik.values?.RecuringJob?.StartDate
//     );
//     formik.setFieldValue("RecuringJob.Duration", newDuration);
//   };

//   const handleValueChange = (e) => {
//     const newValue = e.target.value;
//     setValue(newValue);
//     calculateFutureDate(
//       newValue,
//       duration,
//       formik.values?.RecuringJob?.StartDate
//     );
//     formik.setFieldValue("RecuringJob.Frequency", newValue); // Assuming frequency is the number of duration units
//   };

//   const calculateFutureDate = (num, dur, startDate) => {
//     if (num && dur && startDate) {
//       const durationNumber = parseInt(num, 10);
//       let futureDate;

//       switch (dur) {
//         case "day(s)":
//           futureDate = moment(startDate).add(durationNumber, "days");
//           break;
//         case "week(s)":
//           futureDate = moment(startDate).add(durationNumber, "weeks");
//           futureDate.subtract(1, "days");
//           break;
//         case "month(s)":
//           futureDate = moment(startDate).add(durationNumber, "months");
//           futureDate.subtract(1, "days");
//           break;
//         case "year(s)":
//           futureDate = moment(startDate).add(durationNumber, "years");
//           futureDate.subtract(1, "days");
//           break;
//         default:
//           futureDate = moment(startDate);
//       }

//       setLastDate(futureDate.format("MMMM DD, YYYY"));
//     } else {
//       setLastDate("");
//     }
//   };

//   const calculateTotalVisits = (startDate, lastDate, Repeats) => {
//     if (
//       !startDate ||
//       !lastDate ||
//       !moment(startDate).isValid() ||
//       !moment(lastDate).isValid()
//     ) {
//       return 0;
//     }

//     const start = moment(startDate);
//     const end = moment(lastDate);

//     let totalVisits = 0;
//     switch (Repeats) {
//       case "Weekly on":
//         totalVisits = Math.floor(end.diff(start, "weeks", true)) + 1;
//         break;
//       case "Every 2 Weeks on":
//         totalVisits = Math.floor(end.diff(start, "weeks", true) / 2) + 1;
//         break;
//       case "Monthly on the date":
//         totalVisits = Math.floor(end.diff(start, "months", true)) + 1;
//         break;
//       default:
//         totalVisits = 0;
//     }

//     return totalVisits < 1 ? 1 : totalVisits;
//   };

//   const totalVisits = calculateTotalVisits(
//     formik.values?.RecuringJob?.StartDate,
//     lastDate,
//     Repeats
//   );

//   return (
//     <div className="col-md-4 col-lg-12 col-sm-12 first-tab">
//       <Card>
//         <CardHeader className="d-flex justify-content-between">
//           <p
//             style={{ fontSize: "24px", fontWeight: 600 }}
//             className="my-3 text-blue-color"
//           >
//             Recurring Schedule
//           </p>
//           <Button
//             className="bg-button-blue-color"
//             onClick={toggleCalendarVisibility}
//           >
//             {isCalendarVisible ? "Hide Calendar" : "Show Calendar"}
//           </Button>
//         </CardHeader>
//         <CardBody>
//           <FormGroup>
//             <Label htmlFor="startDate" className="mb-2">
//               Start Date
//             </Label>
//             <Input
//               id="startDate"
//               type="date"
//               name="RecuringJob.StartDate"
//               onChange={formik.handleChange}
//               value={formik.values?.RecuringJob?.StartDate}
//             />
//           </FormGroup>

//           <Label htmlFor="timeRange">Start Time & End Time</Label>
//           <div className="d-flex">
//             <Col className="col-6">
//               <Input
//                 id="startTime"
//                 type="time"
//                 name="RecuringJob.StartTime"
//                 onChange={handleStartTimeChange}
//                 value={formik?.values?.RecuringJob?.StartTime}
//               />
//             </Col>
//             <Col className="col-6">
//               <Input
//                 id="endTime"
//                 type="time"
//                 name="RecuringJob.EndTime"
//                 onChange={handleEndTimeChange}
//                 value={formik.values?.RecuringJob?.EndTime}
//               />
//             </Col>
//           </div>

//           <Label htmlFor="Repeats" className="my-2">
//             Repeats
//           </Label>
//           <Input
//             type="select"
//             name="RecuringJob.Repeats"
//             onChange={handleRepeatChange}
//             value={Repeats}
//           >
//             <option value="">As needed - we won't prompt you</option>
//             <option value={`Weekly on`}>
//               Weekly on{" "}
//               {moment(formik.values?.RecuringJob?.StartDate).format("dddd")}
//             </option>
//             <option value={`Every 2 Weeks on`}>
//               Every 2 Weeks on{" "}
//               {moment(formik.values?.RecuringJob?.StartDate).format("dddd")}
//             </option>
//             <option value={`Monthly on the date`}>
//               Monthly on the{" "}
//               {moment(formik.values?.RecuringJob?.StartDate).format("Do")} of
//               the month
//             </option>
//           </Input>

//           <Label htmlFor="duration" className="my-2">
//             Duration
//           </Label>
//           <Box display="flex" flexDirection="column">
//             <TextField
//               label=""
//               type="number"
//               value={value} // This is now correctly linked to state
//               onChange={handleValueChange} // Handle the change event properly
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <Select value={duration} onChange={handleDurationChange}>
//                       <MenuItem value="day(s)">day(s)</MenuItem>
//                       <MenuItem value="week(s)">week(s)</MenuItem>
//                       <MenuItem value="month(s)">month(s)</MenuItem>
//                       <MenuItem value="year(s)">year(s)</MenuItem>
//                     </Select>
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           </Box>

//           <div>Last visit date: {lastDate}</div>
//           <div>Total visits: {totalVisits}</div>
//         </CardBody>
//       </Card>
//     </div>
//   );
// };

const RecurringContract = ({
  formik,
  isCalendarVisible,
  setIsCalendarVisible,
}) => {
  const [Repeats, setRepeats] = useState("");
  const [duration, setDuration] = useState("month(s)");
  const [value, setValue] = useState(6); // Value for duration number input
  const [lastDate, setLastDate] = useState("");
  const [isCardVisible, setIsCardVisible] = useState(true);

  const handleStartTimeChange = (e) => {
    const StartTime = e.target.value;
    formik.setFieldValue("RecuringJob.StartTime", StartTime);

    if (
      formik.values.RecuringJob?.EndTime &&
      StartTime > formik.values.RecuringJob.EndTime
    ) {
      formik.setFieldValue("RecuringJob.EndTime", StartTime);
    }
  };
  const toggleCalendarVisibility = () => {
    setIsCalendarVisible(!isCalendarVisible);
    setIsCardVisible(!isCardVisible);
  };

  const handleEndTimeChange = (e) => {
    const EndTime = e.target.value;
    formik.setFieldValue("RecuringJob.EndTime", EndTime);

    if (
      formik.values.RecuringJob?.StartTime &&
      EndTime < formik.values.RecuringJob.StartTime
    ) {
      formik.setFieldValue("RecuringJob.StartTime", EndTime);
    }
  };

  const handleRepeatChange = (e) => {
    const selectedValue = e.target.value;
    setRepeats(selectedValue);
    formik.setFieldValue("RecuringJob.Repeats", selectedValue);
  };

  const handleDurationChange = (e) => {
    const newDuration = e.target.value;
    setDuration(newDuration);
    calculateFutureDate(
      value,
      newDuration,
      formik.values?.RecuringJob?.StartDate
    );
    formik.setFieldValue("RecuringJob.Duration", newDuration);
  };

  const handleValueChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    calculateFutureDate(
      newValue,
      duration,
      formik.values?.RecuringJob?.StartDate
    );
    formik.setFieldValue("RecuringJob.Frequency", newValue);
  };

  const calculateFutureDate = (num, dur, startDate) => {
    if (num && dur && startDate) {
      const durationNumber = parseInt(num, 10);
      let futureDate;

      switch (dur) {
        case "day(s)":
          futureDate = moment(startDate).add(durationNumber, "days");
          break;
        case "week(s)":
          futureDate = moment(startDate).add(durationNumber, "weeks");
          futureDate.subtract(1, "days");
          break;
        case "month(s)":
          futureDate = moment(startDate).add(durationNumber, "months");
          futureDate.subtract(1, "days");
          break;
        case "year(s)":
          futureDate = moment(startDate).add(durationNumber, "years");
          futureDate.subtract(1, "days");
          break;
        default:
          futureDate = moment(startDate);
      }

      setLastDate(futureDate.format("MMMM DD, YYYY"));
    } else {
      setLastDate("");
    }
  };

  const calculateTotalVisits = (startDate, lastDate, Repeats) => {
    if (
      !startDate ||
      !lastDate ||
      !moment(startDate).isValid() ||
      !moment(lastDate).isValid()
    ) {
      return 0;
    }

    const start = moment(startDate);
    const end = moment(lastDate);

    let totalVisits = 0;
    switch (Repeats) {
      case "Weekly on":
        totalVisits = Math.floor(end.diff(start, "weeks", true)) + 1;
        break;
      case "Every 2 Weeks on":
        totalVisits = Math.floor(end.diff(start, "weeks", true) / 2) + 1;
        break;
      case "Monthly on the date":
        totalVisits = Math.floor(end.diff(start, "months", true)) + 1;
        break;
      default:
        totalVisits = 0;
    }

    return totalVisits < 1 ? 1 : totalVisits;
  };
  const totalVisits = calculateTotalVisits(
    formik.values?.RecuringJob?.StartDate,
    lastDate,
    Repeats
  );

  return (
    <div className="col-md-4 col-lg-12 col-sm-12 first-tab mb-2">
      <Card className="p-0">
        <CardHeader
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h5>Recurring Schedule</h5>
          <Button
            className="bg-button-blue-color"
            style={{
              padding: "0 14px 0 14px",
              fontSize: "12px",
              marginTop: 0,
              height: "32px",
              color: "#fff",
              borderRadius: "2px",
            }}
            onClick={toggleCalendarVisibility}
          >
            {isCalendarVisible ? "Hide Calendar" : "Show Calendar"}
          </Button>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label htmlFor="startDate">Start Date</Label>
            <Input
              id="startDate"
              type="date"
              name="RecuringJob.StartDate"
              onChange={formik.handleChange}
              value={formik.values?.RecuringJob?.StartDate}
            />
          </FormGroup>

          <Label htmlFor="timeRange" className="mt-2">
            Start Time & End Time
          </Label>
          <div className="d-flex">
            <Col className="col-6">
              <Input
                id="startTime"
                type="time"
                name="RecuringJob.StartTime"
                onChange={handleStartTimeChange}
                value={formik?.values?.RecuringJob?.StartTime}
              />
            </Col>
            <Col className="col-6">
              <Input
                id="endTime"
                type="time"
                name="RecuringJob.EndTime"
                onChange={handleEndTimeChange}
                value={formik.values?.RecuringJob?.EndTime}
              />
            </Col>
          </div>

          <FormGroup>
            <Label htmlFor="Repeats" className="mt-2">
              Repeats
            </Label>
            <Input
              type="select"
              name="RecuringJob.Repeats"
              onChange={handleRepeatChange}
              value={Repeats}
            >
              <option value="">As needed - we won't prompt you</option>
              <option value={`Weekly on`}>
                Weekly on{" "}
                {moment(formik.values?.RecuringJob?.StartDate).format("dddd")}
              </option>
              <option value={`Every 2 Weeks on`}>
                Every 2 Weeks on{" "}
                {moment(formik.values?.RecuringJob?.StartDate).format("dddd")}
              </option>
              <option value={`Monthly on the date`}>
                Monthly on the{" "}
                {moment(formik.values?.RecuringJob?.StartDate).format("Do")} of
                the month
              </option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label htmlFor="duration" className="mt-2">
              Duration
            </Label>
            <Box display="flex" flexDirection="row" alignItems="center">
              <TextField
                label=""
                type="number"
                value={value}
                onChange={handleValueChange}
                style={{ width: "80px", marginRight: "10px" }}
                InputProps={{
                  inputProps: { min: 1 },
                }}
              />
              <Select
                value={duration}
                onChange={handleDurationChange}
                style={{ width: "100%", borderRadius: "10px" }}
              >
                <MenuItem value="day(s)">day(s)</MenuItem>
                <MenuItem value="week(s)">week(s)</MenuItem>
                <MenuItem value="month(s)">month(s)</MenuItem>
                <MenuItem value="year(s)">year(s)</MenuItem>
              </Select>
            </Box>
          </FormGroup>

          {/* <div className="mt-3">
            <strong>Last Visit: </strong>
            {lastDate}
          </div>

          <div className="mt-2">
            <strong>Total Visits: </strong>
            {totalVisits}
          </div> */}

          <div className="d-flex mt-2">
            <div
              className="col-4"
              style={{
                borderRight: "0.5px solid rgba(6,49,100, 0.8)",
              }}
            >
              <p
                className="mb-0"
                color="rgba(6, 49, 100, 30%)"
                style={{ fontSize: "12px" }}
              >
                First
              </p>
              <p
                className="mb-0"
                color="rgba(6, 49, 100, 30%)"
                style={{ fontSize: "12px" }}
              >
                {formik.values?.RecuringJob?.StartDate
                  ? moment(formik.values?.RecuringJob?.StartDate).format(
                      "MMMM DD, YYYY"
                    )
                  : "-"}
              </p>
            </div>
            <div
              className="col-4 mx-1"
              style={{
                borderRight: "0.5px solid rgba(6, 49, 100, 0.8)",
              }}
            >
              <p
                className="mb-0"
                color="rgba(6, 49, 100, 30%)"
                style={{ fontSize: "12px" }}
              >
                Last
              </p>
              <p
                className="mb-0"
                color="rgba(6, 49, 100, 30%)"
                style={{ fontSize: "12px" }}
              >
                {lastDate || "-"}
              </p>
            </div>
            <div className="col-4 mx-1">
              <p
                className="mb-0"
                color="rgba(6, 49, 100, 30%)"
                style={{ fontSize: "12px" }}
              >
                Total
              </p>
              <p
                className="mb-0"
                color="rgba(6, 49, 100, 30%)"
                style={{ fontSize: "12px" }}
              >
                {totalVisits || "-"}
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
const CalendarJOB = ({ isCalendarVisible, setIsCalendarVisible }) => {
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const editEvent = (event) => {
  //   const newTitle = prompt("Edit event name", event.title);
  //   if (newTitle) {
  //     setEvents(
  //       events.map((ev) => (ev === event ? { ...ev, title: newTitle } : ev))
  //     );
  //   }
  // };
  const location = useLocation();
  // const addEvent = (title, date) => {
  //   const newEvent = {
  //     id: events.length,
  //     title,
  //     start: date,
  //     end: date,
  //     allDay: true,
  //   };
  //   setEvents([...events, newEvent]);
  // };

  const CustomToolbar = ({ label, onNavigate }) => {
    const goToBack = () => {
      onNavigate("PREV");
    };

    const goToNext = () => {
      onNavigate("NEXT");
    };

    return (
      <div className="rbc-toolbar">
        <button type="button" onClick={goToBack}>
          Back
        </button>
        <span className="rbc-toolbar-label">{label}</span>

        <button type="button" onClick={goToNext}>
          Next
        </button>
      </div>
    );
  };

  const toggleCalendarVisibility = () => {
    setIsCalendarVisible(!isCalendarVisible);
    setIsCardVisible(!isCardVisible);
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const fetchSheduleData = async () => {
    try {
      const res = await AxiosInstance.get(
        `/visits/schedule/${localStorage.getItem("CompanyId")}`
      );
      const scheduleData = res.data.data;
      const mappedEvents = scheduleData.map((item) => ({
        id: item._id,
        VisitId: item.VisitId,
        ItemName: item.ItemName,
        FirstName: item.FirstName,
        LastName: item.LastName,
        start: new Date(item.StartDate),
        end: new Date(item.EndDate),
        allDay: true,
      }));
      setEvents(mappedEvents);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  useEffect(() => {
    fetchSheduleData();
  }, []);
  const calendarRef = useRef(null);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [clickedDate, setClickedDate] = useState(null);
  const navigate = useNavigate();
  const { companyName } = useParams();

  const eventPropGetter = (event) => ({
    style: {
      border: "none",
      color: "black",
      display: "block",
    },
  });

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };
  const handleSlotClick = ({ start }, e) => {
    if (e && e.clientY && e.clientX) {
      const calendarRect = calendarRef.current.getBoundingClientRect();
      const top = e.clientY - calendarRect.top + window.scrollY;
      const left = e.clientX - calendarRect.left + window.scrollX;
      setAnchorPosition({ top, left });
      setClickedDate(start);
    }
  };
  const handlePopoverClose = () => {
    setAnchorPosition(null);
  };
  const handleClose = () => {
    setSelectedEvent(null);
    setAnchorPosition(null);
  };
  const CustomEvent = ({ event }) => (
    <span>
      <strong>{event.ItemName}</strong>
      <br />
      {/* <span>{`${event.FirstName} ${event.LastName}`}</span> */}
    </span>
  );
  return (
    <div className="col-12 mx-2 my-3 mb-0 first-tab">
      <div>
        {isCalendarVisible && (
          <div
            style={{
              position: "relative",
            }}
          >
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              selectable
              views={["month"]}
              components={{
                event: CustomEvent,
              }}
              eventPropGetter={eventPropGetter}
              onSelectEvent={handleEventClick}
              onSelectSlot={handleSlotClick}
              popup={true}
            />
          </div>
        )}
      </div>
      <Popover
        open={Boolean(anchorPosition)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        onClose={handlePopoverClose}
        PaperProps={{
          style: {
            top: anchorPosition ? `${anchorPosition.top}px` : undefined,
            left: anchorPosition ? `${anchorPosition.left}px` : undefined,
            position: "absolute",
          },
        }}
      >
        <Box p={2}>
          <Typography variant="h6">No Event</Typography>
          <Typography>{`You clicked on ${moment(clickedDate).format(
            "MMMM Do YYYY"
          )}`}</Typography>
        </Box>
      </Popover>
    </div>
  );
};
const ClientMessage = ({ formik }) => {
  return (
    <div className="col-5 order-sm-1 messageinput">
      <FormGroup>
        <InputText
          value={formik.values?.CustomerMessage}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.CustomerMessage &&
            Boolean(formik.errors.CustomerMessage)
          }
          helperText={
            formik.touched.CustomerMessage && formik.errors.CustomerMessage
          }
          name="CustomerMessage"
          label="CustomerMessage"
          type="text"
          className="text-blue-color w-100 m-0 mb-3"
          fieldHeight="56px"
        />
      </FormGroup>
      <FormGroup>
        <InputText
          value={formik.values?.ContractDisclaimer}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.ContractDisclaimer &&
            Boolean(formik.errors.ContractDisclaimer)
          }
          helperText={
            formik.touched.ContractDisclaimer &&
            formik.errors.ContractDisclaimer
          }
          name="ContractDisclaimer"
          label="ContractDisclaimer"
          type="text"
          className="text-blue-color w-100 m-0 mb-3"
          fieldHeight="56px"
        />
      </FormGroup>
    </div>
  );
};

const AddQuoteDiscount = ({ lineItems, formik }) => {
  const [showDiscount, setShowDiscount] = useState(false);
  const [showTax, setShowTax] = useState(false);

  const calculateSubTotal = () => {
    const total = lineItems?.reduce(
      (sum, item) => sum + Number(item.Total || 0),
      0
    );
    return total;
  };

  const subTotal = calculateSubTotal();
  const discountAmount = formik.values.Discount
    ? (Number(formik.values.Discount) * subTotal) / 100
    : 0;
  const discountedTotal = subTotal - discountAmount;
  const taxAmount = formik.values.Tax
    ? (Number(formik.values.Tax) * discountedTotal) / 100
    : 0;
  const Total = (discountedTotal + taxAmount)?.toFixed(2);

  return (
    <div className="col-7 order-sm-2 totalinput">
      <table>
        <tbody>
          <tr>
            <td className="text-blue-color" style={{ height: "50px" }}>
              Subtotal
            </td>
            <td className="text-end text-blue-color">
              ${subTotal?.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="text-blue-color" style={{ height: "50px" }}>
              Discount
            </td>
            <td
              className={`d-flex ${
                !showDiscount
                  ? "justify-content-end"
                  : "justify-content-between"
              }`}
            >
              {showDiscount && (
                <span style={{ position: "relative", display: "inline-block" }}>
                  <InputText
                    value={formik.values?.Discount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.Discount && Boolean(formik.errors.Discount)
                    }
                    helperText={
                      formik.touched.Discount && formik.errors.Discount
                    }
                    name="Discount"
                    label="Discount"
                    type="text"
                    className="text-blue-color w-100 m-0 mb-3"
                    fieldHeight="56px"
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                    }}
                    className="text-blue-color"
                  >
                    %
                  </span>
                </span>
              )}
              <u
                className="text-blue-color"
                style={{
                  fontWeight: 600,
                  cursor: "pointer",
                  textAlign: "end",
                  marginBottom: "9px",
                }}
                onClick={() => setShowDiscount(!showDiscount)}
              >
                {discountAmount > 0
                  ? `$${discountAmount?.toFixed(2)}`
                  : "Add Discount"}
              </u>
            </td>
          </tr>
          <tr>
            <td className="text-blue-color" style={{ height: "50px" }}>
              Tax
            </td>
            <td
              className={`d-flex ${
                !showTax ? "justify-content-end" : "justify-content-between"
              }`}
            >
              {showTax && (
                <span style={{ position: "relative", display: "inline-block" }}>
                  <InputText
                    value={formik.values?.Tax}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.Tax && Boolean(formik.errors.Tax)}
                    helperText={formik.touched.Tax && formik.errors.Tax}
                    name="Tax"
                    label="Tax"
                    type="text"
                    className="text-blue-color w-100 m-0 mb-3"
                    fieldHeight="56px"
                  />
                  <span
                    className="text-blue-color"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                    }}
                  >
                    %
                  </span>
                </span>
              )}
              <u
                className="text-blue-color"
                style={{
                  fontWeight: 600,
                  cursor: "pointer",
                  marginBottom: "9px",
                }}
                onClick={() => setShowTax(!showTax)}
              >
                {taxAmount > 0 ? `$${taxAmount?.toFixed(2)}` : "Add Tax"}
              </u>
            </td>
          </tr>
          <tr className="last-row border-0">
            <td className="border-0 text-blue-color">
              <b>Total</b>
            </td>
            <td className="text-end border-0 text-blue-color">
              <b>${Total}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Team = ({ setIds = (assignPersonId) => {}, ids, isAddTeam = true }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedState, setCheckedState] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tokenDecode, setTokenDecode] = useState({});
  const [assignPersonId, setAssignPersonId] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const fetchDatas = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchDatas();
  }, []);
  const fetchData = async () => {
    try {
      const response = await AxiosInstance.get(
        `/contract/assign_team/${
          localStorage.getItem("CompanyId") || tokenDecode?.companyId
        }`
      );
      if (response.status === 200) {
        setTeamData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching team data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [tokenDecode]);

  const formik = useFormik({
    initialValues: {
      FullName: "",
      Email: "",
      MobileNumber: "",
    },
    validationSchema: Yup.object({
      FullName: Yup.string().required("Full Name is required"),
      Email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      MobileNumber: Yup.string().required("Mobile Number is required"),
    }),
    onSubmit: async (values) => {
      try {
        const companyId =
          localStorage.getItem("CompanyId") || tokenDecode?.companyId;

        if (!companyId) {
          console.error("CompanyId is not found in localStorage");
          return;
        }

        const response = await AxiosInstance.post(`/contract/assign_team`, {
          AssignPersonId: values.AssignPersonId,
          FullName: values.FullName,
          Email: values.Email,
          PhoneNumber: values.MobileNumber,
          CompanyId: companyId,
        });

        if (response.data.statusCode === 201) {
          toggleModal();
          fetchData();
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
    },
  });

  const handleRemoveTeam = (teamName) => {
    setSelectedTeams(selectedTeams.filter((name) => name !== teamName));
    setCheckedState((prevState) => ({
      ...prevState,
      [teamName]: false,
    }));
  };

  const handleTeamSelect = (event, team) => {
    if (event.target.checked) {
      setSelectedTeams([...selectedTeams, team?.FullName]);
      setCheckedState((prevState) => ({
        ...prevState,
        [team?.FullName]: true,
      }));
      setIds(() => [...ids, team.AssignPersonId]);
      setAssignPersonId(team.AssignPersonId);
    } else {
      setSelectedTeams(selectedTeams.filter((name) => name !== team?.FullName));
      setCheckedState((prevState) => ({
        ...prevState,
        [team?.FullName]: false,
      }));
      setIds(() => ids.filter((name) => name !== team?.AssignPersonId));
      setAssignPersonId(null);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleModal = () => {
    if (isAddTeam) {
      setIsModalOpen(!isModalOpen);
    }
  };

  const handleOutsideClick = () => {
    toggleDropdown();
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="jobs">
      <div className="team-card" style={{ width: "100%" }}>
        <Card className="" style={{ height: "140px" }}>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "none",
              background: "none",
            }}
            className="team-header"
          >
            <h3 className="text-blue-color" style={{ fontWeight: 600 }}>
              Team
            </h3>
            <div className="assignbtn">
              <Button
                className="bg-blue-color"
                outline
                style={{
                  padding: "0 14px 0 14px",
                  fontSize: "12px",
                  marginTop: 0,
                  height: "32px",
                  color: "#fff",
                }}
                onClick={toggleDropdown}
              >
                + Assign
              </Button>
              {isDropdownOpen && (
                <div
                  className="assigndrop"
                  style={{
                    position: "absolute",
                    backgroundColor: "#fff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    marginTop: "10px",
                    padding: "10px",
                    zIndex: 1000,
                    width: "250px",
                    right: 0,
                  }}
                >
                  <Card>
                    <CardHeader
                      className="text-white-color bg-blue-color"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Select team
                      <CloseIcon
                        onClick={toggleDropdown}
                        style={{ cursor: "pointer" }}
                      />
                    </CardHeader>
                    <CardBody>
                      <div onClick={handleOutsideClick}>
                        {teamData && teamData.length > 0 ? (
                          teamData.map((person) => (
                            <FormGroup
                              check
                              className="my-3 mb-0"
                              key={person._id}
                            >
                              <Input
                                type="checkbox"
                                checked={
                                  checkedState &&
                                  !!checkedState[person?.FullName]
                                }
                                onChange={(e) => handleTeamSelect(e, person)}
                              />
                              <Label
                                style={{
                                  fontSize: "16px",
                                  color: "rgba(6,49,100,0.7)",
                                  fontWeight: "400",
                                  marginBottom: 0,
                                }}
                              >
                                {person.FullName}
                              </Label>
                            </FormGroup>
                          ))
                        ) : (
                          <p>No team members found.</p>
                        )}
                      </div>
                      {isAddTeam && (
                        <React.Fragment>
                          <hr />
                          <Button
                            className="text-blue-color text-white-color"
                            style={{
                              background: "#063164",
                              color: "#fff",
                              border: "none",
                              fontWeight: "400",
                            }}
                            onClick={toggleModal}
                          >
                            + Create User
                          </Button>
                        </React.Fragment>
                      )}
                    </CardBody>
                  </Card>
                  <Modal isOpen={isModalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Add new user</ModalHeader>
                    <ModalBody
                      className="nozindex"
                      style={{ borderBottom: "none" }}
                    >
                      <FormGroup>
                        <Input
                          name="FullName"
                          placeholder="Full Name"
                          type="text"
                          className="text-blue-color w-100 mb-3"
                          value={formik.values.FullName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.FullName &&
                            Boolean(formik.errors.FullName)
                          }
                        />
                        {formik.touched.FullName && formik.errors.FullName ? (
                          <div className="text-danger">
                            {formik.errors.FullName}
                          </div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          name="Email"
                          placeholder="Email"
                          type="text"
                          className="text-blue-color w-100 mb-3"
                          value={formik.values.Email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.Email && Boolean(formik.errors.Email)
                          }
                        />
                        {formik.touched.Email && formik.errors.Email ? (
                          <div className="text-danger">
                            {formik.errors.Email}
                          </div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          name="MobileNumber"
                          placeholder="Mobile Number"
                          type="text"
                          className="text-blue-color w-100 mb-3"
                          value={formik.values.MobileNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.MobileNumber &&
                            Boolean(formik.errors.MobileNumber)
                          }
                        />
                        {formik.touched.MobileNumber &&
                        formik.errors.MobileNumber ? (
                          <div className="text-danger">
                            {formik.errors.MobileNumber}
                          </div>
                        ) : null}
                      </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="secondary"
                        onClick={toggleModal}
                        className="text-blue-color"
                        style={{
                          background: "none",
                          color: "#063164",
                          borderColor: "#063164",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        onClick={formik.handleSubmit}
                        className="bg-blue-color text-white-color"
                        style={{ borderColor: "#063164" }}
                      >
                        Save User
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              )}
            </div>
          </CardHeader>
          <CardBody>
            <div style={{ marginTop: "-10px", height: "18px" }}>
              {selectedTeams.map((team, index) => (
                <div
                  key={index}
                  className="tag"
                  style={{ marginTop: "0px", marginLeft: "10px" }}
                >
                  <span className="tag-text" style={{ fontSize: "16px" }}>
                    {team}
                  </span>
                  <button
                    className="tag-close"
                    onClick={() => handleRemoveTeam(team)}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>

            <FormGroup check className="my-3 mb-0">
              {/* <Input type="checkbox" />{" "}
              <Label
                check
                style={{
                  fontSize: "12px",
                  color: "rgba(6,49,100,0.7)",
                  fontWeight: "400",
                  marginTop: "10px",
                }}
              >
                Email team about assignment
              </Label> */}
              {/* <div className="checkbox-group my-5">
                <Input type="checkbox" id="any-time" name="any-time" />
                <label for="any-time">Email team about assignment</label>
              </div> */}
            </FormGroup>
            {/* {showEmailField && (
              <FormGroup>
                <InputText
                  name="Email"
                  label="Email"
                  type="text"
                  className="text-blue-color w-100 mb-3"
                  fieldHeight="56px"
                  onChange={handleChange}
                  value={formData.Email}
                />
                <Label htmlFor="email" className="mb-3">
                  An email is required to log in to Jobber
                </Label>
              </FormGroup>
            )} */}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export {
  InternalNotes,
  OneOffContract,
  RecurringContract,
  CalendarJOB,
  ClientMessage,
  AddQuoteDiscount,
  Team,
};
