import { Button, FormGroup, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import googlee from "../../assets/image/icons/googlee.svg";
import "./style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../Views/AxiosInstance";
import { Link, useNavigate } from "react-router-dom";
import backimg from "../../assets/image/icons/back.png";
import facebooke from "../../assets/image/icons/facebook.png";
import applee from "../../assets/image/icons/apple.png";
import locke from "../../assets/image/icons/Rectangle 20.png";
import InputText from "../InputFields/InputText";
import logo from "../../assets/image/CMS_LOGO.svg";

const ForgotPassword = () => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    try {
      await fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then(async (data) => {
          const res = await AxiosInstance.post(`${baseUrl}/admin/login`, {
            ...values,
            ip: data.network,
          });
          if (res.data.statusCode === 300) {
            navigate("/superadmin/index");
          } else if (res.data.statusCode === 301) {
            localStorage.setItem("adminToken", res.data.token);
            navigate(`/${res.data.data.companyName}/index`);
          } else {
            alert(res.data.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching IP address:", error);
        });
    } catch (error) {
      console.error("Error: ", error.message);
    }
  };

  return (
    <>
      <div className="login">
        <Grid container spacing={2} className="vh-100">
          <Grid
            item
            xs={12}
            md={6}
            className="d-flex flex-direction-column align-items-center pt-3 h-100"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Link to="/auth/login">
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    marginTop: "20px",
                    width: "15%",
                    top: 3,
                    position: "absolute",
                    left: 70,
                  }}
                ></img>
              </Link>
            </div>
            <div
              style={{
                width: "80%",
                height: "100%",
                marginTop: "90px",
              }}
              className="d-flex flex-direction-column align-items-center px-3 maon-forget-password"
            >
              <form
                onSubmit={formik.handleSubmit}
                className="d-flex flex-column justify-content-between"
                style={{ height: "60%" }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "21px",
                    textAlign: "left",
                    color: "black",
                    marginTop: "13px",
                    display: "flex",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <img src={backimg} width={20} height={20} />
                  <span className="mx-2">Back to login </span>
                </Typography>

                <div>
                  <Typography
                    className="text"
                    style={{ fontSize: "35px", margintop: "20px" }}
                  >
                    Forgot your password?
                  </Typography>
                  <Typography
                    className="text2"
                    style={{ color: "#606060", margintop: "20px" }}
                  >
                    Don't worry, happens to all of us. Enter your email below to
                    recover your password
                  </Typography>
                  <FormGroup style={{ width: "100%", marginTop: "20px" }}>
                    <InputText
                      value={formik.values?.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      name="email"
                      label="email"
                      type="text"
                      className="text-blue-color w-100"
                      fieldHeight="56px"
                    />
                  </FormGroup>
                  <FormGroup style={{ width: "100%", marginTop: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "rgba(51, 53, 71, 1)",
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "21px",
                        borderRadius: "8px",
                        gap: "4px",
                        height: "48px",
                        textTransform: "none",
                        margintop: "20px",
                      }}
                      type="submit"
                      className="bg-blue-color"
                    >
                      Submit
                    </Button>
                  </FormGroup>
                </div>
              </form>
            </div>
          </Grid>
          <div
            item
            // xs={12}
            // md={6}
            className="d-flex col-md-6 col-xs-12 justify-content-center align-items-center img h-100"
          >
            <img
              src={locke}
              alt="logo"
              style={{ height: "90%", marginTop: "20px" }}
            ></img>
          </div>
        </Grid>
      </div>
    </>
  );
};

export default ForgotPassword;
