// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1ex1afd-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
}
.css-yz9k0d-MuiInputBase-input {
  color: white !important;
}

/* 425 */
@media (max-width: 479px) {
  
}

/* 375 */
@media (max-width: 424px) {
  .plansearch {
    margin-left: 20px !important;
  }
}
/* 320 */
@media (max-width: 374px) {
  
    .plansearch {
      margin-left: 20px !important;
    }
}
/* 280 */
@media (max-width: 319px) {
  .plan {
    font-size: 19px;
    margin-top: 9px;
  }
  .plansearch {
    margin-left: 20px !important;
  }
}
.superadmin-company .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  margin-top: 8px !important;
}`, "",{"version":3,"sources":["webpack://./src/Views/Superadmin/Plan/style.css"],"names":[],"mappings":"AAAA;EACE,uDAAuD;EACvD,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;EACnB,uBAAuB;EACvB,+CAA+C;EAC/C,gBAAgB;EAChB,aAAa;EACb,0BAA0B;AAC5B;AACA;EACE,uBAAuB;AACzB;;AAEA,QAAQ;AACR;;AAEA;;AAEA,QAAQ;AACR;EACE;IACE,4BAA4B;EAC9B;AACF;AACA,QAAQ;AACR;;IAEI;MACE,4BAA4B;IAC9B;AACJ;AACA,QAAQ;AACR;EACE;IACE,eAAe;IACf,eAAe;EACjB;EACA;IACE,4BAA4B;EAC9B;AACF;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".css-1ex1afd-MuiTableCell-root {\n  font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n  font-weight: 400;\n  font-size: 0.875rem;\n  line-height: 1.43;\n  letter-spacing: 0.01071em;\n  display: table-cell;\n  vertical-align: inherit;\n  border-bottom: 1px solid rgba(224, 224, 224, 1);\n  text-align: left;\n  padding: 16px;\n  color: rgba(0, 0, 0, 0.87);\n}\n.css-yz9k0d-MuiInputBase-input {\n  color: white !important;\n}\n\n/* 425 */\n@media (max-width: 479px) {\n  \n}\n\n/* 375 */\n@media (max-width: 424px) {\n  .plansearch {\n    margin-left: 20px !important;\n  }\n}\n/* 320 */\n@media (max-width: 374px) {\n  \n    .plansearch {\n      margin-left: 20px !important;\n    }\n}\n/* 280 */\n@media (max-width: 319px) {\n  .plan {\n    font-size: 19px;\n    margin-top: 9px;\n  }\n  .plansearch {\n    margin-left: 20px !important;\n  }\n}\n.superadmin-company .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {\n  margin-top: 8px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
