import {
  Button,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./style.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import axios from "axios";
import AxiosInstance from "../../Views/AxiosInstance";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import backimg from "../../assets/image/icons/back.png";
import locke from "../../assets/image/icons/Rectangle 20.png";
import * as Yup from "yup";
import InputText from "../InputFields/InputText";

function generateDeviceBoundID() {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const screenResolution = `${window.screen.width}x${window.screen.height}`;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const language = navigator.language || navigator.userLanguage;
  const plugins = getPluginsList();
  const doNotTrack = getDoNotTrack();
  const hardwareConcurrency = getHardwareConcurrency();

  const deviceInfo = `${userAgent}-${platform}-${screenResolution}-${timezone}-${language}-${plugins}-${doNotTrack}-${hardwareConcurrency}`;
  return sha256(deviceInfo);
}

function getPluginsList() {
  let plugins = "";
  if (navigator.plugins.length > 0) {
    for (let i = 0; i < navigator.plugins.length; i++) {
      plugins += `${navigator.plugins[i]?.name}${navigator.plugins[i].description}`;
    }
  }
  return plugins;
}

function getDoNotTrack() {
  const doNotTrack =
    navigator.doNotTrack || window.doNotTrack || navigator.msDoNotTrack;
  return doNotTrack === "1" || doNotTrack === "yes";
}

function getHardwareConcurrency() {
  return navigator.hardwareConcurrency || "unknown";
}

function sha256(str) {
  return crypto.subtle
    .digest("SHA-256", new TextEncoder().encode(str))
    .then((buf) => {
      return Array.from(new Uint8Array(buf))
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
    });
}

const NewPassword = () => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useParams;
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");

  const formik = useFormik({
    initialValues: {
      Password: "",
      confirmpassword: "",
    },
    validationSchema: Yup.object().shape({
      Password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");

      if (!token) {
        console.error("Token not found in query parameters");
        return;
      }

      try {
        const res = await AxiosInstance.get(
          `${baseUrl}/company/newpassword_token?token=${token}`
        );
        setData(res.data.data);
        if (res.data.statusCode === 401) {
          navigate("/auth/login");
        }
        if (res.data.statusCode === 500) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchData();
  }, [navigate]);
  const handleSubmit = async (values) => {
    try {
      const res = await AxiosInstance.put(
        `/customer/set_password/12072024041801`,
        {
          Password: values.Password,
        }
      );
      if (res.data.statusCode === 200) {
        localStorage.setItem("clientToken", res.data.token);
        localStorage.setItem("CustomerId", res.data.CustomerId);
        localStorage.setItem("id", generateDeviceBoundID());
        navigate(`/client/index`);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error("Error: ", error.message);
    }
  };

  return (
    <div className="loginn">
      <Grid container spacing={2} className="vh-100">
        <Grid
          item
          xs={12}
          md={6}
          className="d-flex flex-direction-column align-items-center pt-3"
        >
          <div
            style={{
              width: "80%",
              height: "90%",
              paddingTop: "40px",
              marginLeft: "20px",
            }}
            className="d-flex flex-direction-column align-items-center px-3"
          >
            <form
              style={{ width: "85%", height: "90%", marginTop: "70px" }}
              onSubmit={formik.handleSubmit}
            >
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "21px",
                  textAlign: "left",
                  color: "black",
                  marginTop: "13px",
                  display: "flex",
                }}
              >
                <img src={backimg} width={20} height={20} />
                <span className="mx-2">Back to login </span>
              </Typography>
              <Typography
                className="text"
                style={{ fontSize: "35px", margintop: "20px" }}
              >
                Add your password
              </Typography>
              <Typography
                className="text2"
                style={{ color: "#606060", margintop: "20px" }}
              >
                Please enter your new password below.
              </Typography>
              <FormGroup style={{ width: "100%", marginTop: "24px" }}>
                <InputText
                  value={formik.values?.Password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.Password && Boolean(formik.errors.Password)
                  }
                  helperText={formik.touched.Password && formik.errors.Password}
                  name="Password"
                  label="New Password"
                  type={showPassword ? "text" : "password"}
                  className="text-blue-color w-100 m-0 mb-3"
                  fieldHeight="56px"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormGroup>
              <FormGroup style={{ width: "100%", marginTop: "24px" }}>
                <InputText
                  value={formik.values?.confirmpassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmpassword &&
                    Boolean(formik.errors.confirmpassword)
                  }
                  helperText={
                    formik.touched.confirmpassword &&
                    formik.errors.confirmpassword
                  }
                  name="confirmpassword"
                  label="Re-type New Password"
                  type={showCPassword ? "text" : "password"}
                  className="text-blue-color w-100 m-0 mb-3"
                  fieldHeight="56px"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowCPassword(!showCPassword)}
                        edge="end"
                      >
                        {showCPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormGroup>
              <FormGroup style={{ width: "100%", marginTop: "15px" }}>
                <Button
                  style={{
                    backgroundColor: "rgba(51, 53, 71, 1)",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "21px",
                    borderRadius: "4px",
                    gap: "4px",
                    height: "48px",
                    textTransform: "none",
                    background: "rgb(39 84 110)",
                    marginTop: "20px",
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              </FormGroup>
            </form>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center img"
        >
          <img
            src={locke}
            alt="logo"
            style={{ width: "70%", height: "90%", marginTop: "20px" }}
          ></img>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewPassword;
