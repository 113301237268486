import { FormGroup, Table, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import AxiosInstance from "../../Views/AxiosInstance";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabContent,
  TabPane,
} from "reactstrap";
import InputText from "../InputFields/InputText";
import { DisplayImage } from "../Files/DisplayFiles";
import { Files } from "../Files";

const InternalNotes = ({ notes, setNotes, attachments, setAttachments }) => {
  const handleNoteChange = (event) => {
    setNotes(event.target.value);
  };
  return (
    <>
      <Card
        style={{
          // border: noBorder ? "none" : "1px solid black",
          padding: "15px",
          borderRadius: "10px",
          borderColor: "rgba(6, 49, 100, 30%)",
        }}
      >
        <p className="text-blue-color mx-3" style={{ fontWeight: 600 }}>
          Internal notes and attachments <HelpOutlineOutlinedIcon />
        </p>
        <div
          style={{
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <TextField
            className="note-details text-blue-color"
            id="note"
            name="note"
            label="Note details"
            placeholder="Enter notes here..."
            type="text"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#063164",
                },
                "&:hover fieldset": {
                  borderColor: "#063164",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#063164",
                },
                "& input": {
                  height: "70px",
                  alignItems: "start",
                },
              },
            }}
            InputProps={{
              readOnly: false,
            }}
            style={{ width: "100%", paddingTop: "0px" }}
            InputLabelProps={{
              style: { fontSize: "15px" },
            }}
          />
          <Files
            files={attachments}
            setFiles={(value) => setAttachments(value)}
          />
          <DisplayImage
            files={attachments}
            setFiles={(value) => setAttachments(value)}
            IsDeleted={true}
          />
          <hr />
          <div>
            <p style={{ fontSize: "14px" }} className="text-blue-color">
              Link note to related
            </p>
            <FormGroup check>
              <div className="d-flex gap-2 align-items-center">
                <Input
                  type="checkbox"
                  style={{ color: "#063164", marginTop: 0 }}
                />{" "}
                <Label
                  className="text-blue-color"
                  style={{ fontSize: "15px", marginBottom: 0 }}
                  check
                >
                  Invoices
                </Label>
              </div>
            </FormGroup>
          </div>
        </div>
      </Card>
    </>
  );
};

// const OneOffContract = ({
//   formik,
//   isCalendarVisible,
//   setIsCalendarVisible,
// }) => {
//   const toggleCalendarVisibility = () => {
//     setIsCalendarVisible(!isCalendarVisible);
//   };

// const handleStartDateChange = (e) => {
//   const startDate = e.target.value;
//   formik.setFieldValue("OneoffJob.StartDate", startDate);

//   if (formik.values.OneoffJob.EndDate && startDate > formik.values.OneoffJob.EndDate) {
//     formik.setFieldValue("OneoffJob.EndDate", startDate);
//   }
// };

// const handleEndDateChange = (e) => {
//   const endDate = e.target.value;
//   if (endDate >= formik.values.OneoffJob.StartDate) {
//     formik.setFieldValue("OneoffJob.EndDate", endDate);
//   }
// };

// const handleStartTimeChange = (e) => {
//   const startTime = e.target.value;
//   formik.setFieldValue("OneoffJob.StartTime", startTime);

//   if (formik.values.OneoffJob.EndTime && startTime > formik.values.OneoffJob.EndTime) {
//     formik.setFieldValue("OneoffJob.EndTime", startTime);
//   }
// };

// const handleEndTimeChange = (e) => {
//   const endTime = e.target.value;
//   if (endTime >= formik.values.OneoffJob.StartTime) {
//     formik.setFieldValue("OneoffJob.EndTime", endTime);
//   }
// };

//   return (
//     <div className="col-md-4 col-lg-12 col-sm-12 first-tab jobs">
//       <TabContent
//         activeTab={1}
//         className="text-start my-3 mx-0"
//         style={{ padding: "2px" }}
//       >
//         <TabPane tabId={1}>
//           <Col sm="12" className="d-flex">
//             <Card className="mx-0" style={{ width: "100%" }}>
//               <CardHeader
//                 className="d-flex justify-content-between"
//                 style={{
//                   width: "100%",
//                   alignItems: "center",
//                   padding: "0 10px 0 10px",
//                   borderBottom: "none",
//                 }}
//               >
//                 <p
//                   style={{
//                     fontSize: "24px",
//                     fontWeight: 600,
//                   }}
//                   className="my-3 text-blue-color"
//                 >
//                   One off Contract Schedule
//                 </p>
//                 <Button
//                   className="bg-button-blue-color hide-calendar"
//                   style={{
//                     padding: "0 14px 0 14px",
//                     fontSize: "12px",
//                     marginTop: 0,
//                     height: "32px",
//                     color: "#fff",
//                     borderRadius: "2px",
//                   }}
//                   onClick={toggleCalendarVisibility}
//                 >
//                   {isCalendarVisible ? "Hide Calendar" : "Show Calendar"}
//                 </Button>
//               </CardHeader>
//               <CardBody>
//                 <div className="d-flex row start-end-date">
//                   <FormGroup className="col-6 start-date">
//                     <Label
//                       htmlFor="startDate"
//                       style={{
//                         color: "#000000",
//                         fontWeight: 600,
//                         fontSize: "12px",
//                       }}
//                     >
//                       Start Date
//                     </Label>
//                     <Input
//                       id="startDate"
//                       placeholder="with a placeholder"
//                       type="date"
//                       name="OneoffJob.StartDate"
//                       onChange={formik.handleChange}
//                       value={formik?.values?.OneoffJob?.StartDate}
//                       style={{ fontSize: "12px", height: "40px" }}
//                     />

//                   </FormGroup>
//                   <FormGroup className="col-6 end-date">
//                     <Label
//                       htmlFor="endDate"
//                       style={{
//                         color: "#000000",
//                         fontWeight: 600,
//                         fontSize: "12px",
//                       }}
//                     >
//                       End Date (optional)
//                     </Label>
//                     <Input
//                       id="endDate"
//                       placeholder="with a placeholder"
//                       type="date"
//                       name="OneoffJob.EndDate"
//                       onChange={formik.handleChange}
//                       value={formik?.values?.OneoffJob?.EndDate}
//                       style={{ fontSize: "12px", height: "40px" }}
//                     />
//                   </FormGroup>
//                 </div>

//                 <Label
//                   htmlFor="startTime"
//                   style={{
//                     color: "#000000",
//                     fontWeight: 600,
//                     fontSize: "12px",
//                   }}
//                 >
//                   Start Time & End Time
//                 </Label>

//                 <div className="d-flex time-section" style={{ height: "" }}>
//                   <Col
//                     className="col-6"
//                     style={{
//                       borderBottomLeftRadius: "10px",
//                       border: "1px solid rgba(6, 49, 100, 30%)",
//                       borderTopLeftRadius: "10px",
//                     }}
//                   >
//                     <Input
//                       id="startTime"
//                       placeholder="Start Time"
//                       type="time"
//                       name="OneoffJob.StartTime"
//                       onChange={formik.handleChange}
//                       value={formik?.values?.OneoffJob?.StartTime}
//                       style={{
//                         fontSize: "14px",
//                         border: "none",
//                         borderBottomLeftRadius: "10px",
//                         borderTopLeftRadius: "10px",
//                         fontSize: "12px",
//                         height: "40px",
//                       }}
//                     />
//                   </Col>
//                   <Col
//                     className="col-6"
//                     style={{
//                       borderBottomRightRadius: "10px",
//                       borderTopRightRadius: "10px",
//                       border: "1px solid rgba(6, 49, 100, 30%)",
//                       borderLeft: "none",
//                     }}
//                   >
//                     <Input
//                       id="endTime"
//                       type="time"
//                       name="OneoffJob.EndTime"
//                       onChange={formik.handleChange}
//                       value={formik.values?.OneoffJob?.EndTime}
//                       placeholder="End Time"
//                       style={{
//                         fontSize: "14px",
//                         border: "none",
//                         borderBottomRightRadius: "10px",
//                         borderLeft: "none",
//                         fontSize: "12px",
//                         borderTopRightRadius: "10px",
//                         height: "40px",
//                       }}
//                     />
//                   </Col>
//                 </div>

//                 <Label
//                   className="my-2"
//                   style={{
//                     color: "#000000",
//                     fontWeight: 600,
//                     fontSize: "12px",
//                   }}
//                 >
//                   Repeats
//                 </Label>
//                 <Input
//                   type="select"
//                   name="OneoffJob.Repeats"
//                   className="text-blue-color"
//                   onChange={formik.handleChange}
//                   value={formik.values?.OneoffJob?.Repeats}
//                   style={{
//                     fontSize: "14px",
//                     border: "1px solid rgba(6, 49, 100, 30%)",
//                     borderRadius: "10px",
//                     fontSize: "12px",
//                     height: "40px",
//                   }}
//                 >
//                   <option value="As needed - we won't prompt you">
//                     As needed - we won't prompt you
//                   </option>
//                   <option value="Weekly on">Weekly on</option>
//                   <option value="Every 2 Weeks on">Every 2 Weeks on</option>
//                   <option value="Monthly on the  of the month">
//                     Monthly on the of the month
//                   </option>
//                   <option value="Custom schedule...">Custom schedule...</option>
//                 </Input>

//                 <div>
//                   <FormGroup className="my-3 mb-0 d-flex">
//                     <Input
//                       type="checkbox"
//                       name="OneoffJob.ScheduleLetter"
//                       onChange={formik.handleChange}
//                       checked={formik.values?.OneoffJob?.ScheduleLetter}
//                     />{" "}
//                     <Label
//                       style={{
//                         fontSize: "12px",
//                         color: "rgba(6,49,100,0.7)",
//                         fontWeight: "400",
//                         marginTop: "5px",
//                       }}
//                     >
//                       Schedule letter
//                     </Label>
//                   </FormGroup>
//                 </div>
//               </CardBody>
//             </Card>
//           </Col>
//         </TabPane>
//       </TabContent>
//     </div>
//   );
// };

const OneOffContract = ({
  formik,
  isCalendarVisible,
  setIsCalendarVisible,
}) => {
  const toggleCalendarVisibility = () => {
    setIsCalendarVisible(!isCalendarVisible);
  };

  const handleStartDateChange = (e) => {
    const startDate = e.target.value;
    formik.setFieldValue("OneoffJob.StartDate", startDate);

    if (
      formik.values.OneoffJob?.EndDate &&
      startDate > formik.values.OneoffJob.EndDate
    ) {
      formik.setFieldValue("OneoffJob.EndDate", startDate);
    }
  };

  const handleEndDateChange = (e) => {
    const endDate = e.target.value;
    if (
      formik.values.OneoffJob?.StartDate &&
      endDate >= formik.values.OneoffJob.StartDate
    ) {
      formik.setFieldValue("OneoffJob.EndDate", endDate);
    } else if (
      formik.values.OneoffJob?.StartDate &&
      endDate < formik.values.OneoffJob.StartDate
    ) {
      formik.setFieldValue(
        "OneoffJob.EndDate",
        formik.values.OneoffJob.StartDate
      );
    }
  };

  const handleStartTimeChange = (e) => {
    const startTime = e.target.value;
    formik.setFieldValue("OneoffJob.StartTime", startTime);

    if (
      formik.values.OneoffJob?.EndTime &&
      startTime > formik.values.OneoffJob.EndTime
    ) {
      formik.setFieldValue("OneoffJob.EndTime", startTime);
    }
  };

  const handleEndTimeChange = (e) => {
    const endTime = e.target.value;
    if (
      formik.values.OneoffJob?.StartTime &&
      endTime >= formik.values.OneoffJob.StartTime
    ) {
      formik.setFieldValue("OneoffJob.EndTime", endTime);
    } else if (
      formik.values.OneoffJob?.StartTime &&
      endTime < formik.values.OneoffJob.StartTime
    ) {
      formik.setFieldValue(
        "OneoffJob.EndTime",
        formik.values.OneoffJob.StartTime
      );
    }
  };

  return (
    <div className="col-md-4 col-lg-12 col-sm-12 first-tab jobs">
      <TabContent
        activeTab={1}
        className="text-start my-3 mx-0"
        style={{ padding: "2px" }}
      >
        <TabPane tabId={1}>
          <Col sm="12" className="d-flex">
            <Card className="mx-0" style={{ width: "100%" }}>
              <CardHeader
                className="d-flex justify-content-between"
                style={{
                  width: "100%",
                  alignItems: "center",
                  padding: "0 10px 0 10px",
                  borderBottom: "none",
                }}
              >
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                  className="my-3 text-blue-color"
                >
                  One off Contract Schedule
                </p>
                <Button
                  className="bg-button-blue-color hide-calendar"
                  style={{
                    padding: "0 14px 0 14px",
                    fontSize: "12px",
                    marginTop: 0,
                    height: "32px",
                    color: "#fff",
                    borderRadius: "2px",
                  }}
                  onClick={toggleCalendarVisibility}
                >
                  {isCalendarVisible ? "Hide Calendar" : "Show Calendar"}
                </Button>
              </CardHeader>
              <CardBody>
                <div className="d-flex row start-end-date mb-2">
                  <FormGroup className="col-6 start-date">
                    <Label
                      htmlFor="startDate"
                      style={{
                        color: "#000000",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Start Date
                    </Label>
                    <Input
                      id="startDate"
                      placeholder="with a placeholder"
                      type="date"
                      name="OneoffJob.StartDate"
                      onChange={handleStartDateChange}
                      value={formik?.values?.OneoffJob?.StartDate}
                      style={{ fontSize: "12px", height: "40px" }}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 end-date">
                    <Label
                      htmlFor="endDate"
                      style={{
                        color: "#000000",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      End Date (optional)
                    </Label>
                    <Input
                      id="endDate"
                      placeholder="with a placeholder"
                      type="date"
                      name="OneoffJob.EndDate"
                      onChange={handleEndDateChange}
                      value={formik?.values?.OneoffJob?.EndDate}
                      style={{ fontSize: "12px", height: "40px" }}
                    />
                  </FormGroup>
                </div>

                <Label
                  htmlFor="startTime"
                  style={{
                    color: "#000000",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                  className="my-2"
                >
                  Start Time & End Time
                </Label>

                <div className="d-flex time-section" style={{ height: "" }}>
                  <Col
                    className="col-6"
                    style={{
                      borderBottomLeftRadius: "10px",
                      border: "1px solid rgba(6, 49, 100, 30%)",
                      borderTopLeftRadius: "10px",
                    }}
                  >
                    <Input
                      id="startTime"
                      placeholder="Start Time"
                      type="time"
                      name="OneoffJob.StartTime"
                      onChange={handleStartTimeChange}
                      value={formik?.values?.OneoffJob?.StartTime}
                      style={{
                        fontSize: "14px",
                        border: "none",
                        borderBottomLeftRadius: "10px",
                        borderTopLeftRadius: "10px",
                        height: "40px",
                      }}
                    />
                  </Col>
                  <Col
                    className="col-6"
                    style={{
                      borderBottomRightRadius: "10px",
                      borderTopRightRadius: "10px",
                      border: "1px solid rgba(6, 49, 100, 30%)",
                      borderLeft: "none",
                    }}
                  >
                    <Input
                      id="endTime"
                      type="time"
                      name="OneoffJob.EndTime"
                      onChange={handleEndTimeChange}
                      value={formik.values?.OneoffJob?.EndTime}
                      placeholder="End Time"
                      style={{
                        fontSize: "14px",
                        border: "none",
                        borderBottomRightRadius: "10px",
                        borderTopRightRadius: "10px",
                        height: "40px",
                      }}
                    />
                  </Col>
                </div>

                <Label
                  className="my-2"
                  style={{
                    color: "#000000",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  Repeats
                </Label>
                <Input
                  type="select"
                  name="OneoffJob.Repeats"
                  className="text-blue-color"
                  onChange={formik.handleChange}
                  value={formik.values?.OneoffJob?.Repeats}
                  style={{
                    fontSize: "14px",
                    border: "1px solid rgba(6, 49, 100, 30%)",
                    borderRadius: "10px",
                    fontSize: "12px",
                    height: "40px",
                  }}
                >
                  <option value="As needed - we won't prompt you">
                    As needed - we won't prompt you
                  </option>
                  <option value="Weekly on">Weekly on</option>
                  <option value="Every 2 Weeks on">Every 2 Weeks on</option>
                  <option value="Monthly on the  of the month">
                    Monthly on the of the month
                  </option>
                  <option value="Custom schedule...">Custom schedule...</option>
                </Input>

                <div>
                  <FormGroup className="my-3 mb-0 d-flex">
                    <Input
                      type="checkbox"
                      name="OneoffJob.ScheduleLetter"
                      onChange={formik.handleChange}
                      checked={formik.values?.OneoffJob?.ScheduleLetter}
                    />{" "}
                    <Label
                      style={{
                        fontSize: "12px",
                        color: "rgba(6,49,100,0.7)",
                        fontWeight: "400",
                        marginTop: "5px",
                      }}
                    >
                      Schedule letter
                    </Label>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Col>
        </TabPane>
      </TabContent>
    </div>
  );
};

const RecurringContract = ({
  search,
  setSearch,
  formik,
  isCalendarVisible,
  setIsCalendarVisible,
}) => {
  const [activeTab, setActiveTab] = useState(2);
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const setTabId = (tabId) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
  };
  const toggleCalendarVisibility = () => {
    setIsCalendarVisible(!isCalendarVisible);
    setIsCardVisible(!isCardVisible);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const currentDay = moment().format("dddd");
  const currentDate = moment().format("Do");
  const formattedDate = moment().format("MMMM D, YYYY");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleStartTimeChange = (e) => {
    const startTime = e.target.value;
    formik.setFieldValue("OneoffJob.StartTime", startTime);

    if (
      formik.values.OneoffJob?.EndTime &&
      startTime > formik.values.OneoffJob.EndTime
    ) {
      formik.setFieldValue("OneoffJob.EndTime", startTime);
    }
  };

  const handleEndTimeChange = (e) => {
    const endTime = e.target.value;
    if (
      formik.values.OneoffJob?.StartTime &&
      endTime >= formik.values.OneoffJob.StartTime
    ) {
      formik.setFieldValue("OneoffJob.EndTime", endTime);
    } else if (
      formik.values.OneoffJob?.StartTime &&
      endTime < formik.values.OneoffJob.StartTime
    ) {
      formik.setFieldValue(
        "OneoffJob.EndTime",
        formik.values.OneoffJob.StartTime
      );
    }
  };
  return (
    <>
      <div className="col-md-4 col-lg-12 col-sm-12 first-tab">
        <TabContent
          activeTab={activeTab}
          className="text-start my-3 mx-0"
          style={{ padding: "2px" }}
        >
          <TabPane tabId={2}>
            <Col sm="12" className="d-flex">
              <Card className="mx-0" style={{ width: "100%" }}>
                <CardHeader
                  className="d-flex justify-content-between"
                  style={{
                    width: "100%",
                    alignItems: "center",
                    padding: "0 10px 0 10px",
                    borderBottom: "none",
                  }}
                >
                  <p
                    style={{
                      fontSize: "24px",
                      fontWeight: 600,
                    }}
                    className="my-3 text-blue-color"
                  >
                    Recurring Schedule
                  </p>
                  <Button
                    className="bg-button-blue-color"
                    style={{
                      padding: "0 14px 0 14px",
                      fontSize: "12px",
                      marginTop: 0,
                      height: "32px",
                      color: "#fff",
                      borderRadius: "2px",
                    }}
                    onClick={toggleCalendarVisibility}
                  >
                    {/* Hide Calendar */}
                    {isCalendarVisible ? "Hide Calendar" : "Show Calendar"}{" "}
                  </Button>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label
                      htmlFor="exampleEmail"
                      className="mb-2"
                      style={{
                        color: "#000000",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Start Date
                    </Label>
                    <Input
                      id="exampleEmail"
                      placeholder="with a placeholder"
                      type="date"
                      style={{ fontSize: "12px", height: "40px" }}
                      name="RecuringJob.StartDate"
                      onChange={formik.handleChange}
                      value={formik.values.RecuringJob.StartDate}
                    />
                  </FormGroup>
                  <Label
                    htmlFor="exampleEmail"
                    style={{
                      color: "#000000",
                      fontWeight: 600,
                      fontSize: "12px",
                    }}
                    className="my-2"
                  >
                    Start Time & End Time
                  </Label>
                  <div className="d-flex" style={{ height: "" }}>
                    <Col
                      className="col-6"
                      style={{
                        borderBottomLeftRadius: "10px",
                        border: "1px solid rgba(6, 49, 100, 30%)",
                        borderTopLeftRadius: "10px",
                      }}
                    >
                      <Input
                        id="startTime"
                        placeholder="Start Time"
                        type="time"
                        name="OneoffJob.StartTime"
                        onChange={handleStartTimeChange}
                        value={formik?.values?.OneoffJob?.StartTime}
                        style={{
                          fontSize: "14px",
                          border: "none",
                          borderBottomLeftRadius: "10px",
                          borderTopLeftRadius: "10px",
                          height: "40px",
                        }}
                      />
                    </Col>
                    <Col
                      className="col-6"
                      style={{
                        borderBottomRightRadius: "10px",
                        borderTopRightRadius: "10px",
                        border: "1px solid rgba(6, 49, 100, 30%)",
                        borderLeft: "none",
                      }}
                    >
                      <Input
                        id="endTime"
                        type="time"
                        name="OneoffJob.EndTime"
                        onChange={handleEndTimeChange}
                        value={formik.values?.OneoffJob?.EndTime}
                        placeholder="End Time"
                        style={{
                          fontSize: "14px",
                          border: "none",
                          borderBottomRightRadius: "10px",
                          borderTopRightRadius: "10px",
                          height: "40px",
                        }}
                      />
                    </Col>
                  </div>
                  <Label
                    className="my-2"
                    htmlFor="exampleEmail"
                    style={{
                      color: "#000000",
                      fontWeight: 600,
                      fontSize: "12px",
                    }}
                  >
                    Repeats
                  </Label>
                  <Input
                    type="select"
                    className="text-blue-color"
                    name="RecuringJob.Repeats"
                    onChange={formik.handleChange}
                    value={formik.values.RecuringJob.Repeats}
                    style={{
                      fontSize: "14px",
                      border: "1px solid    rgba(6, 49, 100, 30%)",
                      borderRadius: "10px",
                      fontSize: "12px",
                      height: "40px",
                    }}
                  >
                    <option value="As nedded - we won't prompt you">
                      As nedded - we won't prompt you
                    </option>
                    <option value={`Weekly on ${currentDay}`}>
                      Weekly on {currentDay}
                    </option>
                    <option value={` Every 2 Weeks on ${currentDay}`}>
                      Every 2 Weeks on {currentDay}
                    </option>
                    <option
                      value={`Monthly on the ${currentDate} of the month`}
                    >
                      Monthly on the {currentDate} of the month
                    </option>
                    <option value="Custome schedule...">
                      Custome schedule...
                    </option>
                  </Input>
                  <Label
                    className="my-2"
                    htmlFor="exampleEmail"
                    style={{
                      color: "#000000",
                      fontWeight: 600,
                      fontSize: "12px",
                    }}
                  >
                    Duration
                  </Label>
                  <Input
                    type="select"
                    name="RecuringJob.Duration"
                    onChange={formik.handleChange}
                    value={formik.values.RecuringJob.Duration}
                    className="text-blue-color"
                    style={{
                      fontSize: "14px",
                      border: "1px solid rgba(6, 49, 100, 30%)",
                      borderRadius: "10px",
                      fontSize: "12px",
                      height: "40px",
                    }}
                  >
                    <option value="days(s)">days(s)</option>
                    <option value="week(s)">week(s)</option>
                    <option value="month(s)">month(s)</option>
                    <option value="year(s)">year(s)</option>
                  </Input>
                  <Label
                    className="my-2"
                    htmlFor="exampleEmail"
                    style={{
                      color: "#000000",
                      fontWeight: 600,
                      fontSize: "12px",
                    }}
                  >
                    Visits
                  </Label>
                  <div className="d-flex">
                    <div
                      className="col-4"
                      style={{
                        borderRight: "0.5px solid rgba(6,49,100, 0.8)",
                      }}
                    >
                      <p
                        className="mb-0"
                        color="rgba(6, 49, 100, 30%)"
                        style={{ fontSize: "12px" }}
                      >
                        First
                      </p>
                      <p
                        className="mb-0"
                        color="rgba(6, 49, 100, 30%)"
                        style={{ fontSize: "12px" }}
                      >
                        May 21, 2024
                      </p>
                    </div>
                    <div
                      className="col-4 mx-1"
                      style={{
                        borderRight: "0.5px solid rgba(6, 49, 100, 0.8)",
                      }}
                    >
                      <p
                        className="mb-0"
                        color="rgba(6, 49, 100, 30%)"
                        style={{ fontSize: "12px" }}
                      >
                        First
                      </p>
                      <p
                        className="mb-0"
                        color="rgba(6, 49, 100, 30%)"
                        style={{ fontSize: "12px" }}
                      >
                        May 21, 2024
                      </p>
                    </div>
                    <div className="col-4 mx-1">
                      <p
                        className="mb-0"
                        color="rgba(6, 49, 100, 30%)"
                        style={{ fontSize: "12px" }}
                      >
                        First
                      </p>
                      <p
                        className="mb-0"
                        color="rgba(6, 49, 100, 30%)"
                        style={{ fontSize: "12px" }}
                      >
                        May 21, 2024
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

const CalendarJOB = ({ isCalendarVisible, setIsCalendarVisible }) => {
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const editEvent = (event) => {
    const newTitle = prompt("Edit event name", event.title);
    if (newTitle) {
      setEvents(
        events.map((ev) => (ev === event ? { ...ev, title: newTitle } : ev))
      );
    }
  };
  const addEvent = (title, date) => {
    const newEvent = {
      id: events.length,
      title,
      start: date,
      end: date,
      allDay: true,
    };
    setEvents([...events, newEvent]);
  };

  const CustomToolbar = ({ label, onNavigate }) => {
    const goToBack = () => {
      onNavigate("PREV");
    };

    const goToNext = () => {
      onNavigate("NEXT");
    };

    return (
      <div className="rbc-toolbar">
        <button type="button" onClick={goToBack}>
          Back
        </button>
        <span className="rbc-toolbar-label">{label}</span>

        <button type="button" onClick={goToNext}>
          Next
        </button>
      </div>
    );
  };

  const toggleCalendarVisibility = () => {
    setIsCalendarVisible(!isCalendarVisible);
    setIsCardVisible(!isCardVisible);
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const [isCardVisible, setIsCardVisible] = useState(true);

  return (
    <div className="col-12 mx-2 my-3 mb-0 first-tab">
      <div>
        {isCalendarVisible && (
          <div>
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 434, marginTop: "19.8%" }}
              selectable
              onSelectEvent={(event) => {
                if (
                  window.confirm(
                    `Do you want to edit the event: ${event.title}?`
                  )
                ) {
                  editEvent(event);
                }
              }}
              onSelectSlot={(slotInfo) => {
                const title = prompt("New Event name");
                if (title) {
                  const date = slotInfo.start;
                  addEvent(title, date);
                }
              }}
              components={{
                toolbar: CustomToolbar,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ClientMessage = ({ formik }) => {
  return (
    <div className="col-5 order-sm-1 messageinput">
      <FormGroup>
        <InputText
          value={formik.values?.CustomerMessage}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.CustomerMessage &&
            Boolean(formik.errors.CustomerMessage)
          }
          helperText={
            formik.touched.CustomerMessage && formik.errors.CustomerMessage
          }
          name="CustomerMessage"
          label="CustomerMessage"
          type="text"
          className="text-blue-color w-100 m-0 mb-3"
          fieldHeight="56px"
        />
      </FormGroup>
      <FormGroup>
        <InputText
          value={formik.values?.ContractDisclaimer}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.ContractDisclaimer &&
            Boolean(formik.errors.ContractDisclaimer)
          }
          helperText={
            formik.touched.ContractDisclaimer &&
            formik.errors.ContractDisclaimer
          }
          name="ContractDisclaimer"
          label="ContractDisclaimer"
          type="text"
          className="text-blue-color w-100 m-0 mb-3"
          fieldHeight="56px"
        />
      </FormGroup>
    </div>
  );
};

const AddQuoteDiscount = ({ lineItems, formik }) => {
  const [showDiscount, setShowDiscount] = useState(false);
  const [showTax, setShowTax] = useState(false);

  const calculateSubTotal = () => {
    const total = lineItems?.reduce(
      (sum, item) => sum + Number(item.Total || 0),
      0
    );
    return total;
  };

  const subTotal = calculateSubTotal();
  const discountAmount = formik.values.Discount
    ? (Number(formik.values.Discount) * subTotal) / 100
    : 0;
  const discountedTotal = subTotal - discountAmount;
  const taxAmount = formik.values.Tax
    ? (Number(formik.values.Tax) * discountedTotal) / 100
    : 0;
  const Total = (discountedTotal + taxAmount)?.toFixed(2);

  return (
    <div className="col-7 order-sm-2 totalinput">
      <table>
        <tbody>
          <tr>
            <td className="text-blue-color" style={{ height: "50px" }}>
              Subtotal
            </td>
            <td className="text-end text-blue-color">
              ${subTotal?.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="text-blue-color" style={{ height: "50px" }}>
              Discount
            </td>
            <td
              className={`d-flex ${
                !showDiscount
                  ? "justify-content-end"
                  : "justify-content-between"
              }`}
            >
              {showDiscount && (
                <span style={{ position: "relative", display: "inline-block" }}>
                  <InputText
                    value={formik.values?.Discount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.Discount && Boolean(formik.errors.Discount)
                    }
                    helperText={
                      formik.touched.Discount && formik.errors.Discount
                    }
                    name="Discount"
                    label="Discount"
                    type="text"
                    className="text-blue-color w-100 m-0 mb-3"
                    fieldHeight="56px"
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                    }}
                    className="text-blue-color"
                  >
                    %
                  </span>
                </span>
              )}
              <u
                className="text-blue-color"
                style={{
                  fontWeight: 600,
                  cursor: "pointer",
                  textAlign: "end",
                  marginBottom: "9px",
                }}
                onClick={() => setShowDiscount(!showDiscount)}
              >
                {discountAmount > 0
                  ? `$${discountAmount?.toFixed(2)}`
                  : "Add Discount"}
              </u>
            </td>
          </tr>
          <tr>
            <td className="text-blue-color" style={{ height: "50px" }}>
              Tax
            </td>
            <td
              className={`d-flex ${
                !showTax ? "justify-content-end" : "justify-content-between"
              }`}
            >
              {showTax && (
                <span style={{ position: "relative", display: "inline-block" }}>
                  <InputText
                    value={formik.values?.Tax}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.Tax && Boolean(formik.errors.Tax)}
                    helperText={formik.touched.Tax && formik.errors.Tax}
                    name="Tax"
                    label="Tax"
                    type="text"
                    className="text-blue-color w-100 m-0 mb-3"
                    fieldHeight="56px"
                  />
                  <span
                    className="text-blue-color"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                    }}
                  >
                    %
                  </span>
                </span>
              )}
              <u
                className="text-blue-color"
                style={{
                  fontWeight: 600,
                  cursor: "pointer",
                  marginBottom: "9px",
                }}
                onClick={() => setShowTax(!showTax)}
              >
                {taxAmount > 0 ? `$${taxAmount?.toFixed(2)}` : "Add Tax"}
              </u>
            </td>
          </tr>
          <tr className="last-row border-0">
            <td className="border-0 text-blue-color">
              <b>Total</b>
            </td>
            <td className="text-end border-0 text-blue-color">
              <b>${Total}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Team = ({ setIds = (value) => {}, ids, isAddTeam = true }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedState, setCheckedState] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch team data
  const fetchData = async () => {
    try {
      const response = await AxiosInstance.get(
        `/contract/assign_team/${localStorage.getItem("CompanyId")}`
      );
      if (response.status === 200) {
        setTeamData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching team data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      FullName: "",
      Email: "",
      MobileNumber: "",
    },
    validationSchema: Yup.object({
      FullName: Yup.string().required("Full Name is required"),
      Email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      MobileNumber: Yup.string().required("Mobile Number is required"),
    }),
    onSubmit: async (values) => {
      try {
        const companyId = localStorage.getItem("CompanyId");

        if (!companyId) {
          console.error("CompanyId is not found in localStorage");
          return;
        }

        const response = await AxiosInstance.post(`/contract/assign_team`, {
          AssignPersonId: values.AssignPersonId,
          FullName: values.FullName,
          Email: values.Email,
          PhoneNumber: values.MobileNumber,
          CompanyId: companyId,
        });

        if (response.data.statusCode === 201) {
          toggleModal();
          fetchData();
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
    },
  });

  const handleRemoveTeam = (teamName) => {
    setSelectedTeams(selectedTeams.filter((name) => name !== teamName));
    setCheckedState((prevState) => ({
      ...prevState,
      [teamName]: false,
    }));
  };

  const handleTeamSelect = (event, team) => {
    if (event.target.checked) {
      setSelectedTeams([...selectedTeams, team?.FullName]);
      setCheckedState((prevState) => ({
        ...prevState,
        [team?.FullName]: true,
      }));
      setIds(() => [...ids, team.AssignPersonId]);
    } else {
      setSelectedTeams(selectedTeams.filter((name) => name !== team?.FullName));
      setCheckedState((prevState) => ({
        ...prevState,
        [team?.FullName]: false,
      }));
      setIds(() => ids.filter((name) => name !== team?.AssignPersonId));
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleModal = () => {
    if (isAddTeam) {
      setIsModalOpen(!isModalOpen);
    }
  };

  const handleOutsideClick = () => {
    toggleDropdown();
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="jobs">
      <div className="team-card" style={{ width: "100%" }}>
        <Card className="" style={{ height: "140px" }}>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "none",
              background: "none",
            }}
            className="team-header"
          >
            <h3 className="text-blue-color" style={{ fontWeight: 600 }}>
              Team
            </h3>
            <div className="assignbtn">
              <Button
                className="bg-blue-color"
                outline
                style={{
                  padding: "0 14px 0 14px",
                  fontSize: "12px",
                  marginTop: 0,
                  height: "32px",
                  color: "#fff",
                }}
                onClick={toggleDropdown}
              >
                + Assign
              </Button>
              {isDropdownOpen && (
                <div
                  className="assigndrop"
                  style={{
                    position: "absolute",
                    backgroundColor: "#fff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    marginTop: "10px",
                    padding: "10px",
                    zIndex: 1000,
                    width: "250px",
                    right: 0,
                  }}
                >
                  <Card>
                    <CardHeader
                      className="text-white-color bg-blue-color"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Select team
                      <CloseIcon
                        onClick={toggleDropdown}
                        style={{ cursor: "pointer" }}
                      />
                    </CardHeader>
                    <CardBody>
                      <div onClick={handleOutsideClick}>
                        {teamData && teamData.length > 0 ? (
                          teamData.map((person) => (
                            <FormGroup
                              check
                              className="my-3 mb-0"
                              key={person._id}
                            >
                              <Input
                                type="checkbox"
                                checked={
                                  checkedState &&
                                  !!checkedState[person?.FullName]
                                }
                                onChange={(e) => handleTeamSelect(e, person)}
                              />
                              <Label
                                style={{
                                  fontSize: "16px",
                                  color: "rgba(6,49,100,0.7)",
                                  fontWeight: "400",
                                  marginBottom:0
                                }}
                              >
                                {person.FullName}
                              </Label>
                            </FormGroup>
                          ))
                        ) : (
                          <p>No team members found.</p>
                        )}
                      </div>
                      {isAddTeam && (
                         <React.Fragment>
                    
                          <hr />
                          <Button
                            className="text-blue-color text-white-color"
                            style={{
                              background: "#063164",
                              color: "#fff",
                              border: "none",
                              fontWeight: "400",
                            }}
                            onClick={toggleModal}
                          >
                            + Create User
                          </Button>
                       
                        </React.Fragment>
                      )}
                    </CardBody>
                  </Card>
                  <Modal isOpen={isModalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Add new user</ModalHeader>
                    <ModalBody
                      className="nozindex"
                      style={{ borderBottom: "none" }}
                    >
                      <FormGroup>
                        <Input
                          name="FullName"
                          placeholder="Full Name"
                          type="text"
                          className="text-blue-color w-100 mb-3"
                          value={formik.values.FullName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.FullName &&
                            Boolean(formik.errors.FullName)
                          }
                        />
                        {formik.touched.FullName && formik.errors.FullName ? (
                          <div className="text-danger">
                            {formik.errors.FullName}
                          </div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          name="Email"
                          placeholder="Email"
                          type="text"
                          className="text-blue-color w-100 mb-3"
                          value={formik.values.Email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.Email && Boolean(formik.errors.Email)
                          }
                        />
                        {formik.touched.Email && formik.errors.Email ? (
                          <div className="text-danger">
                            {formik.errors.Email}
                          </div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          name="MobileNumber"
                          placeholder="Mobile Number"
                          type="text"
                          className="text-blue-color w-100 mb-3"
                          value={formik.values.MobileNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.MobileNumber &&
                            Boolean(formik.errors.MobileNumber)
                          }
                        />
                        {formik.touched.MobileNumber &&
                        formik.errors.MobileNumber ? (
                          <div className="text-danger">
                            {formik.errors.MobileNumber}
                          </div>
                        ) : null}
                      </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="secondary"
                        onClick={toggleModal}
                        className="text-blue-color"
                        style={{
                          background: "none",
                          color: "#063164",
                          borderColor: "#063164",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        onClick={formik.handleSubmit}
                        className="bg-blue-color text-white-color"
                        style={{ borderColor: "#063164" }}
                      >
                        Save User
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              )}
            </div>
          </CardHeader>
          <CardBody>
            <div style={{ marginTop: "-10px", height: "18px" }}>
              {selectedTeams.map((team, index) => (
                <div
                  key={index}
                  className="tag"
                  style={{ marginTop: "0px", marginLeft: "10px" }}
                >
                  <span className="tag-text" style={{ fontSize: "16px" }}>
                    {team}
                  </span>
                  <button
                    className="tag-close"
                    onClick={() => handleRemoveTeam(team)}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>

            <FormGroup check className="my-3 mb-0">
              {/* <Input type="checkbox" />{" "}
              <Label
                check
                style={{
                  fontSize: "12px",
                  color: "rgba(6,49,100,0.7)",
                  fontWeight: "400",
                  marginTop: "10px",
                }}
              >
                Email team about assignment
              </Label> */}
              {/* <div className="checkbox-group my-5">
                <Input type="checkbox" id="any-time" name="any-time" />
                <label for="any-time">Email team about assignment</label>
              </div> */}
            </FormGroup>
            {/* {showEmailField && (
              <FormGroup>
                <InputText
                  name="Email"
                  label="Email"
                  type="text"
                  className="text-blue-color w-100 mb-3"
                  fieldHeight="56px"
                  onChange={handleChange}
                  value={formData.Email}
                />
                <Label htmlFor="email" className="mb-3">
                  An email is required to log in to Jobber
                </Label>
              </FormGroup>
            )} */}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export {
  InternalNotes,
  OneOffContract,
  RecurringContract,
  CalendarJOB,
  ClientMessage,
  AddQuoteDiscount,
  Team,
};
