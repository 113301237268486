import React, { useEffect, useState } from "react";

import { Circles } from "react-loader-spinner";

import CardImage from "../../../../assets/image/icons/CreditCard.png";
import AxiosInstance from "../../../AxiosInstance";
import sendToast from "../../../../components/Toast/sendToast";
import AddAchAccount from "../../Account/AddAchAccount";

const AchPayment = ({
  paymentFormik,
  selectedAccount,
  setSelectedAccount,
  companyData,
  CustomerId,
}) => {
  const [accountDetails, setAccountDetails] = useState([]);
  const [paymentMethodLoader, setPaymentMethodLoader] = useState(true);
  const fetchAccountData = async () => {
    if (!CustomerId) {
      sendToast("Something went wrong, please try again.");
      return;
    }
    try {
      const res = await AxiosInstance.post("/nmi/get-billing-customer-vault", {
        CompanyId: localStorage.getItem("CompanyId"),
        type: "ach",
        CustomerId,
      });
      if (
        res.data.status === 200 &&
        res.data.data &&
        res.data.data.customer &&
        res.data.data.customer.billing
      ) {
        paymentFormik.setFieldValue(
          "customer_vault_id",
          res.data.data.customer["@attributes"].id
        );
        if (Array.isArray(res.data.data.customer.billing)) {
          setAccountDetails(res.data.data.customer.billing);
        } else {
          setAccountDetails([res.data.data.customer.billing]);
        }
      } else {
        setAccountDetails([]);
      }
    } catch (error) {
      sendToast("Something went wrong, please try again.");
    } finally {
      setPaymentMethodLoader(false);
    }
  };

  useEffect(() => {
    if (accountDetails?.length === 0) {
      fetchAccountData();
    }
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="mb-3 px-2 d-flex flex-column"
      style={{ background: "#06316412", borderRadius: "8px" }}
    >
      {paymentMethodLoader ? (
        <div
          className="d-flex justify-content-center align-items-center my-2"
          style={{ height: "100%", width: "100%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            visible={paymentMethodLoader}
          />
        </div>
      ) : accountDetails && accountDetails?.length > 0 ? (
        accountDetails.map((item, index) => (
          <div className="d-flex align-items-center justify-content-start w-100 mt-3">
            <div className="d-flex align-items-center w-100">
              <div className="d-flex align-items-center mx-2 accounts">
                <input
                  type="radio"
                  checked={selectedAccount === item}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedAccount(item);
                      paymentFormik.setFieldValue(
                        "billing_id",
                        item["@attributes"].id
                      );
                    } else {
                      setSelectedAccount("");
                    }
                  }}
                  style={{
                    color: "#063164",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column justify-conent-center mx-2">
                  <span
                    className="text-blue-color"
                    style={{ fontSize: "18px" }}
                  >
                    {item.check_account}
                  </span>
                  <span className="text-blue-color">{item.check_name}</span>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-start w-100 my-3">
            <div className="d-flex align-items-center w-100">
              <div className="d-flex align-items-center w-100">
                <div
                  style={{
                    width: "44px",
                    height: "44px",
                    background: "#F0F0F0",
                    borderRadius: "50%",
                    padding: "8px 5px",
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img src={CardImage} alt="" width="90%" height="90%" />
                </div>
                <div className="d-flex flex-column justify-conent-center mx-2">
                  <span className="text-blue-color">No ACH Accounts</span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mx-2">
              <button
                className="p-2 bg-blue-color"
                style={{
                  color: "#fff",
                  borderRadius: "4px",
                  background: "#fff",
                  fontSize: "12px",
                }}
                onClick={() => setIsOpen(true)}
              >
                <span className="full-sentence">Add New Account</span>
              </button>
            </div>
          </div>
        </>
      )}
      {accountDetails && accountDetails?.length > 0 && (
        <div className="d-flex justify-content-end mb-2 mx-2">
          <button
            className="p-2 bg-blue-color"
            style={{
              color: "#fff",
              borderRadius: "4px",
              background: "#fff",
              fontSize: "12px",
            }}
            onClick={() => setIsOpen(true)}
          >
            <span className="full-sentence">Add Account</span>
          </button>
        </div>
      )}
      <AddAchAccount
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        companyData={companyData}
        isBilling={accountDetails?.length > 0}
        vaultId={paymentFormik.values.customer_vault_id}
        fetchData={fetchAccountData}
        CustomerId={CustomerId}
      />
    </div>
  );
};

export default AchPayment;
