import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import {
  Button,
  CardBody,
  CardHeader,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import { File } from "../Files";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import "./style.css";
import { BorderClear } from "@mui/icons-material";
import AxiosInstance from "../../Views/AxiosInstance";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import FormControl from "@mui/material/FormControl";
import * as Yup from "yup";
import CancelIcon from "@mui/icons-material/Close";
import { handleAuth } from "../Login/Auth";
import zIndex from "@mui/material/styles/zIndex";
import InputText from "../InputFields/InputText";
import { useLocation, useNavigate } from "react-router-dom";

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    padding: 0,
    margin: 0,
    border: "1px solid",
    borderRadius: "8px",
    height: "50px",
    borderColor: "#063164",
  }),
  control: (provided) => ({
    ...provided,
    height: "100%",
    border: "0",
    boxShadow: "none",
    padding: 0,
    margin: 0,
    borderColor: "red",
    backgroundColor: "transparent",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
    padding: 0,
    margin: 0,
    zIndex: 9999,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "darkred" : "red",
    color: "black",
    padding: 0,
    margin: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: 0,
    margin: 0,
  }),
};

const CustomOption = ({ data, innerRef, innerProps, selectOption }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleSelectOption = (option) => {
    selectOption(option);
  };

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: isHovered ? "rgba(6, 49, 100, 0.2)" : "white",
        padding: "5px",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        handleSelectOption(data);
      }}
    >
      <div style={{ fontWeight: "bold", paddingLeft: "10px" }}>{data.Name}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <div style={{ width: "90%" }}>{data.Description}</div>
        <div style={{ width: "10%" }}>{data.CostPerUnit || data.CostPerHour || data.CostPerSquare}</div>
        <div style={{ width: "10%" }}>{data.Cost}</div>
      </div>
    </div>
  );
};

const GetProductsForContract = ({
  item,
  index,
  handleSelectChange,
  lineItems,
  setLineItems,
  menuIsOpen,
  setMenuIsOpen,
  deleteLineItem,
  isError,
  productRef,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const navigate = useNavigate();
  const location = useLocation();
  const [groupedOptions, setGroupedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedAdminId, setSelectedAdminId] = useState("");
  const [loading, setLoading] = useState(false);

  const getProducts = async () => {
    try {
      const res = await AxiosInstance.get(
        `${baseUrl}/product/get_products/${
          localStorage.getItem("CompanyId") || tokenDecode?.companyId
        }`
      );
      if (res.data.statusCode === 200) {
        const products = res.data.data;
        setGroupedOptions(products);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    getProducts();
  }, [tokenDecode]);

  const handleInputChange = (e, key) => {
    const newLineItems = [...lineItems];
    newLineItems[index][key] = e.target.value;
    if (key === "Unit" || key === "CostPerUnit") {
      newLineItems[index].Total =
        Number(newLineItems[index].Unit || 0) *
        Number(newLineItems[index].CostPerUnit || 0);
    } else if (key === "Hour" || key === "CostPerHour") {
      newLineItems[index].Total =
        Number(newLineItems[index].Hour || 0) *
        Number(newLineItems[index].CostPerHour || 0);
    } else if (key === "Square" || key === "CostPerSquare") {
      newLineItems[index].Total =
        Number(newLineItems[index].Square || 0) *
        Number(newLineItems[index].CostPerSquare || 0);
    }
    setLineItems(newLineItems);
  };

  const handleSearchInputChange = (value) => {
    setInputValue(value);
  };

  const filteredOptions = groupedOptions.filter((group) =>
    group.options.some(
      (option) =>
        option.Name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.Description.toLowerCase().includes(inputValue.toLowerCase())
    )
  );

  const [modelOpen, setModelOpen] = useState(false);

  const [tokenDecode, setTokenDecode] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await handleAuth(navigate, location);
        setTokenDecode(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    try {
      values["companyId"] =
        localStorage.getItem("CompanyId") || tokenDecode?.companyId || "";
      let res;
      if (!selectedAdminId) {
        res = await AxiosInstance.post(`/product`, {
          ...values,
        });
      } else {
        res = await AxiosInstance.put(
          `${baseUrl}/product/${selectedAdminId}`,
          values
        );
      }
      if (res.data.statusCode === 200) {
        setModelOpen(false);
        setTimeout(() => {
          toast.success(res.data.message);
        }, 500);
        getProducts();
      } else if (res.data.statusCode === 201) {
        setTimeout(() => {
          toast.error(res.data.message);
        }, 500);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  const [unitType, setUnitType] = useState("Hour");
  const [openDropdown, setOpenDropdown] = useState(false);
  const [currentUnitType, setCurrentUnitType] = useState("");
  useEffect(() => {
    if (item.Hour !== null && item.CostPerHour !== null) {
      setCurrentUnitType("Hour");
    } else if (item.Unit !== null && item.CostPerUnit !== null) {
      setCurrentUnitType("Unit");
    } else {
      setCurrentUnitType(unitType);
    }
  }, [item, unitType]);

  const handleDropdownToggle = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setCurrentUnitType(value);
    setOpenDropdown(false);
  };

  return (
    <div
      className="row my-3"
      style={{ paddingRight: "7px", paddingLeft: "7px" }}
    >
      <h5
        style={{ fontSize: "16px", color: "#063164" }}
        className="p-0 mx-2 mb-2"
      >
        Product/ Service
      </h5>
      <div className="row g-2">
        {/* Product/Service Selection */}
        <div className="col-lg-5 col-md-12">
          <div className="card border-blue-color" style={{ border: "none" }}>
            <ReactSelect
              className="basic-single"
              classNamePrefix="select"
              ref={productRef}
              placeholder="Product/Service"
              options={filteredOptions}
              components={{
                Option: (props) => (
                  <CustomOption
                    {...props}
                    selectOption={(option) => handleSelectChange(index, option)}
                  />
                ),
                NoOptionsMessage: () => (
                  <div className="d-flex justify-content-center align-items-center gap-2 py-3">
                    <span>No Data Found</span>
                    <Button
                      style={{
                        color: "#063164",
                        backgroundColor: "#fff",
                        border: "1px solid #063164",
                        borderRadius: "10px",
                        fontSize: "12px",
                      }}
                      onClick={(e) => setModelOpen(true)}
                    >
                      Create New Product
                    </Button>
                  </div>
                ),
              }}
              styles={customStyles}
              value={groupedOptions
                .flatMap((group) => group.options)
                .find((option) => option.Name === item?.Name)}
              menuIsOpen={menuIsOpen[index]}
              onMenuOpen={() => {
                const newMenuState = [...menuIsOpen];
                newMenuState[index] = true;
                setMenuIsOpen(newMenuState);
              }}
              onMenuClose={() => {
                const newMenuState = [...menuIsOpen];
                newMenuState[index] = false;
                setMenuIsOpen(newMenuState);
              }}
              onInputChange={handleSearchInputChange}
            />
            {isError && (
              <div style={{ color: "red" }}>Product is required!</div>
            )}
          </div>
        </div>

        {/* Quantity Input */}
        <div className="col-lg-3 col-md-4 qty">
          <FormGroup>
            <InputText
              value={item[currentUnitType] || ""}
              onChange={(e) => handleInputChange(e, currentUnitType)}
              label={currentUnitType}
              placeholder={`Enter ${currentUnitType} here...`}
              id={`qty_${index}`}
              name={`${currentUnitType}[${index}]`}
              type="number"
              className="text-blue-color w-100 mb-3"
              fieldHeight="56px"
              onInput={(e) => {
                if (e.target.value < 0) {
                  e.target.value = 0;
                  handleInputChange(e, currentUnitType);
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle unit dropdown"
                    onClick={handleDropdownToggle}
                    edge="end"
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            {openDropdown && (
              <InputText
                select
                value={currentUnitType}
                onChange={handleDropdownChange}
                className="text-blue-color w-100"
                fieldHeight="56px"
              >
                <MenuItem value="Unit">Unit</MenuItem>
                <MenuItem value="Hour">Hour</MenuItem>
                <MenuItem value="Hour">Square</MenuItem>
              </InputText>
            )}
          </FormGroup>
        </div>

        <div className="col-lg-2 col-md-4">
          <FormGroup style={{ borderRadius: "8px" }}>
            {currentUnitType === "Unit" ? (
              <InputText
                label="Price"
                className="mb-0 border-blue-color w-100"
                id={`CostPerUnit${index}`}
                name={`CostPerUnit[${index}]`}
                placeholder="Cost"
                type="number"
                value={item.CostPerUnit || ""}
                fieldHeight="56px"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value < 0) {
                    e.target.value = 0;
                  }
                  handleInputChange(e, "CostPerUnit");
                }}
                min="0"
              />
            ) : currentUnitType === "Square" ? (
              <InputText
                label="Price"
                className="mb-0 border-blue-color w-100"
                id={`CostPerSquare${index}`}
                name={`CostPerSquare[${index}]`}
                placeholder="Cost"
                type="number"
                value={item.CostPerSquare || ""}
                fieldHeight="56px"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value < 0) {
                    e.target.value = 0;
                  }
                  handleInputChange(e, "CostPerSquare");
                }}
                min="0"
              />
            ) : (
              <InputText
                label="Price"
                className="mb-0 border-blue-color w-100"
                id={`CostPerHour${index}`}
                name={`CostPerHour[${index}]`}
                placeholder="Cost"
                type="number"
                value={item.CostPerHour || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value < 0) {
                    e.target.value = 0;
                  }
                  handleInputChange(e, "CostPerHour");
                }}
                min="0"
              />
            )}
          </FormGroup>
        </div>

        {/* Total & Delete Button */}
        <div className="col-lg-2 col-md-4 d-flex align-items-start justify-content-between">
          <FormGroup>
            <InputText
              label="Total"
              className="mb-0 border-blue-color w-100"
              id={`total_${index}`}
              name={`Total[${index}]`}
              placeholder="Total"
              type="number"
              value={item.Total || ""}
              disabled={true}
            />
          </FormGroup>

          {lineItems.length > 1 && (
            <button
              className="deleteIcon my-3"
              onClick={() => deleteLineItem(index)}
              style={{ color: "red", border: "none", background: "none" }}
            >
              <DeleteOutlineOutlinedIcon />
            </button>
          )}
        </div>
      </div>

      {/* Description */}
      <TextField
        value={item?.Description || ""}
        id={`description_${index}`}
        name={`Description`}
        label="Description"
        placeholder="Enter Description here..."
        multiline
        rows={4}
        style={{
          border: "none",
          color: "#063164",
          fontSize: "14px",
          height: "100px",
          borderRadius: "8px",
          padding: "0 3px",
          marginTop: "15px",
        }}
        Value={item?.Description || ""}
        onChange={(e) => handleInputChange(e, "Description")}
        InputProps={{
          readOnly: false,
        }}
        className="text-blue-color descriptionItem"
        InputLabelProps={{
          style: {
            fontWeight: "700",
            fontSize: "14px",
            borderRadius: "0",
            margin: "0 5px ",
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#063164",
              borderRadius: "8px",
            },
            "&:hover fieldset": {
              borderColor: "#063164",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#063164",
            },
          },
        }}
      />
    </div>
  );
};

export default GetProductsForContract;
