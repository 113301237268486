import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

const SettingDropdown = ({
  isOpenDropDown,
  toggle,
  companyName,
}) => {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-between align-items-center ">
      <div className="setting-dropdown settingsidebardrop mb-2 my-2 mb-0">
        <Dropdown isOpen={isOpenDropDown} toggle={toggle}>
          <DropdownToggle className="back-color" caret>
            Setting
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <h6 style={{ fontWeight: 600, marginBottom: "10px" }}>
                BUSINESS <br /> MANAGEMENT
              </h6>
            </DropdownItem>
            <DropdownItem
              className="dropdown-link-setting"
              onClick={(e) => {
                navigate(
                    companyName
                      ? `/${companyName}/product&service`
                      : "/superadmin/product&service",
                    {
                      state: {
                        navigats: ["/index", "/product&service"],
                      },
                    }
                  );
              }}
              style={{
                textDecoration: "none",
                color: "#4F963B",
                display: "block",
                marginBottom: "5px",
              }}
            >
              Products & Services
            </DropdownItem>
            <DropdownItem>
              <h6 style={{ fontWeight: 600, marginBottom: "10px" }}>
                TEAM <br /> ORGANIZATION
              </h6>
            </DropdownItem>
            <DropdownItem
              style={{
                textDecoration: "none",
                color: "#4F963B",
                display: "block",
                marginBottom: "5px",
              }}
              className="dropdown-link-setting"
              onClick={() => {
                navigate(
                  companyName
                    ? `/${companyName}/manageteam`
                    : "/superadmin/manageteam",
                  {
                    state: { navigats: ["/index", "/manageteam"] },
                  }
                );
              }}
            >
              Manage Team
            </DropdownItem>
            <DropdownItem></DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
    
  );
};

export default SettingDropdown;
