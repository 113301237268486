import React from "react";
import { useEffect, useState } from "react";
import { useAuthAdmin } from "../../components/Login/Auth";
import axios from "axios";
import AxiosInstance from "../../Views/AxiosInstance";

const CustomerDashboard = () => {
  const [data, setData] = useState({});
  const currentHour = new Date().getHours();

  let welcomeMessage;
  if (currentHour < 12) {
    welcomeMessage = "Good Morning";
  } else if (currentHour < 18) {
    welcomeMessage = "Good Afternoon";
  } else {
    welcomeMessage = "Good Evening";
  }
  useEffect(() => {
    const fetchData = async () => {
      const baseUrl = process.env.REACT_APP_BASE_API;
      const token =
        localStorage.getItem("adminToken") ||
        localStorage.getItem("clientToken") ||
        localStorage.getItem("workerToken");

      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      try {
        const res = await AxiosInstance.post(`${baseUrl}/company/token_data`, {
          token,
        });
        setData(res.data.data);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="px-0">
        <h4
          className="px-1"
          style={{
            fontSize: "35px",
            fontWeight: "700",
            fontFamily: "Poppins",
            lineHeight: "28.8px",
            color: "rgba(42, 79, 97, 1)",
          }}
        >
          {welcomeMessage + ","} {data?.full_name}
        </h4>
      </div>
    </div>
  );
};

export default CustomerDashboard;
