import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Autocomplete,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  FormGroup,
} from "@mui/material";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { Country, State, City } from "country-state-city";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../Views/AxiosInstance";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import InputText from "../InputFields/InputText";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "./style.css";
import { Team } from "./Index";
import { useLocation, useNavigate } from "react-router-dom";
import { handleAuth } from "../../components/Login/Auth";
import swal from "sweetalert";

//2529 companyID
const Visit = ({
  open,
  setOpen,
  data,
  ContractId,
  contractData,
  CompanyId,
  fetchData,
  VisitId,
  setVisitId,
  CustomerId,
}) => {
  //2529 companyID
  const baseUrl = process.env.REACT_APP_BASE_API;
  // const CustomerId = data?.CustomerId;

  // var companyId = localStorage.getItem("CompanyId");

  const [teamData, setTeamData] = useState([]);

  // let fetchVisitData = async () => {
  //   try {
  //     if (!VisitId || !ContractId) {
  //       console.error("VisitId or ContractId is missing!");
  //       return;
  //     }
  //     const visitRes = await AxiosInstance.get(
  //       `/visits/visits/${VisitId}/${ContractId}`
  //     );

  //     formik.setValues({
  //       ItemName: visitRes.data.data.ItemName,
  //       Note: visitRes.data.data.Note,
  //       StartTime: visitRes.data.data.StartTime,
  //       EndTime: visitRes.data.data.EndTime,
  //       StartDate: visitRes.data.data.StartDate,
  //       EndDate: visitRes.data.data.EndDate,
  //       AssignPersonId: visitRes.data.data.AssignPersonId,
  //     });
  //     const member = teamData.find(
  //       (item) => item.AssignPersonId === visitRes.data.data.AssignPersonId
  //     );
  //     setSelectedTeams([member.FullName]);
  //     setCheckedState({
  //       [member?.FullName]: true,
  //     });
  //     setAssignPersonId(visitRes.data.data.AssignPersonId);
  //   } catch (error) {
  //     console.error("Error: ", error.messae);
  //   }
  // };

  let fetchVisitData = async () => {
    try {
      if (!VisitId || !ContractId) {
        console.error("VisitId or ContractId is missing!");
        return;
      }
      const visitRes = await AxiosInstance.get(
        `/visits/visits/${VisitId}/${ContractId}`
      );
      formik.setValues({
        ItemName: visitRes?.data.data.ItemName,
        Note: visitRes?.data.data.Note,
        StartTime: visitRes?.data.data.StartTime,
        EndTime: visitRes?.data.data.EndTime,
        StartDate: visitRes?.data.data.StartDate,
        EndDate: visitRes?.data.data.EndDate,
        AssignPersonId: visitRes?.data.data.AssignPersonId,
      });

      const member = teamData.find(
        (item) => item.AssignPersonId === visitRes?.data.data.AssignPersonId
      );
      if (member) {
        setSelectedTeams([member.FullName]);
        setCheckedState({
          [member.FullName]: true,
        });
        setAssignPersonId(visitRes?.data.data.AssignPersonId);
      }
    } catch (error) {
      console.error("Error: ", error.message);
    }
  };

  useEffect(() => {
    fetchVisitData();
  }, [VisitId, teamData]);

  const formik = useFormik({
    initialValues: {
      CompanyId: "",
      ContractId: "",
      AssignPersonId: "",
      CustomerId: "",
    },
    validationSchema: Yup.object({
      ItemName: Yup.string().required("ItemName is required"),
      // StartTime: Yup.string().required("StartTime is required"),
      // EndDate: Yup.string().required("EndDate is required"),
    }),

    onSubmit: async (values) => {
      if (!VisitId) {
        try {
          values["CompanyId"] = CompanyId;
          values["ContractId"] = ContractId;
          values["CustomerId"] = CustomerId;
          values["AssignPersonId"] = assignPersonId;
          const response = await AxiosInstance.post(
            `${baseUrl}/visits`,
            values
          );

          if (response.data.statusCode === 200) {
            toast.success(response.data.message);
            setOpen(false);
            fetchData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error("An error occurred while submitting the form.");
        }
      } else {
        try {
          values["CompanyId"] = CompanyId;
          values["ContractId"] = ContractId;
          values["CustomerId"] = CustomerId;
          values["AssignPersonId"] = assignPersonId;

          const response = await AxiosInstance.put(
            `/visits/${VisitId}/${ContractId}`,
            values
          );
          if (response.data.statusCode === 200) {
            toast.success(response.data.message);
            setOpen(false);
            fetchData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error("An error occurred while submitting the form.");
        }
      }
      formik.resetForm();
      setVisitId("");
      setSelectedTeams([]);
      setCheckedState("");
      setAssignPersonId(null);
      setOpen({ isOpen: false, propertyData: null });
    },
  });

  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (open?.propertyData) {
      formik.setValues(open?.propertyData);
    } else {
      formik.resetForm();
    }
  }, [open?.propertyData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "Country") {
      formik.setFieldValue(name, value.name);
    } else {
      formik.setFieldValue(name, type === "checkbox" ? checked : value);
    }
  };

  const [tokenDecode, setTokenDecode] = useState({});

  const fetchDatas = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchDatas();
  }, []);

  const [isAnyTime, setIsAnyTime] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [checkedState, setCheckedState] = useState(false);
  const [assignPersonId, setAssignPersonId] = useState();

  // Fetch team data
  const fetchallData = async () => {
    try {
      const response = await AxiosInstance.get(
        `/contract/assign_team/${
          localStorage.getItem("CompanyId") || tokenDecode?.companyId
        }`
      );
      if (response.status === 200) {
        setTeamData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching team data:", error);
    }
  };

  useEffect(() => {
    fetchallData();
  }, [tokenDecode]);

  const handleRemoveTeam = (teamName) => {
    if (teamName === selectedTeams[0]) {
      setAssignPersonId(null);
    }

    setSelectedTeams(selectedTeams.filter((name) => name !== teamName));
    setCheckedState((prevState) => ({
      ...prevState,
      [teamName]: false,
    }));
  };

  const handleTeamSelect = (event, team) => {
    if (event.target.checked) {
      setSelectedTeams([...selectedTeams, team?.FullName]);
      setCheckedState((prevState) => ({
        ...prevState,
        [team?.FullName]: true,
      }));
      setAssignPersonId(team.AssignPersonId);
    } else {
      setSelectedTeams(selectedTeams.filter((name) => name !== team?.FullName));
      setCheckedState((prevState) => ({
        ...prevState,
        [team?.FullName]: false,
      }));
      setAssignPersonId(null);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOutsideClick = () => {
    toggleDropdown();
  };

  const handleFullNameChange = (e, index) => {
    const newTeamData = [...teamData];
    newTeamData[index].FullName = e.target.value;
    setTeamData(newTeamData);
  };

  return (
    <Dialog
      fullWidth
      open={open?.isOpen}
      onClose={() => setOpen({ isOpen: false, propertyData: null })}
      className="client"
    >
      <DialogTitle>
        <div className=" d-flex justify-content-start align-items-center">
          <h4
            className="text-blue-color text-property"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "42px",
              margin: "0 10px",
            }}
          >
            Scheduled a Visit
          </h4>
        </div>
      </DialogTitle>
      <Divider
        style={{ height: "1px", backgroundColor: "rgba(42, 79, 97, 0.8)" }}
      />
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-2 d-flex">
            <div className="col-6" style={{ marginRight: "5px" }}>
              <InputText
                value={formik.values?.ItemName}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.ItemName && Boolean(formik.errors.ItemName)
                }
                helperText={formik.touched.ItemName && formik.errors.ItemName}
                name="ItemName"
                placeholder="Enter Item Name here..."
                label="ItemName"
                type="text"
                className="text-blue-color w-100 mb-2 mx-0"
                fieldHeight="56px"
                InputLabelProps={{
                  shrink:
                    Boolean(formik.values?.ItemName) || formik.touched.ItemName,
                }}
              />
              <TextField
                className="Note-details text-blue-color my-2"
                id="Note"
                name="Note"
                label="Note details"
                value={formik.values?.Note}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                // value={Notes}
                // onChange={handleNoteChange}
                defaultValue=""
                placeholder="Enter Notes here..."
                type="text"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#063164",
                    },
                    "&:hover fieldset": {
                      borderColor: "#063164",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#063164",
                    },
                    "& input": {
                      height: "70px",
                      alignItems: "start",
                    },
                  },
                }}
                InputProps={{
                  readOnly: false,
                }}
                style={{ width: "100%", paddingTop: "0px" }}
                InputLabelProps={{
                  style: { fontSize: "15px" },
                }}
              />
            </div>
            <div className="col-6">
              <p
                className="mb-0 Sub-title text-blue-color"
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Contract details
              </p>
              <hr />
              <Table borderless>
                <tbody className="Contract-table-detail">
                  <tr>
                    <td>Contract#</td>
                    <td>{contractData?.ContractNumber}</td>
                  </tr>
                  <tr>
                    <td>Customer</td>
                    <td>
                      {contractData?.customer?.FirstName || ""}{" "}
                      {contractData?.customer?.LastName || ""}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{contractData?.customer?.PhoneNumber}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>
                      {contractData?.location?.Address || ""}{" "}
                      {contractData?.location?.City || ""}{" "}
                      {contractData?.location?.State || ""}{" "}
                      {contractData?.location?.Country || ""}{" "}
                      {contractData?.location?.Zip || ""}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <hr />
          <div className="d-flex">
            <div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Start Date"
                      value={
                        formik.values.StartDate &&
                        dayjs(formik?.values?.StartDate).isValid()
                          ? dayjs(formik?.values?.StartDate)
                          : null
                      }
                      onChange={(value) =>
                        formik.setFieldValue(
                          "StartDate",
                          value && dayjs(value).isValid()
                            ? value.toISOString()
                            : null
                        )
                      }
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            formik.touched.StartDate &&
                            Boolean(formik.errors.StartDate)
                          }
                          helperText={
                            formik.touched.StartDate && formik.errors.StartDate
                          }
                        />
                      )}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: "8px",
                        },
                        "& .MuiInputBase-input": {
                          color: "#063164",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#063164",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#063164",
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="End Date"
                      value={
                        formik.values.EndDate &&
                        dayjs(formik?.values?.EndDate).isValid()
                          ? dayjs(formik?.values?.EndDate)
                          : null
                      }
                      onChange={(value) =>
                        formik.setFieldValue(
                          "EndDate",
                          value && dayjs(value).isValid()
                            ? value.toISOString()
                            : null
                        )
                      }
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            formik.touched.EndDate &&
                            Boolean(formik.errors.EndDate)
                          }
                          helperText={
                            formik.touched.EndDate && formik.errors.EndDate
                          }
                        />
                      )}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: "8px",
                        },
                        "& .MuiInputBase-input": {
                          color: "#063164",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#063164",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#063164",
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                <div style={{ visibility: isAnyTime ? "hidden" : "visible" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        label="Start Time"
                        value={
                          formik.values.StartTime &&
                          dayjs(formik?.values?.StartTime).isValid()
                            ? dayjs(formik?.values?.StartTime)
                            : null
                        }
                        onChange={(value) =>
                          formik.setFieldValue(
                            "StartTime",
                            value && dayjs(value).isValid()
                              ? value.toISOString()
                              : null
                          )
                        }
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              formik.touched.StartTime &&
                              Boolean(formik.errors.StartTime)
                            }
                            helperText={
                              formik.touched.StartTime &&
                              formik.errors.StartTime
                            }
                          />
                        )}
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "8px",
                          },
                          "& .MuiInputBase-input": {
                            color: "#063164",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#063164",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#063164",
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        label="End Time"
                        {...formik.getFieldProps("EndTime")}
                        value={
                          formik.values.EndTime &&
                          dayjs(formik?.values?.EndTime).isValid()
                            ? dayjs(formik?.values?.EndTime)
                            : null
                        }
                        onChange={(value) =>
                          formik.setFieldValue(
                            "EndTime",
                            value && dayjs(value).isValid()
                              ? value.toISOString()
                              : null
                          )
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.EndTime &&
                          Boolean(formik.errors.EndTime)
                        }
                        helperText={
                          formik.touched.EndTime && formik.errors.EndTime
                        }
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "8px",
                          },
                          "& .MuiInputBase-input": {
                            color: "#063164",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#063164",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#063164",
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="checkbox-group my-2">
                <input
                  type="checkbox"
                  id="any-time"
                  name="any-time"
                  checked={isAnyTime}
                  onChange={(e) => setIsAnyTime(e.target.checked)}
                />
                <label htmlFor="any-time">Any Time</label>
              </div>
            </div>

            <div className="user-assignment mx-5">
              <h2>Visit Schedule</h2>
              <p>No users are currently assigned</p>
              <div className="col-12 ">
                {/* <Team isAddTeam={false} setAssignPersonId /> */}
                <Card className="" style={{ height: "140px" }}>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "none",
                      background: "none",
                    }}
                    className="team-header"
                  >
                    <h3 className="text-blue-color" style={{ fontWeight: 600 }}>
                      Team
                    </h3>
                    <div className="assignbtn">
                      <Button
                        className="bg-blue-color"
                        outline
                        style={{
                          padding: "0 14px 0 14px",
                          fontSize: "12px",
                          marginTop: 0,
                          height: "32px",
                          color: "#fff",
                        }}
                        onClick={toggleDropdown}
                      >
                        + Assign
                      </Button>
                      {isDropdownOpen && (
                        <div
                          className="assigndrop"
                          style={{
                            position: "absolute",
                            backgroundColor: "#fff",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            borderRadius: "4px",
                            marginTop: "10px",
                            padding: "10px",
                            zIndex: 1000,
                            width: "250px",
                            right: 0,
                          }}
                        >
                          <Card>
                            <CardHeader
                              className="text-white-color bg-blue-color"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Select team
                              <CloseIcon
                                onClick={toggleDropdown}
                                style={{ cursor: "pointer" }}
                              />
                            </CardHeader>
                            <CardBody>
                              <div onClick={handleOutsideClick}>
                                {teamData && teamData.length > 0 ? (
                                  teamData.map((person) => (
                                    <FormGroup
                                      check
                                      className="my-3 mb-0"
                                      key={person._id}
                                    >
                                      <Input
                                        type="checkbox"
                                        checked={
                                          checkedState &&
                                          !!checkedState[person?.FullName]
                                        }
                                        onChange={(e) =>
                                          handleTeamSelect(e, person)
                                        }
                                      />
                                      <Label
                                        style={{
                                          fontSize: "16px",
                                          color: "rgba(6,49,100,0.7)",
                                          fontWeight: "400",
                                          marginBottom: 0,
                                        }}
                                      >
                                        {person.FullName}
                                      </Label>
                                    </FormGroup>
                                  ))
                                ) : (
                                  <p>No team members found.</p>
                                )}
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      )}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div style={{ marginTop: "-10px", height: "18px" }}>
                      {selectedTeams?.map((team, index) => (
                        <div
                          key={index}
                          className="tag"
                          style={{ marginTop: "0px", marginLeft: "10px" }}
                        >
                          <span
                            className="tag-text"
                            style={{ fontSize: "16px" }}
                          >
                            {team}
                          </span>
                          <button
                            className="tag-close"
                            onClick={() => handleRemoveTeam(team)}
                          >
                            ×
                          </button>
                        </div>
                      ))}
                    </div>

                    <FormGroup check className="my-3 mb-0">
                      {/* <Input type="checkbox" />{" "}
              <Label
                check
                style={{
                  fontSize: "12px",
                  color: "rgba(6,49,100,0.7)",
                  fontWeight: "400",
                  marginTop: "10px",
                }}
              >
                Email team about assignment
              </Label> */}
                      {/* <div className="checkbox-group my-5">
                <Input type="checkbox" id="any-time" name="any-time" />
                <label for="any-time">Email team about assignment</label>
              </div> */}
                    </FormGroup>
                    {/* {showEmailField && (
              <FormGroup>
                <InputText
                  name="Email"
                  label="Email"
                  type="text"
                  className="text-blue-color w-100 mb-3"
                  fieldHeight="56px"
                  onChange={handleChange}
                  value={formData.Email}
                />
                <Label htmlFor="email" className="mb-3">
                  An email is required to log in to Jobber
                </Label>
              </FormGroup>
            )} */}
                  </CardBody>
                </Card>
              </div>

              {/* <div className="checkbox-group my-5">
                <Input type="checkbox" id="any-time" name="any-time" />
                <label for="any-time">Any Time</label>
              </div> */}
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          className="cancelButton"
          style={{
            backgroundColor: "#fff",
            border: "1px solid rgba(6, 49, 100, 0.8)",
            color: "rgba(6, 49, 100, 1)",
          }}
          // onClick={() => setOpen({ isOpen: false, propertyData: null })}
          onClick={() => {
            formik.resetForm();
            setVisitId("");
            setSelectedTeams([]);
            setCheckedState("");
            setAssignPersonId(null);
            setOpen({ isOpen: false, propertyData: null });
          }}
        >
          Cancel
        </Button>
        <Button
          className="bg-button-blue-color createButton"
          style={{ color: "#fff" }}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Save Visits
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default Visit;
