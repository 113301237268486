// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.setting-sidebar-main {
  height: 100vh;
  margin-top: -30px;
  position: fixed;
  width: 100%;
  transition: margin-top 0.3s ease-in-out;
}

.scrolled .setting-sidebar-main {
  margin-top: 0;
}

.sidebar-link-setting{
    color: #4F963B !important;
    border-bottom: none;
    text-decoration: none !important;
}
/* 1680 */
@media (max-width: 1700px) {
}

/* 1500 */
@media (max-width: 1680px) {
}

/* 1440 */
@media (max-width: 1449px) {
}

/* 1280 */
@media (max-width: 1439px) {
}

/* 992 */
@media (max-width: 1279px) {
}
/* 992 */
@media (max-width: 1228px) {
 
}

/* 768 */
@media (max-width: 991px) {
  .hiren{
    display: none !important;
  }
}

/* 576 */
@media (max-width: 767px) {
   
}

/* 525 */
@media (max-width: 575px) {
}

/* 480 */
@media (max-width: 524px) {
}

/* 425 */
@media (max-width: 479px) {
}

/* 375 */
@media (max-width: 424px) {
}

/* 320 */
@media (max-width: 374px) {
  
}

/* 280 */
@media (max-width: 319px) {
}
`, "",{"version":3,"sources":["webpack://./src/components/Setting/style.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,uCAAuC;AACzC;;AAEA;EACE,aAAa;AACf;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gCAAgC;AACpC;AACA,SAAS;AACT;AACA;;AAEA,SAAS;AACT;AACA;;AAEA,SAAS;AACT;AACA;;AAEA,SAAS;AACT;AACA;;AAEA,QAAQ;AACR;AACA;AACA,QAAQ;AACR;;AAEA;;AAEA,QAAQ;AACR;EACE;IACE,wBAAwB;EAC1B;AACF;;AAEA,QAAQ;AACR;;AAEA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;;AAEA;;AAEA,QAAQ;AACR;AACA","sourcesContent":["\n.setting-sidebar-main {\n  height: 100vh;\n  margin-top: -30px;\n  position: fixed;\n  width: 100%;\n  transition: margin-top 0.3s ease-in-out;\n}\n\n.scrolled .setting-sidebar-main {\n  margin-top: 0;\n}\n\n.sidebar-link-setting{\n    color: #4F963B !important;\n    border-bottom: none;\n    text-decoration: none !important;\n}\n/* 1680 */\n@media (max-width: 1700px) {\n}\n\n/* 1500 */\n@media (max-width: 1680px) {\n}\n\n/* 1440 */\n@media (max-width: 1449px) {\n}\n\n/* 1280 */\n@media (max-width: 1439px) {\n}\n\n/* 992 */\n@media (max-width: 1279px) {\n}\n/* 992 */\n@media (max-width: 1228px) {\n \n}\n\n/* 768 */\n@media (max-width: 991px) {\n  .hiren{\n    display: none !important;\n  }\n}\n\n/* 576 */\n@media (max-width: 767px) {\n   \n}\n\n/* 525 */\n@media (max-width: 575px) {\n}\n\n/* 480 */\n@media (max-width: 524px) {\n}\n\n/* 425 */\n@media (max-width: 479px) {\n}\n\n/* 375 */\n@media (max-width: 424px) {\n}\n\n/* 320 */\n@media (max-width: 374px) {\n  \n}\n\n/* 280 */\n@media (max-width: 319px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
