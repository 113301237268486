// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import { Provider } from "react-redux";
// import store from "./store";
// import "bootstrap/dist/css/bootstrap.min.css";
// import ColorPicker from "./ColorPicker";
// import Layout from "./Layout";

// const LayoutTable = () => {
//   return (
//     <React.StrictMode>
//       <Provider store={store}>
//         <Layout />
//       </Provider>
//     </React.StrictMode>
//   );
// };

// export default LayoutTable;
