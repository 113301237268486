import React from "react";
import { postFile } from "../../../../components/Files/Functions";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
} from "@mui/material";
import { Form, Formik } from "formik";
import InputDropdown from "../../../../components/InputFields/InputDropdown";
import InputText from "../../../../components/InputFields/InputText";
import { SingleFileUpload } from "../../../../components/Files";
import { DisplayImage } from "../../../../components/Files/DisplayFiles";
import AxiosInstance from "../../../AxiosInstance";
import toast from "react-hot-toast";
import * as Yup from "yup";

const AddItems = ({
  modelOpen,
  setModelOpen,
  setSelectedProductAndService,
  selectedProductAndService,
  productsAndService,
  setShowUnitsSection,
  setShowHoursSection,
  setSelectedUnitsAndHours,
  showHoursSection,
  showUnitsSection,
  selectedProduct,
  selectedUnitsAndHours,
  UnitsAndHours,
  selectedAdminId,
  CompanyId,
  getData,
  showSquaresSection,
  setShowSquaresSection,
  inputValue,
  handleSelectChange,
  index,
  setNew = false,
}) => {
  return (
    <Dialog fullWidth open={modelOpen} onClose={() => setModelOpen(false)}>
      <DialogTitle>{"Products & Service Form"}</DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={{
            Type: selectedProduct ? selectedProduct.Type : "",
            Name: selectedProduct
              ? selectedProduct.Name
              : inputValue
              ? inputValue
              : "",
            Description: selectedProduct ? selectedProduct.Description : "",
            Unit: selectedProduct ? selectedProduct.Unit : "",
            CostPerUnit: selectedProduct ? selectedProduct.CostPerUnit : "",
            Hour: selectedProduct ? selectedProduct.Hour : "",
            CostPerHour: selectedProduct ? selectedProduct.CostPerUnit : "",
            Attachment: selectedProduct ? selectedProduct.Attachment : "",
          }}
          validationSchema={Yup.object().shape({
            Type: Yup.string().required("Type is required"),
            Name: Yup.string().required("Name is required"),
            Description: Yup.string().required("Description is required"),
            Unit: showUnitsSection && Yup.number().required("Unit is required"),
            CostPerUnit:
              showUnitsSection &&
              Yup.number().required("Cost per Unit is required"),
            Hour:
              showHoursSection && Yup.number().required("Hours is required"),
            CostPerHour:
              showHoursSection &&
              Yup.number().required("Cost per Hour is required"),
            Square:
              showSquaresSection && Yup.number().required("Square is required"),
            CostPerSquare:
              showSquaresSection &&
              Yup.number().required("Cost per square foot is required"),
          })}
          onSubmit={async (values, { resetForm }) => {
            try {
              if (values.Attachment) {
                const imageForm = new FormData();
                imageForm.append("files", imageForm);
                const image = await postFile();
                values["Attachment"] = image;
              }
              values["companyId"] = CompanyId || " ";
              const object = {
                Type: values.Type,
                Name: values.Name,
                Description: values.Description,
                Attachment: values.Attachment,
                companyId: CompanyId,
                ...(values.UnitsAndHoursType === "Unit"
                  ? {
                      CostPerUnit: values.CostPerUnit,
                      Unit: values.Unit,
                      CostPerSquare: null,
                      Square: null,
                      CostPerHour: null,
                      Hour: null,
                    }
                  : values.UnitsAndHoursType === "Sq. Ft."
                  ? {
                      CostPerSquare: values.CostPerSquare,
                      Square: values.Square,
                      CostPerHour: null,
                      Hour: null,
                      CostPerUnit: null,
                      Unit: null,
                    }
                  : {
                      CostPerHour: values.CostPerHour,
                      Hour: values.Hour,
                      CostPerUnit: null,
                      Unit: null,
                      CostPerSquare: null,
                      Square: null,
                    }),
              };
              let res;
              if (!selectedAdminId) {
                res = await AxiosInstance.post(`/product`, object);
                if (res.data.statusCode === 200) {
                  if (setNew) {
                    handleSelectChange(index, res.data.data);
                  }
                }
              } else {
                res = await AxiosInstance.put(
                  `/product/product/${selectedAdminId}`,
                  values
                );
              }

              if (res.data.statusCode === 200) {
                setModelOpen(false);
                getData();
                toast.success(res.data.message);
                resetForm(values);
              } else if (res.data.statusCode === 201) {
                getData();
                toast.error(res.data.message);
              } else {
                toast.error(res.data.message || "An unexpected error occurred");
              }
            } catch (error) {
              console.error("Submission error:", error);
              toast.error(
                error.response?.data?.message ||
                  error.message ||
                  "An error occurred"
              );
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <Form>
              <div className="form-wrap">
                <FormControl fullWidth>
                  <InputDropdown
                    options={productsAndService}
                    value={selectedProductAndService || null}
                    onChange={(_, newValue) => {
                      setSelectedProductAndService(newValue);
                      handleChange({
                        target: {
                          name: "Type",
                          value: newValue ? newValue.Type : "",
                        },
                      });
                    }}
                    label="Type"
                    inputValue={values?.Type}
                    onTextFieldChange={handleChange}
                    name="Type"
                    onBlur={handleBlur}
                    type="text"
                    getOptionLabel={(option) => option.Type || ""}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.Type.toLowerCase().includes(
                          state.inputValue.toLowerCase()
                        )
                      );
                    }}
                    error={touched?.Type && Boolean(errors?.Type)}
                    helperText={touched?.Type && errors?.Type}
                  />
                </FormControl>
              </div>
              <div>
                <InputText
                  value={values.Name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="Name"
                  label="Name"
                  type="text"
                  className="text-blue-color w-100 m-0 mb-4"
                  error={touched?.Name && Boolean(errors?.Name)}
                  helperText={touched?.Name && errors?.Name}
                />
              </div>
              <div>
                <FormGroup>
                  <InputText
                    id="exampleText"
                    placeholder="Description"
                    name="Description"
                    label="Description"
                    type="textarea"
                    value={values.Description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="text-blue-color w-100 m-0 mb-4"
                    style={{ marginTop: 0 }}
                    error={touched?.Description && Boolean(errors?.Description)}
                    helperText={touched?.Description && errors?.Description}
                  />
                </FormGroup>
              </div>

              <div>
                <FormControl fullWidth>
                  <InputDropdown
                    inputValue={values?.UnitsAndHoursType}
                    onTextFieldChange={handleChange}
                    options={UnitsAndHours}
                    value={selectedUnitsAndHours || null}
                    onChange={(_, newValue) => {
                      setSelectedUnitsAndHours(newValue);
                      setShowUnitsSection(newValue?.Type === "Unit");
                      setShowHoursSection(newValue?.Type === "Hour");
                      setShowSquaresSection(newValue?.Type === "Sq. Ft.");
                      handleChange({
                        target: {
                          name: "UnitsAndHoursType",
                          value: newValue ? newValue.Type : "",
                        },
                      });
                    }}
                    label="Cost Type"
                    name="UnitsAndHoursType"
                    getOptionLabel={(option) => option.Type || ""}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.Type.toLowerCase().includes(
                          state.inputValue.toLowerCase()
                        )
                      );
                    }}
                    error={
                      touched?.UnitsAndHoursType &&
                      Boolean(errors?.UnitsAndHoursType)
                    }
                    helperText={
                      touched?.UnitsAndHoursType && errors?.UnitsAndHoursType
                    }
                  />
                </FormControl>
              </div>

              {showUnitsSection && (
                <div className="d-flex w-100 gap-2">
                  <InputText
                    value={values.Unit}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="Unit"
                    placeholder="Unit"
                    label="Unit"
                    type="number"
                    className="w-100"
                    error={touched?.Unit && Boolean(errors?.Unit)}
                    helperText={touched?.Unit && errors?.Unit}
                  />
                  <InputText
                    value={values.CostPerUnit}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="CostPerUnit"
                    placeholder="$0.00"
                    label="Cost"
                    type="number"
                    className="w-100"
                    error={touched?.CostPerUnit && Boolean(errors?.CostPerUnit)}
                    helperText={touched?.CostPerUnit && errors?.CostPerUnit}
                  />
                </div>
              )}

              {showHoursSection && (
                <div className="d-flex gap-2 w-100">
                  <InputText
                    value={values.Hour}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="Hour"
                    placeholder="Hour"
                    label="Hour"
                    type="number"
                    className="w-100"
                    error={touched.Hour && Boolean(errors.Hour)}
                    helperText={touched.Hour && errors.Hour}
                  />
                  <InputText
                    value={values.CostPerHour}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="CostPerHour"
                    placeholder="$0.00"
                    label="Cost"
                    type="number"
                    className="w-100"
                    error={touched.CostPerHour && Boolean(errors.CostPerHour)}
                    helperText={touched.CostPerHour && errors.CostPerHour}
                  />
                </div>
              )}
              {showSquaresSection && (
                <div className="d-flex gap-2 w-100">
                  {/* <InputText
                    value={values.Square}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="Square"
                    placeholder="Square"
                    label="Square"
                    type="number"
                    className="w-100"
                    error={touched.Square && Boolean(errors.Square)}
                    helperText={touched.Square && errors.Square}
                  /> */}
                  <InputText
                    value={values.CostPerSquare}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="CostPerSquare"
                    placeholder="$0.00"
                    label="Cost"
                    type="number"
                    className="w-100"
                    error={
                      touched.CostPerSquare && Boolean(errors.CostPerSquare)
                    }
                    helperText={touched.CostPerSquare && errors.CostPerSquare}
                  />
                </div>
              )}
              <SingleFileUpload
                file={values.Attachment}
                setFile={(value) => setFieldValue("Attachment", value)}
              />
              <DisplayImage
                files={!values.Attachment ? [] : [values.Attachment]}
                setFiles={(value) => setFieldValue("Attachment", value[0])}
                IsDeleted={true}
              />
              <div className="d-flex gap-2 justify-content-end">
                <Button
                  className="mt-3"
                  onClick={() => setModelOpen(false)}
                  style={{ color: "#063164", border: "1px solid #063164" }}
                >
                  Cancel
                </Button>
                <Button
                  className="mt-3"
                  type="submit"
                  style={{ backgroundColor: "#063164", color: "white" }}
                >
                  {selectedProduct ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddItems;
