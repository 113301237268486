import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { Circles, RotatingLines } from "react-loader-spinner";
import * as Yup from "yup";
import axios from "axios";
import AxiosInstance from "../../../AxiosInstance.js";
import IconButton from "@mui/material/IconButton";
import Previous from "../../../../assets/image/icons/Previous.png";
import toast, { Toaster } from "react-hot-toast";
import Address from "../../../../components/Address/index";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  Card,
  Dropdown,
  DropdownItem,
  Navbar,
  DropdownMenu,
  DropdownToggle,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SettingSidebar from "../../../../components/Setting/SettingSidebar.js";
import { Divider } from "@mui/material";
import { Country } from "country-state-city";
import MenuIcon from "@mui/icons-material/Menu";
import Permissions from "../../../../components/Permissions/index.js";
import profileIcon from "../../../../assets/image/icons/file.logo.svg";
import InputText from "../../../../components/InputFields/InputText.js";

const AddUser = () => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const cdnUrl = process.env.REACT_APP_CDN_API;

  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();

  const [selectedRole, setSelectedRole] = useState(undefined);
  const [loader, setLoader] = useState(true);
  const getData = async () => {
    try {
      if (location.state.id) {
        const response = await AxiosInstance.get(
          `${baseUrl}/worker/get/${location.state.id}`
        );
        formik.setValues(response.data.data);
        const data = response.data.data.permissions;
        setSelectedRole(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const countriesData = Country.getAllCountries();
    const uniqueCountries = countriesData.map((country) => country?.name);
    setCountries(uniqueCountries);
  }, []);

  const [isUploading, setIsUploading] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(profileIcon);
  // const uploadImage = async (file) => {
  //   setIsUploading(true);
  //   try {
  //     if (file) {
  //       const formData = new FormData();
  //       formData.append("files", file);
  //       const url = `${cdnUrl}/upload`;

  //       const result = await axios.post(url, formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });
  //       setIsUploading(false);
  //       const image = result.data.files[0].filename;
  //       setProfilePhoto(image);
  //     }
  //   } catch (error) {
  //     console.error(error, "imgs");
  //   }
  // };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     uploadImage(file);
  //   }
  // };

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const toggle = () => setIsOpenDropDown(!isOpenDropDown);

  const [showPassword, setShowPassword] = useState(false);
  const formik = useFormik({
    initialValues: {
      companyId: "",
      FullName: "",
      City: "",
      State: "",
      Zip: "",
      Country: "",
      PhoneNumber: "",
      EmailAddress: "",
      Address: "",
      LaborCost: "",
      Password: "",
      Title: "",
      Description: "",
    },
    validationSchema: Yup.object({
      FullName: Yup.string().required("Required"),
      EmailAddress: Yup.string()
        .email("Invalid email")
        .required("Email is required")
        .matches(/^[^@]+@[^@]+\.[^@]+$/, "Email must contain '@' and '.'"),
      PhoneNumber: Yup.string().required("Required"),
      Address: Yup.string().required("Required"),
      Country: Yup.string().required("Required"),
      State: Yup.string().required("Required"),
      City: Yup.string().required("Required"),
      Zip: Yup.string().required("Required"),
      LaborCost: Yup.string().required("Required"),
      Password: Yup.string().required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const object = {
          ...values,
          companyId: localStorage.getItem("CompanyId"),
          WorkerPermission: selectedRole,
          ProfileImage: profilePhoto,
        };

        let response;
        if (location.state.id) {
          response = await AxiosInstance.put(
            `${baseUrl}/worker/${location.state.id}`,
            object
          );
        } else {
          response = await AxiosInstance.post(`${baseUrl}/worker`, object);
        }
        if (response.data.statusCode === 200) {
          toast.success(response.data.message);
          navigate(-1);
        } else if (response.data.statusCode === 202) {
          toast.error(response.data.message);
        } else {
          toast.error("", response.data.message, "error");
        }
      } catch (error) {
        toast.error("", error.message, "error");
        console.error("There was an error submitting the form!", error);
      }
    },
  });

  const [selectedCountry, setSelectedCountry] = useState(null);
  useEffect(() => {
    setCountries(Country.getAllCountries());
    if (formik.values.Country) {
      setSelectedCountry(() => {
        const country = Country.getAllCountries().find(
          (item) => item.name === formik.values.Country
        );
        return country;
      });
    }
  }, [formik]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "Country") {
      formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(name, type === "checkbox" ? checked : value);
    }
  };

  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "90vh" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            visible={loader}
          />
        </div>
      ) : (
        <div className="manage-team" style={{ display: "flex" }}>
          <div className="col-2 h-100 hiren">
            <SettingSidebar />
          </div>
          {/* <Divider
            className="divider-manageteam"
            orientation="vertical"
            flexItem
            style={{
              marginLeft: "-30px",
              marginRight: "2%",
              marginTop: "-30px",
              borderRight: "2px solid #063164",
              height: "auto",
              width: "10px",
            }}
          /> */}
          <Navbar
            className="navbar-setting"
            style={{
              zIndex: "9",
              borderRadius: "5px",
              display: "block",
            }}
          >
            <Dropdown
              className="dropdown menus"
              isOpen={isOpenDropDown}
              toggle={toggle}
              style={{ width: "100%" }}
            >
              <DropdownToggle
                style={{
                  background: "#E88C44",
                  border: "none",
                  color: "#FFFF",
                }}
              >
                <IconButton>
                  <MenuIcon style={{ color: "#FFFF" }} />
                </IconButton>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <h6 style={{ fontWeight: 600, marginBottom: "10px" }}>
                    BUSINESS <br /> MANAGEMENT
                  </h6>
                </DropdownItem>
                <DropdownItem>
                  <div className="d-flex flex-column">
                    <div
                      className="sidebar-link-setting"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/${companyName}/product&service`, {
                          state: { navigats: ["/index", "/product&service"] },
                        });
                      }}
                    >
                      Products & Services
                    </div>
                    <div
                      className="sidebar-link-setting"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/${companyName}/profile`, {
                          state: { navigats: ["/index", "/profile"] },
                        });
                      }}
                    >
                      Manage Team
                    </div>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Navbar>

          <div className="col-10">
            <Button
              style={{
                backgroundColor: "#063164",
                color: "#fff",
                marginRight: "10px",
                width: "30px",
                height: "35px",
                marginBottom: "10px",
                padding: "0px 0px",
                borderRadius: "4px",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={Previous} style={{ width: "20px", height: "20px" }} />
            </Button>
            <Card
              style={{
                padding: "40px",
                marginTop: "10px",
                borderRadius: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div className="row">
                <div className="">
                  <div className=" " style={{}}>
                    <p
                      className="text-blue-color person-info"
                      style={{
                        fontSize: "30px",
                        fontWeight: "600",
                      }}
                    >
                      Personal Information
                    </p>
                  </div>

                  <div className="d-flex">
                    <div
                      className="text-center"
                      style={{ marginTop: "0px", marginBottom: "30px" }}
                    >
                      <label htmlFor="upload-button">
                        <input
                          type="file"
                          id="upload-button"
                          style={{ display: "none" }}
                          // onChange={handleFileChange}
                        />
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            backgroundColor: "#063164",
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            boxShadow: "0 4px 5px rgba(0, 0, 0, 0.4)",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            document.getElementById("upload-button").click()
                          }
                        >
                          {isUploading ? (
                            <div
                              style={{
                                // borderRadius: "50%",
                                width: "58%",
                                height: "58%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <RotatingLines
                                strokeColor="white"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="40"
                                visible={true}
                              />
                            </div>
                          ) : (
                            <img
                              src={profilePhoto}
                              alt="Profile"
                              style={{
                                // borderRadius: "50%",
                                width: "58%",
                                height: "58%",
                              }}
                            />
                          )}
                        </div>
                      </label>
                    </div>

                    <div>
                      <label htmlFor="upload-button">
                        <Button
                          component="span"
                          className="btn-upload"
                          style={{
                            color: "#063164",
                            background: "#FFFF",
                            textTransform: "none",
                            marginTop: "5px",
                            marginBottom: "15px",
                            fontSize: "12px",
                            cursor: "pointer",
                            padding: "8px 16px",
                            borderRadius: "4px",
                          }}
                        >
                          Upload image here...
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="responsive-container gap-3">
                  <div className="w-50 sub-div">
                    <InputText
                      value={formik.values?.FullName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.FullName &&
                        Boolean(formik.errors.FullName)
                      }
                      helperText={
                        formik.touched.FullName && formik.errors.FullName
                      }
                      name="FullName"
                      label="FullName"
                      type="text"
                      className="text-blue-color w-100 m-0 mb-4"
                      fieldHeight="56px"
                    />
                    <InputText
                      value={formik.values?.EmailAddress}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.EmailAddress &&
                        Boolean(formik.errors.EmailAddress)
                      }
                      helperText={
                        formik.touched.EmailAddress &&
                        formik.errors.EmailAddress
                      }
                      name="EmailAddress"
                      label="EmailAddress"
                      type="text"
                      className="text-blue-color w-100 m-0 mb-4"
                      fieldHeight="56px"
                    />
                    <InputText
                      value={formik.values?.PhoneNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.PhoneNumber &&
                        Boolean(formik.errors.PhoneNumber)
                      }
                      helperText={
                        formik.touched.PhoneNumber && formik.errors.PhoneNumber
                      }
                      name="PhoneNumber"
                      label="PhoneNumber"
                      type="text"
                      className="text-blue-color w-100 m-0 mb-3 phoneBtn"
                      fieldHeight="56px"
                    />
                  </div>
                  <div className="w-50 sub-div labor-top">
                    <Address
                      setSelectedCountry={setSelectedCountry}
                      selectedCountry={selectedCountry}
                      countries={countries}
                      handleChange={handleChange}
                      formik={formik}
                    />
                  </div>
                </div>

                <div className="d-flex gap-3 responsive-container ">
                  <div className="w-50 sub-div">
                    <p
                      className="text-blue-color labor labor-top"
                      style={{ fontWeight: 700, fontSize: "18px" }}
                    >
                      Labor Cost
                    </p>
                    <InputText
                      value={formik.values?.LaborCost}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.LaborCost &&
                        Boolean(formik.errors.LaborCost)
                      }
                      helperText={
                        formik.touched.LaborCost && formik.errors.LaborCost
                      }
                      name="LaborCost"
                      label="LaborCost"
                      type="text"
                      placeholder="$0.00"
                      className="text-blue-color w-100 m-0 mb-3 "
                      fieldHeight="56px"
                      endAdornment={
                        <InputAdornment position="end">
                          <span style={{ color: "#063164", fontSize: "12px" }}>
                            per hour
                          </span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <div className="w-50 sub-div pass-word">
                    <p
                      className="text-blue-color labor"
                      style={{ fontWeight: 700, fontSize: "18px" }}
                    >
                      Password
                    </p>
                    <InputText
                      value={formik.values?.Password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.Password &&
                        Boolean(formik.errors.Password)
                      }
                      helperText={
                        formik.touched.Password && formik.errors.Password
                      }
                      name="Password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      className="text-blue-color w-100 m-0 mb-3"
                      fieldHeight="56px"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </div>
                </div>
              </div>
            </Card>

            {/* Permission */}
            <Permissions data={selectedRole} setData={setSelectedRole} />

            {/* Communications */}
            <Card
              style={{
                padding: "40px",
                marginTop: "10px",
                borderRadius: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <h3 style={{ fontWeight: 700 }} className="text-blue-color">
                Communications
              </h3>
              <p
                className="text-blue-color"
                style={{
                  fontSize: "18px",
                  marginBottom: 0,
                  fontWeight: 600,
                }}
              >
                Email Subscriptions
              </p>
              <div className="d-flex  gap-2">
                <Input
                  type="checkbox"
                  className="border-blue-color"
                  style={{
                    height: "15px",
                    width: "15px",
                    marginTop: "7px",
                    cursor: "pointer",
                  }}
                  defaultChecked={true}
                />
                <Label check className="ml-2">
                  <span
                    className="text-blue-color"
                    style={{ fontSize: "14px" }}
                  >
                    Surveys
                  </span>{" "}
                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(6, 49, 100, 50%)",
                    }}
                  >
                    Occasionally participate in surveys to provide feedback on
                    our performance
                  </p>
                </Label>
              </div>
              <p
                className="text-blue-color mb-2"
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                Invitation Language
              </p>
              <p style={{ fontSize: "10px", color: "rgba(6, 49, 100, 50%)" }}>
                The selected language for the invitation is fixed and cannot be
                altered once it has been sent.
              </p>
              <FormGroup check>
                <Input
                  type="radio"
                  className="border-blue-color"
                  onChange={() => {}}
                  style={{
                    cursor: "pointer",
                  }}
                  name="invitationLanguage"
                  value="English"
                  checked
                />
                <Label check className="text-blue-color">
                  <span style={{ fontSize: "14px" }}>English</span>
                </Label>
              </FormGroup>

              <div className="d-flex gap-2">
                <Input
                  type="radio"
                  className="border-blue-color"
                  style={{
                    height: "15px",
                    width: "15px",
                    marginTop: "7px",
                    cursor: "pointer",
                  }}
                  onChange={() => {}}
                  name="invitationLanguage"
                  value="Spanish"
                />
                <Label check className="ml-2">
                  <span
                    className="text-blue-color"
                    style={{ fontSize: "14px" }}
                  >
                    Spanish
                  </span>{" "}
                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(6, 49, 100, 50%)",
                    }}
                  >
                    The mobile app is accessible in Spanish exclusively for
                    non-administrative users who have their phone language
                    configured to Spanish.
                  </p>
                </Label>
              </div>
            </Card>

            <div className="mb-3 my-5 d-flex align-items-center gap-2 justify-content-end">
              <Button
                className="border-blue-color text-blue-color outline-button-blue-color"
                style={{
                  fontSize: "14px",
                  border: "1px solid",
                  color: "#063164",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <div className="bg-button-blue-color">
                <Button
                  onClick={formik.handleSubmit}
                  style={{
                    fontSize: "16px",
                    textTransform: "none",
                    color: "white",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                >
                  Save Worker
                </Button>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      )}
      <Toaster />
    </>
  );
};

export default AddUser;
