import { CardContent, FormGroup, TextField } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Table,
} from "reactstrap";
import InputText from "../../../../components/InputFields/InputText";
import GetProductsForContract from "../../../../components/Products/GetProductsForContract";
import { InternalNotes } from "../../../../components/Contract Component/Index";
import InvoiceMail from "../InvoiceMail";
import { Toaster } from "react-hot-toast";
import invoice from "../../../../assets/White-sidebar-icon/Invoice.svg";
import GetProducts from "../../../../components/Products/GetProducts";
import { Circles } from "react-loader-spinner";

const AddInvoice = ({
  invoiceData,
  subTotal,
  formik,
  isNumChange,
  loading,
  setIsNumChange,
  handleInvoiceNumberChange,
  issueDate,
  setIssueDate,
  setDueDate,
  dueDate,
  paymentDue,
  handlePaymentDueChange,
  lineItems,
  handleSelectChange,
  setLineItems,
  menuIsOpen,
  setMenuIsOpen,
  deleteLineItem,
  showCosts,
  setShowCosts,
  addLineItem,
  showDiscount,
  setShowDiscount,
  discountAmount,
  showTax,
  setShowTax,
  taxAmount,
  Total,
  dropdownOpen,
  toggle,
  mail,
  setMail,
  setIsCollect,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <Card
        className="my-2 col-12 border-rgba-blue"
        style={{ borderRadius: "20px" }}
      >
        <CardHeader
          className="invoice-header"
          style={{
            background: "transparent",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              borderRadius: "50%",
              backgroundColor: "rgba(6, 49, 100, 1)",
              padding: "0px 14px 2px 13px",
            }}
          >
            <img src={invoice} alt="Invoice Icon" />
          </span>{" "}
          <div className="invoice-name">
            &nbsp; Invoice for {invoiceData?.customer?.FirstName}{" "}
            {invoiceData?.customer?.LastName}
          </div>
        </CardHeader>
        <div className="first-invoice text-blue-color">
          <Card
            style={{
              marginBottom: "15px",
              border: "none",
            }}
          >
            <CardContent>
              <div
                className="d-flex invoice-first "
                style={{ borderBottom: "1px solid rgba(6, 49, 100, 30%)" }}
              >
                <div className="col-lg-6 px-4 my-5">
                  <p className="mb-3 head">Invoice Subject</p>
                  <InputText
                    value={formik.values?.Subject}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.Subject && Boolean(formik.errors.Subject)
                    }
                    helperText={formik.touched.Subject && formik.errors.Subject}
                    name="Subject"
                    placeholder="For Service Rendered..."
                    label="Subject"
                    type="text"
                    className="text-blue-color w-100 mb-3 subject-invoice"
                    fieldHeight="56px"
                  />
                  <div className="row mt-3">
                    <div className="col">
                      <h6 className="titleAdress"> Billing Address</h6>
                      <p className="subAddress">
                        {invoiceData?.location?.Address}{" "}
                        {invoiceData?.location?.City}
                        {invoiceData?.location?.State}{" "}
                        {invoiceData?.location?.Country}
                        {invoiceData?.location?.Zip}
                      </p>
                    </div>
                    <div className="col">
                      <h6 className="titleAdress">Service Address</h6>
                      <p className="subAddress">(Same as billing address)</p>
                    </div>
                    <div className="col">
                      <h6 className="titleAdress">Contact Details</h6>
                      <p className="subAddress">
                        {invoiceData?.customer?.PhoneNumber}
                        <br />
                        <a href="mailto:abc@gmail.com">
                          {invoiceData?.customer?.EmailAddress}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-2 p-0"
                  style={{
                    height: "auto",
                    background: "rgba(6, 49, 100, 30%)",
                    width: "1px",
                  }}
                ></div>
                <div className="col-lg-6 px-4 second-section-invoice">
                  <h4
                    style={{ fontSize: "10px", fontWeight: 400 }}
                    className="mx-5"
                  >
                    <div className="row">
                      <div className="justify-content-end d-flex my-3">
                        <div className="">
                          {!isNumChange ? (
                            <p
                              style={{ fontSize: "16px", fontWeight: 600 }}
                              className="text-blue-color"
                            >
                              Invoice number #{formik.values.InvoiceNumber}
                            </p>
                          ) : (
                            <InputText
                              value={formik.values.InvoiceNumber}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="InvoiceNumber"
                              id="InvoiceNumber"
                              label="Invoice Number"
                              type="number"
                              className="text-blue-color w-100"
                              fieldHeight="46px"
                            />
                          )}
                        </div>
                        <div className="col-2  text-center  changeText">
                          {!isNumChange ? (
                            <u
                              style={{
                                cursor: "pointer",
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                              onClick={() => setIsNumChange(true)}
                            >
                              Change
                            </u>
                          ) : (
                            <Button
                              onClick={handleInvoiceNumberChange}
                              className="buttons outline-button-blue-color outline selectclientaddquote bg-blue-color"
                            >
                              Done
                            </Button>
                          )}
                        </div>
                      </div>

                      <div className="col-left">
                        <p className="mb-3 invoice-head head">
                          Invoice details
                        </p>

                        <div className="form-group invoice-form-group">
                          <label htmlFor="issuedDate head">Issued Date</label>
                          <div className="invoice-date">
                            <div className="mx-5" style={{ width: "60%" }}>
                              <Input
                                type="date"
                                className="text-blue-color p-3"
                                value={issueDate}
                                onChange={(e) => setIssueDate(e.target.value)}
                                name="IssueDate"
                                style={{
                                  height: "20px",
                                  fontSize: "14px",
                                  justifyContent: "between",
                                  display: "flex",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group invoice-form-group mt-2">
                          <label htmlFor="dueDate head">Due Date</label>
                          <div
                            className="d-flex gap-1 invoice-date"
                            style={{ marginLeft: "20px" }}
                          >
                            <div className="mx-5" style={{ width: "59%" }}>
                              <Input
                                type="date"
                                className="text-blue-color p-3"
                                value={dueDate}
                                onChange={(e) => setDueDate(e.target.value)}
                                name="DueDate"
                                style={{
                                  height: "20px",
                                  fontSize: "14px",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="paymentDue"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Payment Due
                          </label>
                          <div
                            className="Payment my-2 d-flex gap-2 mx-3"
                            style={{ marginLeft: "40px" }}
                          >    
                            <FormGroup
                              style={{ width: "160px", marginLeft: "22px" }}
                            >
                              <Input
                                style={{ height: "20px", fontSize: "12px" }}
                                id="paymentDueSelect"
                                name="PaymentDue"
                                type="select"
                                className="text-blue-color"
                                value={paymentDue}
                                onChange={handlePaymentDueChange}
                              >
                                <option value="Upon Receipt">
                                  Upon Receipt
                                </option>
                                <option value="15 Days">15 Days</option>
                                <option value="30 Days">30 Days</option>
                                <option value="45 Days">45 Days</option>
                              </Input>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </h4>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card
            className="p-3"
            style={{
              paddingRight: "30px",
              border: "none",
              marginBottom: "15px",
            }}
          >
            {lineItems.map((item, index) => (
              <React.Fragment key={index}>
                <GetProducts
                  item={item}
                  index={index}
                  handleSelectChange={handleSelectChange}
                  lineItems={lineItems}
                  setLineItems={setLineItems}
                  showCosts={showCosts}
                  setShowCosts={setShowCosts}
                  menuIsOpen={menuIsOpen}
                  setMenuIsOpen={setMenuIsOpen}
                  deleteLineItem={deleteLineItem}
                  // isError={isError}
                  // setIsError={setIsError}
                  // productRef={productRef}
                />
              </React.Fragment>
            ))}

            <div
              className="d-flex justify-content-between align-items-center mb-0 pb-0 newlineitem"
              style={{ marginTop: "20px", background: "none", border: "none" }}
            >
              <Button
                className="bg-button-blue-color addnewline mx-0 text-white-color "
                outline
                style={{
                  fontSize: "12px",
                  marginTop: 0,
                  height: "32px",
                  fontWeight: "400",
                }}
                onClick={addLineItem}
              >
                New Line Item
              </Button>
              <div>
                <div className="d-flex align-items-center mb-0 gap-3 line-items-total ">
                  <p
                    style={{ fontWeight: 600 }}
                    className="mb-0 text-blue-color"
                  >
                    Total price
                  </p>
                  <p
                    className="mb-0 text-blue-color"
                    style={{ fontWeight: 600 }}
                  >
                    ${subTotal}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex my-4 client-message">
              <div className="col-5 order-sm-1 messageinput">
                <FormGroup>
                  <TextField
                    value={formik.values.Message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name={`Message`}
                    id="client_message"
                    placeholder="Enter Client Message here..."
                    label="Client Message"
                    type="text"
                    className="text-blue-color mx-0 w-100"
                    multiline
                    rows={4}
                  />
                </FormGroup>
                <FormGroup rmGroup>
                  <Input
                    id="ContractDisclaimer"
                    name="ContractDisclaimer"
                    placeholder="Contract/ Disclaimer"
                    type="textarea"
                    className="text-blue-color contractDis mt-3"
                    style={{
                      fontSize: "14px",
                      paddingLeft: "15px",
                      height: "96px",
                    }}
                    value={formik.values.ContractDisclaimer}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormGroup>
              </div>
              <div className="col-7 order-sm-2 totalinput">
                <Table>
                  <tbody>
                    <tr>
                      <td
                        className="text-blue-color  "
                        style={{ height: "50px" }}
                      >
                        Subtotal
                      </td>
                      <td className="text-end text-blue-color">
                        ${subTotal?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="text-blue-color"
                        style={{ height: "50px" }}
                      >
                        Discount
                      </td>
                      <td
                        className={`d-flex ${
                          !showDiscount
                            ? "justify-content-end"
                            : "justify-content-between"
                        }`}
                      >
                        {showDiscount && (
                          <span
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <Input
                              id="Discount"
                              name="Discount"
                              placeholder="0.00"
                              className="text-blue-color"
                              style={{
                                fontSize: "14px",
                                paddingLeft: "15px",
                              }}
                              value={formik.values.Discount}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                              }}
                              className="text-blue-color"
                            >
                              %
                            </span>
                          </span>
                        )}
                        <u
                          className="text-blue-color"
                          style={{
                            fontWeight: 600,
                            cursor: "pointer",
                            textAlign: "end",
                            marginBottom: "9px",
                          }}
                          onClick={() => setShowDiscount(!showDiscount)}
                        >
                          {discountAmount > 0
                            ? `$${discountAmount?.toFixed(2)}`
                            : "Add Discount"}
                        </u>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="text-blue-color"
                        style={{ height: "50px" }}
                      >
                        Tax
                      </td>
                      <td
                        className={`d-flex ${
                          !showTax
                            ? "justify-content-end"
                            : "justify-content-between"
                        }`}
                      >
                        {showTax && (
                          <span
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <Input
                              id="Tax"
                              name="Tax"
                              placeholder="0.00"
                              className="text-blue-color"
                              style={{
                                fontSize: "14px",
                                paddingLeft: "15px",
                              }}
                              value={formik.values.Tax}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <span
                              className="text-blue-color"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                              }}
                            >
                              %
                            </span>
                          </span>
                        )}
                        <u
                          className="text-blue-color"
                          style={{
                            fontWeight: 600,
                            cursor: "pointer",
                            marginBottom: "9px",
                          }}
                          onClick={() => setShowTax(!showTax)}
                        >
                          {taxAmount > 0
                            ? `$${taxAmount?.toFixed(2)}`
                            : "Add Tax"}
                        </u>
                      </td>
                    </tr>
                    <tr className="last-row border-0">
                      <td className="border-0 text-blue-color">
                        <b>Total</b>
                      </td>
                      <td className="text-end border-0 text-blue-color">
                        <b>${Total}</b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Card>
          <div className="p-3">
            <InternalNotes
              Total={0}
              notes={formik.values.Notes}
              setNotes={(value) => formik.setFieldValue("Notes", value)}
              attachments={formik.values.Attachment}
              setAttachments={(value) =>
                formik.setFieldValue("Attachment", value)
              }
              // noBorder={false}
            />
          </div>

          <div
            className="d-flex justify-content-between button-responsive p-4"
            style={{ marginTop: "80px", gap: "10px" }}
          >
            <div>
              <Button
                outline
                className="footer-buttons"
                style={{ fontSize: "16px" }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
            {loading ? (
            //5206 code start : "Loader"
            <div className="d-flex justify-content-center text-blue-color ">
              <Circles
                height="20"
                width="20"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                visible={loading}
                />
            </div>
              //5206 code start : "Loader" 
            ) : (
            <div className="d-flex gap-2">
              <Button
                outline
                className="footer-buttons"
                style={{ fontSize: "16px" }}
                onClick={formik.handleSubmit}
              >
                {location.state?.invoiceId ? " Update Invoice" : "Save Invoice"}
              </Button>
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle className="bg-blue-color" caret>
                  Save &{" "}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="text-blue-color"
                    onClick={() => setMail(true)}
                  >
                    Send As Email
                  </DropdownItem>
                  <DropdownItem
                    className="text-blue-color"
                    onClick={() => {
                      setIsCollect(true);
                      formik.handleSubmit();
                    }}
                  >
                    Collect Payment
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
              )}
          </div>
        </div>
      </Card>
      <InvoiceMail
        modal={mail}
        setModal={setMail}
        customerData={invoiceData?.customer}
        Total={Total}
        invoiceData={invoiceData}
        Attachment={formik.values.Attachment}
        handleSubmit={formik.handleSubmit}
        DueDate={dueDate}
      />
      <Toaster />
    </>
  );
};

export default AddInvoice;
