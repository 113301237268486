import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import {
  JobberTable,
  JobberSearch,
  JobberPagination,
} from "../../../../components/MuiTable/index.js";
import "../style.css";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Circles } from "react-loader-spinner";

const Customer = ({
  loader,
  search,
  setSearch,
  cellData,
  page,
  setPage,
  setRowsPerPage,
  companyName,
  countData,
  rowsPerPage,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className="justify-content-center align-items-center mb-3">
        <div className="d-flex justify-content-between mb-2 align-items-center">
          <h3 className="text-blue-color" style={{ fontWeight: 700 }}>
            Customer
          </h3>
          <Button
            style={{ color: "#fff" }}
            onClick={() => {
              navigate(`/${companyName}/add-customer`, {
                state: {
                  navigats: [...location.state.navigats, "/add-customer"],
                },
              });
            }}
            className="text-capitalize bg-button-blue-color"
          >
            Add Customer
          </Button>
        </div>
        <Card
          style={{
            borderRadius: "20px",
            border: "2px solid #063164",
            padding: 0,
          }}
        >
          <CardHeader
            className="d-flex justify-content-between align-items-center table-header bg-blue-color"
            style={{
              borderBottom: "2px solid #063164",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <h5 className="custe text-light">Customer</h5>
            <div className="customer d-flex">
              <JobberSearch
                search={search}
                setSearch={setSearch}
                style={{ background: "transparant", color: "white" }}
              />
            </div>
          </CardHeader>
          {loader ? (
            <div className="d-flex justify-content-center align-items-center my-5 text-blue-color">
              <Circles
                height="50"
                width="50"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loader}
                color="#063164"
              />
            </div>
          ) : (
            <CardBody style={{ padding: "10px 0px" }}>
              <JobberTable
                headerData={[
                  "Sr No.",
                  "Full Name ",
                  "Email",
                  "Address",
                  "Action",
                ]}
                cellData={cellData}
                isCollapse={false}
                page={page}
                isNavigate={true}
                navigatePath={`/${companyName}/customerdetails`}
              />
            </CardBody>
          )}
          <CardFooter
            className="bg-orange-color"
            style={{
              borderTop: "2px solid #063164",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <JobberPagination
              totalData={countData}
              currentData={rowsPerPage}
              dataPerPage={rowsPerPage}
              pageItems={[10, 25, 50]}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </CardFooter>
        </Card>
      </div>
    </>
  );
};

export default Customer;
