import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import swal from "sweetalert";
// import "./style.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth.js";
import moment from "moment";
import AxiosInstance from "../../AxiosInstance.js";
import {
  JobberPagination,
  JobberSearch,
  JobberTable,
} from "../../../components/MuiTable/index.js";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { Circles } from "react-loader-spinner";

const WorkerContract = () => {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customerData, setCustomerData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);
  const [tokenDecode, setTokenDecode] = useState({});
  const fetchData = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getData = async () => {
    if (tokenDecode?.companyId) {
      try {
        const res = await AxiosInstance(`/contract/${tokenDecode?.companyId}`, {
          params: {
            pageSize: rowsPerPage,
            pageNumber: page,
            search: search || "",
          },
        });
        setCustomerData(res.data.data.contracts);
        setCountData(res.data.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search, tokenDecode]);

  const handleEditClick = (id) => {
    if (id) {
      navigate("/staff-member/add-contract", {
        state: {
          id: id,
          navigats: [...location.state.navigats, "/add-contract"],
        },
      });
    }
  };

  // Delete
  const handleDelete = (id) => {
    swal("Are you sure you want to delete?", {
      buttons: ["No", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await AxiosInstance.delete(`/contract/${id}`);
          if (response.data.statusCode === 200) {
            setTimeout(() => {
              toast.success(response.data.message);
            }, 500);
            getData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error(error);
        }
      }
    });
  };

  const cellData = customerData?.map((item, index) => {
    const properties = item?.properties || [];
    let propertyDisplay;
    if (properties.length === 1) {
      const property = properties[0];
      propertyDisplay = `${property?.street_address1 || ""}
       ${property?.street_address2 || ""}
       ${property?.street_address3 || ""} ${property?.city || ""} 
       ${property?.state || ""} 
      ${property?.country || ""} 
      ${property?.zip_code || ""}`;
    } else {
      propertyDisplay = `${properties?.length} ${
        properties?.length > 1 ? "Properties" : "Property"
      }`;
    }

    return {
      key: item.ContractId,
      value: [
        page * rowsPerPage + index + 1,
        `${item?.customer?.FirstName} ${item?.customer?.LastName}`,
        <>
          #{item?.ContractNumber}
          <br />
          {item?.Title}
        </>,
        <div
          dangerouslySetInnerHTML={{
            __html: `${item?.location?.Address || ""} ${
              item?.location?.City || ""
            }<br>${item?.location?.State || ""} ${
              item?.location?.Country || ""
            } ${item?.location?.Zip || ""}`,
          }}
        />,
        // moment(item.OneoffJob.StartDate).format("DD/MM/YYYY"),
        item.RecuringJob?.StartDate
          ? moment(item.RecuringJob.StartDate).format("DD/MM/YYYY")
          : item.OneoffJob?.StartDate
          ? moment(item.OneoffJob.StartDate).format("DD/MM/YYYY")
          : "-",

        <b
          style={{
            color:
              item.Status === "Unscheduled"
                ? "#E88C44"
                : item.Status === "Today"
                ? "#089F57"
                : item.Status === "Upcoming"
                ? "#089F57"
                : item.Status === "Scheduled"
                ? "#C8CC00"
                : "",
          }}
        >
          {item?.Status}
        </b>,
        `${item?.Total}`,
        <>
          <img
            src={Edit}
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(item.ContractId);
            }}
          />
          <img
            className="mx-1"
            src={Delete}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item.ContractId);
            }}
          />
        </>,
      ],
    };
  });

  return (
    <>
      <div className="justify-content-center align-items-center mb-3">
        <div className="d-flex justify-content-between mb-2 align-items-center">
          <h3 className="text-blue-color" style={{ fontWeight: 700 }}>
            Contract
          </h3>
          <Button
            style={{ color: "#fff" }}
            onClick={() => {
              navigate(`/staff-member/add-contract`, {
                state: {
                  navigats: [...location.state.navigats, "/add-contract"],
                },
              });
            }}
            className="text-capitalize bg-button-blue-color"
          >
            Add Contract
          </Button>
        </div>
        <Card
          style={{
            borderRadius: "20px",
            border: "2px solid #063164",
            padding: 0,
          }}
        >
          <CardHeader
            className="d-flex justify-content-between align-items-center table-header bg-blue-color"
            style={{
              borderBottom: "2px solid #063164",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <h5 className="contrac text-light">All Contracts</h5>
            <div className="contractsearch d-flex">
              <JobberSearch
                search={search}
                setSearch={setSearch}
                style={{ background: "transparant", color: "white" }}
              />
            </div>
          </CardHeader>
          {loader ? (
            <div className="d-flex justify-content-center align-items-center my-5">
              <Circles
                height="50"
                width="50"
                color="#063164"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loader}
              />
            </div>
          ) : (
            <CardBody style={{ padding: "10px 0px" }}>
              <JobberTable
                headerData={[
                  "Sr No.",
                  "Customer Name",
                  "Contract number",
                  "Property",
                  "Schedule",
                  "Status",
                  "Total",
                  "Action",
                ]}
                cellData={cellData}
                isCollapse={false}
                page={page}
                isNavigate={true}
                navigatePath={`/staff-member/worker-contract-details`}
              />
            </CardBody>
          )}
          <CardFooter
            className="bg-orange-color"
            style={{
              borderTop: "2px solid #063164",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <JobberPagination
              totalData={countData}
              currentData={rowsPerPage}
              dataPerPage={rowsPerPage}
              pageItems={[10, 25, 50]}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </CardFooter>
        </Card>
      </div>
      <Toaster />
    </>
  );
};

export default WorkerContract;
