import AxiosInstance from "./axios";

// Get tokenization key to generate CollectJS script
export const getTokenizationKey = async (Company_Id) => {
  try {
    const response = await AxiosInstance.get(`nmi/nmi_public_key/${Company_Id}`);
    return response.data;
  } catch (error) {
    if (typeof error === "string") throw error;
    else if (Object.keys(error).length) throw JSON.stringify(error);
    else throw "Network problem";
  }
};

// add card details by tenant
export const addCardDetails = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      `nmi/add-customer-card`,
      payload
    );

    return response.data;
  } catch (error) {
    if (typeof error === "string") throw error;
    else if (Object.keys(error).length) throw JSON.stringify(error);
    else throw "Network problem";
  }
};
