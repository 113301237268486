import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
} from "reactstrap";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import AxiosInstance from "../../AxiosInstance";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import "../style.css";
import { Circles } from "react-loader-spinner";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Swal from "sweetalert2"; 

function Quotes() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [quotesData, setQuotesData] = useState([]);
  const [tokenDecode, setTokenDecode] = useState({});
  const [isConfirmed, setIsConfirmed] = useState(null); 

  const fetchData = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const CompanyId = tokenDecode.CompanyId;
  useEffect(() => {
    handleAuth(navigate, location);

    if (CompanyId && location.state.id) {
      setLoader(true); 
      AxiosInstance.get(
        `/visits/customervisits/${location.state.id}/${location.state.contractId}`
      )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setQuotesData(response.data.data);
            const apiConfirmStatus = response.data.data[0]?.IsConfirm;
            if (apiConfirmStatus !== undefined) {
              setIsConfirmed(apiConfirmStatus); 
            } else {
              const savedStatus = localStorage.getItem("isConfirmed");
              setIsConfirmed(savedStatus === "true");
            }
          } else {
            console.error("Error fetching data");
          }
        })
        .catch((error) => {
          console.error("API error:", error);
        })
        .finally(() => {
          setLoader(false); 
        });
    } else {
      console.error("CompanyId or Visit ID is not valid.");
      setLoader(false);
    }
  }, [navigate, location, CompanyId]);

  const handleConfirmAppointment = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to confirm this appointment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, confirm it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await AxiosInstance.put(
            `/visits/confirm/${location.state.id}/${location.state.contractId}`
          );
          setIsConfirmed(true); 
          localStorage.setItem("isConfirmed", "true");
          Swal.fire(
            "Confirmed!",
            "The appointment has been confirmed.",
            "success"
          );
        } catch (error) {
          console.error("Error confirming appointment:", error);
          Swal.fire(
            "Error!",
            "Something went wrong. Please try again.",
            "error"
          );
        }
      }
    });
  };

  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center my-5 text-blue-color"
          style={{ height: "100vh" }}
        >
          <Circles
            height="50"
            width="50"
            ariaLabel="circles-loading"
            visible={loader}
            color="#063164"
          />
        </div>
      ) : (
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Card className="invoice-card col-6">
            <CardHeader className="">
              <div className="text-end">
                {isConfirmed !== null && (
                  <>
                    {isConfirmed ? (
                      <Button
                        className="Appoiment text-blue-color border-blue-color"
                        style={{
                          border: "2px solid",
                          background: "#DBECFF",
                          borderRadius: "20px",
                          fontWeight: 600,
                        }}
                      >
                        Confirmed
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        className="bg-blue-color text-white-color"
                        onClick={handleConfirmAppointment}
                      >
                        Confirm Appointment
                      </Button>
                    )}
                  </>
                )}
              </div>
            </CardHeader>
            <CardBody>
              <CardText className="invoice-card-text">
                <AccessTimeIcon className="invoice-icon" />
                {quotesData[0]?.StartTime
                  ? moment(quotesData[0].StartTime, "HH:mm").isValid()
                    ? moment(quotesData[0].StartTime, "HH:mm").format("hh:mm A")
                    : "Invalid Time"
                  : "N/A"}
              </CardText>
              <CardText className="invoice-card-text">
                <CalendarMonthOutlinedIcon className="invoice-icon" />{" "}
                <b>Appointment On : </b>&nbsp;
                <b>
                  {moment(quotesData[0]?.StartDate).format("dddd, DD-MM-YYYY")}
                </b>
              </CardText>
              <CardText className="invoice-card-text">
                <MyLocationOutlinedIcon className="invoice-icon" />{" "}
                {quotesData[0]?.Location?.Address}{" "}
                {quotesData[0]?.Location?.City},
                {quotesData[0]?.Location?.State}, <br />{" "}
                {quotesData[0]?.Location?.Zip}-{" "}
                {quotesData[0]?.Location?.Country}
              </CardText>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
}

export default Quotes;
