import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader, CardText } from "reactstrap";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import AxiosInstance from "../../AxiosInstance";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import "./style.css"; // Import the CSS file for styling
import dayjs from "dayjs";
import { Circles } from "react-loader-spinner";

function Invoice() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    handleAuth(navigate, location);
  }, [navigate, location]);

  const [invoices, setInvoices] = useState({
    Paid: [],
    UnPaid: [],
    Pending: [],
  });
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(null);

  const CustomerId = localStorage.getItem("CustomerId");

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await AxiosInstance.get(
          `/invoice/invoice/${CustomerId}`
        );
        if (response.data.statusCode === 200) {
          setInvoices(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoader(false);
      }
    };

    fetchInvoices();
  }, [CustomerId]);

  // Check if there are any invoices across all statuses
  const hasInvoices = Object.values(invoices).some(
    (status) => status.length > 0
  );

  if (!hasInvoices) return <p>Invoice not available</p>;

  const renderInvoices = (status) => {
    const invoiceList = invoices[status];
    return invoiceList.map((invoice) => (
      <Card
        className="invoice-card"
        key={invoice.invoiceId}
        onClick={() =>
          navigate(`/customers/invoice-details`, {
            state: {
              id: invoice.InvoiceId,
              navigats: [...location.state.navigats, "/invoice-details"],
            },
          })
        }
      >
        <CardHeader className="invoice-card-header">
          Invoice #{invoice.InvoiceNumber}
        </CardHeader>
        <CardBody>
          <CardText className="invoice-card-text">
            <CalendarMonthOutlinedIcon className="invoice-icon" /> Sent{" "}
            {dayjs(invoice.createdAt).toString()}
          </CardText>
          <CardText className="invoice-card-text">
            <MyLocationOutlinedIcon className="invoice-icon" />{" "}
            {invoice?.location?.Address} {invoice?.location?.City} <br />
            {invoice?.location?.State}, {invoice.location?.state}
            {invoice?.property?.Country}
          </CardText>
        </CardBody>
        <CardFooter className="invoice-card-footer">
          <b>Balance : ${invoice.balance}</b>
          <b>Total : ${invoice.Total}</b>
        </CardFooter>
      </Card>
    ));
  };

  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center align-items-center my-5 text-blue-color" style={{height:"100vh"}}>
          <Circles
            height="50"
            width="50"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
            color="#063164"
          />
        </div>
      ) : (
        <div>
          <p className="invoice-header">Your invoices</p>
          <div className="invoice-grid">
            {invoices && Object.keys(invoices).length > 0 ? (
              Object.keys(invoices).map(
                (status) =>
                  invoices[status]?.length > 0 && (
                    <div key={status} className="invoice-status-section">
                      <p className="invoice-status-header">{status}</p>
                      <div className="invoice-status-cards">
                        {renderInvoices(status)}
                      </div>
                    </div>
                  )
              )
            ) : (
              <>invoices Not Available</>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Invoice;
