// import React, { useEffect, useState } from "react";
// import { Card, CardBody, CardFooter, CardHeader, CardText } from "reactstrap";
// import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
// import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
// import AxiosInstance from "../../AxiosInstance";
// import moment from "moment";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { handleAuth } from "../../../components/Login/Auth";

// function Contract() {
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     handleAuth(navigate, location);
//   }, []);

//   const baseUrl = process.env.REACT_APP_BASE_API;
//   const [contracts, setcontracts] = useState({
//     "Upcoming ": [],
//     "Today": [],
//     "Scheduled": [],
//     Approved: [],
//   });
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const CustomerId = localStorage.getItem("client_id");

//   useEffect(() => {
//     const fetchcontracts = async () => {
//       try {
//         const response = await AxiosInstance.get(
//           `/contract/contracts/${CustomerId}`
//         );
//         if (response.data.statusCode === 200) {
//           setcontracts(response.data.data);
//         } else {
//           setError(response.data.message);
//         }
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchcontracts();
//   }, []);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   const rendercontracts = (status) => {
//     return contracts[status].map((contract) => (
//       <Card
//         className="my-2"
//         key={contract.ContractId}
//         style={{
//           width: "30rem",
//           border: "1px solid #063164",
//         }}
//         onClick={() =>
//           navigate(`/customers/contract-details`, {
//             state: {
//               id: contract.ContractId,
//               navigats: [...location.state.navigats, "/contract-details"],
//             },
//           })
//         }
//       >
//         <CardHeader
//           className="text-blue-color"
//           style={{
//             fontSize: "23px",
//             fontWeight: 500,
//             backgroundColor: "#F8D5B9",
//             borderBottom: "1px solid #063164",
//           }}
//         >
//           Contract #{contract.ContractNumber}
//         </CardHeader>
//         <CardBody>
//           <CardText
//             className="text-blue-color"
//             style={{
//               display: "flex",
//               alignItems: "center",
//             }}
//           >
//             <CalendarMonthOutlinedIcon style={{ marginRight: "10px" }} /> Sent{" "}
//             {moment(contract.createdAt).format("ll")}
//           </CardText>
//           <CardText
//             className="text-blue-color"
//             style={{
//               display: "flex",
//               alignItems: "center",
//             }}
//           >
//             <MyLocationOutlinedIcon style={{ marginRight: "10px" }} />{" "}
//             {contract?.address?.Address} {contract?.address?.City} <br />
//             {contract?.address?.State}, {contract.address?.state}
//             {contract?.property?.Country}
//           </CardText>
//         </CardBody>
//         <CardFooter
//           style={{
//             display: "flex",
//             justifyContent: "end",
//             borderTop: "1px solid #063164",
//           }}
//         >
//           Total : ${contract.Total}
//         </CardFooter>
//       </Card>
//     ));
//   };

//   return (
//     <div>
//       <p
//         className="text-blue-color"
//         style={{ fontWeight: 700, fontSize: "30px" }}
//       >
//         Your contracts
//       </p>
//       <>
//         <div>
//           {contracts["Today"]?.length > 0 && (
//             <>
//               <p
//                 className="col-2 d-flex justify-content-center text-blue-color"
//                 style={{
//                   border: "1px solid #063164",
//                   borderRadius: "5px",
//                   backgroundColor: "#F8D5B9",
//                 }}
//               >
//                 Today
//               </p>
//               <div>{rendercontracts("Today")}</div>
//             </>
//           )}
//         </div>

//         <div className="mt-4">
//           {contracts["Upcoming"]?.length > 0 && (
//             <>
//               <p
//                 className="col-2 d-flex justify-content-center text-blue-color"
//                 style={{
//                   border: "1px solid #063164",
//                   borderRadius: "5px",
//                   backgroundColor: "#F8D5B9",
//                 }}
//               >
//                 Upcoming
//               </p>
//               <div>{rendercontracts("Upcoming")}</div>
//             </>
//           )}
//         </div>
//         <div className="mt-4">
//           {contracts["Scheduled"]?.length > 0 && (
//             <>
//               <p
//                 className="col-2 d-flex justify-content-center text-blue-color"
//                 style={{
//                   border: "1px solid #063164",
//                   borderRadius: "5px",
//                   backgroundColor: "#F8D5B9",
//                 }}
//               >
//                 Scheduled
//               </p>
//               <div>{rendercontracts("Scheduled")}</div>
//             </>
//           )}
//         </div>

//         <div>
//           {contracts?.Approved?.length > 0 && (
//             <>
//               <p
//                 className="col-2 d-flex justify-content-center text-blue-color"
//                 style={{
//                   border: "1px solid #063164",
//                   borderRadius: "5px",
//                   backgroundColor: "#F8D5B9",
//                 }}
//               >
//                 Approved
//               </p>
//               <div>{rendercontracts("Approved")}</div>
//             </>
//           )}
//         </div>
//       </>
//     </div>
//   );
// }

// export default Contract;

import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader, CardText } from "reactstrap";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import AxiosInstance from "../../AxiosInstance";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import "../style.css";
import { Circles } from "react-loader-spinner";

function Quotes() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    handleAuth(navigate, location);
  }, [navigate, location]);

  const baseUrl = process.env.REACT_APP_BASE_API;
  const [quotes, setquotes] = useState({
    Paid: [],
    UnPaid: [],
    Pending: [],
  });
  const [error, setError] = useState(null);

  const CustomerId = localStorage.getItem("CustomerId");

  useEffect(() => {
    const fetchquotes = async () => {
      try {
        const response = await AxiosInstance.get(
          `/contract/contracts/${CustomerId}`
        );
        if (response.data.statusCode === 200) {
          setquotes(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoader(false);
      }
    };

    fetchquotes();
  }, [CustomerId]);

  const renderquotes = (status) => {
    return quotes[status].map((quote) => (
      <Card
        className="invoice-card"
        key={quote.ContractId}
        onClick={() =>
          navigate(`/customers/contract-details`, {
            state: {
              id: quote.ContractId,
              navigats: [...location.state.navigats, "/contract-details"],
            },
          })
        }
      >
        <CardHeader className="invoice-card-header">
          quote #{quote.ContractNumber}
        </CardHeader>
        <CardBody>
          <CardText className="invoice-card-text">
            <CalendarMonthOutlinedIcon className="invoice-icon" /> Sent{" "}
            {moment(quote.createdAt).format("MM-DD-YYYY")}
          </CardText>
          <CardText className="invoice-card-text">
            <MyLocationOutlinedIcon className="invoice-icon" />{" "}
            {quote?.address?.Address} {quote?.address?.City} <br />
            {quote?.address?.State}, {quote.address?.state}
            {quote?.property?.Country}
          </CardText>
        </CardBody>
        <CardFooter className="invoice-card-footer">
          <b>Total : ${quote.Total}</b>
        </CardFooter>
      </Card>
    ));
  };

  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center align-items-center my-5 text-blue-color" style={{height:"100vh"}}>
          <Circles
            height="50"
            width="50"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
            color="#063164"
          />
        </div>
      ) : (
        <div>
          <p className="invoice-header">Your Contract</p>

          <div className="invoice-grid">
            {quotes && Object.keys(quotes).length > 0 ? (
              Object.keys(quotes).map(
                (status) =>
                  quotes[status]?.length > 0 && (
                    <div key={status} className="invoice-status-section">
                      <p className="invoice-status-header">{status}</p>
                      <div className="invoice-status-cards">
                        {renderquotes(status)}
                      </div>
                    </div>
                  )
              )
            ) : (
              <>Contract Not Available</>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Quotes;
