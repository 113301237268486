import React, { useEffect, useState } from "react";
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import * as Yup from "yup";
import clientdetails from "../../../assets/image/client/Clientdetails.svg";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import truck from "../../../assets/image/icons/truck.svg";
import schedule from "../../../assets/image/icons/schedule.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import { useAuthAdmin } from "../../../components/Login/Auth";
import ClientProperty from "../../Admin/Client/Customer-Property/index";
import toast, { Toaster } from "react-hot-toast";
import { Circles } from "react-loader-spinner";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import { Formik } from "formik";
import Previous from "../../../assets/image/icons/Previous.png";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import swal from "sweetalert";
import moment from "moment";
import AxiosInstance from "../../AxiosInstance";

function WorkerCustomerDetail() {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const location = useLocation();
  const navigate = useNavigate();
  const { companyName } = useParams();
  const [tab, setTab] = useState(1);
  const [data, setdata] = useState();
  const [open, setOpen] = useState({ isOpen: false, propertyData: null });
  const [loader, setLoader] = useState(true);
  const [modelOpen, setModelOpen] = useState(false);

  const [file, setFile] = useState(null);
  const [note, setNote] = useState("");

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const getData = async () => {
    try {
      const res = await AxiosInstance.get(
        `/customer/detail/${location.state.id}`
      );
      setdata(res.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [location.state.id]);

  const fetchQuotes = async () => {
    try {
      const res = await AxiosInstance.get(
        `/customer/detail/${location.state.id}`
      );
      const object = [];
      for (const item of res.data.totalQuotes) {
        const propertyData = data.properties.find((elem) => {
          return elem.LocationId === item.LocationId;
        });
        object.push({ ...item, propertyData });
      }
      setTab(res.data.totalQuotes);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchQuotes();
  }, [data]);

  const handlePropertyDelete = async (id) => {
    swal("Are you sure you want to delete?", {
      buttons: ["No", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const res = await AxiosInstance.delete(
            `${baseUrl}/location/${id}`
          );
          if (res.data.statusCode === 200) {
            setTimeout(() => {
              toast.success(res.data.message);
            }, 500);
            getData();
          } else {
            toast.error("", res.data.message, "error");
          }
        } catch (error) {
          toast.error("", error.message, "error");
          console.error("Error: ", error.message);
        }
      }
    });
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (email) => {
    setLoading(true);
    try {
      let res = await AxiosInstance.post(`${baseUrl}/client/send_mail`, {
        email,
      });

      if (res.data.statusCode === 200) {
        setTimeout(() => {
          toast.success(res.data.message);
        }, 500);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
      setModelOpen(false);
    }
  };
  const [activeTabId, setActiveTabId] = useState(1);

  const handleClick = (tabId) => {
    setActiveTabId(tabId);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const moreActiontoggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%", width: "100%", marginTop: "25%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </div>
      ) : (
        <div className="justify-content-center align-items-center mb-3 mt-5 client">
          <Button
            style={{
              color: "#fff",
              marginRight: "10px",
              width: "50px",
              height: "40px",
              marginBottom: "10px",
              padding: "0px 0px",
              borderRadius: "4px",
              marginTop: "10px",
            }}
            onClick={() => {
              navigate(-1);
            }}
            className="text-capitalize bg-button-blue-color"
          >
            <ArrowBackOutlinedIcon />
          </Button>
          <div
            className="d-flex align-items-center justify-content-between username-action"
            style={{ display: "" }}
          >
            <div>
              <div className="d-flex justify-content-start usrName">
                <div
                  className="username text-blue-color"
                  style={{
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "38px",
                  }}
                >
                  <div
                    className="bg-blue-color"
                    style={{
                      borderRadius: "50%",
                      marginRight: "10px",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <img src={clientdetails} />
                  </div>
                  {data?.FirstName ? data?.FirstName : "-"}&nbsp;
                  {data?.LastName ? data?.LastName : "-"}
                </div>
              </div>
            </div>
            <div>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={moreActiontoggle}
                style={{ zIndex: 0 }}
              >
                <DropdownToggle
                  className="text-blue-color outline"
                  style={{
                    background: "none",
                    border: "1px solid #063164",
                  }}
                >
                  {" "}
                  <MoreHorizIcon /> More Actions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="dropdown-items"
                    style={{ fontSize: "16px" }}
                  >
                    <RequestQuoteOutlinedIcon
                      className="icones-dropdown"
                      style={{ fontSize: "18px" }}
                    />
                    Quote
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-items"
                    style={{ fontSize: "16px" }}
                  >
                    <WorkOutlineOutlinedIcon
                      className="icones-dropdown"
                      style={{ fontSize: "18px" }}
                    />
                    Job
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-items"
                    style={{ fontSize: "16px" }}
                  >
                    <FileCopyOutlinedIcon
                      className="icones-dropdown"
                      style={{ fontSize: "18px" }}
                    />
                    Invoice
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-items"
                    style={{ fontSize: "16px" }}
                    onClick={() => {
                      setModelOpen(true);
                    }}
                  >
                    <MarkEmailReadOutlinedIcon
                      className="icones-dropdown"
                      style={{ fontSize: "18px" }}
                    />
                    Send a mail
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div>
            <div
              className="my-2 detail-card "
              style={{ border: "none", gap: "12px" }}
            >
              <div className=" col-8 first-card">
                <div
                  className="address"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    border: "0.5px solid rgba(6, 49, 100, 80%)",
                  }}
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center p-2"
                    style={{ border: "none" }}
                  >
                    <h5 style={{ fontWeight: 600 }} className="text-blue-color">
                      Properties
                    </h5>
                    {companyName && (
                      <Button
                        className="bg-button-blue-color company-detail-btn"
                        style={{
                          padding: "3px 10px 3px 10px",
                          fontSize: "14px",
                        }}
                        onClick={() =>
                          setOpen({ isOpen: true, propertyData: null })
                        }
                      >
                        + New Property
                      </Button>
                    )}
                  </CardHeader>
                  <CardBody style={{ padding: "10px 0px" }}>
                    <Table borderless>
                      <TableHead>
                        <tr>
                          <th style={{ paddingLeft: "20px" }}>Address</th>
                          <th className="text-center">City</th>
                          <th className="text-center">State</th>
                          <th className="text-center">Country</th>
                          <th className="text-center">Zip</th>
                          {companyName && (
                            <th style={{ textAlign: "center" }}>Actions</th>
                          )}
                        </tr>
                      </TableHead>
                      <tbody>
                        {data?.location?.map((property, index) => (
                          <tr
                            style={{ cursor: "pointer", width: "318px" }}
                            key={index}
                            // onClick={() =>
                            //   navigate(`/${companyName}/property-details`, {
                            //     state: {
                            //       id: property.LocationId,
                            //       navigats: [
                            //         ...location.state.navigats,
                            //         "/property-details",
                            //       ],
                            //     },
                            //   })
                            // }
                          >
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "20px",
                              }}
                            >
                              <MyLocationIcon
                                className="text-blue-color"
                                style={{ marginRight: "15px" }}
                              />
                              <span
                                className="text-blue-color"
                                style={{ fontSize: "14px" }}
                              >
                                {property?.Address ? property.Address : "-"}{" "}
                              </span>
                            </td>
                            <td
                              className="text-center text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {property.City ? property.City : "-"}
                            </td>
                            <td
                              className="text-center text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {property.State ? property.State : "-"}
                            </td>
                            <td
                              className="text-center text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {property.Country ? property.Country : "-"}
                            </td>
                            <td
                              className="text-center text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {property.Zip ? property.Zip : "-"}
                            </td>
                            {companyName && (
                              <td
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton>
                                  <img
                                    src={Edit}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setOpen({
                                        isOpen: true,
                                        propertyData: property,
                                      });
                                    }}
                                  />
                                </IconButton>
                                <IconButton>
                                  <img
                                    src={Delete}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handlePropertyDelete(property.LocationId);
                                    }}
                                  />
                                </IconButton>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </div>
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    border: "0.5px solid rgba(6, 49, 100, 30%)",
                  }}
                  className="my-4"
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center p-2"
                    style={{ border: "none" }}
                  >
                    <h5 className="text-blue-color" style={{ fontWeight: 600 }}>
                      Overview
                    </h5>

                    {companyName && (
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="bg-button-blue-color"
                          caret
                          color="dark"
                          style={{
                            padding: "3px 10px 3px 10px",
                            fontSize: "14px",
                            border: "none",
                          }}
                        >
                          + Add
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem className="dropdown-items text-blue-color">
                            <RequestQuoteOutlinedIcon className="icones-dropdown text-blue-color" />
                            Quote
                          </DropdownItem>
                          <DropdownItem className="dropdown-items text-blue-color">
                            <WorkOutlineOutlinedIcon className="icones-dropdown text-blue-color" />
                            Job
                          </DropdownItem>
                          <DropdownItem className="dropdown-items text-blue-color">
                            <FileCopyOutlinedIcon className="icones-dropdown text-blue-color" />
                            Invoice
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </CardHeader>
                  <CardBody style={{ padding: "10px 0px" }}>
                    <div className="text-start">
                      <Nav
                        className="bg-orange-color"
                        tabs
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <NavItem>
                          <NavLink
                            className={activeTabId === 1 ? "active" : ""}
                            onClick={() => handleClick(1)}
                            style={{ cursor: "pointer" }}
                          >
                            Active Work
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 3 ? "active" : ""}
                            onClick={() => handleClick(3)}
                            style={{ cursor: "pointer" }}
                          >
                            Quotes
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 4 ? "active" : ""}
                            onClick={() => handleClick(4)}
                            style={{ cursor: "pointer" }}
                          >
                           Contracts
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 5 ? "active" : ""}
                            onClick={() => handleClick(5)}
                            style={{ cursor: "pointer" }}
                          >
                            Invoices
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={activeTabId}
                        className="text-start"
                      >
                        <TabPane tabId={1}>
                          <Row>
                            <Col sm="12" className="d-flex">
                              <div
                                style={{
                                  backgroundColor: "rgba(6, 49, 100, 30%)",
                                  padding: "15px",
                                  borderRadius: "50%",
                                  height: "50px",
                                  width: "50px",
                                }}
                              >
                                <img src={truck} />
                              </div>
                              <div className="mx-2">
                                <h6
                                  className="mb-0 my-2 text-blue-color"
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Active Work
                                </h6>
                                <p
                                  className="text-blue-color"
                                  style={{ fontSize: "12px" }}
                                >
                                  No active jobs, invoices or quotes for this
                                  client yet
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId={3}>
                          <Row>
                          
                            <Col sm="12" className="d-flex">
                              <div
                                style={{
                                  backgroundColor: "rgba(6, 49, 100, 30%)",
                                  padding: "15px",
                                  borderRadius: "50%",
                                  height: "50px",
                                  width: "50px",
                                }}
                              >
                                <img src={truck} />
                              </div>
                              <div className="mx-2">
                                <h6
                                  className="mb-0 my-2 text-blue-color"
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Quotes
                                </h6>
                                <p
                                  className="text-blue-color"
                                  style={{ fontSize: "12px" }}
                                >
                                  No active quotes for this
                                  client yet
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={4}>
                          <Row>
                            <Col sm="12" className="d-flex">
                              <div
                                style={{
                                  backgroundColor: "rgba(6, 49, 100, 30%)",
                                  padding: "15px",
                                  borderRadius: "50%",
                                  height: "50px",
                                  width: "50px",
                                }}
                              >
                                <img src={truck} />
                              </div>
                              <div className="mx-2">
                                <h6
                                  className="mb-0 my-2 text-blue-color"
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                 Contracts
                                </h6>
                                <p
                                  className="text-blue-color"
                                  style={{ fontSize: "12px" }}
                                >
                                  No active contracts for this
                                  customer yet
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={5}>
                          <Row>
                            <Col sm="12" className="d-flex">
                              <div
                                style={{
                                  backgroundColor: "rgba(6, 49, 100, 30%)",
                                  padding: "15px",
                                  borderRadius: "50%",
                                  height: "50px",
                                  width: "50px",
                                }}
                              >
                                <img src={truck} />
                              </div>
                              <div className="mx-2">
                                <h6
                                  className="mb-0 my-2 text-blue-color"
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Invoices
                                </h6>
                                <p
                                  className="text-blue-color"
                                  style={{ fontSize: "12px" }}
                                >
                                  No active invoices for this
                                  client yet
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </div>
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    border: "0.5px solid rgba(6, 49, 100, 30%)",
                  }}
                  className="my-4"
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center p-2"
                    style={{ border: "none" }}
                  >
                    <h5 className="text-blue-color" style={{ fontWeight: 600 }}>
                      Schedule
                    </h5>

                    {companyName && (
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="bg-blue-color"
                          caret
                          color="dark"
                          style={{
                            padding: "3px 10px 3px 10px",
                            fontSize: "14px",
                            border: "none",
                          }}
                        >
                          + Add
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem className="dropdown-items text-blue-color">
                            <TaskAltIcon className="icones-dropdown text-blue-color" />
                            Task
                          </DropdownItem>
                          <DropdownItem className="dropdown-items text-blue-color">
                            <CalendarMonthOutlinedIcon className="icones-dropdown text-blue-color" />
                            Calendar Event
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </CardHeader>
                  <CardBody style={{ padding: "10px 0px" }}>
                    <Row>
                      <Col
                        sm="12"
                        className="d-flex"
                        style={{ paddingLeft: "30px" }}
                      >
                        <div
                          style={{
                            backgroundColor: "rgba(6, 49, 100, 30%)",
                            borderRadius: "50%",
                            height: "50px",
                            width: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img src={schedule} />
                        </div>
                        <div className="mx-2">
                          <h6
                            className="mb-0 my-2 text-blue-color"
                            style={{ fontSize: "14px", fontWeight: 600 }}
                          >
                            No scheduled items
                          </h6>
                          <p
                            className="text-blue-color"
                            style={{ fontSize: "12px", color: "#063164" }}
                          >
                            Nothing is scheduled for this client yet{" "}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </div>
              </div>
              <div className="col-4 first-card">
                <h3 className="text-blue-color">Contact Info.</h3>
                <div className="table-responsive mx-0">
                  <Table style={{ width: "100%", tableLayout: "auto" }}>
                    <TableBody>
                      <TableRow className="companydata">
                      </TableRow>
                      <TableRow className="phoneno">
                        <TableCell
                          className="text-blue-color tabledata-contactinfo"
                          style={{
                            fontWeight: 600,
                            fontSize: "14px",
                            minWidth: "150px", 
                          }}
                        >
                          Phone Number
                        </TableCell>
                        <TableCell
                          className="text-end text-blue-color"
                          style={{ fontSize: "14px" }}
                        >
                          {data?.PhoneNumber ? (
                            <a
                              style={{
                                color: "#063164",
                                textDecoration: "none",
                              }}
                              href={`tel:${data.PhoneNumber}`}
                            >
                              {data.PhoneNumber}
                            </a>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow className="email">
                        <TableCell
                          className="text-blue-color tabledata-contactinfo"
                          style={{
                            fontWeight: 600,
                            fontSize: "14px",
                            minWidth: "150px",
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          className="text-end text-blue-color"
                          style={{ fontSize: "14px" }}
                        >
                          {data?.EmailAddress ? (
                            <a
                              style={{
                                color: "#063164",
                                textDecoration: "none",
                              }}
                              href={`tel:${data.EmailAddress}`}
                            >
                              {data.EmailAddress}
                            </a>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>

                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    border: "0.5px solid rgba(5, 15, 36, 30%)",
                  }}
                  className="my-4"
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center p-2"
                    style={{ border: "none" }}
                  >
                    <h5
                      className="text-blue-color"
                      style={{
                        fontWeight: 600,
                        fontSize: "26px",
                      }}
                    >
                      Billing History
                    </h5>

                    {companyName && (
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="bg-button-blue-color"
                          caret
                          color="dark"
                          style={{
                            padding: "3px 10px 3px 10px",
                            fontSize: "14px",
                            border: "none",
                          }}
                        >
                          + Add
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem className="dropdown-items text-blue-color">
                            Collect Payments
                          </DropdownItem>
                          <DropdownItem className="dropdown-items text-blue-color">
                            Record Deposit
                          </DropdownItem>
                          <DropdownItem className="dropdown-items text-blue-color">
                            Invoice
                          </DropdownItem>
                          <DropdownItem className="dropdown-items text-blue-color">
                            Set Initial Balance
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </CardHeader>
                  <CardBody style={{ padding: "10px 0px" }}>
                    <Row>
                      <Col
                        sm="12"
                        className="d-flex"
                        style={{ paddingLeft: "30px" }}
                      >
                        <div
                          style={{
                            backgroundColor: "rgba(6, 49, 100, 30%)",
                            borderRadius: "50%",
                            height: "50px",
                            width: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img src={schedule} />
                        </div>
                        <div className="mx-2">
                          <h6
                            className="mb-0 my-2 text-blue-color"
                            style={{ fontSize: "14px", fontWeight: 600 }}
                          >
                            No billing history
                          </h6>
                          <p
                            className="text-blue-color"
                            style={{ fontSize: "12px" }}
                          >
                            This client hasn’t been billed yet.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter
                    className="d-flex justify-content-between bg-orange-color text-white-color"
                    style={{
                      borderTop: "1px solid #063164",
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      alignItems: "center",
                      padding: "12px 13px 0",
                    }}
                  >
                    <p>Current Balance</p>
                    <p>$ 00</p>
                  </CardFooter>
                </div>
                <p className="text-blue-color" style={{ fontWeight: 600 }}>
                  Internal notes and attachments <HelpOutlineOutlinedIcon />
                </p>
                <div
                  className=""
                  style={{
                    border: "1px solid #063164",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <textarea
                    className="note-details text-blue-color border-blue-color"
                    placeholder="Note details"
                    value={note}
                    onChange={handleNoteChange}
                    style={{
                      border: "0.5px solid rgba(6, 49, 100, 80%)",
                      padding: "15px",
                      borderRadius: "10px",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  ></textarea>
                  <div className="file-upload">
                    <input
                      type="file"
                      id="file-input"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="file-input"
                      className="text-blue-color"
                      style={{
                        cursor: "pointer",
                        display: "block",
                        padding: "13px",
                        borderRadius: "10px",
                        textAlign: "center",
                      }}
                    >
                      Drag your files here or <span>Select a File</span>
                    </label>
                    {file && <p>Selected File: {file.name}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ClientProperty
        open={open}
        setOpen={setOpen}
        data={data}
        getData={getData}
      />
      <Dialog fullWidth open={modelOpen} onClose={() => setModelOpen(false)}>
        <DialogTitle>{"Please Confirm"}</DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              item: "",
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              item: Yup.string().required("Required"),
              product_name: Yup.string().required("Required"),
              description: Yup.string().required("Required"),
              cost: Yup.number().required("Required"),
              markup: Yup.number().required("Required"),
              unit_price: Yup.number().required("Required"),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <Form>
                <div>
                  <p>
                    This will send a Client Hub login email to
                    <br /> <b>{data?.email}</b> Do you want to continue?
                  </p>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      className="text-blue-color border-blue-color nogoback"
                      style={{
                        padding: "3px 10px 3px 10px",
                        fontSize: "14px",
                        background: "none",
                      }}
                      onClick={() => setModelOpen(false)}
                    >
                      No, Go Back
                    </Button>
                    <Button
                      className="bg-blue-color"
                      style={{
                        padding: "3px 10px 3px 10px",
                        fontSize: "14px",
                        marginLeft: "15px",
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Yes, Send Mail"
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Toaster />
    </>
  );
}

export default WorkerCustomerDetail;
