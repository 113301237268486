import React from "react";
import { Circles } from "react-loader-spinner";
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import clientdetails from "../../../../assets/White-sidebar-icon/Customer.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import Edit from "../../../../assets/image/icons/edit.svg";
import Delete from "../../../../assets/image/icons/delete.svg";
import truck from "../../../../assets/image/icons/truck.svg";
import schedule from "../../../../assets/image/icons/schedule.svg";
import InvoiceBlue from "../../../../assets/Blue-sidebar-icon/Invoice.svg";
import PaymentBlue from "../../../../assets/Orange-icons/payment.svg";
import CustomerProperty from "../Customer-Property";
import { Toaster } from "react-hot-toast";

const CustomerDetails = ({
  loader,
  navigate,
  data,
  dropdownOpen,
  moreActiontoggle,
  companyName,
  location,
  setModelOpen,
  activeTabId,
  handleClick,
  quotes,
  invoice,
  contract,
  setOpen,
  handlePropertyDelete,
  handleQuoteNavigate,
  moment,
  handleContractNavigate,
  handleInvoiceNavigate,
  open,
  modelOpen,
  getData,
  loading,
  sendMail,
}) => {
  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%", width: "100%", marginTop: "25%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </div>
      ) : (
        <div className="justify-content-center align-items-center mb-3 mt-5 client">
          <Button
            style={{
              color: "#fff",
              marginRight: "10px",
              width: "50px",
              height: "40px",
              marginBottom: "10px",
              padding: "0px 0px",
              borderRadius: "4px",
              marginTop: "10px",
            }}
            onClick={() => {
              navigate(-1);
            }}
            className="text-capitalize bg-button-blue-color"
          >
            <ArrowBackOutlinedIcon />
          </Button>
          <div
            className="d-flex align-items-center justify-content-between username-action"
            style={{ display: "" }}
          >
            <div>
              <div className="d-flex justify-content-start usrName">
                <div
                  className="username text-blue-color"
                  style={{
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "38px",
                  }}
                >
                  <div
                    className="bg-blue-color"
                    style={{
                      borderRadius: "50%",
                      marginRight: "10px",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <img src={clientdetails} />
                  </div>
                  {data?.FirstName ? data?.FirstName : "-"}&nbsp;
                  {data?.LastName ? data?.LastName : "-"}
                </div>
              </div>
            </div>
            <div>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={moreActiontoggle}
                style={{ zIndex: 0 }}
              >
                <DropdownToggle
                  className="text-blue-color outline"
                  style={{
                    background: "none",
                    border: "1px solid #063164",
                  }}
                >
                  {" "}
                  <MoreHorizIcon /> More Actions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="dropdown-items"
                    style={{ fontSize: "16px" }}
                    onClick={() => {
                      navigate(`/${companyName}/add-quotes`, {
                        state: {
                          Customer: data,
                          CustomerId: data.CustomerId,
                          navigats: [...location.state.navigats, "/add-quotes"],
                        },
                      });
                    }}
                  >
                    <RequestQuoteOutlinedIcon />
                    Quote
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-items"
                    style={{ fontSize: "16px" }}
                    onClick={() => {
                      navigate(`/${companyName}/add-contract`, {
                        state: {
                          Customer: data,
                          CustomerId: data.CustomerId,
                          navigats: [
                            ...location.state.navigats,
                            "/add-contract",
                          ],
                        },
                      });
                    }}
                  >
                    <WorkOutlineOutlinedIcon
                      className="icones-dropdown"
                      style={{ fontSize: "18px" }}
                    />
                    Contract
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-items"
                    style={{ fontSize: "16px" }}
                    onClick={() => {
                      navigate(`/${companyName}/invoicetable`, {
                        state: {
                          CustomerId: data.CustomerId,
                          navigats: [
                            ...location.state.navigats,
                            "/invoicetable",
                          ],
                        },
                      });
                    }}
                  >
                    <FileCopyOutlinedIcon
                      className="icones-dropdown"
                      style={{ fontSize: "18px" }}
                    />
                    Invoice
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-items"
                    style={{ fontSize: "16px" }}
                    onClick={() => {
                      setModelOpen(true);
                    }}
                  >
                    <MarkEmailReadOutlinedIcon
                      className="icones-dropdown"
                      style={{ fontSize: "18px" }}
                    />
                    Send a mail
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div>
            <div
              className="my-2 detail-card "
              style={{ border: "none", gap: "12px" }}
            >
              <div className=" col-8 first-card">
                <div
                  className="address"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    border: "0.5px solid rgba(6, 49, 100, 80%)",
                  }}
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center p-2"
                    style={{ border: "none" }}
                  >
                    <h5 style={{ fontWeight: 600 }} className="text-blue-color">
                      Properties
                    </h5>
                    {companyName && (
                      <Button
                        className="bg-button-blue-color company-detail-btn"
                        style={{
                          padding: "3px 10px 3px 10px",
                          fontSize: "14px",
                        }}
                        onClick={() =>
                          setOpen({ isOpen: true, propertyData: null })
                        }
                      >
                        + New Property
                      </Button>
                    )}
                  </CardHeader>
                  <CardBody
                    className="card-body client-details"
                    style={{ padding: "10px 0px" }}
                  >
                    <Table borderless>
                      <TableHead>
                        <tr>
                          <th style={{ paddingLeft: "20px" }}>Address</th>
                          <th className="text-center">City</th>
                          <th className="text-center">State</th>
                          <th className="text-center">Country</th>
                          <th className="text-center">Zip</th>
                          {companyName && (
                            <th style={{ textAlign: "center" }}>Actions</th>
                          )}
                        </tr>
                      </TableHead>
                      <tbody>
                        {data?.location?.map((property, index) => (
                          <tr
                            style={{ cursor: "pointer", width: "318px" }}
                            key={index}
                            onClick={() =>
                              navigate(`/${companyName}/property-details`, {
                                state: {
                                  id: property.LocationId,
                                  navigats: [
                                    ...location.state.navigats,
                                    "/property-details",
                                  ],
                                },
                              })
                            }
                          >
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "20px",
                              }}
                            >
                              <MyLocationIcon
                                className="text-blue-color"
                                style={{ marginRight: "15px" }}
                              />
                              <span
                                className="text-blue-color"
                                style={{ fontSize: "14px" }}
                              >
                                {property?.Address ? property.Address : "-"}{" "}
                              </span>
                            </td>
                            <td
                              className="text-center text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {property.City ? property.City : "-"}
                            </td>
                            <td
                              className="text-center text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {property.State ? property.State : "-"}
                            </td>
                            <td
                              className="text-center text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {property.Country ? property.Country : "-"}
                            </td>
                            <td
                              className="text-center text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {property.Zip ? property.Zip : "-"}
                            </td>
                            {companyName && (
                              <td
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={Edit}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen({
                                      isOpen: true,
                                      propertyData: property,
                                    });
                                  }}
                                />
                                <img
                                  src={Delete}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handlePropertyDelete(property.LocationId);
                                  }}
                                  className="mx-1"
                                />
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </div>
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    border: "0.5px solid rgba(6, 49, 100, 30%)",
                  }}
                  className="my-4"
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center p-2"
                    style={{ border: "none" }}
                  >
                    <h5 className="text-blue-color" style={{ fontWeight: 600 }}>
                      Overview
                    </h5>

                    {companyName && (
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="bg-button-blue-color"
                          caret
                          color="dark"
                          style={{
                            padding: "3px 10px 3px 10px",
                            fontSize: "14px",
                            border: "none",
                          }}
                        >
                          + Add
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            className="dropdown-items text-blue-color "
                            onClick={() => {
                              navigate(`/${companyName}/add-quotes`, {
                                state: {
                                  Customer: data,
                                  CustomerId: data.CustomerId,
                                  navigats: [
                                    ...location.state.navigats,
                                    "/add-quotes",
                                  ],
                                },
                              });
                            }}
                          >
                            <RequestQuoteOutlinedIcon className="icones-dropdown text-blue-color" />
                            Quote
                          </DropdownItem>
                          <DropdownItem
                            className="dropdown-items text-blue-color"
                            onClick={() => {
                              navigate(`/${companyName}/add-contract`, {
                                state: {
                                  Customer: data,
                                  CustomerId: data.CustomerId,
                                  navigats: [
                                    ...location.state.navigats,
                                    "/add-contract",
                                  ],
                                },
                              });
                            }}
                          >
                            <WorkOutlineOutlinedIcon className="icones-dropdown text-blue-color" />
                            Contract
                          </DropdownItem>
                          <DropdownItem
                            className="dropdown-items text-blue-color"
                            onClick={() => {
                              navigate(`/${companyName}/invoicetable`, {
                                state: {
                                  Customer: data,
                                  CustomerId: data.CustomerId,
                                  navigats: [
                                    ...location.state.navigats,
                                    "/invoicetable",
                                  ],
                                },
                              });
                            }}
                          >
                            <FileCopyOutlinedIcon className="icones-dropdown text-blue-color" />
                            Invoice
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </CardHeader>
                  <CardBody style={{ padding: "10px 0px" }}>
                    <div className="text-start">
                      <Nav
                        className="bg-orange-color"
                        tabs
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <NavItem>
                          <NavLink
                            className={activeTabId === 1 ? "active" : ""}
                            onClick={() => handleClick(1)}
                            style={{ cursor: "pointer" }}
                          >
                            All
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 2 ? "active" : ""}
                            onClick={() => handleClick(2)}
                            style={{ cursor: "pointer" }}
                          >
                            Active Work
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 3 ? "active" : ""}
                            onClick={() => handleClick(3)}
                            style={{ cursor: "pointer" }}
                          >
                            Quotes
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 4 ? "active" : ""}
                            onClick={() => handleClick(4)}
                            style={{ cursor: "pointer" }}
                          >
                            Contract
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 5 ? "active" : ""}
                            onClick={() => handleClick(5)}
                            style={{ cursor: "pointer" }}
                          >
                            Invoices
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={activeTabId}
                        className="text-start"
                      >
                        <TabPane tabId={2}>
                          <Row>
                            <Col sm="12" className="d-flex">
                              <div
                                style={{
                                  backgroundColor: "rgba(6, 49, 100, 30%)",
                                  padding: "15px",
                                  borderRadius: "50%",
                                  height: "50px",
                                  width: "50px",
                                }}
                              >
                                <img src={truck} />
                              </div>
                              <div className="mx-2">
                                <h6
                                  className="mb-0 my-2 text-blue-color"
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Active Work
                                </h6>
                                <p
                                  className="text-blue-color"
                                  style={{ fontSize: "12px" }}
                                >
                                  No active works Customer yet
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={1}>
                          <Row>
                            <Col
                              sm="12"
                              className="d-flex flex-column"
                              // style={{
                              //   gap: "10px",
                              //   maxHeight:
                              //     quotes?.length +
                              //       invoice?.length +
                              //       contract?.length >
                              //     3
                              //       ? "300px"
                              //       : "auto",
                              //   overflowY:
                              //     quotes?.length +
                              //       invoice?.length +
                              //       contract?.length >
                              //     3
                              //       ? "auto"
                              //       : "visible",
                              // }}
                              style={{
                                gap: "10px",
                                maxHeight:
                                  quotes?.length > 3 ? "300px" : "auto",
                                overflowY:
                                  quotes?.length > 3 ? "auto" : "visible",
                              }}
                            >
                              {(quotes && quotes?.length > 0) ||
                              (contract && contract?.length > 0) ||
                              (invoice && invoice.length > 0) ? (
                                <>
                                  {quotes &&
                                    quotes?.length > 0 &&
                                    quotes.map((data, index) => (
                                      <div
                                        key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleQuoteNavigate(data?.QuoteId)
                                        }
                                      >
                                        <div className="container">
                                          <div
                                            className="row align-items-start pt-2"
                                            style={{
                                              border: "1px solid #063164",
                                              fontSize: "12px",
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              borderRadius: "8px",
                                              border:
                                                "0.5px solid rgba(6, 49, 100, 0.8)",
                                              color: "#063164",
                                            }}
                                          >
                                            <div className="col">
                                              <p
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <strong>
                                                  Quote #{data?.QuoteNumber}
                                                </strong>
                                              </p>
                                              <span
                                                style={{
                                                  color:
                                                    data?.status === "Approved"
                                                      ? "rgb(88, 204, 88)"
                                                      : data?.status ===
                                                        "Awaiting Response"
                                                      ? "orange"
                                                      : "#063164",
                                                }}
                                              >
                                                <strong> {data?.status}</strong>
                                              </span>
                                            </div>
                                            <div className="col text-center">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                TITLE
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {data?.Title}
                                              </p>
                                            </div>
                                            <div className="col text-center">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                ADDRESS
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {data?.location?.Address || "-"}
                                                , {data?.location?.City || "-"},{" "}
                                                {data?.location?.State || "-"}
                                              </p>
                                            </div>
                                            <div className="col text-right">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                CREATED ON
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {moment(data.createdAt).format(
                                                  "ll"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  {contract &&
                                    contract?.length > 0 &&
                                    contract.map((data, index) => (
                                      <div
                                        key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleContractNavigate(
                                            data?.ContractId
                                          )
                                        }
                                      >
                                        <div className="container">
                                          <div
                                            className="row align-items-start pt-2"
                                            style={{
                                              border: "1px solid #063164",
                                              fontSize: "12px",
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              borderRadius: "8px",
                                              border:
                                                "0.5px solid rgba(6, 49, 100, 0.8)",
                                              color: "#063164",
                                            }}
                                          >
                                            <div className="col">
                                              <p
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <strong>
                                                  Contract #
                                                  {data?.ContractNumber}
                                                </strong>
                                              </p>
                                              <span
                                                style={{
                                                  color:
                                                    data?.Status ===
                                                    "Unscheduled"
                                                      ? "#E88C44"
                                                      : data?.Status === "Today"
                                                      ? "#089F57"
                                                      : data?.Status ===
                                                        "Upcoming"
                                                      ? "#089F57"
                                                      : data?.Status ===
                                                        "Scheduled"
                                                      ? "#C8CC00"
                                                      : "",
                                                }}
                                              >
                                                <strong>{data?.Status}</strong>
                                              </span>
                                            </div>
                                            <div className="col text-center">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                TITLE
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {data?.Title}
                                              </p>
                                            </div>
                                            <div className="col text-center">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                ADDRESS
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {data?.location?.Address || "-"}
                                                , {data?.location?.City || "-"},{" "}
                                                {data?.location?.State || "-"}
                                              </p>
                                            </div>
                                            <div className="col text-right">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                CREATED ON
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {moment(data.createdAt).format(
                                                  "ll"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  {invoice &&
                                    invoice.length > 0 &&
                                    invoice.map((data, index) => (
                                      <div
                                        key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleInvoiceNavigate(data?.InvoiceId)
                                        }
                                      >
                                        <div className="container">
                                          <div
                                            className="row align-items-start pt-2 "
                                            style={{
                                              border: "1px solid #063164",
                                              fontSize: "12px",
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              borderRadius: "8px",
                                              border:
                                                "0.5px solid rgba(6, 49, 100, 0.8)",
                                              color: "#063164",
                                            }}
                                          >
                                            <div className="col">
                                              <p
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <strong>
                                                  Invoice #{data?.InvoiceNumber}
                                                </strong>
                                              </p>
                                              <span
                                                style={{
                                                  color:
                                                    data?.status === "Approved"
                                                      ? "rgb(88, 204, 88)"
                                                      : data?.status ===
                                                        "Awaiting Response"
                                                      ? "orange"
                                                      : "#063164",
                                                }}
                                              >
                                                <strong> {data?.Status}</strong>
                                              </span>
                                            </div>
                                            <div className="col text-center">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                TITLE
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {data?.Subject}
                                              </p>
                                            </div>
                                            <div className="col text-center">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                ADDRESS
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {data?.location?.Address || "-"}
                                                , {data?.location?.City || "-"},{" "}
                                                {data?.location?.State || "-"}
                                              </p>
                                            </div>
                                            <div className="col text-right">
                                              <p
                                                className="bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                CREATED ON
                                              </p>
                                              <p
                                                className="text-bolt"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                {moment(data.createdAt).format(
                                                  "ll"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              ) : (
                                <Col sm="12" className="d-flex">
                                  <div
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 30%)",
                                      padding: "15px",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                  >
                                    <img src={truck} alt="Truck Icon" />
                                  </div>
                                  <div className="mx-2">
                                    <h6
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      All
                                    </h6>
                                    <p
                                      className="text-blue-color"
                                      style={{ fontSize: "12px" }}
                                    >
                                      No active Work, Quotes, Contracts and
                                      Invoices this Customer yet
                                    </p>
                                  </div>
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Row>
                            <Col
                              sm="12"
                              className="d-flex flex-column"
                              style={{
                                gap: "10px",
                                maxHeight:
                                  quotes?.length > 3 ? "300px" : "auto",
                                overflowY:
                                  quotes?.length > 3 ? "auto" : "visible",
                              }}
                            >
                              {quotes?.length > 0 ? (
                                quotes.map((data, index) => (
                                  <div
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleQuoteNavigate(data?.QuoteId)
                                    }
                                  >
                                    <div className="container">
                                      <div
                                        className="row align-items-start pt-2"
                                        style={{
                                          border: "1px solid #063164",
                                          fontSize: "12px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                          borderRadius: "8px",
                                          border:
                                            "0.5px solid rgba(6, 49, 100, 0.8)",
                                          color: "#063164",
                                        }}
                                      >
                                        <div className="col">
                                          <p style={{ marginBottom: "5px" }}>
                                            <strong>
                                              Quote #{data?.QuoteNumber}
                                            </strong>
                                          </p>
                                          <span
                                            style={{
                                              color:
                                                data?.status === "Approved"
                                                  ? "rgb(88, 204, 88)"
                                                  : data?.status ===
                                                    "Awaiting Response"
                                                  ? "orange"
                                                  : "#063164",
                                            }}
                                          >
                                            <strong> {data?.status}</strong>
                                          </span>
                                        </div>
                                        <div className="col text-center">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            TITLE
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {data?.Title}
                                          </p>
                                        </div>
                                        <div className="col text-center">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            ADDRESS
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {data?.location?.Address || "-"},{" "}
                                            {data?.location?.City || "-"},{" "}
                                            {data?.location?.State || "-"}
                                          </p>
                                        </div>
                                        <div className="col text-right">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            CREATED ON
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {moment(data.createdAt).format(
                                              "ll"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <Col sm="12" className="d-flex">
                                  <div
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 30%)",
                                      padding: "15px",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                  >
                                    <img src={truck} alt="Truck Icon" />
                                  </div>
                                  <div className="mx-2">
                                    <h6
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Quotes
                                    </h6>
                                    <p
                                      className="text-blue-color"
                                      style={{ fontSize: "12px" }}
                                    >
                                      No active quotes for this Customer yet
                                    </p>
                                  </div>
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={4}>
                          <Row>
                            <Col
                              sm="12"
                              className="d-flex flex-column"
                              style={{
                                gap: "10px",
                                maxHeight:
                                  contract?.length > 3 ? "300px" : "auto",
                                overflowY:
                                  contract?.length > 3 ? "auto" : "visible",
                              }}
                            >
                              {contract && contract?.length > 0 ? (
                                contract.map((data, index) => (
                                  <div
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleContractNavigate(data?.ContractId)
                                    }
                                  >
                                    <div className="container">
                                      <div
                                        className="row align-items-start pt-2"
                                        style={{
                                          border: "1px solid #063164",
                                          fontSize: "12px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                          borderRadius: "8px",
                                          border:
                                            "0.5px solid rgba(6, 49, 100, 0.8)",
                                          color: "#063164",
                                        }}
                                      >
                                        <div className="col">
                                          <p style={{ marginBottom: "5px" }}>
                                            <strong>
                                              Contract #{data?.ContractNumber}
                                            </strong>
                                          </p>
                                          <span
                                            style={{
                                              color:
                                                data?.Status === "Unscheduled"
                                                  ? "#E88C44"
                                                  : data?.Status === "Today"
                                                  ? "#089F57"
                                                  : data?.Status === "Upcoming"
                                                  ? "#089F57"
                                                  : data?.Status === "Scheduled"
                                                  ? "#C8CC00"
                                                  : "",
                                            }}
                                          >
                                            <strong>{data?.Status}</strong>
                                          </span>
                                        </div>
                                        <div className="col text-center">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            TITLE
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {data?.Title}
                                          </p>
                                        </div>
                                        <div className="col text-center">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            ADDRESS
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {data?.location?.Address || "-"},{" "}
                                            {data?.location?.City || "-"},{" "}
                                            {data?.location?.State || "-"}
                                          </p>
                                        </div>
                                        <div className="col text-right">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            CREATED ON
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {moment(data.createdAt).format(
                                              "ll"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <Col sm="12" className="d-flex">
                                  <div
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 30%)",
                                      padding: "15px",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                  >
                                    <img src={truck} alt="Truck Icon" />
                                  </div>
                                  <div className="mx-2">
                                    <h6
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Contract
                                    </h6>
                                    <p
                                      className="text-blue-color"
                                      style={{ fontSize: "12px" }}
                                    >
                                      No active jobs for this Customer yet
                                    </p>
                                  </div>
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={5}>
                          <Row>
                            <Col
                              sm="12"
                              className="d-flex flex-column"
                              style={{
                                gap: "10px",
                                maxHeight:
                                  invoice?.length > 3 ? "300px" : "auto",
                                overflowY:
                                  invoice?.length > 3 ? "auto" : "visible",
                              }}
                            >
                              {invoice && invoice.length > 0 ? (
                                invoice.map((data, index) => (
                                  <div
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleInvoiceNavigate(data?.InvoiceId)
                                    }
                                  >
                                    <div className="container">
                                      <div
                                        className="row align-items-start pt-2"
                                        style={{
                                          border: "1px solid #063164",
                                          fontSize: "12px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                          borderRadius: "8px",
                                          border:
                                            "0.5px solid rgba(6, 49, 100, 0.8)",
                                          color: "#063164",
                                        }}
                                      >
                                        <div className="col">
                                          <p style={{ marginBottom: "5px" }}>
                                            <strong>
                                              Invoice #{data?.InvoiceNumber}
                                            </strong>
                                          </p>
                                          <span
                                            style={{
                                              color:
                                                data?.status === "Approved"
                                                  ? "rgb(88, 204, 88)"
                                                  : data?.status ===
                                                    "Awaiting Response"
                                                  ? "orange"
                                                  : "#063164",
                                            }}
                                          >
                                            <strong> {data?.Status}</strong>
                                          </span>
                                        </div>
                                        <div className="col text-center">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            Subject
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {data?.Subject}
                                          </p>
                                        </div>
                                        <div className="col text-center">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            ADDRESS
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {data?.location?.Address || "-"},{" "}
                                            {data?.location?.City || "-"},{" "}
                                            {data?.location?.State || "-"}
                                          </p>
                                        </div>
                                        <div className="col text-right">
                                          <p
                                            className="bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            CREATED ON
                                          </p>
                                          <p
                                            className="text-bolt"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {moment(data.createdAt).format(
                                              "ll"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <Col sm="12" className="d-flex">
                                  <div
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 30%)",
                                      padding: "15px",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                  >
                                    <img src={truck} alt="Truck Icon" />
                                  </div>
                                  <div className="mx-2">
                                    <h6
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Invoice
                                    </h6>
                                    <p
                                      className="text-blue-color"
                                      style={{ fontSize: "12px" }}
                                    >
                                      No active Invoices this Customer yet
                                    </p>
                                  </div>
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </div>
              </div>
              <div className="col-4 first-card">
                <h3 className="text-blue-color">Contact Info.</h3>
                <div className="table-responsive mx-0">
                  <Table style={{ width: "100%", tableLayout: "auto" }}>
                    <TableBody>
                      <TableRow className="companydata"></TableRow>
                      <TableRow className="phoneno">
                        <TableCell
                          className="text-blue-color tabledata-contactinfo"
                          style={{
                            fontWeight: 600,
                            fontSize: "14px",
                            minWidth: "150px",
                          }}
                        >
                          Phone Number
                        </TableCell>
                        <TableCell
                          className="text-end text-blue-color"
                          style={{ fontSize: "14px" }}
                        >
                          {data?.PhoneNumber ? (
                            <a
                              style={{
                                color: "#063164",
                                textDecoration: "none",
                              }}
                              href={`tel:${data.PhoneNumber}`}
                            >
                              {data.PhoneNumber}
                            </a>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow className="email">
                        <TableCell
                          className="text-blue-color tabledata-contactinfo"
                          style={{
                            fontWeight: 600,
                            fontSize: "14px",
                            minWidth: "150px",
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          className="text-end text-blue-color"
                          style={{ fontSize: "14px" }}
                        >
                          {data?.EmailAddress ? (
                            <a
                              style={{
                                color: "#063164",
                                textDecoration: "none",
                              }}
                              href={`tel:${data.EmailAddress}`}
                            >
                              {data.EmailAddress}
                            </a>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>

                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    border: "0.5px solid rgba(5, 15, 36, 30%)",
                  }}
                  className="my-4"
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center p-2"
                    style={{ border: "none" }}
                  >
                    <h5
                      className="text-blue-color"
                      style={{
                        fontWeight: 600,
                        fontSize: "26px",
                      }}
                    >
                      Billing History
                    </h5>

                    {companyName && (
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="bg-button-blue-color"
                          caret
                          color="dark"
                          style={{
                            padding: "3px 10px 3px 10px",
                            fontSize: "14px",
                            border: "none",
                          }}
                        >
                          + Add
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem className="dropdown-items text-blue-color">
                            Collect Payments
                          </DropdownItem>
                          <DropdownItem className="dropdown-items text-blue-color">
                            Record Deposit
                          </DropdownItem>
                          <DropdownItem className="dropdown-items text-blue-color">
                            Invoice
                          </DropdownItem>
                          <DropdownItem className="dropdown-items text-blue-color">
                            Set Initial Balance
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </CardHeader>
                  <CardBody
                    style={{
                      padding: "10px 0px",
                      height: "336px",
                      overflow: "auto",
                    }}
                    className="d-flex flex-column mx-3"
                  >
                    {data?.invoiceData && data.invoiceData?.length > 0 ? (
                      data.invoiceData?.map((item, index) => (
                        <div className="d-flex flex-column">
                          {item?.payments &&
                            item?.payments?.length > 0 &&
                            item?.payments?.map((elem, i) => (
                              <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                  <div
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 10%)",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img src={PaymentBlue} />
                                  </div>
                                  <div className="mx-2">
                                    <h6
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {item?.IssueDate}
                                    </h6>
                                    <p
                                      className="text-blue-color"
                                      style={{ fontSize: "14px" }}
                                    >
                                      Payemnt for invoice #{item?.InvoiceNumber}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex align-items-end">
                                  <p
                                    className="text-blue-color"
                                    style={{ fontSize: "14px" }}
                                  >
                                    -${elem?.amount}
                                  </p>
                                </div>
                              </div>
                            ))}
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <div
                                style={{
                                  backgroundColor: "rgba(6, 49, 100, 10%)",
                                  borderRadius: "50%",
                                  height: "50px",
                                  width: "50px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img src={InvoiceBlue} />
                              </div>
                              <div className="mx-2">
                                <h6
                                  className="mb-0 my-2 text-blue-color"
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {item?.IssueDate}
                                </h6>
                                <p
                                  className="text-blue-color"
                                  style={{ fontSize: "14px" }}
                                >
                                  Invoice #{item?.InvoiceNumber}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end">
                              <p
                                className="text-blue-color"
                                style={{ fontSize: "14px" }}
                              >
                                ${item?.Total}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="d-flex">
                        <div
                          style={{
                            backgroundColor: "rgba(6, 49, 100, 30%)",
                            borderRadius: "50%",
                            height: "50px",
                            width: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img src={schedule} />
                        </div>
                        <div className="mx-2">
                          <h6
                            className="mb-0 my-2 text-blue-color"
                            style={{ fontSize: "14px", fontWeight: 600 }}
                          >
                            No billing history
                          </h6>
                          <p
                            className="text-blue-color"
                            style={{ fontSize: "12px" }}
                          >
                            This Customer hasn’t been billed yet.
                          </p>
                        </div>
                      </div>
                    )}
                  </CardBody>
                  <CardFooter
                    className="d-flex justify-content-between bg-orange-color text-white-color"
                    style={{
                      borderTop: "1px solid #063164",
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      alignItems: "center",
                      padding: "12px 13px 0",
                    }}
                  >
                    <p>Current Balance</p>
                    <p>${data?.currentBalance}</p>
                  </CardFooter>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <CustomerProperty
        open={open}
        setOpen={setOpen}
        data={data}
        getData={getData}
      />
      <Dialog fullWidth open={modelOpen} onClose={() => setModelOpen(false)}>
        <DialogTitle>{"Please Confirm"}</DialogTitle>
        <DialogContent dividers>
          <div>
            <p>
              This will send a Customer Hub login email to
              <b> {data?.EmailAddress}</b> Do you want to continue?
            </p>

            <div style={{ display: "flex", justifyContent: "end" }}>
              {loader ? (
                <div className="d-flex justify-content-center">
                  <Circles
                    height="20"
                    width="20"
                    color="#063164"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    visible={loader}
                  />
                </div>
              ) : (
                <>
                  <Button
                    className="text-blue-color border-blue-color nogoback"
                    style={{
                      padding: "3px 10px",
                      fontSize: "14px",
                      background: "none",
                    }}
                    onClick={() => setModelOpen(false)}
                  >
                    No, Go Back
                  </Button>

                  <Button
                    className="bg-blue-color"
                    style={{
                      padding: "3px 10px",
                      fontSize: "14px",
                      marginLeft: "15px",
                    }}
                    disabled={loading}
                    onClick={sendMail}
                  >
                    Yes, Send Mail
                  </Button>
                </>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Toaster />
    </>
  );
};

export default CustomerDetails;
