// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-sentence {
  overflow: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.text-left {
  text-align: left;
}

@media (min-width: 768px) {
  .text-md-right {
    text-align: right;
  }
}

.accounts input[type="radio"] {
  width: 18px;
  height: 18px;
  accent-color: #063164;
}

.accounts input[type="radio"]:checked {
  background-color: #063164;
  border-color: #063164;
}

.accounts input[type="radio"]:checked::after {
  border: 2px solid #063164;
}
`, "",{"version":3,"sources":["webpack://./src/Views/Admin/Account/style.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".full-sentence {\n  overflow: auto;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  word-break: keep-all;\n}\n\n.text-left {\n  text-align: left;\n}\n\n@media (min-width: 768px) {\n  .text-md-right {\n    text-align: right;\n  }\n}\n\n.accounts input[type=\"radio\"] {\n  width: 18px;\n  height: 18px;\n  accent-color: #063164;\n}\n\n.accounts input[type=\"radio\"]:checked {\n  background-color: #063164;\n  border-color: #063164;\n}\n\n.accounts input[type=\"radio\"]:checked::after {\n  border: 2px solid #063164;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
