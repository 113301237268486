import {
  Button,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./style.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import AxiosInstance from "../../Views/AxiosInstance";
import { useNavigate } from "react-router-dom";
import backimg from "../../assets/image/icons/back.png";
import locke from "../../assets/image/icons/Rectangle 20.png";
import InputText from "../InputFields/InputText";
import AppLogo from "../../assets/image/CMS_LOGO.svg";

const ResetPassword = () => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmpassword: "",
    },
    validate: (values) => {
      const errors = {};
      if (values.password !== values.confirmpassword) {
        errors.confirmpassword = "Passwords does not match";
      }
      return errors;
    },
    onSubmit: (values) => {},
  });

  return (
    <div className="loginnn">
      <Grid container spacing={2} className="vh-100">
        <Grid
          item
          xs={12}
          md={6}
          className="d-flex flex-direction-column align-items-center pt-3"
        >
         
          <div
            style={{
              width: "80%",
              height: "90%",
              paddingTop: "40px",
              marginLeft: "20px",
            }}
            className="d-flex flex-direction-column align-items-center px-3 maon-forget-password"
          >
             <Typography
            className="resetPassword-logo"
            style={{
             marginRight:"160px"
            }}
          >
            <img src={AppLogo} alt="logo"  style={{ width: '250px', height: '60px' }}></img>
          </Typography>
            <form
              style={{ width: "85%", height: "90%", marginTop: "70px" }}
              onSubmit={formik.handleSubmit}
            >
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "21px",
                  textAlign: "left",
                  color: "black",
                  marginTop: "0px",
                  paddingTop: "0px",
                  display: "flex",
                  marginBottom: "15px",
                }}
              >
                <img src={backimg} width={20} height={20} />
                <span className="mx-2">Back to login </span>
              </Typography>
              <Typography
                className="text"
                style={{ fontSize: "35px", margintop: "20px" }}
              >
                Forgot your password?
              </Typography>
              <Typography
                className="text2"
                style={{ color: "#606060", margintop: "20px" }}
              >
                Don't worry, happens to all of us. Enter your email below to
                recover your password
              </Typography>
              <FormGroup style={{ width: "100%", marginTop: "24px" }}>
                <InputText
                  value={formik.values?.Password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.Password && Boolean(formik.errors.Password)
                  }
                  helperText={formik.touched.Password && formik.errors.Password}
                  name="Password"
                  label="New Password"
                  type={showPassword ? "text" : "password"}
                  className="text-blue-color w-100 m-0 "
                  fieldHeight="56px"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormGroup>
              <FormGroup style={{ width: "100%", marginTop: "24px" }}>
                <InputText
                  value={formik.values?.confirmpassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmpassword &&
                    Boolean(formik.errors.confirmpassword)
                  }
                  helperText={
                    formik.touched.confirmpassword &&
                    formik.errors.confirmpassword
                  }
                  name="confirmpassword"
                  label="Re-type New Password"
                  type={showCPassword ? "text" : "password"}
                  className="text-blue-color w-100 m-0 mb-3"
                  fieldHeight="56px"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowCPassword(!showCPassword)}
                        edge="end"
                      >
                        {showCPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormGroup>
              <FormGroup style={{ width: "100%", marginTop: "15px" }}>
                <Button
                  style={{
                    backgroundColor: "rgba(51, 53, 71, 1)",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "21px",
                    borderRadius: "4px",
                    gap: "4px",
                    height: "48px",
                    textTransform: "none",
                    background: "rgb(39 84 110)",
                    // marginTop: "20px",
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              </FormGroup>
            </form>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center img reset-image"
        >
          <img
            src={locke}
            alt="logo"
            style={{ width: "70%", height: "90%", marginTop: "20px" }}
          ></img>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPassword;
