import React, { useEffect, useState } from "react";

import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import axios from "axios";
import AxiosInstance from "../../Views/AxiosInstance";
import moment from "moment";
import "./style.css";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { FormControl } from "react-bootstrap";
import html2canvas from "html2canvas";
import toast, { Toaster } from "react-hot-toast";
import { generateQuoteCustomerPDF } from "../../components/Files/quoteCustomerPDF";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableHead,
} from "@mui/material";
import { Circles } from "react-loader-spinner";
import InputText from "../../components/InputFields/InputText";

function Quotesdetail() {
  const cdnUrl = process.env.REACT_APP_CDN_API;

  const [signatureRef, setSignatureRef] = useState(null);
  const navigate = useNavigate();

  const clearSignature = () => {
    signatureRef?.clear();
  };

  const [typedSignature, setTypedSignature] = useState("");

  // ----------------------------------------------------------------------
  const baseUrl = process.env.REACT_APP_BASE_API;
  const location = useLocation();
  const [approving, setApproving] = useState(false);

  const [modal, setModal] = useState(false);
  const [modall, setModall] = useState(false);

  const togglee = () => setModall(!modall);
  const setTabId = (tabId) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
  };
  const [activeTab, setActiveTab] = useState(1);

  const [quoteDetail, setQuoteDetail] = useState("");
  let fetchData = async () => {
    try {
      const res = await AxiosInstance.get(
        `/quote/quote_details/${location.state.id}`
      );
      setQuoteDetail(res.data.data);
    } catch (error) {
      console.error("Error: ", error.message);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // -------------------------------------------------

  const [savedSignature, setSavedSignature] = useState(null);
  const [signatureSaved, setSignatureSaved] = useState(false);
  const [loader, setLoader] = useState(true);
  const [changeRequestReason, setChangeRequestReason] = useState("");

  const saveSignature = async () => {
    const labelElement = document.getElementById("typeSignatureLabel") || "";
    let image = "";
    let changeRequest = {
      CustomerId: "11062024191201",
      reason: changeRequestReason,
      date: moment().utcOffset(330).format("YYYY-MM-DD HH:mm:ss"),
    };
    if (activeTab === 2 && typedSignature && labelElement) {
      try {
        const canvas = await html2canvas(labelElement);
        const signatureData = canvas.toDataURL();

        const signatureBlob = dataURLtoBlob(signatureData);
        const imageData = new FormData();
        imageData.append("files", signatureBlob, "signature.png");

        const url = `${cdnUrl}/upload`;
        const result = await axios.post(url, imageData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setSavedSignature(signatureData);
        image = result.data.files[0].filename;
      } catch (error) {
        console.error(error, "imgs");
      }
    } else if (activeTab === 1 && signatureRef) {
      try {
        const signatureCanvas = signatureRef;
        const signatureDataURL = signatureCanvas.toDataURL();
        const signatureBlob = await fetch(signatureDataURL).then((res) =>
          res.blob()
        );

        const imageData = new FormData();
        imageData.append("files", signatureBlob, "signature.png");

        const url = `${cdnUrl}/upload`;
        const result = await axios.post(url, imageData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setSavedSignature(signatureDataURL);
        image = result.data.files[0].filename;
      } catch (error) {
        console.error(error, "imgs");
      }
    } else {
    }
    setSignatureSaved(true);

    const quoteApiUrl = `${baseUrl}/quote/${quoteDetail.quote_id}`;
    try {
      setLoader(true);
      const response = await AxiosInstance.put(quoteApiUrl, {
        signature: image || "",
        change_request: changeRequest || "",
      });

      if (response.data.statusCode === 200) {
        fetchData();
        setModal(false);
        setModall(false);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        console.error("Failed to update quote status:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating quote status:", error);
      toast.error(error);
    } finally {
      setLoader(false);
      setModal(false);
      setModall(false);
    }
  };

  function dataURLtoBlob(dataurl) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const toggle = () => setModal(!modal);

  const handleApprove = async () => {
    setApproving(true);
    try {
      const quoteApiUrl = `${baseUrl}/quote/${quoteDetail.QuoteId}`;
      const response = await AxiosInstance.put(quoteApiUrl, {
        status: "Approved",
      });
      if (response.data.statusCode === 200) {
        fetchData();
        setModal(false);
        toast.success("Quote approved successfully!");
      } else {
        console.error("Failed to update quote status:", response.data.message);
        toast.error("Failed to update quote status: " + response.data.message);
      }
    } catch (error) {
      console.error(
        "Error approving quote:",
        error.response?.data || error.message || error
      );
      toast.error(
        "Error approving quote: " +
          (error.response?.data?.message || error.message || "Unknown error")
      );
    } finally {
      setApproving(false);
    }
  };
  const downloadPdf = async () => {
    try {
      const res = await AxiosInstance.post(
        `/quote/quotepdf/${location.state.id}`
      );
      if (res.data.statusCode === 200) {
        const url = `https://app.cloudjobmanager.com/cdn/upload/${res.data.fileName}`;
        fetch(url)
          .then((response) => {
            if (!response.ok) throw new Error("Network response was not ok");
            return response.blob();
          })
          .then((blob) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "quotes_document.pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((error) =>
            console.error(
              "There was a problem with the fetch operation:",
              error
            )
          );
      }
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const [RequestMessage, setRequestMessage] = useState("");
  // const [errors, setErrors] = useState({});

  // const handleChange = (e) => {
  //   setRequestMessage(e.target.value);
  // };

  // const validate = () => {
  //   let tempErrors = {};
  //   if (!RequestMessage) {
  //     tempErrors.RequestMessage = "RequestMessage is required";
  //   }
  //   return tempErrors;
  // };

  // const handleSaveRequest = async () => {
  //   const tempErrors = validate();
  //   if (Object.keys(tempErrors).length === 0) {
  //     try {
  //       const data = {
  //         RequestMessage: RequestMessage,
  //       };
  //       const response = await AxiosInstance.post(
  //         "/requestchange",
  //         data
  //       );
  //       console.log("Request sent successfully:", response.data);
  //       handleClose(); // Close the dialog after successful post
  //     } catch (error) {
  //       console.error("Error posting request:", error);
  //     }
  //   } else {
  //     setErrors(tempErrors); // Set validation errors
  //   }
  // };
  const [RequestMessage, setRequestMessage] = useState("");
  const [errors, setErrors] = useState({});

  // Assuming you have these IDs available in your component
  const CompanyId = `${quoteDetail.CompanyId}`; // Replace with actual source
  const QuoteId = `${quoteDetail.QuoteId}`; // Replace with actual source
  const LocationId = `${quoteDetail.LocationId}`; // Replace with actual source
  const CustomerId = localStorage.getItem("CustomerId"); // Retrieve CustomerId from localStorage

  const handleChange = (e) => {
    setRequestMessage(e.target.value);
  };

  const validate = () => {
    let tempErrors = {};
    if (!RequestMessage) {
      tempErrors.RequestMessage = "RequestMessage is required";
    }
    return tempErrors;
  };

  // const handleSaveRequest = async () => {
  //   const tempErrors = validate();
  //   if (Object.keys(tempErrors).length === 0) {
  //     try {
  //       const data = {
  //         RequestMessage: RequestMessage,
  //         CompanyId: CompanyId, // Add CompanyId
  //         QuoteId: QuoteId, // Add QuoteId
  //         LocationId: LocationId, // Add LocationId
  //         CustomerId: CustomerId, // Add CustomerId from localStorage
  //       };
  //       const response = await AxiosInstance.post("/requestchange", data);
  //       handleClose(); // Close the dialog after successful post
  //     } catch (error) {
  //       console.error("Error posting request:", error);
  //     }
  //   } else {
  //     setErrors(tempErrors); // Set validation errors
  //   }
  // };

  // const handleSaveRequest = async () => {
  //   const tempErrors = validate();
  //   if (Object.keys(tempErrors).length === 0) {
  //     try {
  //       const data = {
  //         RequestMessage: RequestMessage,
  //         CompanyId: CompanyId,
  //         QuoteId: QuoteId,
  //         LocationId: LocationId,
  //         CustomerId: CustomerId,
  //       };
  //       const response = await AxiosInstance.post("/requestchange", data);

  //       if (response.status === 200) {
  //         toast.success("Request sent successfully!");
  //       }

  //       handleClose();
  //     } catch (error) {
  //       console.error("Error posting request:", error);
  //     }
  //   } else {
  //     setErrors(tempErrors);
  //   }
  // };

  const handleSaveRequest = async () => {
    const tempErrors = validate();
    if (Object.keys(tempErrors).length === 0) {
      try {
        const data = {
          RequestMessage: RequestMessage,
          CompanyId: CompanyId,
          QuoteId: QuoteId,
          LocationId: LocationId,
          CustomerId: CustomerId,
        };
        setLoader(true);
        const response = await AxiosInstance.post("/requestchange", data);

        if (response.status === 200) {
          toast.success("Request sent successfully!");
          await fetchData();
        }

        handleClose();
      } catch (error) {
        console.error("Error posting request:", error);
      }finally {
        setLoader(false);
      }
    } else {
      setErrors(tempErrors);
    }
  };

  return (
    <>
      <div>
        {loader ? (
          <div className="d-flex justify-content-center align-items-center my-5 text-blue-color">
            <Circles
              height="50"
              width="50"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={loader}
              color="#063164"
            />
          </div>
        ) : (
          <div>
            <div
              className="col-9 d-flex download-sec "
              style={{
                display: "flex",
                justifyContent: "space-between",
                width:
                  quoteDetail?.status === "Approved" ||
                  quoteDetail?.status === "Request changed"
                    ? "100%"
                    : quoteDetail?.status === "Awaiting Response"
                    ? "79%"
                    : "auto",
              }}
            >
              <Button className="bg-blue-color" onClick={() => navigate(-1)}>
                <ArrowBackIcon /> Back
              </Button>{" "}
              <Button
                className="bg-blue-color"
                onClick={() => {
                  downloadPdf();
                }}
              >
                <FileDownloadOutlinedIcon /> Download Pdf
              </Button>
            </div>
            <div className="d-flex client-cliend-details">
              <div
                className="col-9"
                style={{
                  width:
                    quoteDetail?.status === "Approved" ||
                    quoteDetail?.status === "Request changed"
                      ? "100%"
                      : quoteDetail?.status === "Awaiting Response"
                      ? "79%"
                      : "auto",
                }}
              >
                <Card className="my-4" style={{ border: "1px solid #063164" }}>
                  <CardHeader
                    className="p-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "none",
                    }}
                  >
                    <p
                      className="mb-0 text-blue-color"
                      style={{ fontSize: "19px" }}
                    >
                      Quotes{" "}
                      {quoteDetail?.QuoteNumber
                        ? "#" + quoteDetail?.QuoteNumber
                        : ""}
                    </p>
                    <p
                      className="mb-0 text-blue-color"
                      style={{
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor:
                          quoteDetail?.status === "Awaiting Response"
                            ? "rgba(255, 165, 0, 0.2)"
                            : quoteDetail?.status === "Request changed"
                            ? "#FFDFF6"
                            : quoteDetail?.status === "Approved"
                            ? "rgba(88, 230, 88, 0.2)"
                            : "",
                        padding: "10px 24px 10px",
                        borderRadius: "25px",
                      }}
                    >
                      <p
                        style={{
                          backgroundColor:
                            quoteDetail?.status === "Awaiting Response"
                              ? "orange"
                              : quoteDetail?.status === "Request changed"
                              ? "#FF33C6"
                              : quoteDetail?.status === "Approved"
                              ? "#58cc58"
                              : "",
                          borderRadius: "50%",
                          padding: "6px",
                          marginRight: "10px",
                          marginBottom: 0,
                        }}
                      ></p>
                      {quoteDetail?.status}
                    </p>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex sec-sec">
                      <div
                        className="col-8 p-3"
                        style={{
                          border: "1px solid #063164",
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                        }}
                      >
                        <h5
                          className="text-blue-color"
                          style={{
                            fontSize: "20px",
                            fontWeight: 600,
                          }}
                        >
                          {quoteDetail?.FirstName} {quoteDetail?.LastName}
                        </h5>
                        <p
                          className="text-blue-color"
                          style={{ fontSize: "15px" }}
                        >
                          {quoteDetail?.location?.Address
                            ? quoteDetail?.location?.Address + "/"
                            : ""}{" "}
                          {quoteDetail?.location?.City
                            ? quoteDetail?.location?.City + ","
                            : ""}{" "}
                          {quoteDetail?.location?.State
                            ? quoteDetail?.location?.State + " - "
                            : ""}
                          {quoteDetail?.location?.Zip
                            ? quoteDetail?.location?.Zip
                            : ""}
                        </p>
                        <p
                          style={{ fontSize: "15px" }}
                          className="mb-0 text-blue-color"
                        >
                          {quoteDetail?.PhoneNumber}
                        </p>
                      </div>
                      <div
                        className="col-4 p-3"
                        style={{
                          border: "1px solid #063164",
                          borderTopRightRadius: "5px",
                          borderBottomRightRadius: "5px",
                          borderLeft: "none",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{ fontSize: "15px" }}
                          className="mb-0 text-blue-color"
                        >
                          <b>Sent on :</b>
                        </p>
                        <p
                          style={{ fontSize: "15px" }}
                          className="mb-0 text-blue-color"
                        >
                          {moment(quoteDetail?.createdAt).format("ll")}
                        </p>
                      </div>
                    </div>
                    {/* 5150 start  */}
                    <div
                      className="col-12 border-blue-color"
                      style={{
                        marginTop: "16px",
                        border: "2px solid ",
                        paddingLeft: "15px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Row>
                        <Col
                          xl="6"
                          lg="6"
                          md="6"
                          sm="12"
                          className="text-blue-color"
                          style={{
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          Product/Service
                        </Col>
                        <Col
                          xl="2"
                          lg="2"
                          md="2"
                          sm="12"
                          className="text-blue-color"
                          style={{
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          Qty.
                        </Col>
                        <Col
                          xl="2"
                          lg="2"
                          md="2"
                          sm="12"
                          className="text-blue-color"
                          style={{
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          Unit Price
                        </Col>
                        <Col
                          xl="2"
                          lg="2"
                          md="2"
                          sm="12"
                          className="text-blue-color"
                          style={{
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          Total
                        </Col>
                      </Row>
                      <div>
                        {quoteDetail?.products?.length > 0 ? (
                          quoteDetail.products.map((product, index) => (
                            <Row key={index}>
                              <Col
                                xl="6"
                                lg="6"
                                md="6"
                                sm="12"
                                className="text-blue-color"
                                style={{
                                  padding: "10px",
                                  border: "none",
                                }}
                              >
                                <h5>{product.Name}</h5>
                                {product.Description}
                              </Col>
                              <Col
                                xl="2"
                                lg="2"
                                md="2"
                                sm="12"
                                className="text-blue-color"
                                style={{
                                  padding: "10px",
                                  border: "none",
                                }}
                              >
                                {product?.Unit
                                  ? product?.Unit
                                  : product?.Square
                                  ? product?.Square
                                  : product?.Hour}
                              </Col>
                              <Col
                                xl="2"
                                lg="2"
                                md="2"
                                sm="12"
                                className="text-blue-color"
                                style={{
                                  padding: "10px",
                                  border: "none",
                                }}
                              >
                                {product?.CostPerHour
                                  ? Number(product.CostPerHour).toFixed(2)
                                  : product?.CostPerSquare
                                  ? Number(product.CostPerSquare).toFixed(2)
                                  : Number(product?.CostPerUnit).toFixed(2)}
                              </Col>
                              <Col
                                xl="2"
                                lg="2"
                                md="2"
                                sm="12"
                                className="text-blue-color"
                                style={{
                                  padding: "10px",
                                  border: "none",
                                }}
                              >
                                $
                                {Number(product?.Total)
                                  ? Number(product.Total).toFixed(2)
                                  : ""}
                              </Col>
                            </Row>
                          ))
                        ) : (
                          <Row>
                            <Col colSpan="4">No products available.</Col>
                          </Row>
                        )}
                      </div>
                    </div>
                    {/* 5150 end  */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "15px",
                      }}
                    >
                      <div
                        className="col-3"
                        style={{
                          border: "1px solid #063164",
                          borderRadius: "5px",
                          padding: "15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-0 text-blue-color">
                            <b>Sub Total</b>
                          </p>
                          <p className="mb-0 text-blue-color">
                            <b>
                              {quoteDetail?.SubTotal
                                ? "$" + quoteDetail?.SubTotal
                                : ""}
                            </b>
                          </p>
                        </div>
                        {quoteDetail?.Discount ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="mb-0 text-blue-color">
                              <b>Discount</b>
                            </p>
                            <p className="mb-0 text-blue-color">
                              <b>
                                -{" "}
                                {quoteDetail?.Discount
                                  ? quoteDetail?.Discount + "%"
                                  : ""}
                              </b>
                            </p>
                          </div>
                        ) : null}
                        {quoteDetail?.Tax ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="mb-0 text-blue-color">
                              <b>Tax</b>
                            </p>
                            <p className="mb-0 text-blue-color">
                              <b>
                                +{" "}
                                {quoteDetail?.Tax ? quoteDetail?.Tax + "%" : ""}
                              </b>
                            </p>
                          </div>
                        ) : null}
                        <hr className="my-1 mb-1 text-blue-color bg-blue-color" />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-0 text-blue-color">
                            <b>Total</b>
                          </p>
                          <p className="mb-0 text-blue-color">
                            <b>
                              {quoteDetail?.Total
                                ? "$" + quoteDetail?.Total
                                : ""}
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="my-5">
                      <p
                        className="mb-0 text-blue-color"
                        style={{ fontWeight: 600 }}
                      >
                        {quoteDetail?.CustomerMessage}
                      </p>
                      <p className="mb-0 text-blue-color">
                        {quoteDetail?.ContractDisclaimer}
                      </p>
                    </div>
                    {quoteDetail?.signature ? (
                      <div className="col-7 d-flex align-items-center">
                        <div className="col-8 text-center">
                          <img
                            src={`${cdnUrl}/upload/` + quoteDetail?.signature}
                            style={{ border: "none", height: "80px" }}
                          />
                          <hr />
                          <p> Signature </p>
                        </div>
                        <div
                          className="col-4 text-center mx-3"
                          style={{ marginTop: "34px" }}
                        >
                          <p>
                            {" "}
                            {moment(quoteDetail?.approvedAt).format("ll")}{" "}
                          </p>
                          <hr />
                          <p className="mb-0"> Date </p>
                        </div>
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
              </div>

              {quoteDetail?.status === "Awaiting Response" ? (
                <div className="col-3 my-4 mx-2">
                  <Card style={{ height: "100%", border: "1px solid #063164" }}>
                    <CardHeader
                      className="text-center text-blue-color"
                      style={{
                        fontSize: "24px",
                        fontWeight: 700,
                        borderBottom: 0,
                      }}
                    >
                      Quote Total{" "}
                      {quoteDetail?.Total ? "$" + quoteDetail?.Total : ""}
                    </CardHeader>
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          style={{
                            backgroundColor: "#07CF10",
                            border: "none",
                            marginBottom: "10px",
                            width: "70%",
                          }}
                          onClick={handleApprove}
                          disabled={approving}
                        >
                          {approving ? "Approving..." : "Approve"}
                        </Button>

                        {/* <Button
                          className="outline-button-blue-color text-blue-color outline"
                          outline
                          style={{
                            marginBottom: "10px",
                            width: "70%",
                          }}
                          onClick={togglee}
                        >
                          Request Changes
                        </Button> */}
                        <Button
                          className="outline-button-blue-color text-blue-color outline"
                          outline
                          style={{
                            marginBottom: "10px",
                            width: "70%",
                          }}
                          onClick={handleClickOpen} // Open dialog on click
                        >
                          Request Changes
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ) : null}
            </div>
          </div>
        )}

        {/* <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Request Changes</DialogTitle>
          <DialogContent>
            <InputText
              // value={formik?.values?.Address}
              // onChange={formik?.handleChange}
              // onBlur={formik?.handleBlur}
              // error={
              //   formik?.touched?.Address && Boolean(formik?.errors?.Address)
              // }
              // helperText={formik?.touched?.Address && formik?.errors?.Address}
              type="text"
              name="Address"
              label="Enter Request Message here..."
              multiline
              rows={2}
              className="text-blue-color w-100 mt-4"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} outline className="outline">
              Cancel
            </Button>
            <Button onClick={handleClose} className="bg-blue-color">
              Save Request
            </Button>
          </DialogActions>
        </Dialog> */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Request Changes</DialogTitle>
          <DialogContent>
            <InputText
              value={RequestMessage}
              onChange={handleChange}
              type="text"
              name="RequestMessage"
              label="Enter Request Message here..."
              multiline
              rows={2}
              className="text-blue-color w-100 mt-4"
              error={Boolean(errors.RequestMessage)}
              helperText={errors.RequestMessage}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} outline className="outline">
              Cancel
            </Button>
            {loader ? (
              <div className="d-flex justify-content-center">
                <Circles
                  height="10"
                  width="20"
                  color="#063164"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  visible={loader}
                />
              </div>
            ) : (
              <Button onClick={handleSaveRequest} className="bg-blue-color">
                Save Request
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Toaster />
      </div>
    </>
  );
}

export default Quotesdetail;
