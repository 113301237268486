import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import { generateInvoicePDF } from "../../../components/Files/InvoicePdf";
import AxiosInstance from "../../AxiosInstance";
import InvoiceDetail from "./Views/InvoiceDetail";
import toast, { Toaster } from "react-hot-toast";
import sendToast from "../../../components/Toast/sendToast";

const options = { year: "numeric", month: "short", day: "numeric" };

function InvoiceDetails() {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const [invoicedata, setInvoicedata] = useState({});
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const [mail, setMail] = useState(false);

  let fetchData = async () => {
    try {
      const res = await AxiosInstance.get(
        `/invoice/invoice_detail/${location.state.id}`
      );
      setInvoicedata(res.data.data);
    } catch (error) {
      console.error("Error: ", error.messae);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleCopy = () => {
    const fullName = `${invoicedata?.customer?.FirstName?.charAt(
      0
    )?.toUpperCase()}${invoicedata?.customer?.FirstName?.slice(
      1
    )} ${invoicedata?.customer?.LastName?.charAt(
      0
    )?.toUpperCase()}${invoicedata?.customer?.LastName?.slice(1)}`;
    navigator.clipboard.writeText(fullName);
  };

  const handleEditClick = () => {
    navigate(`/${companyName}/addinvoice`, {
      state: {
        invoiceId: location.state.id,
        navigats: [...location.state.navigats, "/addinvoice"],
      },
    });
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const sendMail = async () => {
    try {
      const mailRes = await AxiosInstance.post(
        `/invoice-payment/send_mail/${location.state.id}`
      );
      if (mailRes.data.statusCode === 200) {
        toast.success(mailRes.data.message);
      } else {
        sendToast(mailRes.data.message);
      }
    } catch (error) {
      sendToast(error.response.data.error);
    }
  };

  return (
    <>
      <InvoiceDetail
        loader={loader}
        companyName={companyName}
        location={location}
        invoicedata={invoicedata}
        setMail={setMail}
        handleEditClick={handleEditClick}
        dropdownOpen={dropdownOpen}
        toggle={toggle}
        generateInvoicePDF={generateInvoicePDF}
        handleCopy={handleCopy}
        open={open}
        setOpen={setOpen}
        file={file}
        mail={mail}
        options={options}
        navigate={navigate}
        sendMail={sendMail}
      />
      <Toaster />
    </>
  );
}

export default InvoiceDetails;
