import React, { useEffect, useState } from "react";
import { handleAuth } from "../../components/Login/Auth";
import { useLocation, useNavigate } from "react-router-dom";

const ClientDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const data = await handleAuth(navigate, location);
      setData(data.data);
    };
    fetchData();
  }, []);

  const currentHour = new Date().getHours();

  let welcomeMessage;
  if (currentHour < 12) {
    welcomeMessage = "Good Morning";
  } else if (currentHour < 18) {
    welcomeMessage = "Good Afternoon";
  } else {
    welcomeMessage = "Good Evening";
  }

  return (
    <div>
      <div className="px-0">
        <h4
          className="px-1"
          style={{
            fontSize: "35px",
            fontWeight: "700",
            fontFamily: "Poppins",
            lineHeight: "28.8px",
            color: "#063164 ",
          }}
        >
          {welcomeMessage}, {data?.full_name}
        </h4>
      </div>
    </div>
  );
};

export default ClientDashboard;
