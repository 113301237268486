import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Autocomplete,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { Button, Col, Input } from "reactstrap";
import { Country, State, City } from "country-state-city";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../components/Contract Component/style.css";
import AxiosInstance from "../../Views/AxiosInstance";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import InputText from "../InputFields/InputText";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputDropdown from "../InputFields/InputDropdown";
import dayjs from "dayjs";
import { handleAuth } from "../Login/Auth";
import { useLocation, useNavigate } from "react-router-dom";

//2529 CompanyId props pass
const TimeEmpty = ({
  open,
  setOpen,
  data,
  ContractId,
  fetchData,
  CompanyId,
  LabourId,
  setLabourId,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const [selectedPerson, setSelectedPerson] = useState(null);
  let fetchLabourData = async () => {
    try {
      if (!LabourId || !ContractId) {
        console.error("LabourId or ContractId is missing!");
        return; 
      }
      const labourRes = await AxiosInstance.get(
        `/labour/labours/${LabourId}/${ContractId}`
      );
      formik.setValues({
        Notes: labourRes.data.data.Notes,
        Hours: labourRes.data.data.Hours,
        Minutes: labourRes.data.data.Minutes,
        LabourCost: labourRes.data.data.LabourCost,
        StartTime: labourRes.data.data.StartTime,
        DatePicker: labourRes.data.data.DatePicker,
        AssignPersonId: labourRes.data.data.AssignPersonId,
      });
      const person = teamData.find(
        (teamMember) => teamMember.code === labourRes.data.data.AssignPersonId
      );
      setSelectedPerson(person || null);
    } catch (error) {
      console.error("Error: ", error.messae);
    }
  };
  useEffect(() => {
    fetchLabourData();
  }, [LabourId]);

  const formik = useFormik({
    initialValues: {
      CompanyId: "",
      Address: "",
      AssignPersonId: "",
      City: "",
      State: "",
      Zip: "",
      Team: "",
      ContractId: "",
      StartTime: null,
      EndTime: null,
      Hours: "",
      Minutes: "",
      Notes: "",
      DatePicker: dayjs().toDate(),
      LabourCost: "",
      TotalCost: "0.00",
    },
    onSubmit: async (values) => {
      if (!LabourId) {
        try {
          values["CompanyId"] = CompanyId;
          values["ContractId"] = ContractId;

          const response = await AxiosInstance.post(
            `${baseUrl}/labour`,
            values
          );
          if (response.data.statusCode === 200) {
            toast.success(response.data.message);
            setOpen(false);
            fetchData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error("An error occurred while submitting the form.");
        }
      } else {
        try {
          values["CompanyId"] = CompanyId;
          values["ContractId"] = ContractId;
          const labourId = values.LabourId;
          const response = await AxiosInstance.put(
            `/labour/${LabourId}/${ContractId}`,
            values
          );
          if (response.data.statusCode === 200) {
            toast.success(response.data.message);
            setOpen(false);
            fetchData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error("An error occurred while submitting the form.");
        }
      }
      
      formik.resetForm();
      setLabourId("");
      setSelectedPerson(null);
      setOpen({ isOpen: false, propertyData: null });
    },
  });

  useEffect(() => {
    if (open?.propertyData) {
      formik.setValues(open?.propertyData);
    } else {
      formik.resetForm();
    }
  }, [open?.propertyData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "Country") {
      formik.setFieldValue(name, value.name);
    } else {
      formik.setFieldValue(name, type === "checkbox" ? checked : value);
    }
  };

  const [teamData, setTeamData] = useState([]);
  // 2529 CompanyId create
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AxiosInstance.get(
          `/contract/assign_team/${CompanyId}`
        );
        if (response.status === 200) {
          const teamDataOptions = response.data.data.map((item) => ({
            name: item?.FullName,
            code: item?.AssignPersonId,
          }));
          setTeamData(teamDataOptions);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [CompanyId]);
  // 2529 CompanyId create

  const calculateTimeDifference = (start, end) => {
    if (!start || !end) return { hours: "", minutes: "" };

    const startDate = dayjs(start);
    const endDate = dayjs(end);

    const diff = endDate.diff(startDate);
    const diffInHours = Math.floor(diff / (1000 * 60 * 60));
    const diffInMinutes = Math.floor((diff / (1000 * 60)) % 60);

    return {
      hours: diffInHours.toString(),
      minutes: diffInMinutes.toString(),
    };
  };

  useEffect(() => {
    if (formik.values.StartTime && formik.values.EndTime) {
      const { hours, minutes } = calculateTimeDifference(
        formik.values.StartTime,
        formik.values.EndTime
      );
      formik.setFieldValue("Hours", hours);
      formik.setFieldValue("Minutes", minutes);
    }
  }, [formik.values.StartTime, formik.values.EndTime]);

  useEffect(() => {
    if (
      formik.values.Hours !== "" &&
      formik.values.Minutes !== "" &&
      formik.values.StartTime
    ) {
      const startTime = dayjs(formik.values.StartTime);
      const newEndTime = startTime
        .add(Number(formik.values.Hours), "hour")
        .add(Number(formik.values.Minutes), "minute")
        .toISOString();

      formik.setFieldValue("EndTime", newEndTime);
    }
  }, [formik.values.Hours, formik.values.Minutes, formik.values.StartTime]);

  useEffect(() => {
    const calculateTotalCost = () => {
      const hours = parseFloat(formik.values.Hours) || 0;
      const minutes = parseFloat(formik.values.Minutes) || 0;
      const labourCost = parseFloat(formik.values.LabourCost) || 0;

      const totalHours = hours + minutes / 60;
      const totalCost = (totalHours * labourCost).toFixed(2);

      formik.setFieldValue("TotalCost", totalCost);
    };

    calculateTotalCost();
  }, [formik.values.Hours, formik.values.Minutes, formik.values.LabourCost]);

  return (
    <Dialog
      open={open?.isOpen}
      onClose={() => {
        setSelectedPerson(null);
        formik.resetForm(); // Reset form values to initial state
        setOpen({ isOpen: false, propertyData: null });
      }}
      className="client"
    >
      <DialogTitle>
        <div className="w-100 d-flex justify-content-start align-items-center">
          <h4
            className="text-blue-color text-property"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "42px",
              margin: "0 10px",
            }}
          >
            New Time Entry
          </h4>
        </div>
      </DialogTitle>
      <Divider
        style={{ height: "1px", backgroundColor: "rgba(42, 79, 97, 0.8)" }}
      />
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex gap-1">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label="Start Time"
                  {...formik.getFieldProps("StartTime")}
                  value={
                    formik.values.StartTime &&
                    dayjs(formik.values.StartTime).isValid()
                      ? dayjs(formik.values.StartTime)
                      : null
                  }
                  onChange={(value) =>
                    formik.setFieldValue(
                      "StartTime",
                      value && dayjs(value).isValid()
                        ? value.toISOString()
                        : null
                    )
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.StartTime && Boolean(formik.errors.StartTime)
                  }
                  helperText={
                    formik.touched.StartTime && formik.errors.StartTime
                  }
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiInputBase-input": {
                      color: "#063164",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#063164",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#063164",
                    },
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label="End Time"
                  {...formik.getFieldProps("EndTime")}
                  value={
                    formik.values.EndTime &&
                    dayjs(formik.values.EndTime).isValid()
                      ? dayjs(formik.values.EndTime)
                      : null
                  }
                  onChange={(value) =>
                    formik.setFieldValue(
                      "EndTime",
                      value && dayjs(value).isValid()
                        ? value.toISOString()
                        : null
                    )
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.EndTime && Boolean(formik.errors.EndTime)
                  }
                  helperText={formik.touched.EndTime && formik.errors.EndTime}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiInputBase-input": {
                      color: "#063164",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#063164",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#063164",
                    },
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className="d-flex gap-1 my-2 col-12">
            <div className="col-6" style={{ width: "49.5%" }}>
              <InputText
                value={formik.values?.Hours}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.Hours && Boolean(formik.errors.Hours)}
                helperText={formik.touched.Hours && formik.errors.Hours}
                name="Hours"
                placeholder="Enter Hours here..."
                label="Hours"
                type="text"
                className="text-blue-color w-100 mb-2 mx-0"
                fieldHeight="56px"
              />
            </div>
            <div className="col-6" style={{ width: "49.5%" }}>
              <InputText
                value={formik.values?.Minutes}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.Minutes && Boolean(formik.errors.Minutes)}
                helperText={formik.touched.Minutes && formik.errors.Minutes}
                name="Minutes"
                placeholder="Enter Minutes here..."
                label="Minutes"
                type="text"
                className="text-blue-color w-100 mb-2 mx-0"
                fieldHeight="56px"
              />
            </div>
          </div>
          <div className="col-12">
            <InputText
              value={formik.values?.Notes}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.Notes && Boolean(formik.errors.Notes)}
              helperText={formik.touched.Notes && formik.errors.Notes}
              name="Notes"
              id="Notes"
              placeholder="Enter Notes here..."
              label="Notes"
              type="text"
              className="text-blue-color w-100"
              fieldHeight="56px"
            />
          </div>
          <div className="col-12 time-compo my-2">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Basic date picker"
                  value={
                    formik.values.DatePicker
                      ? dayjs(formik.values.DatePicker)
                      : null
                  }
                  onChange={(value) =>
                    formik.setFieldValue(
                      "DatePicker",
                      value ? value.toDate() : null
                    )
                  }
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        formik.touched.DatePicker &&
                        Boolean(formik.errors.DatePicker)
                      }
                      helperText={
                        formik.touched.DatePicker && formik.errors.DatePicker
                      }
                    />
                  )}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiInputBase-input": {
                      color: "#063164",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#063164",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#063164",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className="d-flex gap-1 my-2 col-12">
            <div className="col-6" style={{ width: "49.5%" }}>
              <FormControl fullWidth>
                <InputDropdown
                  onChange={(_, newValue) => {
                    const selectedPersonId = newValue ? newValue.code : "";
                    formik.setFieldValue("AssignPersonId", selectedPersonId);
                    setSelectedPerson(newValue);
                  }}
                  textFieldProps={formik.getFieldProps("AssignPersonId")}
                  options={teamData}
                  value={selectedPerson || null}
                  inputValue={selectedPerson ? selectedPerson.name : ""}
                  onTextFieldChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  getOptionLabel={(option) => option.name || ""}
                  error={
                    formik.touched.AssignPersonId &&
                    Boolean(formik.errors.AssignPersonId)
                  }
                  helperText={
                    formik.touched.AssignPersonId &&
                    formik.errors.AssignPersonId
                  }
                  filterOptions={(options, state) => {
                    return options?.filter((option) =>
                      option?.name
                        ?.toLowerCase()
                        ?.includes(state?.inputValue?.toLowerCase() || "")
                    );
                  }}
                  name="Employee"
                  label="Employee"
                  type="text"
                />
              </FormControl>
            </div>
            <div className="col-6" style={{ width: "49.5%" }}>
              <InputText
                value={formik.values?.LabourCost}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.LabourCost && Boolean(formik.errors.LabourCost)
                }
                helperText={
                  formik.touched.LabourCost && formik.errors.LabourCost
                }
                name="LabourCost"
                label="Labour Cost"
                type="text"
                placeholder="$0.00"
                className=" w-100 m-0 mb-3 "
                fieldHeight="56px"
                endAdornment={
                  <InputAdornment position="end">
                    <span style={{ color: "#063164", fontSize: "12px" }}>
                      per hour
                    </span>
                  </InputAdornment>
                }
              />
            </div>
          </div>
          <i>
            <p
              className="mb-0"
              style={{ color: "rgba(6, 49, 100, 70%)", fontSize: "14px" }}
            >
              Total Cost : ${formik.values.TotalCost}
            </p>
          </i>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          className="cancelButton"
          style={{
            backgroundColor: "#fff",
            border: "1px solid rgba(6, 49, 100, 0.8)",
            color: "rgba(6, 49, 100, 1)",
          }}
          onClick={() => {
            formik.resetForm();
            setLabourId("");
            setSelectedPerson(null);
            setOpen({ isOpen: false, propertyData: null });
          }}
        >
          Cancel
        </Button>
        <Button
          className="bg-button-blue-color createButton"
          style={{ color: "#fff" }}
          onClick={() => {
            formik.handleSubmit();
            // formik.resetForm();
            setSelectedPerson(null);
          }}
        >
          Save Time Entry
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeEmpty;
