import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import AxiosInstance from "../../AxiosInstance";

const MailModel = ({ setIsOpen, isOpen, item }) => {
  const [mailConfigurations, setMailConfigurations] = useState([]);

  useEffect(() => {
    AxiosInstance.get("/mailconfiguration")
      .then((response) => {
        if (response.data.statusCode === 200) {
          setMailConfigurations(response.data.result);
        } else {
          console.error("Failed to fetch data");
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  const [selectedConfigId, setSelectedConfigId] = useState(null);
  // const handleRadioChange = (configId) => {
  //   setSelectedConfigId(configId);
  // };

  const handleSave = async () => {
    if (!selectedConfigId || !item.companyId) {
      console.error(
        "MailConfigurationId or CompanyId is missing from local storage"
      );
      return;
    }

    const postData = {
      MailConfigurationId: selectedConfigId,
      CompanyId: item.companyId,
      IsSelected: selectedConfigId ? true : false,
      SelectedConfigId: selectedConfigId,
    };

    try {
      const response = await AxiosInstance.post("/companymail", postData);
      if (response.data.statuscode === 201) {
        setIsOpen(false);
      }
    } catch (error) {
      console.error(
        "Error posting data:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsOpen(false);
    }
  };
  const handleCloseModal = () => {
    setIsOpen(false);
    setSelectedConfigId(null);
  };

  const handleRadioChange = (configId) => {
    if (selectedConfigId === configId) {
      setSelectedConfigId(null);
    } else {
      setSelectedConfigId(configId);
    }
  };

  return (
    <>
      {/* <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <Row
          className="mx-2  d-flex align-items-center py-1"
          style={{
            borderRadius: "10px",
            height: "auto",
          }}
        >
          <Col>
            <Row className="mb-4 mt-3 d-flex  ">
              <Col className="col-8 head-text" style={{ fontSize: "25px" }}>
                Change Mail Configuration
              </Col>
              <Col
                className=" col-4 close-btn text-end"
                style={{ fontSize: "32px", cursor: "pointer" }}
                onClick={() => setIsOpen(false)}
              >
                &times;
              </Col>
            </Row>
            <hr
              style={{
                width: "100%",
                padding: "0",
                border: "none",
                borderTop: "2px solid rgba(6, 49, 100, 0.2)",
              }}
            />
            <Row>
              <Col lg="1" md="1" sm="2" xs="3"></Col>
              <Col lg="11" md="11" sm="10" xs="9">
                <Row
                  className=" mx-0 d-flex align-items-center header-text"
                  style={{
                    border: "2px solid rgba(50, 69, 103, 1)",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    height: "45px",
                    fontSize: "14px",
                    fontFamily: "poppins",
                    fontWeight: "600",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    backgroundColor: "rgba(6, 49, 100, 1)",
                    color: "white",
                  }}
                >
                  <Col>Host</Col>
                  <Col>Port </Col>
                  <Col>Email</Col>
                </Row>
              </Col>
            </Row>
            {mailConfigurations.map((config) => (
              <Row key={config._id}>
                <Col lg="1" md="1" sm="2" xs="3">
                  <input
                    className="mt-4"
                    type="radio"
                    name="mail-config"
                    checked={selectedConfigId === config._id}
                    onChange={() => handleRadioChange(config._id)}
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </Col>

                <Col lg="11" md="11" sm="10" xs="9">
                  <Row
                    className="mx-0 mt-3"
                    style={{
                      border: "0.5px solid rgba(50, 69, 103, 1)",
                      borderRadius: "10px",
                      overflow: "hidden",
                      fontSize: "16px",
                      fontWeight: "600",
                      cursor: "pointer",
                      fontFamily: "Poppins",
                      alignItems: "center",
                    }}
                  >
                    <Col
                      className="col-4 "
                      style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                    >
                      {config.Host}
                    </Col>
                    <Col className="col-4 ">{config.Port}</Col>
                    <Col
                      className="col-4 "
                      style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                    >
                      {config.User}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
            <Row className="mt-4 text-end mb-4">
              <Col>
                <Button
                  color="secondary"
                  className="text-blue-color  me-3"
                  style={{
                    background: "none",
                    color: "#063164",
                    borderColor: "#063164",
                  }}
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="save-client-button bg-blue-color"
                  style={{ fontSize: "16px" }}
                  onClick={handleSave}
                  onChange={() => setIsOpen(false)}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Dialog> */}

      <Dialog open={isOpen} onClose={handleCloseModal}>
        <Row
          className="mx-2  d-flex align-items-center py-1"
          style={{
            borderRadius: "10px",
            height: "auto",
          }}
        >
          <Col>
            <Row className="mb-4 mt-3 d-flex  ">
              <Col className="col-8 head-text" style={{ fontSize: "25px" }}>
                Change Mail Configuration
              </Col>
              <Col
                className=" col-4 close-btn text-end"
                style={{ fontSize: "32px", cursor: "pointer" }}
                onClick={handleCloseModal}
              >
                &times;
              </Col>
            </Row>
            <hr
              style={{
                width: "100%",
                padding: "0",
                border: "none",
                borderTop: "2px solid rgba(6, 49, 100, 0.2)",
              }}
            />
            <Row>
              <Col lg="1" md="1" sm="2" xs="3"></Col>
              <Col lg="11" md="11" sm="10" xs="9">
                <Row
                  className=" mx-0 d-flex align-items-center header-text"
                  style={{
                    border: "2px solid rgba(50, 69, 103, 1)",
                    borderRadius: "10px",
                    height: "45px",
                    fontSize: "14px",
                    fontFamily: "poppins",
                    fontWeight: "600",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    backgroundColor: "rgba(6, 49, 100, 1)",
                    color: "white",
                  }}
                >
                  <Col>Host</Col>
                  <Col>Port </Col>
                  <Col>Email</Col>
                </Row>
              </Col>
            </Row>
            {/* {mailConfigurations.map((config) => (
              <Row key={config._id}>
                <Col lg="1" md="1" sm="2" xs="3">
                  <input
                    className="mt-4"
                    type="radio"
                    name="mail-config"
                    checked={selectedConfigId === config._id}
                    onChange={() => handleRadioChange(config._id)}
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </Col>

                <Col lg="11" md="11" sm="10" xs="9">
                  <Row
                    className="mx-0 mt-3"
                    style={{
                      border: "0.5px solid rgba(50, 69, 103, 1)",
                      borderRadius: "10px",
                      overflow: "hidden",
                      fontSize: "16px",
                      fontWeight: "600",
                      cursor: "pointer",
                      height: "40px",
                      fontFamily: "Poppins",
                      alignItems: "center",
                    }}
                  >
                    <Col>{config.Host}</Col>
                    <Col>{config.Port}</Col>
                    <Col>{config.User}</Col>
                  </Row>
                </Col>
              </Row>
            ))} */}
            {mailConfigurations.map((config) => (
              <Row key={config._id}>
                <Col lg="1" md="1" sm="2" xs="3">
                  <input
                    className="mt-4"
                    type="radio"
                    name="mail-config"
                    checked={selectedConfigId === config._id}
                    onChange={() => handleRadioChange(config._id)}
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </Col>

                <Col lg="11" md="11" sm="10" xs="9">
                  <Row
                    className="mx-0 mt-3"
                    style={{
                      border: "0.5px solid rgba(50, 69, 103, 1)",
                      borderRadius: "10px",
                      overflow: "hidden",
                      fontSize: "16px",
                      fontWeight: "600",
                      cursor: "pointer",
                      height: "auto",
                      fontFamily: "Poppins",
                      alignItems: "center",
                    }}
                  >
                    <Col
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        maxWidth: "150px",
                      }}
                    >
                      {config.Host}
                    </Col>
                    <Col
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        maxWidth: "150px",
                      }}
                    >
                      {config.Port}
                    </Col>
                    <Col
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        maxWidth: "150px",
                      }}
                    >
                      {config.User}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}

            <Col className="text-end my-2">
              <Button
                color="secondary"
                className="text-blue-color  me-2"
                style={{
                  background: "none",
                  color: "#063164",
                  borderColor: "#063164",
                }}
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                className="save-client-button bg-blue-color"
                style={{ fontSize: "16px" }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Col>
          </Col>
        </Row>
      </Dialog>
    </>
  );
};

export default MailModel;
