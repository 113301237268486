// import React from "react";
// import "./style.css";

// const ActiveUsers = ({ count, total }) => {
//   return (
//     <div className="active-users d-flex justify-content-between align-items-center" >
//       <div>
//         <p
//           style={{ textAlign: "center", marginTop: "18px", fontWeight: "500" }}
//         >
//           {" "}
//           Active Users
//         </p>
//       </div>
//       <div className="gap-4">
//         <span className="count" style={{ fontWeight: "500" }}>
//           3
//         </span>{" "}
//         of{" "}
//         <span className="total" style={{ fontWeight: "500" }}>
//           2
//         </span>
//       </div>
//     </div>
//   );
// };

// export default ActiveUsers;

import React, { useState, useEffect } from "react";
import "./style.css";

const ActiveUsers = ({ totalCount, activeCount }) => {
  return (
    <div className="active-users d-flex justify-content-between align-items-center">
      <div>
        <p
          style={{ textAlign: "center", marginTop: "18px", fontWeight: "500" }}
        >
          Active Users
        </p>
      </div>
      <div className="gap-4">
        <span className="count" style={{ fontWeight: "500" }}>
          {activeCount}
        </span>{" "}
        of{" "}
        <span className="total" style={{ fontWeight: "500" }}>
          {totalCount}
        </span>
      </div>
    </div>
  );
};

export default ActiveUsers;
