import React, { useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import WhiteCompany from "../../assets/White-sidebar-icon/Superadmin-Company.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { BarChart, Bar, XAxis, ResponsiveContainer } from "recharts";
import {
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import SuperadminGraphs from "./SuperadminGraphs";
const PlanCard = () => {
  return (
    <>
      <div>
        <div className="d-flex gap-3">
          <Paper
            elevation={3}
            sx={{
              width: "30%",
              height: "195px",
              borderRadius: "12px",
              backgroundColor: "#063164",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "bold" }}
            >
              Plan
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#063164",
                  fontSize: "18px",
                  background: "#fff",
                  borderRadius: "10px",
                  padding: "5px 8px",
                }}
              >
                Total : 500
              </Typography>
              <DescriptionIcon sx={{ color: "#ffffff", fontSize: "60px" }} />
            </Box>
          </Paper>
          <Paper
            className="bg-orange-color"
            elevation={3}
            sx={{
              width: "30%",
              height: "195px",
              borderRadius: "12px",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography
                variant="h6"
                sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "bold" }}
              >
                Company
              </Typography>
              <p className="text-white-color mb-0" style={{ opacity: "70%" }}>
                Active Company : 500
              </p>
              <p className="text-white-color mb-0" style={{ opacity: "70%" }}>
                In Active Company : 500
              </p>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#063164",
                    fontSize: "18px",
                    background: "#fff",
                    borderRadius: "10px",
                    padding: "5px 8px",
                    marginBottom: "1px",
                  }}
                >
                  Total : 1000
                </Typography>
                <img
                  src={WhiteCompany}
                  sx={{ color: "#ffffff", fontSize: "60px" }}
                />
              </Box>
            </div>
          </Paper>
        </div>
        <SuperadminGraphs />
      </div>
    </>
  );
};

export default PlanCard;
