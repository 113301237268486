import React, { useEffect, useState, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
  Box,
  Popover,
} from "@mui/material";
import AxiosInstance from "../../../AxiosInstance";
import { Watch } from "react-loader-spinner";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Customer from "../../../../assets/Blue-sidebar-icon/Customer.svg";
import Quote from "../../../../assets/Blue-sidebar-icon/Quote.svg";
import Contract from "../../../../assets/Blue-sidebar-icon/Contract.svg";
import Invoice from "../../../../assets/Blue-sidebar-icon/Invoice.svg";

const localizer = momentLocalizer(moment);

function Schedual() {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();

  const calendarRef = useRef(null);

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [clickedDate, setClickedDate] = useState(null);

  const fetchSheduleData = async () => {
    try {
      const res = await AxiosInstance.get(
        `${baseUrl}/quote/shedule/${localStorage.getItem("CompanyId")}`
      );
      const scheduleData = res.data.data;
      const mappedEvents = scheduleData.map((item) => ({
        id: item._id,
        QuoteId: item.QuoteId,
        Title: item.Title,
        QuoteNumber: item.QuoteNumber,
        FirstName: item.FirstName,
        LastName: item.LastName,
        Address: item.Address,
        City: item.City,
        State: item.State,
        Country: item.Country,
        Zip: item.Zip,
        start: new Date(item.sheduleDate),
        end: new Date(item.sheduleDate),
        allDay: true,
      }));
      setEvents(mappedEvents);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSheduleData();
  }, []);

  const eventPropGetter = (event) => ({
    style: {
      border: "none",
      color: "black",
      display: "block",
    },
  });

  const CustomEvent = ({ event }) => (
    <span>
      <strong>{event.Title}</strong>
    </span>
  );

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleClose = () => {
    setSelectedEvent(null);
    setAnchorPosition(null);
  };

  const handleSlotClick = ({ start }) => {
    const hasEvent = events.some((event) =>
      moment(event.start).isSame(start, "day")
    );

    if (!hasEvent) {
      setClickedDate(start);
      const { x, y } = mousePosition;
      setAnchorPosition({ top: y, left: x });
    } else {
    }
  };

  // Store the last mouse position
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseDown = (event) => {
    const { clientX, clientY } = event;
    setMousePosition({ x: clientX, y: clientY });
  };

  const handlePopoverClose = () => {
    setAnchorPosition(null);
  };

  return (
    <div>
      <div style={{ position: "relative" }} ref={calendarRef}>
        <div
          style={{
            position: "relative",
            filter: loading ? "blur(2px)" : "none",
          }}
          onMouseDown={handleMouseDown}
        >
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            selectable
            views={["month"]}
            components={{
              event: CustomEvent,
            }}
            eventPropGetter={eventPropGetter}
            onSelectEvent={handleEventClick}
            onSelectSlot={handleSlotClick}
            popup={true}
          />
        </div>
        {loading && (
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <Watch
              visible={true}
              height="50"
              width="50"
              radius="48"
              color="#063164"
              ariaLabel="watch-loading"
            />
          </div>
        )}
      </div>

      <Popover
        open={Boolean(anchorPosition)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        onClose={handlePopoverClose}
        PaperProps={{
          style: {
            position: "absolute",
            border: "1px solid #ccc",
            borderRadius: "8px",
            background: "linear-gradient(135deg, #f0f4f8, #ffffff)",
            boxShadow: "0 6px 12px rgba(0,0,0,0.2)",
          },
        }}
      >
        <Box p={2} style={{ width: "270px" }}>
          <Typography
            variant="h7"
            style={{ fontWeight: "bold", color: "#333", textAlign: "center" }}
          >{`${moment(clickedDate).format("MMMM Do YYYY")}`}</Typography>

          {/* Full-Width Divider */}
          <Divider
            style={{
              margin: "12px 0",
              backgroundColor: "#333",
              height: "1px",
              width: "100%",
            }}
          />

          {/* Options with Icons */}
          <Typography
            variant="body1"
            style={{
              cursor: "pointer",
              marginTop: "12px",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              padding: "4px",
              // color: "#007BFF",
            }}
            className="text-blue-color"
            onClick={() => {
              navigate(`/${companyName}/add-customer`, {
                state: {
                  navigats: [...location.state.navigats, "/add-customer"],
                },
              });
            }}
          >
            <span style={{ marginRight: "8px" }}>
              {" "}
              <img src={Customer} />{" "}
            </span>{" "}
            New Customer
          </Typography>

          <Typography
            variant="body1"
            style={{
              cursor: "pointer",
              marginTop: "12px",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              padding: "4px",
              // color: "#28a745",
            }}
            className="text-blue-color"
            onClick={() => {
              navigate(`/${companyName}/add-quotes`, {
                state: {
                  navigats: [...location.state.navigats, "/add-quotes"],
                },
              });
            }}
          >
            <span style={{ marginRight: "8px" }}>
              <img src={Quote} />{" "}
            </span>{" "}
            New Quotes
          </Typography>

          <Typography
            variant="body1"
            style={{
              cursor: "pointer",
              marginTop: "12px",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              padding: "4px",
              // color: "#ffc107",
            }}
            className="text-blue-color"
            onClick={() => {
              navigate(`/${companyName}/add-contract`, {
                state: {
                  navigats: [...location.state.navigats, "/add-contract"],
                  startDate: moment(clickedDate).format("YYYY-MM-DD"),
                },
              });
            }}
          >
            <span style={{ marginRight: "8px" }}>
              <img src={Contract} />
            </span>{" "}
            New Contract
          </Typography>

          <Typography
            variant="body1"
            style={{
              cursor: "pointer",
              marginTop: "12px",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              padding: "4px",
              // color: "#17a2b8",
            }}
            className="text-blue-color"
            onClick={() => {
              navigate(`/${companyName}/addinvoice`, {
                state: {
                  navigats: [...location.state.navigats, "/addinvoice"],
                },
              });
            }}
          >
            <span style={{ marginRight: "8px" }}>
              <img src={Invoice} />
            </span>{" "}
            New Invoice
          </Typography>
        </Box>
      </Popover>

      {selectedEvent && (
        <Dialog
          open={!!selectedEvent}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Reminder about quote #{selectedEvent.QuoteNumber} for{" "}
            {selectedEvent.FirstName} {selectedEvent.LastName}
          </DialogTitle>
          <DialogContent dividers>
            <FormControlLabel control={<Checkbox />} label="Completed" />
            <Divider />
            <Box my={2}>
              <Typography variant="h6">Details</Typography>
              <Typography>
                Sample Customer - Quote # {selectedEvent.QuoteNumber}
              </Typography>
              <Typography>
                Quote was sent on{" "}
                {moment(selectedEvent.start).format("MMMM Do YYYY")} but no job
                has been generated yet
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="h6">Location</Typography>
              <Typography>
                {selectedEvent?.Address ? selectedEvent?.Address + " /" : null}{" "}
                {selectedEvent?.City} / {selectedEvent?.State} /{" "}
                {selectedEvent?.Country} , {selectedEvent?.Zip}{" "}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                const quoteId = selectedEvent.QuoteId;
                navigate(`/${companyName}/add-quotes`, {
                  state: {
                    id: quoteId,
                    navigats: [...location.state.navigats, "/add-quotes"],
                  },
                });
              }}
              className="text-blue-color border-blue-color"
              style={{ border: "2px solid", textTransform: "capitalize" }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                const quoteId = selectedEvent.QuoteId;
                navigate(`/${companyName}/quotes-detail `, {
                  state: {
                    id: quoteId,
                    navigats: [...location.state.navigats, "/quotes-detail"],
                  },
                });
              }}
              className="bg-blue-color text-white-color"
              style={{ textTransform: "capitalize" }}
            >
              View Details
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default Schedual;
