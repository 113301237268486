import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import swal from "sweetalert";
import "./style.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth.js";
import moment from "moment";
import AxiosInstance from "../../AxiosInstance.js";
import ContractView from "./views/Contract.js";

const Contract = () => {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customerData, setCustomerData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);

  const getData = async () => {
    try {
      const res = await AxiosInstance.get(
        `/contract/${localStorage.getItem("CompanyId")}`,
        {
          params: {
            pageSize: rowsPerPage,
            pageNumber: page,
            search: search || "",
          },
        }
      );
      setCustomerData(res.data.data.contracts);
      setCountData(res.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search]);

  const handleEditClick = (id) => {
    if (id) {
      navigate("/" + companyName + "/add-contract", {
        state: {
          id: id,
          navigats: [...location.state.navigats, "/add-contract"],
        },
      });
    }
  };

  // Delete
  const handleDelete = (id) => {
    swal("Are you sure you want to delete?", {
      buttons: ["No", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await AxiosInstance.delete(`/contract/${id}`);
          if (response.data.statusCode === 200) {
            setTimeout(() => {
              toast.success(response.data.message);
            }, 500);
            getData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error(error);
        }
      }
    });
  };

  const cellData =
    customerData &&
    customerData.length > 0 &&
    customerData?.map((item, index) => {
      const properties = item?.properties || [];
      let propertyDisplay;
      if (properties.length === 1) {
        const property = properties[0];
        propertyDisplay = `${property.street_address1 || ""}
       ${property.street_address2 || ""}
       ${property.street_address3 || ""} ${property.city || ""} 
       ${property.state || ""} 
      ${property.country || ""} 
      ${property.zip_code || ""}`;
      } else {
        propertyDisplay = `${properties.length} ${
          properties.length > 1 ? "Properties" : "Property"
        }`;
      }

    return {
      key: item.ContractId,
      value: [
        page * rowsPerPage + index + 1,
        `${item?.customer?.FirstName} ${item?.customer?.LastName}`,
        <>
          #{item?.ContractNumber}
          <br />
          {item?.Title}
        </>,
        <div
          dangerouslySetInnerHTML={{
            __html: `${item?.location?.Address || ""} ${
              item?.location?.City || ""
            }<br>${item?.location?.State || ""} ${
              item?.location?.Country || ""
            } ${item?.location?.Zip || ""}`,
          }}
        />,
        // moment(item.OneoffJob.StartDate).format("DD/MM/YYYY"),
        item.RecuringJob?.StartDate
          ? moment(item.RecuringJob.StartDate).format("DD/MM/YYYY")
          : item.OneoffJob?.StartDate
          ? moment(item.OneoffJob.StartDate).format("DD/MM/YYYY")
          : "-",

        <b
          style={{
            color:
              item.Status === "Unscheduled"
                ? "#E88C44"
                : item.Status === "Today"
                ? "#089F57"
                : item.Status === "Upcoming"
                ? "#089F57"
                : item.Status === "Scheduled"
                ? "#C8CC00"
                : "",
          }}
        >
          {item?.Status}
        </b>,
        `${item?.Total}`,
        <>
          <img
            src={Edit}
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(item.ContractId);
            }}
          />
          <img
            className="mx-1"
            src={Delete}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item.ContractId);
            }}
          />
        </>,
      ],
    };
  });

  return (
    <>
      <ContractView
        loader={loader}
        search={search}
        setSearch={setSearch}
        cellData={cellData}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        companyName={companyName}
        countData={countData}
        rowsPerPage={rowsPerPage}
      />
      <Toaster />
    </>
  );
};

export default Contract;
