// import React, { useState, useEffect } from "react";
// import CustomerView from "../Client/Views/Customer.js";
// import toast, { Toaster } from "react-hot-toast";
// import Edit from "../../../assets/image/icons/edit.svg";
// import Delete from "../../../assets/image/icons/delete.svg";
// import swal from "sweetalert";
// import "./style.css";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import AxiosInstance from "../../AxiosInstance.js";
// import { handleAuth } from "../../../components/Login/Auth.js";

// const Customer = () => {
//   useEffect(() => {
//     handleAuth(navigate, location);
//   }, []);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { companyName } = useParams();
//   const [search, setSearch] = useState("");
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [customerData, setcustomerData] = useState([]);
//   const [loader, setLoader] = useState(true);
//   const [countData, setCountData] = useState(0);

//   const getData = async () => {
//     try {
//       const res = await AxiosInstance.get(
//         `/customer/get/${localStorage.getItem("CompanyId")}`,
//         {
//           params: {
//             pageSize: rowsPerPage,
//             pageNumber: page,
//             search: search || "",
//           },
//         }
//       );
//       if (res.data.statusCode === 200) {
//         setcustomerData(res.data.data);
//         setCountData(res.data.totalPages);
//         setLoader(false);
//       } else {
//         setLoader(false);
//         return;
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoader(false);
//     }
//   };

//   useEffect(() => {
//     getData();
//   }, [rowsPerPage, page, search]);

//   const handleEditClick = (id) => {
//     if (id) {
//       navigate("/" + companyName + "/add-customer", {
//         state: { id, navigats: [...location.state.navigats, "/add-customer"] },
//       });
//     }
//   };

//   // Delete
//   const handleDelete = (id) => {
//     swal("Are you sure you want to delete?", {
//       buttons: ["No", "Yes"],
//     }).then(async (willDelete) => {
//       if (willDelete) {
//         try {
//           const response = await AxiosInstance.delete(`/customer/${id}`);
//           if (response.data.statusCode === 200) {
//             setTimeout(() => {
//               toast.success(response.data.message);
//             }, 500);
//             getData();
//           } else {
//             toast.error(response.data.message);
//           }
//         } catch (error) {
//           console.error("Error:", error);
//           toast.error(error);
//         }
//       }
//     });
//   };

//   const cellData = customerData?.map((item, index) => {
//     const properties = item?.location || [];

//     let propertyDisplay;
//     if (properties.length === 1) {
//       const property = properties[0];
//       propertyDisplay = `${property.Address || ""}`;
//     } else {
//       propertyDisplay = `${properties.length} ${
//         properties.length > 1 ? "Properties" : "Property"
//       }`;
//     }

//     return {
//       key: item?.CustomerId,
//       value: [
//         page * rowsPerPage + index + 1,
//         `${item?.FirstName} ${item?.LastName}`,
//         item.EmailAddress,
//         properties.length === 1 ? (
//           propertyDisplay
//         ) : (
//           <span>{propertyDisplay}</span>
//         ),
//         <>
//           <img
//             src={Edit}
//             onClick={(e) => {
//               e.stopPropagation();
//               handleEditClick(item.CustomerId);
//             }}
//           />
//           <img
//             className="mx-1"
//             src={Delete}
//             onClick={(e) => {
//               e.stopPropagation();
//               handleDelete(item.CustomerId);
//             }}
//           />
//         </>,
//       ],
//     };
//   });

//   return (
//     <>
//       <CustomerView
//         loader={loader}
//         search={search}
//         setSearch={setSearch}
//         cellData={cellData}
//         page={page}
//         setPage={setPage}
//         setRowsPerPage={setRowsPerPage}
//         companyName={companyName}
//         countData={countData}
//         rowsPerPage={rowsPerPage}
//       />
//       <Toaster />
//     </>
//   );
// };

// export default Customer;

import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import swal from "sweetalert";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth.js";
import moment from "moment";
import AxiosInstance from "../../AxiosInstance.js";
import Client from "./Views/Customer.js";

const Customer = () => {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customersData, setcustomersData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);
// 5150 start 
  const getData = async () => {
    setLoader(true);
    try {
      const res = await AxiosInstance.get(
        `/customer/get/${localStorage.getItem("CompanyId")}`,
        {
          params: {
            pageSize: rowsPerPage,
            pageNumber: page,
            search: search || "",
          },
        }
      );
      setcustomersData(res.data.data);
      setCountData(res.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search]);
// 5150 end 
  const handleEditClick = (id) => {
    navigate(`/${companyName}/add-customer`, {
      state: {
        id,
        navigats: [...location.state.navigats, "/add-customer"],
      },
    });
  };

  const handleDelete = (id) => {
    swal("Are you sure you want to delete?", {
      buttons: ["No", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await AxiosInstance.delete(`/customer/${id}`);
          if (response.data.statusCode === 200) {
            setTimeout(() => {
              toast.success(response.data.message);
            }, 500);
            getData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error(error.message || "An error occurred");
        }
      }
    });
  };

  const cellData = customersData?.map((item, index) => {
    const properties = item?.location || [];

    let propertyDisplay;
    if (properties.length === 1) {
      const property = properties[0];
      propertyDisplay = `${property.Address || ""} ,${property.City || ""} ,${
        property.State || ""
      } ,${property.Country || ""},${property.Zip || ""} `;
    } else {
      propertyDisplay = `${properties.length} ${
        properties.length > 1 ? "Properties" : "Property"
      }`;
    }
    return {
      key: item.CustomerId,
      value: [
        page * rowsPerPage + index + 1,
        `${item?.FirstName} ${item?.LastName}`,
        item.EmailAddress,
        // `${item?.location?.Address || ""} ${item?.location?.City || ""} ${
        //   item?.location?.State || ""
        // } ${item?.location?.Country || ""} ${item?.location?.Zip || ""}`,
        properties.length === 1 ? (
          propertyDisplay
        ) : (
          <span>{propertyDisplay}</span>
        ),
        <>
          <img
            src={Edit}
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(item.CustomerId);
            }}
          />
          <img
            className="mx-1"
            src={Delete}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item.CustomerId);
            }}
          />
        </>,
      ],
    };
  });

  const collapseData = customersData?.map((item) => ({
    createdAt: item.createdAt || "No details provided",
  }));

  return (
    <>
      <Client
        loader={loader}
        search={search}
        setSearch={setSearch}
        cellData={cellData}
        collapseData={collapseData}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        companyName={companyName}
        countData={countData}
      />
      <Toaster />
    </>
  );
};

export default Customer;
