// =====================================================================
import React from "react";
import "./style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Divider } from "@mui/material";

function SettingSidebar() {
  const { companyName } = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <div className="setting-sidebar-main">
        <div
          className=""
          style={{
            marginRight: "10px",
            width: "12%",
            borderRight: "0.5px solid rgba(6, 49, 100, 30%)",
            height: "100vh",
          }}
        >
          <h3 style={{ color: "#063164", paddingTop: "40px" }}>Setting</h3>
          <h6 className="text-blue-color" style={{ fontWeight: 600 }}>
            BUSINESS <br /> MANAGEMENT
          </h6>
          <div className="d-flex flex-column">
            <div
              className="sidebar-link-setting"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  companyName
                    ? `/${companyName}/product&service`
                    : "/superadmin/product&service",
                  {
                    state: { navigats: ["/index", "/product&service"] },
                  }
                );
              }}
            >
              Products & Services
            </div>
            <h6
              className="text-blue-color"
              style={{ fontWeight: 600, marginTop: "16px" }}
            >
              TEAM <br /> ORGANIZATION
            </h6>
            <div
              className="sidebar-link-setting"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  companyName
                    ? `/${companyName}/manageteam`
                    : "/superadmin/manageteam",
                  {
                    state: { navigats: ["/index", "/manageteam"] },
                  }
                );
              }}
            >
              Manage Team
            </div>
            {/* 
            <h6
              className="text-blue-color"
              style={{ fontWeight: 600, marginTop: "16px" }}
            >
              CHANGE <br /> LAYOUT
            </h6>
            <div
              className="sidebar-link-setting"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  companyName
                    ? `/${companyName}/layout-table`
                    : "/superadmin/layout-table",
                  {
                    state: { navigats: ["/index", "/layout-table"] },
                  }
                );
              }}
            >
              Change Layout
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingSidebar;
